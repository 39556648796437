import type {
  EntityConfigCustomOperation,
  CustomOperationStep,
  EntityRootData,
} from '@web-config-app/core';
import { cloneDeep, get, set } from 'lodash-es';
import { injectOperationEntityMetadataRequiredFields } from '../inject-operation-entity-metadata-required-fields/inject-operation-entity-metadata-required-fields';

/**
 * Creates a custom request body for an entity operation.
 *
 * This function constructs the request body based on the provided data and
 * the operation's configuration. It maps data fields to the correct request
 * body structure, including injecting required entity metadata fields.
 *
 * **Custom Request Body Mapping:**
 * The `operation.parameterMappings.requestBody` property defines how the
 * request body should be structured.  It can be one of the following:
 *
 * - `false`:  Indicates that no request body should be sent.  The function
 *   returns `undefined` in this case.
 * - `true`:  Indicates that all data, including properties potentially changed via
 * `requiredEntityMetadataFieldValuesWithMapping` should be included in the request body.
 * - An array of objects: Each object in the array defines a mapping between a
 *   request body field and a path to the data in the input `data` or the
 *   `requiredEntityMetadataFieldValuesWithMapping` object. For example,
 * "attributes.nested.property" would create a nested "attributes" field with
 * a "nested" field containing a "property" field.
 * The keys of these objects define the fields in the request body. Dot notation is supported,
 *  enabling the creation of nested fields within the request body.
 * The values represent the paths to the corresponding data.  For example:
 *   `[{ "attributes.status": "attributes.entityMetadata.status" }, { "name": "name" }]`.
 *   If a value at the specified path does not exist in the input data, the
 *   corresponding property will be omitted from the request body.
 *
 * **Required Entity Metadata Injection:**
 * Some operations might require specific metadata fields (e.g., a "publish"
 * operation might require `attributes.entityMetadata.status` to be set to
 * "published"). The `injectOperationEntityMetadataRequiredFields` function
 * determines these required fields and their values.  This function then
 * integrates these required fields into the request body.
 *
 * @param operation The configuration for the custom operation.
 * @param data The initial entity data.
 * @returns A JSON string representing the constructed request body, or
 *   `undefined` if no request body is needed (i.e. `requestBody` is `false`).
 */

export const createCustomRequestBodyData = (
  operation: EntityConfigCustomOperation | CustomOperationStep,
  data?: EntityRootData,
  initialData?: { [key: string]: any },
) => {
  const requestMapping = operation?.parameterMappings.requestBody;

  if (requestMapping === false) return undefined;

  const requiredEntityMetadataFieldValuesWithMapping =
    injectOperationEntityMetadataRequiredFields(data, operation);

  const requestBodyObject =
    requestMapping !== true
      ? requestMapping.reduce(
          (acc, obj) => {
            const [[targetPath, sourcePath]] = Object.entries(obj);
            const retrievedValue = get(
              requiredEntityMetadataFieldValuesWithMapping,
              sourcePath,
            );

            // if value in the data doesn't exist, don't return the property
            if (retrievedValue !== undefined) {
              set(acc, targetPath, retrievedValue);
            }
            return acc;
          },
          initialData ? cloneDeep(initialData) : {},
        )
      : requiredEntityMetadataFieldValuesWithMapping;

  return JSON.stringify({
    data: requestBodyObject,
  });
};
