import { useCallback } from 'react';
import type { FormError } from '@web-config-app/validation';
import { useIntl } from '@leagueplatform/locales';
import { EntityDetailError } from '@web-config-app/core';
import { useEntityDetailsProps } from '../use-entity-details-props/use-entity-details-props';
import { findClosestNodeIdFromInstancePath } from '../../utils/find-closest-node-id-from-instance-path/find-closest-node-id-from-instance-path.util';

type FormatMessage = ReturnType<typeof useIntl>['formatMessage'];

const getDetail = ({
  error,
  formatMessage,
}: {
  error: FormError;
  formatMessage: FormatMessage;
}) => {
  if (error.message) {
    return error.propertyName
      ? `${formatMessage({ id: error.propertyName })} ${error.message}`
      : error.message;
  }
  return `${error.keyword} error: ${error.schemaPath}`;
};

/**
 * This hook constructs a ConfigError
 * uses the `error.propertyName` (if available) to build the error detail.
 *
 * @returns {function} A memoized callback function that takes an array of `FormError` objects and returns a `ConfigError` object.
 */
export const useFormatFormErrors = (): ((
  formErrors: FormError[],
) => EntityDetailError[]) => {
  const { formatMessage } = useIntl();
  const { nodeMap } = useEntityDetailsProps();

  return useCallback(
    (formErrors: FormError[]) => {
      const errors = formErrors.map((error) => ({
        detail: getDetail({ error, formatMessage }),
        path: findClosestNodeIdFromInstancePath(
          Array.from(nodeMap.keys()),
          error.instancePath,
        ),
      }));

      return errors;
    },
    [formatMessage, nodeMap],
  );
};
