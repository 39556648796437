import * as React from 'react';

export const ArrayEntityReferenceControlContext =
  React.createContext<boolean>(false);

export const ArrayEntityReferenceControlProvider = ({
  children,
}: React.PropsWithChildren) => (
  <ArrayEntityReferenceControlContext.Provider value>
    {children}
  </ArrayEntityReferenceControlContext.Provider>
);
