import { ConfigErrorType } from '../constants';

/**
 * Returns the ConfigErrorType based on the api error response - http status
 */
export const mapStatusToErrorType = (
  status: number,
): ConfigErrorType | null => {
  if (status >= 500) {
    return ConfigErrorType.ServerError;
  }
  if (status >= 400) {
    return ConfigErrorType.ClientError;
  }
  return null;
};
