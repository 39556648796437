import { createPrimitiveControlRenderer } from '../utils/create-renderer';
import { RuleTemplateJsonLogicInputControl } from '../components/controls-primitive/json-logic-input-control/rule-template-json-logic-input-control.component';

import { isRuleTemplateJsonLogicInputControl } from '../testers/control-testers';

// we export ruleTemplateJsonLogicInputControlRenderer renderer separately for use on the EntityDetails main EntityForm, and so it can be omitted from the RuleTemplateEntity form and not included in the default renderers
export const ruleTemplateJsonLogicInputControlRenderer =
  createPrimitiveControlRenderer(
    isRuleTemplateJsonLogicInputControl,
    RuleTemplateJsonLogicInputControl,
  );
