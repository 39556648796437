import * as React from 'react';
import {
  Box,
  UtilityText,
  InputStatusMessage,
  Spinner,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { Entity } from '@web-config-app/core';
import { useIntl } from '@leagueplatform/locales';
import { HintText } from '@web-config-app/core-react-ui';
import { EntitySelectValue } from './entity-select-value.component';
import { AddItemModalTriggers } from '../../../add-item-modal-triggers/add-item-modal-triggers.component';
import { AddItemByIdModal } from '../../../add-item-by-id-modal/add-item-by-id-modal.component';
import { AddEntityReferenceFromListModal } from './add-entity-reference-from-list-modal/add-entity-reference-from-list-modal.component';
import { PrimitiveControlFormFieldBanner } from '../../../primitive-control-form-field/primitive-control-form-field.component';
import type { EntityFormControlProps } from '../../../../types/controls';
import type { AddItemModalTrigger } from '../../../../types/components';
import { EntitySelectModalHeading } from './entity-select-modal-heading.component';

/**
 * Separate out the pure UI component from the functionality so that it's possible to show it in storybook
 */

interface EntitySelectControlPresenterProps
  extends Pick<
    EntityFormControlProps,
    'label' | 'hint' | 'errors' | 'inputStatus' | 'banner'
  > {
  isLoading: boolean;
  isError: boolean;
  entityReferenceData?: any;
  isAddingById: boolean;
  isAddingFromList: boolean;
  isLoadingByIdSubmission: boolean;
  isLoadingByIdError: boolean;
  openChooseFromList: () => void;
  closeChooseFromList: () => void;
  closeAddById: () => void;
  addItemTriggerList: AddItemModalTrigger[];
  entity: Entity;
  onSubmitAddById: (args: any) => void;
  onSubmitChooseFromList: (args: any) => void;
  onClearEntityReference: (args: any) => void;
  idValue: string;
  setIdValue: React.Dispatch<React.SetStateAction<string>>;
  isArrayItem?: boolean;
}

export const EntitySelectControlPresenter: React.FC<
  EntitySelectControlPresenterProps
> = ({
  label,
  hint,
  errors,
  inputStatus,
  banner,
  entityReferenceData,
  isLoading,
  isError,
  isLoadingByIdSubmission,
  isLoadingByIdError,
  addItemTriggerList,
  entity,
  onSubmitAddById,
  onSubmitChooseFromList,
  onClearEntityReference,
  isAddingById,
  isAddingFromList,
  closeAddById,
  closeChooseFromList,
  openChooseFromList,
  idValue,
  setIdValue,
  isArrayItem = false,
}) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <PrimitiveControlFormFieldBanner banner={banner}>
        <Box css={{ width: '100%' }}>
          <UtilityText
            emphasis="subtle"
            css={{ typography: '$label', marginBottom: '$quarter' }}
          >
            {label}
          </UtilityText>
          {hint && (
            <HintText css={{ paddingBottom: '$quarter' }}>{hint}</HintText>
          )}

          <div aria-live="polite">
            {!entityReferenceData && (
              <StackLayout css={{ width: '100%' }} horizontalAlignment="center">
                <Spinner loading={isLoading} />
              </StackLayout>
            )}
            {entityReferenceData && (
              <EntitySelectValue
                entity={entity}
                entityDetail={entityReferenceData}
                openChooseFromList={openChooseFromList}
                isArrayItem={isArrayItem}
                onClear={onClearEntityReference}
              />
            )}
            {isError && (
              <InputStatusMessage inputStatus="error">
                {formatMessage({ id: 'ERROR_LOADING_ENTITY_REFERENCE' })}
              </InputStatusMessage>
            )}
          </div>

          {!entityReferenceData && (
            <AddItemModalTriggers triggerList={addItemTriggerList} />
          )}
          {inputStatus && (
            <InputStatusMessage inputStatus={inputStatus}>
              {errors}
            </InputStatusMessage>
          )}
        </Box>
      </PrimitiveControlFormFieldBanner>
      <AddItemByIdModal
        headingLabel={<EntitySelectModalHeading entity={entity} />}
        open={isAddingById}
        fieldLabel={formatMessage({ id: 'ENTITY_ID' })}
        errorMessage={
          isLoadingByIdError
            ? formatMessage(
                { id: 'UNABLE_TO_FIND_ENTITY' },
                {
                  entityName: formatMessage(
                    { id: entity.nameTranslationKey },
                    { count: 1 },
                  ),
                },
              )
            : undefined
        }
        onCancel={() => closeAddById()}
        onSubmit={onSubmitAddById}
        idValue={idValue}
        setIdValue={setIdValue}
        isLoadingSubmission={isLoadingByIdSubmission}
      />
      {entity && (
        <AddEntityReferenceFromListModal
          headingLabel={<EntitySelectModalHeading entity={entity} />}
          open={isAddingFromList}
          onCancel={() => closeChooseFromList()}
          entity={entity}
          onSubmit={onSubmitChooseFromList}
        />
      )}
    </>
  );
};
