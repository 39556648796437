export const EN_TEST_USER_STRINGS = {
  TEST_USERS: '{count, plural, one {Test User} other {Test Users}}',
  USER_ATTRIBUTES_LABEL: 'User Attributes',
  TEST_USER_ENTITY_NAME: 'Test User',
  USER_NAME_LABEL: 'Username',
  USER_STATUS_LABEL: 'Status',
  USER_GENDER_LABEL: 'Gender',
  USER_TYPE_LABEL: 'Member Type',
  CLIENT_ID: 'Client ID',
  PROGRAM_ID: 'Program ID',
  PROGRAM_NAME: 'Program Name',
  ELIGIBLE_DESC: 'Eligible',
  ADD_REWARD_PROGRAM: 'Add Reward Program',
  ENROLLMENT_STATUS: 'Enrollment Status',
  USER_DATA_COUNT_LABEL: 'Total User Data',
  MEDICAL: 'Medical',
  ASSOCIATE: 'Associate',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  PURGE_USER_LABEL: 'Purge User',
  PURGE_USER_LOADING_MESSAGE: 'Purging User Details',
  PURGE_USER_SUCCESS_MESSAGE: 'User purge request has been successfully sent',
  PURGE_CONFIRMATION_TITLE: 'Purge User Data',
  PURGE_CONFIRMATION_MESSAGE: 'Are you sure you want to purge this user?',
  PURGE_CONFIRMATION_LABEL: 'Confirm Purge',
};
