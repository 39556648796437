import { useState } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { EntityFormControlProps } from '../../../../types/controls';
import { useGetAssetDataById } from '../use-get-asset-data-by-id.hook';

export const useAttachmentInputControl = ({
  handleChange,
  path,
}: Pick<EntityFormControlProps, 'handleChange' | 'path'>) => {
  const { formatMessage } = useIntl();
  const [inputValue, setInputValue] = useState('');

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isUploadingFromComputer, setIsUploadingFromComputer] = useState(false);

  const {
    isError: isGetAttachmentByIdError,
    isLoading: isLoadingAttachmentById,
    findEntityById,
  } = useGetAssetDataById(inputValue, {
    enabled: false,
    retry: 1,
  });

  const errorMessage = isGetAttachmentByIdError
    ? formatMessage({ id: 'UNABLE_TO_FIND_ATTACHMENT' })
    : undefined;

  const addItemTriggerList = [
    {
      buttonLabel: formatMessage({
        id: 'ADD_ATTACHMENT_BY_CONTENTFUL_ID',
      }),
      openFn: () => {
        setIsAddModalOpen(true);
      },
    },
    {
      buttonLabel: formatMessage({
        id: 'UPLOAD_ATTACHMENT_FROM_COMPUTER',
      }),
      openFn: () => setIsUploadingFromComputer(true),
    },
  ];

  // close + submit functions for ID modal
  const closeAddModal = () => {
    setInputValue('');
    setIsAddModalOpen(false);
  };
  const handleSubmitById = () =>
    findEntityById({
      onSuccess: () => {
        handleChange(path, inputValue);
        closeAddModal();
      },
    });

  // close + submit functions for the upload modal
  const closeUploadAttachment = () => setIsUploadingFromComputer(false);
  const handleSubmitUploadedAttachment = ({ id }: { id: string }) =>
    handleChange(path, id);

  const addItemModalProps = {
    headingLabel: formatMessage({ id: 'ADD_ATTACHMENT' }),
    fieldLabel: formatMessage({
      id: 'CONTENTFUL_ID',
    }),
    open: isAddModalOpen,
    onCancel: closeAddModal,
    idValue: inputValue,
    setIdValue: setInputValue,
    onSubmit: handleSubmitById,
    errorMessage,
    isLoadingSubmission: isLoadingAttachmentById,
  };

  const uploadAttachmentModalProps = {
    open: isUploadingFromComputer,
    onCancel: closeUploadAttachment,
    onSubmitSuccess: handleSubmitUploadedAttachment,
  };

  return {
    addItemTriggerList,
    addItemModalProps,
    uploadAttachmentModalProps,
  };
};
