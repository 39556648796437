import * as React from 'react';
import { Box, StackLayout, Divider } from '@leagueplatform/genesis-core';
import { TOP_BAR_LINEAR_GRADIENT } from '../../constants';

type EntityDetailsPageLayoutProps = {
  topBar: React.ReactNode | JSX.Element;
  sidePanel: React.ReactNode | JSX.Element;
  previewPanel?: React.ReactNode | JSX.Element;
  statusBanners?: React.ReactNode | JSX.Element;
};

const SIDE_PANEL_WIDTH = '20rem';

export const EntityDetailsPageLayout: React.FC<
  React.PropsWithChildren<EntityDetailsPageLayoutProps>
> = ({ topBar, sidePanel, statusBanners, previewPanel, children }) => {
  const topBarRef = React.useRef<HTMLDivElement>(null);

  /**
   * The top bar height is dynamic and can change with the addition of the status banner, but we need to always track the height to know where to position the side panels and main content on the page, below the static top bar
   *
   * This useEffect sets the css variable for the top-bar-height on change of the statusBanner prop
   */
  React.useLayoutEffect(() => {
    if (topBarRef.current) {
      const height = topBarRef.current.offsetHeight;

      // Set the CSS variable on the document's root element so it is available everywhere
      document.documentElement.style.setProperty(
        '--top-bar-height',
        `${height}px`,
      );
    }
  }, [statusBanners]);

  return (
    <Box
      css={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        ref={topBarRef}
        css={{
          position: 'fixed',
          top: 0,
          borderBottom:
            'solid $colors$onSurfaceBorderSubdued $borderWidths$thin',
          zIndex: '$sticky',
          background: '$surfaceBackgroundPrimary',
          width: '100%',
        }}
      >
        {topBar}
        <Box aria-live="polite">
          {statusBanners && (
            <Box css={{ maxHeight: '14rem', overflowY: 'auto' }}>
              {statusBanners}
            </Box>
          )}
        </Box>
      </Box>
      <StackLayout
        orientation="horizontal"
        spacing="$none"
        css={{ width: '100%', flexGrow: 1 }}
        verticalAlignment="stretch"
      >
        <Box
          css={{
            minWidth: SIDE_PANEL_WIDTH,
            width: SIDE_PANEL_WIDTH,
            borderRight:
              'solid $colors$onSurfaceBorderSubdued $borderWidths$thin',
            position: 'fixed',
            top: 'var(--top-bar-height)',
            height: 'calc(100% - var(--top-bar-height))',
          }}
        >
          {sidePanel}
        </Box>
        <Box
          as="main"
          css={{
            flexGrow: 1,
            paddingLeft: SIDE_PANEL_WIDTH,
            width: '100%',
            marginTop: 'var(--top-bar-height)',
          }}
        >
          {/* This is to show a very subtle white gradient as the entity form scrolls */}
          <Box
            css={{
              height: '$one',
              backgroundImage: TOP_BAR_LINEAR_GRADIENT,
              position: 'fixed',
              width: '100%',
              top: 'var(--top-bar-height)',
            }}
          />
          <Box
            css={{
              maxWidth: '45rem',
              marginY: '$two',
              marginX: '$five',
            }}
          >
            {children}
          </Box>
        </Box>
        {previewPanel && (
          <>
            <Divider orientation="vertical" />
            <Box
              css={{
                minWidth: '25rem',
                padding: '$one',
                marginTop: 'var(--top-bar-height)',
              }}
            >
              {previewPanel}
            </Box>
          </>
        )}
      </StackLayout>
    </Box>
  );
};
