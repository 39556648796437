import React, { FC } from 'react';
import { ActionMenu } from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';
import { Button, StackItem, StatusBadge } from '@leagueplatform/genesis-core';
import type {
  EntityDetailActionType,
  EnvironmentKey,
  Nullable,
  StatusBadge as StatusBadgeProps,
} from '@web-config-app/core';
import type {
  MultiEnvironmentActions,
  AvailableEnvironment,
} from '@web-config-app/core-react';
import { EnvironmentSelect } from '../environment-select/environment-select.component';

export interface EntityDetailsRightHeaderActionsProps {
  statusBadge?: StatusBadgeProps;
  menuItemGroup: {
    menuItems: {
      id: EntityDetailActionType | string;
      label: React.ReactNode;
    }[];
    onMenuItemClicked: (itemId: EntityDetailActionType | string) => void;
  };
  secondaryAction?: React.ReactNode | JSX.Element;
  primaryAction?: React.ReactNode | JSX.Element;
  entityConfigName: string;
  currentAuthoringEnvironment: EnvironmentKey;
  availableEnvironments: Nullable<AvailableEnvironment[]>;
  multiEnvironmentAuthoringEnabled: boolean;
  multiEnvironmentActions?: MultiEnvironmentActions;
  isEntitySingleEnv?: boolean;
}

export const EntityDetailsRightHeaderActions: FC<
  EntityDetailsRightHeaderActionsProps
> = ({
  entityConfigName,
  currentAuthoringEnvironment,
  availableEnvironments,
  multiEnvironmentAuthoringEnabled,
  statusBadge,
  menuItemGroup,
  secondaryAction,
  primaryAction,
  multiEnvironmentActions,
  isEntitySingleEnv,
}) => {
  const { formatMessage } = useIntl();
  const { status, label } = statusBadge ?? {};
  const showEnvironmentSelect =
    multiEnvironmentAuthoringEnabled && Array.isArray(availableEnvironments);
  const showStatusBadge = status && label && !showEnvironmentSelect;
  return (
    <StackItem
      css={{
        flexDirection: 'row',
        gap: '$threeQuarters',
        alignItems: 'center',
      }}
    >
      {showStatusBadge && (
        <StatusBadge showLight={false} status={status} label={label} />
      )}
      {showEnvironmentSelect && (
        <StackItem css={{ flexGrow: 0 }}>
          <EnvironmentSelect
            currentAuthoringEnvironment={currentAuthoringEnvironment}
            entityName={entityConfigName}
            availableEnvironments={availableEnvironments}
            additionalActions={multiEnvironmentActions}
            disabled={isEntitySingleEnv}
          />
        </StackItem>
      )}
      {menuItemGroup.menuItems.length > 0 && (
        <ActionMenu
          css={{
            '.GDS-action-menu-popup': {
              borderRadius: '$extraLarge',
              border: '$borderWidths$thin solid $interactiveBorderDefault',
            },
            '.GDS-action-menu-list-item': {
              padding: 0,
            },
            '.GDS-action-menu-list': {
              margin: 0,
              'li:first-child': {
                'border-top-left-radius': '$extraLarge',
                'border-top-right-radius': '$extraLarge',
              },
              'li:last-child': {
                'border-bottom-left-radius': '$extraLarge',
                'border-bottom-right-radius': '$extraLarge',
              },
            },
          }}
          menuButton={
            <Button
              hideLabel
              priority="tertiary"
              size="medium"
              icon="interfaceMeatballs"
            >
              {formatMessage({ id: 'MORE_ACTIONS' })}
            </Button>
          }
          menuId="entityDetailsActionMenu"
          menuItems={menuItemGroup.menuItems}
          onSelectItem={menuItemGroup.onMenuItemClicked}
        />
      )}
      {secondaryAction}
      {primaryAction}
    </StackItem>
  );
};
