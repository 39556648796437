import type {
  EntityDetailSchemaWithRelationships,
  EntityDetailSchemaWithoutRelationships,
  EndpointQueryParameter,
  ComputedEndpointParam,
} from '@web-config-app/core';
import { getIncludeEntityTypes } from '../get-included-entity-types/get-included-entity-types';

const isEntityDetailSchemaWithRelationships = (
  detailSchema:
    | EntityDetailSchemaWithRelationships
    | EntityDetailSchemaWithoutRelationships,
): detailSchema is EntityDetailSchemaWithRelationships =>
  'relationships' in detailSchema.properties;

/**
 * The include param is used to return any entities included in the fetched relationships
 *  entity's relationships.
 *
 * {@link https://jsonapi.org/format/#fetching-includes}
 */

export const getIncludeParam = (
  queryParameters: EndpointQueryParameter[],
  schema:
    | EntityDetailSchemaWithRelationships
    | EntityDetailSchemaWithoutRelationships
    | undefined,
  getIncludedEntities: boolean,
  includeValue?: string,
): ComputedEndpointParam => {
  if (!getIncludedEntities) {
    return null;
  }
  const includeEntitiesParam = queryParameters?.find(
    (param) => param.name === 'include',
  );
  if (includeValue) {
    return { name: 'include', value: includeValue };
  }

  if (
    typeof schema === 'undefined' ||
    !isEntityDetailSchemaWithRelationships(schema)
  ) {
    return null;
  }

  const relationshipSchemas = schema.properties.relationships?.properties;
  const includeEntityTypes =
    includeEntitiesParam && typeof relationshipSchemas === 'object'
      ? getIncludeEntityTypes(Object.keys(relationshipSchemas), schema)
      : [];
  return includeEntityTypes.length > 0
    ? { name: 'include', value: includeEntityTypes.join(',') }
    : null;
};
