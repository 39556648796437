import { RulesTemplate, RulesTemplateId } from '@web-config-app/core';
import { commonRulesTemplates } from '../configs/common/rules-templates';

/**
 * Returns an object map whose keys all RulesTemplateIds (string) and values are all RulesTemplate. The
 * second param, `baseTemplates`, is optional and will default to the `commonRulesTemplates`.
 *
 * Any rules in `templatesToMerge` with an id matching an existing id in `baseTemplates` will OVERRIDE that
 * rule (which may be helpful for a tenant)
 */

export const mergeRulesTemplates = (
  templatesToMerge: RulesTemplate[] = [],
  baseTemplates: { [k: RulesTemplateId]: RulesTemplate } = commonRulesTemplates,
) => {
  const mergedTemplates = { ...baseTemplates };
  templatesToMerge.forEach((template) => {
    mergedTemplates[template.id] = template;
  });

  return mergedTemplates;
};
