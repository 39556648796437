import { DataPoints } from '@web-config-app/core';
import { commonDataPoints } from '../configs/common/data-points';

/**
 * Merges data points from different sources into the base data points array.
 *
 * This function takes two `DataPoints` arrays: `dataPointsToMerge` and `baseDataPoints`.
 * It iterates through the `dataPointsToMerge` array and, for each object, checks if a
 * corresponding object with the same `id` exists in the `baseDataPoints` array.
 *
 * If a match is found, the `dataPoints` arrays from both objects are concatenated.
 * If no match is found, the original object from baseDataPoints is returned as is.
 *
 * @param {DataPoints} dataPointsToMerge - The data points to merge into the base data points.
 * @param {DataPoints} [baseDataPoints=allDataPoints] - The base data points to merge into.
 * Defaults to the `commonDataPoint` array.
 * @returns {DataPoints} A new array containing the merged data points.
 */

export const mergeLocalDataPoints = (
  dataPointsToMerge: DataPoints = [],
  baseDataPoints: DataPoints = commonDataPoints,
) => {
  const dataPointsToMergeMap = new Map(
    dataPointsToMerge.map((item) => [item.id, item]),
  );

  return baseDataPoints.map((baseDataPoint) => {
    const matchingDataPoint = dataPointsToMergeMap.get(baseDataPoint.id);
    if (matchingDataPoint) {
      return {
        ...baseDataPoint,
        dataPoints: [
          ...baseDataPoint.dataPoints,
          ...matchingDataPoint.dataPoints,
        ],
      };
    }
    return baseDataPoint;
  });
};
