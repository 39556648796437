import { DataPoint } from '@web-config-app/core';

export const userProfileDataPoints: DataPoint[] = [
  {
    id: 'up',
    label: 'User profile',
    description: '',
    dataType: 'object',
    dataHandlerId: 'local',
    dataPoints: [
      {
        id: 'up.age',
        label: 'Age',
        description: 'Users age in years',
        dataType: 'number',
        dataHandlerId: 'local',
      },
      {
        id: 'up.sex',
        label: 'Sex',
        description: 'Users biological sex',
        dataType: 'string',
        dataHandlerId: 'local',
      },
      {
        id: 'up.joined_timestamp',
        label: 'Joined timestamp',
        description: 'Date and time when the user joined',
        dataType: 'datetime',
        dataHandlerId: 'local',
      },
    ],
  },
];
