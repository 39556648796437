import {
  AnnotatedJsonSchema,
  RulesTemplate,
  RulesTemplateWithSchema,
} from '@web-config-app/core';

/**
 * Retrieves the schema for a given rule template.
 *
 * If the template has inputs, it constructs a JSON schema with the inputs as properties.
 * The schema is then added to the rule template and returned.
 *
 * @param {object} params - The parameters for retrieving the schema.
 * @param {RulesTemplate} [params.selectedRuleTemplate] - The selected rule template.
 * @param {string} [params.parentLabelKey] - The label key for the parent element.
 *
 * @returns {RulesTemplateWithSchema | undefined} The rule template with the schema, or undefined if no template is provided.
 */
export const getRulesTemplateWithSchema = ({
  selectedRuleTemplate,
  parentLabelKey,
}: {
  selectedRuleTemplate?: RulesTemplate;
  parentLabelKey?: string;
}): RulesTemplateWithSchema | undefined => {
  if (!selectedRuleTemplate) return undefined;

  if (!selectedRuleTemplate.inputs) return selectedRuleTemplate;

  const requiredInputKeys = Object.keys(selectedRuleTemplate.inputs);

  const schema = {
    type: 'object',
    'x-entity-control': {
      type: 'omitObjectHeading',
    },
    'x-entity-label': {
      key: parentLabelKey || 'RULE',
    },
    properties: selectedRuleTemplate?.inputs,
    required: requiredInputKeys,
  } as AnnotatedJsonSchema;

  return {
    ...selectedRuleTemplate,
    schema,
  };
};
