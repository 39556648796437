import { useQuery } from '@tanstack/react-query';
import {
  API_JSON_CONTENT_TYPE,
  createEndpointFetch,
} from '@web-config-app/api';
import { DataPoints, EnvironmentKey } from '@web-config-app/core';
import { useEnvironmentFetch } from '../use-environment-fetch/use-environment-fetch';

interface UseDMLProps {
  environmentKey: EnvironmentKey;
  path?: string;
}

export const useDML = ({ environmentKey, path }: UseDMLProps) => {
  const headers = {
    'Content-Type': API_JSON_CONTENT_TYPE,
  };

  const environmentFetch = useEnvironmentFetch(environmentKey);

  const queryFn = path
    ? async () => {
        const endpointConfig = {
          path,
          method: 'GET',
        };

        // TODO:: Update to avoid a response that avoids a result with a `result.data.data` structure and to use <DmlResponse> type for createEndpointFetch instead of DataPoints
        // @ts-expect-error - https://everlong.atlassian.net/browse/CACT-1931
        const dmlFetch = createEndpointFetch<DataPoints>(
          endpointConfig,
          environmentFetch,
        );

        return dmlFetch({
          headers,
        });
      }
    : undefined;

  return useQuery({
    queryKey: ['dml', path, environmentKey],
    queryFn,
    enabled: !!path,
  });
};
