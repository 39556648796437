import * as React from 'react';
import { composePaths } from '@jsonforms/core';
import {
  StackLayout,
  StackItem,
  Box,
  Button,
} from '@leagueplatform/genesis-core';
import { JsonFormsDispatch } from '@jsonforms/react';
import { useRuleTemplatesEditorStateStoreActions } from '@web-config-app/core-react';
import { useRuleCombinationContext } from '../../controls-primitive/json-logic-input-control/rule-combination-provider/rule-combination-provider.component';
import { EntityFormArrayControl } from '../../entity-form-array-control/entity-form-array-control.component';
import { ArrayPrimitiveGroup } from '../../array-primitive-group/array-primitive-group.components';
import { ArrayActionButtons } from '../../array-action-buttons/array-action-buttons.component';
import {
  ArrayControl,
  EntityFormArrayControlProps,
} from '../../../types/controls';
import { EmptyArrayState } from '../../empty-array-state/empty-array-state.component';
import { useEntityDetailsProps } from '../../../hooks/use-entity-details-props/use-entity-details-props';
import { useArrayPrimitiveActions } from '../array-primitive-control/use-array-primitive-actions';

export const ArrayJsonLogicContents = ({
  id,
  hint,
  label,
  banner,
  data,
  errors,
  addItem,
  hideLabel,
  removeItems,
  moveDown,
  moveUp,
  arrayAddLabel,
  path,
  schema,
  rootSchema,
  uischemas = [],
  uischema,
  renderers,
}: EntityFormArrayControlProps) => {
  const entityDetailsProps = useEntityDetailsProps();
  const formPath = entityDetailsProps?.formPath;

  const { removeFromNestedFieldsArray, addNestedFieldToArray } =
    useRuleTemplatesEditorStateStoreActions();

  const ruleContext = useRuleCombinationContext();

  const { childUiSchema, handleCreateDefaultValue, handleRemoveItem } =
    useArrayPrimitiveActions({
      removeItems,
      moveDown,
      moveUp,
      rootSchema,
      uischemas,
      uischema,
      path,
      schema,
      data,
      formPath,
    });

  const arrayItemsEmpty = !data || data?.length === 0;

  const arrayLength = data?.length;

  const deleteItem = React.useCallback(
    (itemPath: string, index: number) => () => {
      handleRemoveItem(itemPath, index)();
      if (ruleContext?.combinationFieldPath) {
        removeFromNestedFieldsArray?.({
          fieldPath: ruleContext.combinationFieldPath,
          arrayIndex: index,
        });
      }
    },
    [
      ruleContext?.combinationFieldPath,
      handleRemoveItem,
      removeFromNestedFieldsArray,
    ],
  );

  const handleAddItem = React.useCallback(() => {
    if (ruleContext?.combinationFieldPath) {
      addNestedFieldToArray?.({
        fieldPath: ruleContext.combinationFieldPath,
      });
    }
    addItem(path, handleCreateDefaultValue())();
  }, [
    addNestedFieldToArray,
    ruleContext?.combinationFieldPath,
    addItem,
    path,
    handleCreateDefaultValue,
  ]);

  return (
    // re-renders when array length changes to handle bug when the form was not re-rendering when a rule was being deleted!!
    <Box
      css={{
        marginTop: `${hideLabel ? '$none' : '$oneAndQuarter'}`,
        marginBottom: '$two',
      }}
      key={arrayLength}
    >
      <ArrayPrimitiveGroup
        hint={hint}
        label={label}
        banner={banner}
        errors={errors}
        hideLabel={hideLabel}
      >
        {arrayItemsEmpty ? (
          <EmptyArrayState item={label} />
        ) : (
          data?.map((_: any, index: number) => {
            const childPath = composePaths(path, `${index}`);
            const uniqueId = `${id}-${index}`;

            return (
              <StackLayout
                key={uniqueId}
                as="li"
                orientation="horizontal"
                verticalAlignment="center"
                spacing="$half"
                css={{
                  width: '100%',
                  marginTop: '$half',
                  flexGrow: 0,
                }}
              >
                <StackItem css={{ width: '100%' }}>
                  <JsonFormsDispatch
                    schema={schema}
                    uischema={childUiSchema || uischema}
                    path={childPath}
                    key={childPath}
                    renderers={renderers}
                  />
                </StackItem>
                <StackItem>
                  <ArrayActionButtons
                    arrayLength={data.length}
                    itemIndex={index}
                    itemLabel={label}
                    onDelete={deleteItem(path, index)}
                  />
                </StackItem>
              </StackLayout>
            );
          })
        )}
      </ArrayPrimitiveGroup>
      <Button
        icon="interfacePlus"
        size="medium"
        priority="secondary"
        onClick={() => handleAddItem()}
        css={{ marginTop: '$one' }}
      >
        {arrayAddLabel}
      </Button>
    </Box>
  );
};

const renderControl = (formContentsProps: EntityFormArrayControlProps) => (
  <ArrayJsonLogicContents {...formContentsProps} />
);

export const ArrayJsonLogicControl: ArrayControl = (props) => (
  <EntityFormArrayControl {...props} renderControl={renderControl} />
);
