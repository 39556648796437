export const EN_QUESTION_UNIT_STRINGS = {
  QUESTION_UNIT: '{count, plural, one {Question Unit} other {Question Units}}',
  QUESTION_UNIT_ENTITY_NAME: 'Question Unit',
  QUESTION_UNIT_METADATA_LABEL: 'Question Unit',
  QUESTION_UNIT_METADATA_CODE_LABEL: 'Code',
  QUESTION_UNIT_METADATA_CODE_HINT: 'Displays the code for the question unit',
  QUESTION_UNIT_METADATA_SHORT_NAME_LABEL: 'Name',
  QUESTION_UNIT_METADATA_SHORT_NAME_HINT:
    'Displays the short name for the question unit',
  QUESTION_UNIT_METADATA_SYSTEM_LABEL: 'Unit System',
  QUESTION_UNIT_METADATA_SYSTEM_HINT:
    'Unit System which the unit belongs to (e.g., League Custom, UCUM Standard)',
  QUESTION_UNIT_METADATA_SYSTEM_UCUM_STANDARD: 'UCUM Standard',
  QUESTION_UNIT_METADATA_SYSTEM_LEAGUE_CUSTOM: 'League Custom',
  QUESTION_UNIT_METADATA_LONG_NAME_LABEL: 'Long Name',
  QUESTION_UNIT_METADATA_LONG_NAME_HINT:
    'Displays the long name for the question unit',
  QUESTION_UNIT_QA_BANNER_TITLE: 'These configurations are under development.',
  QUESTION_UNIT_QA_BANNER_DESCRIPTION:
    'Please do not use to create the question for questionnaire.',
};
