import type { RulesTemplateWithoutEntityType } from '@web-config-app/core';
import { mapRulesTemplatesForEntity } from '../../../utils/map-rules-templates-for-entity';

const activityPartials: RulesTemplateWithoutEntityType[] = [
  {
    id: 'highmark_activity_eligibility_medical',
    label: 'HIGHMARK_ACTIVITY_ELIGIBILITY_MEDICAL',
    name: 'Eligibility - Medical',
    type: 'eligibilityCriteria',
    rules: {
      and: [
        {
          '==': [
            {
              var: 'lpf.highmark_user_benefits_observation.{clientNumber}.value',
            },
            'is_eligible',
          ],
        },
        {
          '==': [
            {
              var: 'lpf.highmark_user_benefits_observation.{clientNumber}.component.GROUP_CLASS_ASSOC_MBR_GROUP_IND',
            },
            'MEDICAL',
          ],
        },
      ],
    },
    inputs: {
      clientNumber: {
        type: 'string',
        'x-entity-label': {
          key: 'CLIENT_NUMBER',
        },
      },
    },
  },
  {
    id: 'highmark_activity_eligibility_associate',
    label: 'HIGHMARK_ACTIVITY_ELIGIBILITY_ASSOCIATE',
    name: 'Eligibility - Associate',
    type: 'eligibilityCriteria',
    rules: {
      and: [
        {
          '==': [
            {
              var: 'lpf.highmark_user_benefits_observation.{clientNumber}.value',
            },
            'is_eligible',
          ],
        },
        {
          '==': [
            {
              var: 'lpf.highmark_user_benefits_observation.{clientNumber}.component.GROUP_CLASS_ASSOC_MBR_GROUP_IND',
            },
            'ASSOCIATE',
          ],
        },
      ],
    },
    inputs: {
      clientNumber: {
        type: 'string',
        'x-entity-label': {
          key: 'CLIENT_NUMBER',
        },
      },
    },
  },
  {
    id: 'highmark_activity_eligibility_swordmsk',
    label: 'HIGHMARK_ACTIVITY_ELIGIBILITY_SWORDMSK',
    name: 'Eligibility - SwordMsk',
    type: 'eligibilityCriteria',
    rules: {
      '==': [
        {
          var: 'lpf.highmark_program_eligibility_observation.Member_Status::SwordMsk.value',
        },
        'is_eligible',
      ],
    },
  },
  {
    id: 'highmark_activity_eligibility_HealthmapCKDSecondPhase',
    label: 'HIGHMARK_ACTIVITY_ELIGIBILITY_HEALTHMAPCKDSECONDPHASE',
    name: 'Eligibility - HealthmapCKDSecondPhase',
    type: 'eligibilityCriteria',
    rules: {
      '==': [
        {
          var: 'lpf.highmark_program_eligibility_observation.Member_Status::HealthmapCKDSecondPhase.value',
        },
        'is_eligible',
      ],
    },
  },
  {
    id: 'highmark_activity_eligibility_OnduoT2D',
    label: 'HIGHMARK_ACTIVITY_ELIGIBILITY_ONDUOT2D',
    name: 'Eligibility - OnduoT2D',
    type: 'eligibilityCriteria',
    rules: {
      '==': [
        {
          var: 'lpf.highmark_program_eligibility_observation.Member_Status::OnduoT2D.value',
        },
        'is_eligible',
      ],
    },
  },
  {
    id: 'highmark_activity_eligibility_lark_htn',
    label: 'HIGHMARK_ACTIVITY_ELIGIBILITY_LARK_HTN',
    name: 'Eligibility - Lark HTN',
    type: 'eligibilityCriteria',
    rules: {
      '==': [
        {
          var: 'lpf.highmark_program_eligibility_observation.Member_Status::LarkHTN.value',
        },
        'is_eligible',
      ],
    },
  },
  {
    id: 'highmark_activity_eligibility_lark_wellness',
    label: 'HIGHMARK_ACTIVITY_ELIGIBILITY_LARK_WELLNESS',
    name: 'Eligibility - Lark Wellness',
    type: 'eligibilityCriteria',
    rules: {
      '==': [
        {
          var: 'lpf.highmark_program_eligibility_observation.Member_Status::LarkWellness.value',
        },
        'is_eligible',
      ],
    },
  },
  {
    id: 'highmark_activity_eligibility_lark_htn_or_lark_wellness',
    label: 'HIGHMARK_ACTIVITY_ELIGIBILITY_LARK_HTN_OR_LARK_WELLNESS',
    name: 'Eligibility - Lark HTN or Lark Wellness',
    type: 'eligibilityCriteria',
    rules: {
      or: [
        {
          '==': [
            {
              var: 'lpf.highmark_program_eligibility_observation.Member_Status::LarkHTN.value',
            },
            'is_eligible',
          ],
        },
        {
          '==': [
            {
              var: 'lpf.highmark_program_eligibility_observation.Member_Status::LarkWellness.value',
            },
            'is_eligible',
          ],
        },
      ],
    },
  },
  {
    id: 'highmark_activity_eligibility_sex',
    label: 'HIGHMARK_ACTIVITY_ELIGIBILITY_SEX',
    name: 'Eligibility - Sex',
    type: 'eligibilityCriteria',
    rules: {
      '==': [
        {
          var: 'up.sex',
        },
        '{sex}',
      ],
    },
    inputs: {
      sex: {
        type: 'string',
        enum: ['male', 'female'],
        'x-entity-label': {
          key: 'SEX',
        },
      },
    },
  },
  {
    id: 'highmark_activity_eligibility_medical_and_female',
    label: 'HIGHMARK_ACTIVITY_ELIGIBILITY_MEDICAL_AND_FEMALE',
    name: 'Eligibility - Medical and Female',
    type: 'eligibilityCriteria',
    rules: {
      and: [
        {
          '==': [
            {
              var: 'lpf.highmark_user_benefits_observation.<insert_client_number_here>.value',
            },
            'is_eligible',
          ],
        },
        {
          '==': [
            {
              var: 'lpf.highmark_user_benefits_observation.<insert_client_number_here>.component.GROUP_CLASS_ASSOC_MBR_GROUP_IND',
            },
            'MEDICAL',
          ],
        },
        {
          '==': [
            {
              var: 'up.sex',
            },
            'female',
          ],
        },
      ],
    },
  },
];

export const highmarkActivityRulesTemplates = activityPartials.map(
  mapRulesTemplatesForEntity('config-activity'),
);

const campaignPartials: RulesTemplateWithoutEntityType[] = [
  {
    id: 'highmark_campaign_eligibility_reward_program_segmentation',
    label: 'HIGHMARK_CAMPAIGN_ELIGIBILITY_REWARD_PROGRAM_SEGMENTATION',
    name: 'Reward Program Segmentation',
    type: 'eligibilityCriteria',
    rules: {
      and: [
        {
          any_string_in_list_match_ignore_case: [
            {
              var: 'lpf.highmark_program_eligibility_observation.eligible_category:program_eligibility',
            },
            '{rewardProgramId}',
          ],
        },
        {
          all_strings_in_list_match_ignore_case: [
            ['gina_consent', 'sdoh_consent'],
            {
              var: 'lpf.lpf_user_consent_observation.eligible_category:consent',
            },
          ],
        },
      ],
    },
    inputs: {
      rewardProgramId: {
        type: 'string',
        'x-entity-label': {
          key: 'REWARD_PROGRAM_ID',
        },
      },
    },
  },
  {
    id: 'highmark_campaign_eligibility_group_challenge_segmentation',
    label: 'HIGHMARK_CAMPAIGN_ELIGIBILITY_GROUP_CHALLENGE_SEGMENTATION',
    name: 'Group Challenge Segmentation',
    type: 'eligibilityCriteria',
    rules: {
      and: [
        {
          any_string_in_list_match_ignore_case: [
            {
              var: 'lpf.highmark_program_eligibility_observation.eligible_category:program_eligibility',
            },
            '{rewardProgramIds}',
          ],
        },
        {
          all_strings_in_list_match_ignore_case: [
            ['gina_consent', 'sdoh_consent'],
            {
              var: 'lpf.lpf_user_consent_observation.eligible_category:consent',
            },
          ],
        },
      ],
    },
    inputs: {
      rewardProgramIds: {
        type: 'array',
        items: {
          type: 'string',
          'x-entity-label': {
            key: 'REWARD_PROGRAM_IDS',
          },
        },
        'x-entity-label': {
          key: 'REWARD_PROGRAM_IDS',
        },
      },
    },
  },
  {
    id: 'highmark_campaign_eligibility_health_assessment',
    label: 'HIGHMARK_CAMPAIGN_ELIGIBILITY_HEALTH_ASSESSMENT',
    name: 'Health Assessment',
    type: 'eligibilityCriteria',
    rules: {
      all_strings_in_list_match_ignore_case: [
        ['gina_consent', 'sdoh_consent'],
        {
          var: 'lpf.lpf_user_consent_observation.eligible_category:consent',
        },
      ],
    },
  },
  {
    id: 'highmark_campaign_eligibility_challenge_with_client_and_group_segmentation',
    label:
      'HIGHMARK_CAMPAIGN_ELIGIBILITY_CHALLENGE_WITH_CLIENT_AND_GROUP_SEGMENTATION',
    name: 'Challenge with client_id and group_id segmentation',
    type: 'eligibilityCriteria',
    rules: {
      and: [
        {
          any_string_in_list_match_ignore_case: [
            '{clientIds}',
            {
              var: 'UserDataPoints.LPF.highmark_user_benefits_observation.eligible_category:benefits:benefits_client',
            },
          ],
        },
        {
          any_string_in_list_match_ignore_case: [
            '{clientGroupIds}',
            {
              var: 'UserDataPoints.LPF.highmark_user_benefits_observation.eligible_category:benefits:benefits_client_group',
            },
          ],
        },
      ],
    },
    inputs: {
      clientIds: {
        type: 'array',
        items: {
          type: 'string',
          'x-entity-label': {
            key: 'CLIENT_IDS',
          },
        },
        'x-entity-label': {
          key: 'CLIENT_IDS',
        },
      },
      clientGroupIds: {
        type: 'array',
        items: {
          type: 'string',
          'x-entity-label': {
            key: 'CLIENT_GROUP_IDS',
          },
        },
        'x-entity-label': {
          key: 'CLIENT_GROUP_IDS',
        },
      },
    },
  },
];

export const highmarkCampaignRulesTemplates = campaignPartials.map(
  mapRulesTemplatesForEntity('config-campaign'),
);
