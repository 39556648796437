import * as React from 'react';
import { StackLayout, InputLabel } from '@leagueplatform/genesis-core';

// TODO :: DataIcon, since it is implemented in our module, is currently not taking
// in the TextActions current color since the Icon element it rendering the svg as
// an img. Need to sync with Genesis team on possible fixes for this or get the
// DataIcon added to the genesis core icon set

export interface DataSourceFormFieldProps {
  uniqueId: string;
  label: string;
  editDataPoint: boolean;
  children: React.ReactNode; // the input we pass
  onSelectDataPoint: Function;
  inputIndex?: number; // inputIndex passed if operator is 'between', or 'notBetween' for updating value onSelectDataPoint
}

export const DataSourceFormField = ({
  uniqueId,
  label,
  editDataPoint,
  children,
  onSelectDataPoint,
  inputIndex,
}: DataSourceFormFieldProps) => {
  console.log({ editDataPoint, onSelectDataPoint, inputIndex });
  return (
    <StackLayout css={{ flexDirection: 'column-reverse', width: '100%' }}>
      {children}
      <StackLayout
        horizontalAlignment="spaceBetween"
        orientation="horizontal"
        css={{ width: '100%' }}
      >
        <InputLabel
          css={{ typography: '$bodyTwo', marginBottom: '$half' }}
          htmlFor={uniqueId}
          required
        >
          {label}
        </InputLabel>
      </StackLayout>
    </StackLayout>
  );
};
