import { RulesTemplateWithoutEntityType } from '@web-config-app/core';
import { mapRulesTemplatesForEntity } from '../../utils/map-rules-templates-for-entity';

export const commonMasonryFilterRulesTemplatePartials: RulesTemplateWithoutEntityType[] =
  [
    // Province filter rule
    {
      id: 'user_province_filter_rule',
      name: 'User Province Filer Rule',
      label: 'USER_PROVINCE_FILER_MASONRY',
      type: 'filterRule',
      rules: { '==': [{ var: 'userProfile.provinceCode' }, 'CA-ON'] },
    },
    // Date range filter rule
    {
      id: 'date_range_filter_rule',
      name: 'Date range filter Rule',
      label: 'DATE_RANGE_RULE_MASONRY',
      type: 'filterRule',
      rules: {
        and: [
          {
            '>': [
              { var: 'system.systemTimeFormattedUTC' },
              '2023-01-12T20:08:57',
            ],
          },
          {
            '<': [
              { var: 'system.systemTimeFormattedUTC' },
              '2024-01-12T20:08:57',
            ],
          },
        ],
      },
    },
    // LPF Code
    {
      id: 'lpf_code_filter_rule',
      name: 'LPF Code filter Rule',
      label: 'LPF_CODE_RULE_MASONRY',
      type: 'filterRule',
      rules: {
        '==': [{ var: '<LPF_observation_value_variable_name>' }, 'true'],
      },
    },
    // HPQ Risk factor rule
    {
      id: 'hpq_risk_factor_filter_rule',
      name: 'HPQ Risk factor filter Rule',
      label: 'HPQ_RISK_FACTOR_RULE_MASONRY',
      type: 'filterRule',
      rules: { '==': [{ var: '<riskFactorPresent_variable_name>' }, true] },
    },
    // Item list rule
    {
      id: 'item_list_filter_rule',
      name: 'Item List filter Rule',
      label: 'ITEM_LIST_RULE_MASONRY',
      type: 'filterRule',
      rules: { '!': { '>': [{ var: 'itemsLength' }, 0] } },
    },
    // Client version/platform rule
    {
      id: 'client_version_platform_filter_rule',
      name: 'Client Version or Platform filter Rule',
      label: 'CLIENT_VERSION_OR_PLATFORM_RULE_MASONRY',
      type: 'filterRule',
      rules: { var: 'abtest.userInFiftyPercentFlag' },
    },
    // Node dismisses rule
    {
      id: 'node_dismisses_filter_rule',
      name: 'Node dismissed filter Rule',
      label: 'NODE_DISMISSED_RULE_MASONRY',
      type: 'filterRule',
      rules: { var: 'dismissed.isDismissed' },
    },
    // Region Rule
    {
      id: 'region_filter_rule',
      name: 'Region filter Rule',
      label: 'REGION_RULE_MASONRY',
      type: 'filterRule',
      rules: { '==': [{ var: 'request.country' }, 'CA'] },
    },
    // Region with province rule
    {
      id: 'region_with_province_filter_rule',
      name: 'Region with province filter Rule',
      label: 'REGION_WITH_PROVINCE_RULE_MASONRY',
      type: 'filterRule',
      rules: {
        or: [
          {
            and: [
              { '==': [{ var: 'request.clientCountry' }, 'CA'] },
              { '==': [{ var: 'request.clientRegionCode' }, 'ON'] },
            ],
          },
          {
            and: [
              { '==': [{ var: 'request.clientCountry' }, 'CA'] },
              { '==': [{ var: 'request.clientRegionCode' }, 'MB'] },
            ],
          },
        ],
      },
    },
    // Device consent
    {
      id: 'device_concent_filter_rule',
      name: 'Device concent filter Rule',
      label: 'DEVICE_CONCENT_RULE_MASONRY',
      type: 'filterRule',
      rules: { var: 'healthClient.isConsentValid' },
    },
    // Health assessment completed rule
    {
      id: 'health_assessment_completed_filter_rule',
      name: 'Health assessment completed filter Rule',
      label: 'HEALTH_ASSESSMENT_COMPLETED_RULE_MASONRY',
      type: 'filterRule',
      rules: { var: 'healthProfile.isHealthAssessmentCompleted' },
    },
    // Health profile rule
    {
      id: 'health_profile_filter_rule',
      name: 'Health Profile filter Rule',
      label: 'HEALTH_PROFILE_RULE_MASONRY',
      type: 'filterRule',
      rules: { var: 'healthProfile.isHealthProfileEnabled' },
    },
  ];

export const commonMasonryFilterRulesTemplates =
  commonMasonryFilterRulesTemplatePartials.map(
    mapRulesTemplatesForEntity('config-node'),
  );
