import { RulesTemplate, RulesTemplateId } from '@web-config-app/core';
import { oldCommonRulesTemplates } from './old-rules-templates';
import { tenantMappingToOldRuleTemplates } from './tenants/tenant-mapping-to-old-rule-templates';

export interface UseRulesTemplatesProps {
  entityType: string;
  rulesType?: RulesTemplate['type'];
  tenantName: string;
  controlType?: 'ruleCombination' | 'ruleTemplate';
}

export const mergeRulesTemplates = (
  templatesToMerge: RulesTemplate[] = [],
  baseTemplates: {
    [k: RulesTemplateId]: RulesTemplate;
  } = oldCommonRulesTemplates,
) => {
  const mergedTemplates = { ...baseTemplates };
  templatesToMerge.forEach((template) => {
    mergedTemplates[template.id] = template;
  });

  return mergedTemplates;
};

export const useRulesTemplates = (props: UseRulesTemplatesProps) => {
  const { entityType, rulesType, tenantName } = props;
  const rulesTemplates = mergeRulesTemplates(
    tenantMappingToOldRuleTemplates[tenantName],
  );

  if (!rulesType) return undefined;

  const allRulesTemplates = Object.values(rulesTemplates ?? {});

  return entityType || rulesType
    ? allRulesTemplates.filter((rulesTemplate) => {
        if (entityType && rulesType) {
          return (
            rulesTemplate.entityType === entityType &&
            rulesTemplate.type === rulesType
          );
        }

        if (entityType) {
          return rulesTemplate.entityType === entityType;
        }

        if (rulesType) {
          return rulesTemplate.type === rulesType;
        }

        return false;
      })
    : allRulesTemplates;
};
