import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useIntl } from '@leagueplatform/locales';
import type {
  Entity,
  EnvironmentKey,
  EntityRootData,
  EntityConfigMultiStepCustomOperation,
} from '@web-config-app/core';
import { useEnvironmentFetch } from '../use-environment-fetch/use-environment-fetch';
import { getEntityEndpoint } from '../../utilities/get-entity-endpoint/get-entity-endpoint.util';
import { createCustomRequestBodyData } from '../../utilities/create-custom-request-body-data/create-custom-request-body-data';
import { getEndpointParamsWithValues } from '../../utilities/get-endpoint-params-with-values/get-endpoint-params-with-values';
import { getCustomOperationParameters } from '../../utilities/get-custom-operation-parameters/get-custom-operation-parameters';
import { useGetTranslatedText } from '../use-get-translated-text/use-get-translated-text';
import type { UseEntityCustomOperationOptions } from '../use-entity-custom-operation/use-entity-custom-operation';

export interface UseEntityMultiStepCustomOperationProps {
  entity: Entity;
  entityName: string;
  environment: EnvironmentKey;
  operation: EntityConfigMultiStepCustomOperation;
  data?: EntityRootData;
  headers?: HeadersInit;
  options?: UseEntityCustomOperationOptions;
}

interface StepResult {
  error: any;
  data: any;
}
/**
 * So what the heck is this thing doing? It takes an array of promises
 * and chains them together in a serial fashion. Each function is passed
 * the result of the previous one if it's truthy, otherwise it will be
 * passed whatever the value of defaultData is.
 * If any task rejects (returns an error), the entire chain is immediately rejected,
 * preventing subsequent tasks from executing.
 */
async function serial(tasks: Array<Function>, defaultData?: any) {
  return tasks.reduce(
    (promise, currPromise) =>
      promise.then(async (val) => {
        try {
          const result = await currPromise(
            (val as unknown as StepResult)?.data ?? defaultData,
          );
          if (result && result.error) {
            await Promise.reject(result.error);
          }
          return result;
        } catch (error) {
          return Promise.reject(error);
        }
      }),
    Promise.resolve(),
  );
}

/**
 * Utility to format errors in the correct structure to show in ConfigErrorBanner
 * Should be tightened up .
 */
export const formatError = (error: Error | any, stepMessage?: string) => {
  const errorMessage = (() => {
    if (
      error &&
      typeof error === 'object' &&
      'errors' in error &&
      Array.isArray(error.errors)
    ) {
      return error.errors[0].detail;
    }
    return error.toString();
  })();

  const detail = stepMessage ? `${stepMessage} ${errorMessage}` : errorMessage;

  return {
    error: {
      status: error.status,
      errors: [
        {
          detail,
        },
      ],
    },
  };
};

export const useEntityMultiStepCustomOperation = ({
  entity,
  environment,
  operation,
  entityName,
  data,
  headers,
  options,
}: UseEntityMultiStepCustomOperationProps) => {
  const { formatMessage } = useIntl();
  const getTranslatedText = useGetTranslatedText();
  const currentEnvironmentFetch = useEnvironmentFetch(
    environment,
    entity.apiUrl,
  );

  /**
   * Because we need to call the queries in a serial fashion, we
   * need to use the queryClient to make calls directly. tanstack does
   * have a `useQueries` hook that could be used to make parallel
   * queries but not serial ones.
   */
  const queryClient = useQueryClient();

  /**
   * Construct an array of async functions from the operation's steps
   * that will be executed when the custom operation is run.
   */
  const steps = useMemo(
    () =>
      operation.steps.map((step) => {
        /**
         * For each `step` we create an async function that will
         * call the operation described in the step including
         * calculating its params and body values.
         */
        const func = async (value: any) => {
          // `value` is the result of the previous step
          try {
            const {
              parameters: pathParameters,
              paramsWithValues: pathParamsWithValues,
            } = getCustomOperationParameters(
              'path',
              step.parameterMappings?.path,
              value,
            );
            const {
              parameters: queryParameters,
              paramsWithValues: queryParamsWithValues,
            } = getCustomOperationParameters(
              'query',
              step.parameterMappings?.query,
            );

            const params = getEndpointParamsWithValues([
              ...pathParamsWithValues,
              ...queryParamsWithValues,
            ]);

            const { endpointFetch } = getEntityEndpoint(
              {
                path: step.path,
                method: step.method,
                queryParameters,
                pathParameters,
              },
              options?.fetchFn ?? currentEnvironmentFetch,
            );

            const body = createCustomRequestBodyData(
              step,
              value,
              step.initialBody,
            );
            const result = await queryClient.fetchQuery({
              queryKey: [environment, step.path],
              queryFn: () => endpointFetch({ params, body, headers }),
            });

            return result;
          } catch (error: Error | any) {
            const stepErrorMessage = step.name
              ? formatMessage(
                  { id: 'CUSTOM_OPERATION_STEP_FAILED' },
                  { stepName: step.name },
                )
              : undefined;

            return formatError(error, stepErrorMessage);
          }
        };
        return func;
      }),
    [
      operation,
      currentEnvironmentFetch,
      environment,
      options,
      headers,
      queryClient,
      formatMessage,
    ],
  );

  const runAction = async () => {
    /**
     * This will only handle the `serial` concurrency mode. We'll
     * need to handle (in theory) the `parallel` concurrency mode
     * as well in future work.
     */
    const loadingMessage = getTranslatedText(
      entityName,
      operation.loadingMessage,
    );
    options?.onMutate?.(loadingMessage);

    /**
     * TODO: Naughty type assertion follows.
     */
    try {
      const result = await serial(steps, data);

      const { data: resultData } = result as unknown as StepResult;

      const onSuccessMessage = getTranslatedText(
        entityName,
        operation.successMessage,
      );
      options?.onSuccess?.(onSuccessMessage, resultData);
      options?.onSettled?.(resultData, null, resultData, data);
      return result;
    } catch (error: Error | any) {
      options?.onError?.(error, undefined, data);
      options?.onSettled?.(undefined, error, undefined, data);
      return Promise.reject(error);
    }
  };

  return runAction;
};
