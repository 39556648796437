import { useMemo, useState } from 'react';
import type { DataPoints, ParentId } from '@web-config-app/core';
import {
  getCategoryById,
  getCurrentCategoryBreadcrumbs,
  getDataPointsInCategoryById,
} from '../../utilities/data-points-categories/data-point-categories';
import { transformAndFlattenDataPoints } from '../../utilities/transform-and-flatten-data-points/transform-and-flatten-data-points';

export const useNavigateDataPoints = (dataPoints: DataPoints) => {
  const flattenedDataPoints = useMemo(
    () => transformAndFlattenDataPoints(dataPoints),
    [dataPoints],
  );
  const [currentCategoryId, setCurrentCategoryId] = useState(null as ParentId);

  const categoryDataPoints = useMemo(
    () => getDataPointsInCategoryById(currentCategoryId, flattenedDataPoints),
    [currentCategoryId, flattenedDataPoints],
  );

  const previousCategoryId = useMemo(
    () =>
      getCategoryById(currentCategoryId, flattenedDataPoints)?.parentId || null,
    [currentCategoryId, flattenedDataPoints],
  );

  const currentCategory = useMemo(
    () => getCategoryById(currentCategoryId, flattenedDataPoints),
    [currentCategoryId, flattenedDataPoints],
  );

  const categoryBreadcrumbs = useMemo(
    () =>
      currentCategory
        ? getCurrentCategoryBreadcrumbs(currentCategory, flattenedDataPoints)
        : [],
    [currentCategory, flattenedDataPoints],
  );

  const selectableDataPoints = categoryDataPoints.filter(
    (point) => !point.isCategory,
  );

  const hasSelectableDataPoint = selectableDataPoints.length > 0;
  const isTopLevelCategory = !currentCategory;

  const onNavigate = (navigateToId: ParentId = null): void => {
    const validNavigateToId = getCategoryById(navigateToId, flattenedDataPoints)
      ? navigateToId
      : null;
    setCurrentCategoryId(validNavigateToId);
  };

  const onGoBack = () => onNavigate(previousCategoryId);

  return {
    categoryBreadcrumbs,
    categoryDataPoints,
    currentCategory,
    hasSelectableDataPoint,
    isTopLevelCategory,
    onGoBack,
    onNavigate,
    selectableDataPoints,
  };
};
