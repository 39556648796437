import type { EntityDetail } from '@web-config-app/core';

/**
 * Transposes the `id` property of an entity by removing it at its original location
 * and moving it to the `sourceId` property, located in the `entityMetadata` object.
 * This allows the BE to correctly identify entity instance to clone from.
 */

export const transposeIdToSourceId = ({
  id,
  ...entityDetail
}: EntityDetail) => ({
  ...entityDetail,
  attributes: {
    ...entityDetail.attributes,
    entityMetadata: {
      ...entityDetail.attributes.entityMetadata,
      sourceId: id,
    },
  },
});
