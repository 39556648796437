import { RulesTemplate } from '@web-config-app/core';
import {
  highmarkActivityRulesTemplates,
  highmarkCampaignRulesTemplates,
} from './old-highmark-iel-rule-templates';
import { manulifeCampaignRulesTemplates } from './old-manulife-rule-templates';

export const tenantMappingToOldRuleTemplates: Record<string, RulesTemplate[]> =
  {
    Manulife: manulifeCampaignRulesTemplates,
    'Highmark IEL': [
      ...highmarkActivityRulesTemplates,
      ...highmarkCampaignRulesTemplates,
    ],
  };
