/* istanbul ignore file */
import type { AuthoringEnvironmentNameKeyMap } from './types/authoring';

export const environmentKeys: AuthoringEnvironmentNameKeyMap = {
  production: 'PRODUCTION',
  uat: 'UAT',
  staging: 'STAGING',
  development: 'DEVELOPMENT',
  test: 'TEST',
};

export const enum EntityStatusValue {
  Published = 'published',
  Archived = 'archived',
  Draft = 'draft',
  Default = 'default',
  // Used to keep track of new entities without a BE state
  New = 'new',
}

export const entityMetadataActions = ['publish', 'clone', 'update'];

export enum EntityOperationStatus {
  Draft = 'draft',
  Published = 'published',
  Archived = 'archived',
}

export const entityDetailActionTypes = [
  'custom',
  'create',
  'update',
  'publish',
  'republish',
  'unpublish',
  'archive',
  'unarchive',
  'duplicateAsDraft',
  'deepClone',
  'exitAndDiscardChanges',
] as const;

// There's some places in code that could be updated to use OperationMethod instead
// of using hard-coded strings
// https://everlong.atlassian.net/browse/CACT-1159

export enum OperationMethod {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}
/**
 * Category types for Domains and Entities - 'hidden' will hide the domain and entity from the navigation menu and entities with 'hidden' will not have a list page
 */
export enum Category {
  Default = 'default',
  Hidden = 'hidden',
  Core = 'core',
}

export const CustomOperationType = 'custom';

export const requiredOperations = ['list', 'get'] as const;

export const optionalOperations = [
  'create',
  'update',
  'archive',
  'publish',
  'republish',
  'unpublish',
  'unarchive',
  'duplicateAsDraft',
  'deepClone',
  'copyToEnvironment',
  'custom',
] as const;

export const operations = [
  ...requiredOperations,
  ...optionalOperations,
] as const;

export enum RulesTemplateType {
  completionCriteria = 'completionCriteria',
  rewardCriteria = 'rewardCriteria',
  eligibilityCriteria = 'eligibilityCriteria',
  filterRule = 'filterRule',
  branchingRule = 'branchingRule',
  challengeRule = 'challengeRule',
  ruleCombination = 'ruleCombination',
  cohortRule = 'cohortRule',
}

/**
 * Different error types we will handle in the config app
 * all 500 api errors are grouped to 'server-error-response'
 * all 400 api errors are grouped to 'client-error-response'
 * form validation (local) errors
 */
export enum ConfigErrorType {
  ServerError = 'server-error-response',
  ClientError = 'client-error-response',
  FormValidationError = 'form-validation-error',
}
export const entityDetailActionPendingKey: Partial<
  Record<(typeof operations)[number], string>
> = {
  create: 'SAVING_ACTION',
  update: 'SAVING_ACTION',
  publish: 'PUBLISHING_ACTION',
  republish: 'REPUBLISHING_ACTION',
  unpublish: 'UNPUBLISHING_ACTION',
  archive: 'ARCHIVING_ACTION',
  unarchive: 'UNARCHIVING_ACTION',
  duplicateAsDraft: 'DUPLICATING_AS_DRAFT_ACTION',
  copyToEnvironment: 'COPYING_TO_ENVIRONMENT_ACTION',
  deepClone: 'DEEP_CLONING_ACTION',
};

export const AuthoringEnvironmentValues = ['base', 'preview'] as const;
