import {
  Box,
  HeadingText,
  Modal,
  ParagraphText,
  StackLayout,
} from '@leagueplatform/genesis-core';
import React from 'react';

// modal width according to designs
const MODAL_CONTENT_WIDTH = 668;

export const ConfigModalContent: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <Modal.Content width={MODAL_CONTENT_WIDTH} padding="$two">
    <StackLayout spacing="$one" horizontalAlignment="stretch">
      {children}
    </StackLayout>
  </Modal.Content>
);

export const ConfigModalTitle = ({ title }: { title: string }) => (
  <Modal.Title>
    <HeadingText size="xl" level="2">
      {title}
    </HeadingText>
  </Modal.Title>
);

export const ConfigModalDescription = ({
  description,
}: {
  description: string;
}) => (
  <Modal.Description>
    <Box>
      <ParagraphText>{description}</ParagraphText>
    </Box>
  </Modal.Description>
);
