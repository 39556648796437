import { EN_STRING_ACTIVITY_OVERRIDES } from './entities/activity-strings';
import { EN_CAMPAIGN_STRINGS } from './entities/campaigns-strings';
import { EN_QUESTION_CODE_STRINGS } from './entities/question-code-strings';
import { EN_QUESTION_UNIT_STRINGS } from './entities/question-units-strings';
import { EN_QUESTIONNAIRE_STRINGS } from './entities/questionnaire-strings';
import { EN_STRING_NODE_OVERRIDES } from './entities/nodes-strings';
import { EXAMPLE_ENTITY_STRINGS } from './entities/example-entity-strings';
import { EN_TEST_USER_STRINGS } from './entities/test-users';
import { EN_LPF_STRINGS } from './entities/lpf-strings';
import { COMMON_ENTITY_STRINGS } from './entities/common-entity-strings';

export const EN_STRING_OVERRIDES = {
  DEVELOPMENT: 'Development',
  STAGING: 'Staging',
  PRODUCTION: 'Production',
  UAT: 'UAT',
  TEST: 'Test',
  EMPTY_ENTITY_TITLE: 'No {entity} here (yet)',
  EMPTY_ENTITY_DESCRIPTION:
    'You can manage {entity} here and reference them across the {tenant} experience.',
  ERROR_ENTITY_TITLE: 'Something went wrong loading {entity}.',
  ERROR_ENTITY_DESCRIPTION:
    'We are unable to access {entity} at this time, please try again later.',
  CREATE: 'Create',
  EXIT_ENTITY_DETAIL_TITLE: 'Discard changes to {entity}?',
  EXIT_ENTITY_DETAIL_DESCRIPTION:
    'Changes you have made since you last saved or published will not be persisted.',
  EXIT_ENTITY_DETAIL_PRIMARY_CTA: 'Discard and Close',
  UNPUBLISH_MODAL_TITLE: 'Unpublish {entity} from {env}?',
  UNPUBLISH_MODAL_DESCRIPTION:
    'When removed, users will no longer be able to see this {entity} on the live environment. You can still make edits and/or promote in the future.',
  UNPUBLISH_PRIMARY_CTA: 'Unpublish from {env}',
  ENTITY_DETAIL_LOAD_ERROR_TITLE: 'Loading Error',
  ENTITY_DETAIL_LOAD_ERROR_DESCRIPTION:
    'An unexpected error occurred while loading this page',
  RETURN_HOME: 'Return Home',
  LOADING: 'Loading',
  PUBLISHED: 'Published',
  PUBLISH_CHANGES: 'Publish Changes',
  PUBLISHING: 'Publishing',
  RE_PUBLISHING: 'Re-publishing',
  DRAFT: 'Draft',
  ARCHIVED: 'Archived',
  ARCHIVE: 'Archive',
  UN_ARCHIVE: 'Unarchive as Draft',
  UN_ARCHIVING: 'Unarchiving',
  UNSAVED_CHANGES: 'Unsaved changes',
  ALL_CHANGES_SAVED: 'All changes saved',
  ALL_CHANGES_PUBLISHED: 'All changes published',
  UNPUBLISHED_CHANGES: 'Unpublished changes',
  UNPUBLISH: 'Unpublish',
  DUPLICATE_AS_DRAFT: 'Duplicate as Draft',
  EXIT_AND_DISCARD_CHANGES: 'Exit and Discard Changes',
  UNPUBLISH_BEFORE_YOU_ARCHIVE:
    'You must unpublish this {entity} before you can archive it.',
  SAVE_BEFORE_YOU_DUPLICATE:
    'You must save your changes before you can duplicate this {entity}.',
  SAVE_DRAFT: 'Save Draft',
  SAVE_CHANGES: 'Save Changes',
  SAVING: 'Saving',
  NAME: 'Name',
  ID: 'ID',
  LAST_UPDATED: 'Last Updated',
  STATUS: 'Status',
  COPY_ENTITY_ID: 'Copy entity ID',
  FIRST: 'First',
  PREVIOUS: 'Previous',
  NEXT: 'Next',
  LAST: 'Last',
  DELETE_ITEM_ARRAY_TOOLTIP: 'Delete {item} from list.',
  DUPLICATE_ITEM_TOOLTIP: 'Duplicate {item}.',
  MOVE_ITEM_UP_TOOLTIP: 'Move {item} up.',
  MOVE_ITEM_DOWN_TOOLTIP: 'Move {item} down.',
  ADD_ITEM: 'Add {item}',
  DELETE: 'Delete',
  DUPLICATE: 'Duplicate',
  UP: 'Up',
  DOWN: 'Down',
  CANCEL: 'Cancel',
  DELETE_CONFIRMATION_MODAL_TITLE: 'Are you sure?',
  DELETE_ARRAY_ITEM_MODAL_DESCRIPTION:
    'Deleting <strong>{item}</strong> will remove all configurations that you have made for that item, and any of its children.',
  LIST_ITEM: 'List item',
  ADD_BY_ID: 'Add by ID',
  CHOOSE_FROM_LIST: 'Choose from a list',
  CHANGE_SELECTION: 'Change Selection',
  ENTITY_NAME: 'Entity Name',
  ENTITY_NAME_ARTICLE:
    '{startsWith, select, consonant {a} vowel {an} other {a}}',
  CHOOSE_ENTITY: 'Choose {entityName}',
  ENTITY_ID: 'Entity ID',
  OBJECT_CARD_LINK_OPENS_IN_OWN_PAGE: 'link, opens in its own page',
  MISSING_LABEL_ANNOTATION: 'MISSING: x-entity-label annotation at {path}',
  ADDRESS_OR_USER: 'Address or User',
  USER_KEY: 'User',
  ADDRESS_KEY: 'Address',
  INTERNAL_NAME: 'Internal Name',
  LIST: 'List',
  LOW: 'Low',
  MEDIUM: 'Medium',
  HIGH: 'High',
  SIMPLE: 'Simple',
  STANDARD: 'Standard',
  DETAILED: 'Detailed',
  PRIMARY: 'Primary',
  SECONDARY: 'Secondary',
  SUBTLE: 'Subtle',
  TITLE: 'Title',
  SINGLE: 'Single',
  MULTI: 'Multi',
  REQUIRED: 'Required',
  MANUAL: 'Manual',
  SLIDER: 'Slider',
  COUNTER: 'Counter',
  BADGE: 'Badge',
  EVENT: 'Event',
  ALT_TEXT: 'Alt Text',
  BACKGROUND_COLOR: 'Background Color',
  BORDER_RADIUS: 'Border Radius',
  HORIZONTAL_ALIGNMENT: 'Horizontal Alignment',
  START: 'Start',
  CENTER: 'Center',
  END: 'End',
  UNIT: 'Unit',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  VALUE: 'Value',
  INFO: 'Info',
  STRING: 'String',
  INTEGER: 'Integer',
  DECIMAL: 'Decimal',
  CODING: 'Coding',
  DATE: 'Date',
  TIME: 'Time',
  ANSWER: 'Answer',
  CODE: 'Code',
  METRIC: 'Metric',
  OBSERVATION: 'Observation',
  CORE: 'Core',
  LPF: 'lpf',
  GOAL: 'Goal',
  PILL: 'Pill',
  LINE: 'Line',
  BOX: 'Box',
  CHALLENGE: 'Challenge',
  NEW: 'New',
  DUPLICATE_OF: 'Duplicate of',
  /** rich text toolbar */
  NORMAL_LABEL: 'Normal',
  HEADING_1_LABEL: 'Heading 1',
  HEADING_2_LABEL: 'Heading 2',
  HEADING_3_LABEL: 'Heading 3',
  HEADING_4_LABEL: 'Heading 4',
  HEADING_5_LABEL: 'Heading 5',
  HEADING_6_LABEL: 'Heading 6',
  NUMBER_LIST_LABEL: 'Number list',
  BULLET_LIST_LABEL: 'Bullet list',
  QUOTE_LABEL: 'Quote',
  UNDO_LABEL: 'Undo',
  REDO_LABEL: 'Redo',
  BOLD_LABEL: 'Bold',
  INSERT_LINK_LABEL: 'Insert link',
  ITALIC_LABEL: 'Italic',
  STYLES_LIST: 'Styles list. {selectedBlockElement} text selected.',
  UNTITLED: 'Untitled',
  DEFAULT_ENUM_PLACEHOLDER: 'Select an option',
  ADD_IMAGE: 'Add Image',
  CONTENTFUL_ID: 'Contentful ID',
  ADD_IMAGE_BY_CONTENTFUL_ID: 'Add image by Contentful ID',
  IMAGE_URL: 'Image URL',
  USE_IMAGE_URL: 'Use Image URL',
  IMAGE_URL_HINT:
    'Please use a fully qualified URL. For example, (https://....)',
  INVALID_URL: 'Not a valid URL. Please check your input and try again.',
  CHOOSE_IMAGE_FROM_COMPUTER: 'Choose image from computer',
  CLEAR_SELECTED_IMAGE: 'Clear selected image',
  UNABLE_TO_FIND_IMAGE:
    'Unable to find this image. Please check the Contentful gallery or fix any entry errors.',
  UNABLE_TO_FIND_ENTITY: 'No {entityName} was found matching this ID.',
  COULD_NOT_LOAD_IMAGE: 'Could not load image from Contentful',
  MORE_ACTIONS: 'More actions',
  NO_ARRAY_ITEMS_YET: 'No {item} yet.',
  RULE_TEMPLATE: 'Rule Template',
  CHOOSE_A_RULE_TEMPLATE: 'Choose a rule template',
  JSON_LOGIC: 'JSONLogic',
  EQUALS: 'Equals',
  RULE_VIEW_OPTIONS_ARIA_LABEL: 'Rule view options',
  RULE: 'Rule',
  APPLY: 'Apply',
  UPLOAD_IMAGE: 'Upload Image',
  IMAGE_FILE: 'Image File',
  CHANGE_IMAGE: 'Change Image',
  FILE_TYPE_NOT_SUPPORTED:
    'File type {fileType} is not supported. The following file types are supported: {allowedFileTypes}',
  FILE_TOO_LARGE_ERROR:
    'Maximum file size is {maxSize}. This file is {currentSize}.',
  NO_FILE_INCLUDED_ERROR: 'Please add a file before attempting to upload',
  NO_TITLE_INCLUDED_ERROR: 'Please add a title before attempting to upload',
  ERROR_LOADING_ENTITY_REFERENCE: 'There was a problem loading this content',
  CLEAR_ENTITY_REFERENCE_SELECTION: 'Clear Selection',
  WIDGETS: '{count, plural, one {Widget} other {Widgets}}',
  CONTAINERS: '{count, plural, one {Container} other {Containers}}',
  ASSETS: '{count, plural, one {Asset} other {Assets}}',
  EMPTY_RULES_TEMPLATE: 'None',
  CONFIGURATION_ERROR:
    '{count, plural, one {There is an error in your configuration.} other {There are # errors in your configuration}}',
  SERVER_ERROR: 'Server Error',
  SELECTED: 'Selected',
  CURRENT_ENVIRONMENT_PRECEDING_LABEL: 'Current Environment:',
  ENVIRONMENT_SELECT: 'Environment select: {environmentName} {status} selected',
  EXIT_ENVIRONMENT_MODAL_CONFIRM_CTA: 'Discard and Switch',
  EXIT_ENVIRONMENT_MODAL_DESCRIPTION:
    'Changes you have made since you last saved or published will not be persisted if you switch environments.',
  CHOOSE_ENVIRONMENT: 'Choose Environment',
  PUBLISHING_DANGEROUSLY_STATUS_BANNER:
    'You are editing {environmentName} directly. Any change you submit will be immediately published to the live app.',
  READONLY_CONFIG_STATUS_BANNER:
    'This is a read only {entity} config. Any updates will not be saved.',
  SAVING_ACTION: 'Saving {entity}...',
  PUBLISHING_ACTION: 'Publishing {entity}...',
  REPUBLISHING_ACTION: 'Republishing {entity}...',
  UNPUBLISHING_ACTION: 'Unpublishing {entity}...',
  ARCHIVING_ACTION: 'Archiving {entity}...',
  UNARCHIVING_ACTION: 'Unarchiving {entity}...',
  DUPLICATING_AS_DRAFT_ACTION: 'Duplicating {entity}...',
  COPYING_TO_ENVIRONMENT_ACTION: 'Copying {entity} to {environment}...',
  DEEP_CLONING_ACTION: 'Cloning {entity}...',
  SYNCING_ASSETS_WITH_ENVIRONMENT_ACTION:
    'Syncing images with {environment}...',
  COPY_TO_ENVIRONMENT_ACTION: 'Copy to {environment}',
  PUBLISH_DANGEROUSLY_MODAL_TITLE: 'Publish to {environment}',
  PUBLISH_DANGEROUSLY_MODAL_DESCRIPTION:
    'Publishing to {environment} will make this {entity} visible to your customers.',
  PUBLISH_DANGEROUSLY_MODAL_PRIMARY_CTA: 'Publish to {environment}',
  PUBLISH_DANGEROUSLY_MODAL_CHECKBOX:
    'I acknowledge that this {entity} is being published in {environment}.',
  COPY_TO_ENVIRONMENT_UNSAVED_CHANGES:
    'You must save your changes before you can copy this {entity}.',
  ENVIRONMENT_LABEL: 'Environment:',
  API_URL_LABEL: 'API URL:',
  AUDIENCE_LABEL: 'Audience:',
  ENVIRONMENT_TYPE_LABEL: 'Environment Type:',
  MULTI_ENVIRONMENT_ENABLED_LABEL: 'Multi-Environment Authoring Enabled:',
  ENVIRONMENT_INFO: 'Environment Info',
  VIEW_ALL_DATA: 'View all data',
  PATH: 'Path',
  DATA: 'Value',
  NO_RULE_PARAMETERS_NEEDED: 'No additional parameters needed',
  MINIMUM_VALUE: 'Minimum Value',
  MAXIMUM_VALUE: 'Maximum Value',
  PATH_TO_OBSERVATION_VALUE: 'Path to Observation Value',
  AGE: 'Age',
  CONDITION: 'Condition',
  DATA_POINT: 'Data Point',
  ALL_STRINGS_IN_LIST: 'All Strings in the List',
  STRING_LIST_DATA_POINT: 'String List Data Point',
  STRING_LIST: 'String List',
  JOINED_BEFORE_DAYS: 'Joined Before Days',
  CUTOFF_DATE: 'Cutoff Date',
  VALUE_ONE: 'Value One',
  VALUE_TWO: 'Value Two',
  ANSWER_CODES: 'Answer Codes',
  CLIENT_IDS: 'Client IDs',
  COHORT: 'Cohort',
  CLIENT_NUMBER: 'Client Number',
  SEX: 'Sex',
  CLIENT_GROUP_IDS: 'Client Group IDs',
  DATA_FOR_ENVIRONMENT: '{environment} Data',
  DOWNLOAD_AS_CSV: 'Download CSV',
  ENTITY_SINGLE_ENVIRONMENT_STATUS_BANNER:
    'This entity is only configurable on the {environment} environment.',
  DATA_POINT_MODAL_HEADING: 'Select Category',
  DATA_POINT_MODAL_INSTRUCTIONS: 'Choose a category to find a data point:',
  DATA_POINTS_ONLY_MODAL_HEADING: 'Select Data Point',
  SELECT_DATA_POINT: 'Select Data Point',
  BROWSE: 'Browse',
  VIEW_ATTACHMENTS: 'View Attachments',
  DEEP_CLONE_OPTIONAL_ENTITY_ITEM_RENAME: 'New name for {originalName}',
  DEEP_CLONE_ENTITY_CLONE_ITEM_CHILD_ENTITIES: 'Child entities ({count})',
  UPLOAD_ATTACHMENT_FROM_COMPUTER: 'Upload attachment from computer',
  ADD_ATTACHMENT_BY_CONTENTFUL_ID: 'Add attachment by Contentful ID',
  COULD_NOT_LOAD_ATTACHMENT: 'Could not load attachment from Contentful',
  UNABLE_TO_FIND_ATTACHMENT:
    'Unable to find this attachment. Please check Contentful or fix any entry errors.',
  ADD_ATTACHMENT: 'Add Attachment',
  UPLOAD_ATTACHMENT: 'Upload Attachment',
  FILE: 'File',
  COPY_FILE_PATH: 'Copy file path',
  DEEP_CLONE: 'Deep Clone',
  DEEP_CLONE_MODAL_TITLE: 'Assign names to duplicates',
  DEEP_CLONE_MODAL_DESCRIPTION:
    'The following entities will be duplicated. If you want to assign their names before duplication, you can do so below.',
  DEEP_CLONE_MODAL_PROCEED_BUTTON: 'Proceed with duplication',
  DEEP_CLONE_MODAL_FETCHING_TEXT: 'Preparing deep clone data&hellip;',
  DUPLICATE_THIS_AND_ALL_CHILDREN: 'Duplicate this and all children?',
  DUPLICATE_DISCLAIMER_MESSAGE:
    'This {entity} is linked to other entities. Would you like to also duplicate everything this {entity} points to? The new {entity} will point to those duplicated entities.',
  DUPLICATE_SECONDARY_ACTION_TEXT: 'Only duplicate this {entity}',
  DUPLICATE_PRIMARY_ACTION_TEXT: 'Duplicate all children as well',
  THIS_ENTITY: 'This {entity}',
  ATTACHMENT: 'Attachment',
  ATTACHMENTS: 'Attachments',
  ATTACHMENTS_MODAL_DESCRIPTION:
    'These attachments are associated to this entity. You can copy and paste the URLs below within other fields or links within rich-text. Note: We do not currently support deleting assets from the storage endpoint. If the asset is removed from this list, it will still exist in the system and can be referenced in the future.',
  CUSTOM_OPERATION_STEP_FAILED: 'Operation failed at "{stepName}":',
  OR_AT_LEAST_ONE_MUST_MATCH_RULE_COMBINATION:
    '(OR) At least one must match...',
  AND_ALL_MUST_MATCH_RULE_COMBINATION: '(AND) All must match...',
  NOR_MUST_NOT_MATCH_ANY_RULE_COMBINATION:
    '(NOR) Must not match any of the below...',
  SINGLE_RULE_CONDITION: 'Single rule condition...',
  COMBINE_RULES_WITH: 'Combine rules with...',
  NONE_RULES_TEMPLATE: 'None',
  ...EN_STRING_ACTIVITY_OVERRIDES,
  ...EN_CAMPAIGN_STRINGS,
  ...EN_STRING_NODE_OVERRIDES,
  ...EN_QUESTION_CODE_STRINGS,
  ...EN_QUESTION_UNIT_STRINGS,
  ...EN_QUESTIONNAIRE_STRINGS,
  ...EXAMPLE_ENTITY_STRINGS,
  ...EN_LPF_STRINGS,
  ...COMMON_ENTITY_STRINGS,
  ...EN_TEST_USER_STRINGS,
};
