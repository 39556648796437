import React, {
  FC,
  FocusEventHandler,
  KeyboardEventHandler,
  useRef,
} from 'react';
import dompurify from 'dompurify';
import { HeadingText, StackLayout } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { SaveStatus } from '../save-status/save-status.component';
import { ValueWithCopyButton } from '../value-with-copy-button/value-with-copy-button.component';

interface EntityNameAndSaveStatusProps {
  entityInstanceName?: string;
  instanceId?: string;
  saveStatusMessage?: string;
  isEntitySaved: boolean;
  isArchived: boolean;
  /**
   * Indicates if the user is able to edit the entity name directly via contentEditable
   */
  isNameEditable?: boolean;
  /**
   * Callback to set the entity's name when isNameEditable is true and user has typed
   */
  setEntityName: (name: string) => void;
}

export const EntityNameAndSaveStatus: FC<EntityNameAndSaveStatusProps> = ({
  entityInstanceName,
  instanceId,
  isArchived,
  saveStatusMessage,
  isEntitySaved,
  isNameEditable = false,
  setEntityName,
}) => {
  const { formatMessage } = useIntl();
  const nameRef = useRef<HTMLSpanElement>(null);
  const onBlur: FocusEventHandler<HTMLSpanElement> = (evt) => {
    setEntityName?.(dompurify.sanitize(evt.currentTarget.innerText));
  };
  const onKeyDown: KeyboardEventHandler<HTMLSpanElement> = (evt) => {
    if (evt.key === 'Enter') {
      nameRef.current?.blur();
    }
  };

  const editableNameProps = isNameEditable
    ? {
        onBlur,
        onKeyDown,
        contentEditable: true,
        tabIndex: 0,
        role: 'textbox',
        'aria-label': formatMessage({ id: 'ENTITY_NAME' }),
        /**
         * This avoids adding a console warning about managing react
         * children that we don't need to care about
         */
        suppressContentEditableWarning: true,
      }
    : undefined;

  return (
    <StackLayout
      spacing="$none"
      css={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      <HeadingText
        level="1"
        size="lg"
        css={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '100%',
        }}
        ref={nameRef}
        {...editableNameProps}
      >
        {entityInstanceName}
      </HeadingText>
      <StackLayout
        orientation="horizontal"
        spacing="$half"
        verticalAlignment="center"
        css={{ minHeight: 32 }}
      >
        {instanceId && (
          <ValueWithCopyButton
            value={instanceId}
            buttonLabel={formatMessage({ id: 'COPY_ENTITY_ID' })}
          />
        )}
        {!isArchived && saveStatusMessage && (
          <SaveStatus
            isEntitySaved={isEntitySaved}
            saveStatusMessage={saveStatusMessage}
          />
        )}
      </StackLayout>
    </StackLayout>
  );
};
