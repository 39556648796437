import type {
  ConfigError,
  ConfigMessage,
  EntityDetailError,
} from '@web-config-app/core';
import React, { createContext, useContext, useRef } from 'react';
import { createStore, useStore } from 'zustand';

/**
 * Store used to track user feedback messages in the config app including error messages and validation
 */
export interface ConfigUserFeedbackStoreState {
  /**
   * Populated on entity operation to show a loading message and state on the entity details page
   */
  entityOperationLoadingMsg: string | null;
  setEntityOperationLoadingMsg: (value: string | null) => void;
  /**
   * Populated on config errors we want to track, egL onError of ReactQuery requests of Entity Operations ('get', 'update', 'create', 'duplicate')
   */
  configError: ConfigError | null;
  /**
   * Provides the ability to set configErrors, used onError in ReactQuery
   */
  setConfigError: (error: ConfigError | null) => void;
  /**
   * * Storage for validation errors that occur outside the main instance of the Entity Form, stored with a unique key of where the error occurred. These errors are showed in the Error banner when it is visible.
   */
  additionalValidationErrors: {
    [key: string]: EntityDetailError[];
  };
  setAdditionalValidationErrors: (
    key: string,
    errors: EntityDetailError[],
  ) => void;
  removeAdditionalValidationErrors: (key: string) => void;
  /**
   * Provides the ability to reset the configErrors back to null
   */
  resetConfigError: () => void;
  /**
   * When true, form field input validation states and messages will be shown to users and validation will be re-called in the until all form errors are resolved
   */
  showFormValidation: boolean;
  setShowFormValidation: (value: boolean) => void;
  /**
   * When true, error banner (If there are configErrors) will be shown to users
   */
  showErrorBanner: boolean;
  setShowErrorBanner: (value: boolean) => void;
  /**
   * * an array of error keywords that should be filtered out of the errors ajv g
   */
  filteredErrorKeywords: string[];
  setFilteredErrorKeywords: (value: string[]) => void;

  addConfigMessage(message: ConfigMessage): void;
  configMessages: ConfigMessage[];
  resetConfigMessages(): void;
  /**
   * * Reset the store properties to their clean initial state
   */
  resetConfigUserFeedbackStore: () => void;
}

/**
 * Create the Zustand store for managing user feedback messaging
 */
export const configUserFeedbackStore =
  createStore<ConfigUserFeedbackStoreState>()((set, get) => ({
    entityOperationLoadingMsg: null,
    setEntityOperationLoadingMsg: (value) => {
      set({
        entityOperationLoadingMsg: value,
      });
    },
    showFormValidation: false,
    setShowFormValidation: (value) => {
      set({
        showFormValidation: value,
      });
    },
    showErrorBanner: false,
    setShowErrorBanner: (value) => {
      set({
        showErrorBanner: value,
      });
    },
    filteredErrorKeywords: [],
    setFilteredErrorKeywords: (value) => {
      set({
        filteredErrorKeywords: value,
      });
    },
    configError: null,
    setConfigError: (error: ConfigError | null) => {
      set({
        configError: error,
      });
    },
    additionalValidationErrors: {},
    setAdditionalValidationErrors: (key, errors: EntityDetailError[]) => {
      set((state) => ({
        additionalValidationErrors: {
          ...state.additionalValidationErrors,
          [key]: errors,
        },
      }));
    },
    removeAdditionalValidationErrors: (key: string) => {
      set((state) => {
        const { [key]: removed, ...rest } = state.additionalValidationErrors;
        return { additionalValidationErrors: rest };
      });
    },
    resetConfigError: () => set({ configError: null }),
    configMessages: [],
    addConfigMessage: (message: ConfigMessage) => {
      const newMessages = [...get().configMessages, message];
      set({
        configMessages: newMessages,
      });
    },
    resetConfigMessages: () => set({ configMessages: [] }),
    resetConfigUserFeedbackStore: () =>
      set({
        configError: null,
        configMessages: [],
        filteredErrorKeywords: [],
        showFormValidation: false,
        showErrorBanner: false,
      }),
  }));

/**
 * React Context for providing the user feedback store
 */
export const ConfigUserFeedbackContext = createContext<
  typeof configUserFeedbackStore | null
>(null);

/**
 * Provider to make the ConfigUserFeedbackStore available to its children, this must be used around an app in order for the app to have access to the useConfigUserFeedback hooks (and therefore the config error management)
 */
export function ConfigUserFeedbackProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  /**
   * create a stable reference to the store using useRef, ensuring the store is only created once
   */
  const store = useRef(configUserFeedbackStore);

  return (
    <ConfigUserFeedbackContext.Provider value={store.current}>
      {children}
    </ConfigUserFeedbackContext.Provider>
  );
}

/**
 * useConfigUserFeedback provides access to the `configUserFeedbackStore`'s state and actions.
 *
 * Utilizes Zustand's `useStore` with selectors for optimized rendering.
 * Components will only re-render when the selected or derived values from the store change.
 *
 * @template T - the type of the value returned by the selector function.
 * @param selector - a function that selects or derives the desired data from the `ConfigErrorsStoreState`.
 * @returns the selected or derived value from the store.
 *
 * @example
 * // Access a specific property
 * const configError = useConfigUserFeedback(state => state.configError);
 *
* // Access multiple properties
 *   const { setConfigError, resetConfigError } = useConfigUserFeedback((state) => ({
    setConfigError: state.setConfigError,
    resetConfigError: state.resetConfigError,
  }));
 * 
 * // Derive a new value
 * const hasError = useConfigUserFeedback(state => state.configError !== null);
 */
export function useConfigUserFeedback<T>(
  selector: (state: ConfigUserFeedbackStoreState) => T,
): T {
  const configUserFeedbackStoreContext = useContext(ConfigUserFeedbackContext);
  if (!configUserFeedbackStoreContext) {
    throw new Error(
      'useConfigUserFeedback must be used within the ConfigUserFeedbackProvider',
    );
  }
  /**
   * use Zustand's useStore to get the store's state and actions
   */
  return useStore(configUserFeedbackStoreContext, selector);
}
