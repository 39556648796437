import { useRuleTemplatesEditorStateStore } from '../use-rule-templates-editor-state-store/use-rule-templates-editor-state-store';

export const useRuleTemplatesEditorStateStoreActions = () => {
  const {
    setRuleTemplatesData,
    resetRuleTemplatesData,
    getRuleTemplateFieldData,
    removeFromNestedFieldsArray,
    setRuleTemplateFieldData,
    addNestedFieldToArray,
  } = useRuleTemplatesEditorStateStore((state) => ({
    setRuleTemplatesData: state.setRuleTemplatesData,
    resetRuleTemplatesData: state.resetRuleTemplatesData,
    getRuleTemplateFieldData: state.getRuleTemplateFieldData,
    removeFromNestedFieldsArray: state.removeFromNestedFieldsArray,
    setRuleTemplateFieldData: state.setRuleTemplateFieldData,
    addNestedFieldToArray: state.addNestedFieldToArray,
  }));

  return {
    addNestedFieldToArray,
    setRuleTemplatesData,
    resetRuleTemplatesData,
    getRuleTemplateFieldData,
    removeFromNestedFieldsArray,
    setRuleTemplateFieldData,
  };
};
