import { captureError } from '@leagueplatform/observability';
import { EditorState, EntityDetail } from '@web-config-app/core';

export const parseEditorStateEntityMetadata = (data: EntityDetail) => {
  const editorState = data?.attributes?.entityMetadata?.editorState;

  if (!editorState) {
    return undefined;
  }

  try {
    return JSON.parse(editorState) as EditorState;
  } catch (error) {
    captureError(
      new Error(`Failed to parse editorState received from the BE - ${error}`),
    );
    console.error('Unable to parse editor state', error);
    return undefined;
  }
};
