import * as React from 'react';
import { EntityName } from '@web-config-app/core-react-ui';
import { useIntl } from '@leagueplatform/locales';
import type { Entity } from '@web-config-app/core';

export const EntitySelectModalHeading = ({ entity }: { entity: Entity }) => {
  const { formatMessage } = useIntl();
  const modalHeadingLabel = formatMessage(
    { id: 'CHOOSE_ENTITY' },
    {
      entityName: (
        <EntityName id={entity.nameTranslationKey} singular withArticle />
      ),
    },
  );

  // Actually, eslint, this fragment is pretty useful
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{modalHeadingLabel}</>;
};
