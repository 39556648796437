import {
  RulesTemplate,
  RulesTemplateWithoutEntityType,
} from '@web-config-app/core';

export const standAloneRuleCombinationPartials = (
  rulesTemplateType: RulesTemplate['type'],
) =>
  [
    {
      id: `single_rule_condition`,
      name: 'Single rule condition',
      label: 'SINGLE_RULE_CONDITION',
      type: 'ruleCombination',
      rules: '{ruleCombination}',
      inputs: {
        ruleCombination: {
          type: 'string',
          'x-entity-label': {
            key: 'RULE',
          },
          format: 'json-logic',
          'x-entity-presentation': {
            rulesTemplateType,
            hideLabel: true,
          },
          'x-entity-control': {
            type: 'ruleTemplateJsonLogic',
          },
        },
      },
    },
    {
      id: `or_at_least_one_must_match_rule_combination`,
      name: 'OR At least one must match',
      label: 'OR_AT_LEAST_ONE_MUST_MATCH_RULE_COMBINATION',
      type: 'ruleCombination',
      rules: {
        or: ['{ruleCombination}'],
      },
      inputs: {
        ruleCombination: {
          type: 'array',
          'x-entity-label': {
            key: 'RULES',
          },
          'x-entity-presentation': {
            hideLabel: true,
          },
          items: {
            type: 'string',
            'x-entity-label': {
              key: 'RULE',
            },
            format: 'json-logic',
            'x-entity-presentation': {
              rulesTemplateType,
              hideLabel: true,
            },
            'x-entity-control': {
              type: 'ruleTemplateJsonLogic',
            },
          },
        },
      },
    },
    {
      id: `and_all_must_match_rule_combination`,
      name: 'AND All must match',
      label: 'AND_ALL_MUST_MATCH_RULE_COMBINATION',
      type: 'ruleCombination',
      rules: {
        and: ['{ruleCombination}'],
      },
      inputs: {
        ruleCombination: {
          type: 'array',
          'x-entity-label': {
            key: 'RULES',
          },
          'x-entity-presentation': {
            hideLabel: true,
          },
          items: {
            type: 'string',
            'x-entity-label': {
              key: 'RULE',
            },
            format: 'json-logic',
            'x-entity-presentation': {
              rulesTemplateType,
              hideLabel: true,
            },
            'x-entity-control': {
              type: 'ruleTemplateJsonLogic',
            },
          },
        },
      },
    },
    {
      id: `none_must_match_rule_combination`,
      name: 'NOR Must not match any of the below',
      label: 'NOR_MUST_NOT_MATCH_ANY_RULE_COMBINATION',
      type: 'ruleCombination',
      rules: {
        '!': {
          or: ['{ruleCombination}'],
        },
      },
      inputs: {
        ruleCombination: {
          type: 'array',
          'x-entity-label': {
            key: 'RULES',
          },
          'x-entity-presentation': {
            hideLabel: true,
          },
          items: {
            type: 'string',
            'x-entity-label': {
              key: 'RULE',
            },
            format: 'json-logic',
            'x-entity-presentation': {
              rulesTemplateType,
              hideLabel: true,
            },
            'x-entity-control': {
              type: 'ruleTemplateJsonLogic',
            },
          },
        },
      },
    },
  ] as RulesTemplateWithoutEntityType[];
