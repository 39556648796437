import { useMemo } from 'react';
import { useIntl } from '@leagueplatform/locales';
import type {
  CustomWorkflowActionMenuItem,
  CustomWorkflowMultiStepActionMenuItem,
  ActionMenuItem,
} from '@web-config-app/core';
import { useGetTranslatedText, useConfigApp } from '@web-config-app/core-react';
import { isUsingConfigAppBackend } from '@web-config-app/common';
import type { EntityWorkflowActionsProps } from '../../types/hooks';

export const useMenuItems = ({
  operations,
  status,
  entity,
  isEntitySaved,
  entityConfigName,
  entityListPath,
  environmentName,
  resetEntityData,
}: EntityWorkflowActionsProps): ActionMenuItem[] => {
  const { formatMessage } = useIntl();
  const getTranslatedText = useGetTranslatedText();
  const { isUsingConfigAppBackend: isTenantUsingConfigAppBackend = false } =
    useConfigApp();

  const customActionMenuItems: Array<
    CustomWorkflowActionMenuItem | CustomWorkflowMultiStepActionMenuItem
  > = useMemo(
    () =>
      Object.entries(entity.endpoints.custom ?? {}).map(
        ([actionId, operation]) => ({
          type: 'custom',
          id: actionId,
          operation,
          label:
            operation.label.text ?? formatMessage({ id: operation.label.key }),
          display: true,
          modal: operation.confirmationModal
            ? {
                type: 'confirmation',
                title: getTranslatedText(
                  entityConfigName,
                  operation?.confirmationModal.title,
                )!,
                description: getTranslatedText(
                  entityConfigName,
                  operation.confirmationModal.message,
                )!,
                confirmCtaText: getTranslatedText(
                  entityConfigName,
                  operation.confirmationModal.action.buttonLabel,
                )!,
                destructive: operation.confirmationModal.action.destructive,
              }
            : undefined,
        }),
      ),
    [
      entity.endpoints.custom,
      formatMessage,
      getTranslatedText,
      entityConfigName,
    ],
  );

  const tempDeepCloneMenuItems: ActionMenuItem[] = useMemo(
    () =>
      isUsingConfigAppBackend({
        tenantLevelOverride: isTenantUsingConfigAppBackend,
        entityLevelOverride: entity.isUsingConfigAppBackend,
      })
        ? [
            {
              type: 'deepClone',
              label: formatMessage({ id: 'DEEP_CLONE' }),
              display: true,
              disabled: false,
              action: () => {},
            },
          ]
        : [],
    [
      formatMessage,
      entity.isUsingConfigAppBackend,
      isTenantUsingConfigAppBackend,
    ],
  );

  const menuItems: ActionMenuItem[] = useMemo(() => {
    switch (status) {
      case 'draft':
        return [
          {
            type: 'duplicateAsDraft',
            label: formatMessage({ id: 'DUPLICATE_AS_DRAFT' }),
            tooltip: formatMessage(
              { id: 'SAVE_BEFORE_YOU_DUPLICATE' },
              { entity: entityConfigName },
            ),
            // only allow the user to action on the duplicateAsDraft menu item when the entity is saved
            disabled: !isEntitySaved,
            display: Boolean(operations?.duplicateAsDraft?.enableAction),
            action:
              isEntitySaved && !operations?.deepClone?.enableAction
                ? operations?.duplicateAsDraft?.mutate
                : null,
            modal: operations?.deepClone?.enableAction
              ? {
                  type: 'option',
                  title: formatMessage({
                    id: 'DUPLICATE_THIS_AND_ALL_CHILDREN',
                  }),
                  description: formatMessage(
                    { id: 'DUPLICATE_DISCLAIMER_MESSAGE' },
                    { entity: entityConfigName },
                  ),
                  primaryCtaText: formatMessage({
                    id: 'DUPLICATE_PRIMARY_ACTION_TEXT',
                  }),
                  // To be updated to open the deep clone modal
                  primaryCtaAction: () => {},
                  secondaryCtaText: formatMessage(
                    { id: 'DUPLICATE_SECONDARY_ACTION_TEXT' },
                    { entity: entityConfigName },
                  ),
                  secondaryCtaAction: operations?.duplicateAsDraft?.mutate,
                }
              : undefined,
          },
          {
            type: 'archive',
            label: formatMessage({ id: 'ARCHIVE' }),
            tooltip: formatMessage(
              { id: 'UNPUBLISH_BEFORE_YOU_ARCHIVE' },
              { entity: entityConfigName },
            ),
            disabled: false,
            display: Boolean(operations?.archive?.enableAction),
            action: operations?.archive?.mutate,
          },
          ...tempDeepCloneMenuItems,
          ...customActionMenuItems,
        ];
      case 'published':
        return [
          {
            type: 'unpublish',
            label: formatMessage({ id: 'UNPUBLISH' }),
            disabled: false,
            display: Boolean(operations?.unpublish?.enableAction),
            modal: {
              type: 'confirmation',
              confirmCtaAction: operations?.unpublish?.mutate,
              title: formatMessage(
                { id: 'UNPUBLISH_MODAL_TITLE' },
                { entity: entityConfigName, env: environmentName },
              ),
              description: formatMessage(
                {
                  id: 'UNPUBLISH_MODAL_DESCRIPTION',
                },
                { entity: entityConfigName },
              ),
              confirmCtaText: formatMessage(
                {
                  id: 'UNPUBLISH_PRIMARY_CTA',
                },
                { env: environmentName },
              ),
            },
          },
          {
            type: 'duplicateAsDraft',
            label: formatMessage({ id: 'DUPLICATE_AS_DRAFT' }),
            tooltip: formatMessage(
              { id: 'SAVE_BEFORE_YOU_DUPLICATE' },
              { entity: entityConfigName },
            ),
            // only allow the user to action on the duplicateAsDraft menu item when the entity is saved
            disabled: !isEntitySaved,
            display: Boolean(operations?.duplicateAsDraft?.enableAction),
            action:
              isEntitySaved && !operations?.deepClone?.enableAction
                ? operations?.duplicateAsDraft?.mutate
                : null,
            modal: operations?.deepClone?.enableAction
              ? {
                  type: 'option',
                  title: formatMessage({
                    id: 'DUPLICATE_THIS_AND_ALL_CHILDREN',
                  }),
                  description: formatMessage(
                    { id: 'DUPLICATE_DISCLAIMER_MESSAGE' },
                    { entity: entityConfigName },
                  ),
                  primaryCtaText: formatMessage({
                    id: 'DUPLICATE_PRIMARY_ACTION_TEXT',
                  }),
                  // To be updated to open the deep clone modal
                  primaryCtaAction: () => {},
                  secondaryCtaText: formatMessage(
                    { id: 'DUPLICATE_SECONDARY_ACTION_TEXT' },
                    { entity: entityConfigName },
                  ),
                  secondaryCtaAction: operations?.duplicateAsDraft?.mutate,
                }
              : undefined,
          },
          {
            type: 'archive',
            label: formatMessage({ id: 'ARCHIVE' }),
            tooltip: formatMessage(
              { id: 'UNPUBLISH_BEFORE_YOU_ARCHIVE' },
              { entity: entityConfigName },
            ),
            disabled: true,
            display: Boolean(operations?.archive?.enableAction),
          },
          ...tempDeepCloneMenuItems,
          ...customActionMenuItems,
        ];
      case 'archived':
        return [];
      case 'new':
      default:
        return [
          {
            type: 'exitAndDiscardChanges',
            label: formatMessage({ id: 'EXIT_AND_DISCARD_CHANGES' }),
            disabled: false,
            display: !isEntitySaved,
            modal: {
              destructive: true,
              type: 'confirmation',
              confirmCtaAction: entityListPath,
              confirmCtaOnclick: resetEntityData,
              title: formatMessage(
                { id: 'EXIT_ENTITY_DETAIL_TITLE' },
                { entity: entityConfigName },
              ),
              description: formatMessage({
                id: 'EXIT_ENTITY_DETAIL_DESCRIPTION',
              }),
              confirmCtaText: formatMessage({
                id: 'EXIT_ENTITY_DETAIL_PRIMARY_CTA',
              }),
            },
          },
        ];
    }
  }, [
    entityConfigName,
    formatMessage,
    isEntitySaved,
    operations?.archive?.enableAction,
    operations?.archive?.mutate,
    operations?.duplicateAsDraft?.enableAction,
    operations?.duplicateAsDraft?.mutate,
    operations?.unpublish?.enableAction,
    operations?.unpublish?.mutate,
    operations?.deepClone?.enableAction,
    status,
    entityListPath,
    customActionMenuItems,
    resetEntityData,
    environmentName,
    tempDeepCloneMenuItems,
  ]);

  return useMemo(() => menuItems.filter((item) => item.display), [menuItems]);
};
