import {
  EntityDetailSchema,
  EnvironmentKey,
  findDMLDataSource,
  AnnotatedJsonSchema,
} from '@web-config-app/core';
import { useConfigApp, useDML } from '@web-config-app/core-react';

interface UseDataPointsProps {
  rootSchema: EntityDetailSchema;
  currentAuthoringEnvironment: EnvironmentKey;
  schema: AnnotatedJsonSchema;
  entityType: string;
}

export const useDataPoints = ({
  schema,
  rootSchema,
  currentAuthoringEnvironment,
  entityType,
}: UseDataPointsProps) => {
  const entityDataPoint = schema?.['x-entity-data-point'];
  // Dml data points
  const dmlDataPointSource = entityDataPoint?.dataPointSources?.includes('dml');
  // if the control's dataSources contains `dml` - grab the dml namespaced dataSource from the entity's registry
  const dmlSource = dmlDataPointSource
    ? findDMLDataSource(rootSchema['x-entity-data-source-registry']?.sources)
    : undefined;

  const dmlApiPath = dmlSource?.apiPath;

  const {
    data: dmlDataPoints,
    isError,
    isLoading,
  } = useDML({
    environmentKey: currentAuthoringEnvironment,
    path: dmlApiPath,
  });

  // local dataPoints
  const localDataPointSource =
    entityDataPoint?.dataPointSources?.includes('config');

  const { dataPoints: localDataPoints } = useConfigApp();

  // if the control's dataSources contains `config` - grab local data points from the entity flow
  const entityTypeLocalDataPoints =
    localDataPointSource && localDataPoints && entityType
      ? localDataPoints.filter((flow) => flow.id === entityType)
      : [];

  const dataPoints = [...entityTypeLocalDataPoints, ...(dmlDataPoints ?? [])];

  return { dataPoints, isError, isLoading };
};
