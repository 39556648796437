import * as React from 'react';
import type {
  Nullable,
  AnnotatedJsonSchema,
  Entity,
} from '@web-config-app/core';
import { EntityLoader } from '@web-config-app/core-react';
import { getEntityInstanceName } from '@web-config-app/core';
import { PrimitiveObjectArrayItem } from './primitive-object-array-item.component';
import type { EntitySelectControlSchema } from '../../../types/controls';
import { useEntityReferenceSchemaType } from '../../../hooks/use-entity-reference-schema-type/use-entity-reference-schema-type';
import { useReferencedEntityData } from '../../../hooks/use-referenced-entity-data/use-referenced-entity-data';
import { useEntityRelationshipSchema } from '../../../hooks/use-entity-relationship-schema/use-entity-relationship-schema';
import { useEntityDetailsProps } from '../../../hooks/use-entity-details-props/use-entity-details-props';
import { isRelationshipEntityReference } from '../../../utils/is-relationship-entity-reference/is-relationship-entity-reference';
import type { PrimitiveObjectArrayItemProps } from '../../../types/components';

export interface PrimitiveObjectArrayItemWithEntityReferenceProps
  extends PrimitiveObjectArrayItemProps {
  entityReferencePropertySchema: Nullable<AnnotatedJsonSchema>;
  referenceInstanceId?: string;
}

/**
 * Special wrapper around the array of primitive object items when the item contains a property
 * that is an entity reference. This wrapper allows us to retrieve the entity's name and display it
 * as the item's label
 */

const PrimitiveObjectArrayItemWithEntityRelationshipWrapper = ({
  entityReferencePropertySchema: schema,
  referenceInstanceId: instanceId,
  referencedEntity,
  ...props
}: PrimitiveObjectArrayItemWithEntityReferenceProps & {
  referencedEntity: Entity;
}) => {
  const { itemLabel, handleRemoveItem, path, index } = props;
  const entityReferenceSchema = schema as EntitySelectControlSchema;
  const { removeRelationshipItem } = useEntityRelationshipSchema({
    schema: entityReferenceSchema,
  });

  const { currentAuthoringEnvironment } = useEntityDetailsProps();

  const { referencedEntity: entityReferenceData } = useReferencedEntityData({
    entity: referencedEntity,
    instanceId,
    environment: currentAuthoringEnvironment,
    isRelationshipReference: true,
  });

  const handleRemoveEntityReference = () => {
    handleRemoveItem(path, index)();

    if (entityReferenceData) {
      removeRelationshipItem(entityReferenceData.id);
    }
  };

  const itemLabelWithEntityTitle = entityReferenceData
    ? getEntityInstanceName(entityReferenceData, referencedEntity)
    : itemLabel;

  return (
    <PrimitiveObjectArrayItem
      {...props}
      handleRemoveItem={() => handleRemoveEntityReference}
      itemLabel={itemLabelWithEntityTitle}
    />
  );
};

const PrimitiveObjectArrayItemWithEntityReferenceWrapper = ({
  entityReferencePropertySchema: schema,
  referenceInstanceId: instanceId,
  referencedEntity,
  ...props
}: PrimitiveObjectArrayItemWithEntityReferenceProps & {
  referencedEntity: Entity;
}) => {
  const { itemLabel, handleRemoveItem, path, index } = props;

  const { currentAuthoringEnvironment } = useEntityDetailsProps();

  const { referencedEntity: entityReferenceData } = useReferencedEntityData({
    entity: referencedEntity,
    instanceId,
    environment: currentAuthoringEnvironment,
    isRelationshipReference: false,
  });

  const handleRemoveEntityReference = () => {
    handleRemoveItem(path, index)();
  };

  const itemLabelWithEntityTitle = entityReferenceData
    ? getEntityInstanceName(entityReferenceData, referencedEntity)
    : itemLabel;

  return (
    <PrimitiveObjectArrayItem
      {...props}
      handleRemoveItem={() => handleRemoveEntityReference}
      itemLabel={itemLabelWithEntityTitle}
    />
  );
};

export const PrimitiveObjectArrayItemWithEntityReference = (
  props: PrimitiveObjectArrayItemWithEntityReferenceProps,
) => {
  const entitySelectSchema =
    // eslint-disable-next-line react/destructuring-assignment
    props.entityReferencePropertySchema as EntitySelectControlSchema;

  const entityType = useEntityReferenceSchemaType({
    schema: entitySelectSchema,
  });

  const isRelationshipReference = isRelationshipEntityReference(
    entitySelectSchema['x-entity-reference'],
  );

  const Wrapper = isRelationshipReference
    ? PrimitiveObjectArrayItemWithEntityRelationshipWrapper
    : PrimitiveObjectArrayItemWithEntityReferenceWrapper;

  return (
    <EntityLoader
      entityType={entityType}
      render={(entity) => <Wrapper {...props} referencedEntity={entity} />}
    />
  );
};
