import { DataPoints, FlowTypeDataPoint } from '@web-config-app/core';
import { userProfileDataPoints } from './data-points/user-profile-data-points';
import { campaignCompletionDataPoints } from './data-points/campaign-completion-data-points';
import { rewardDataPoints } from './data-points/reward-data-points';

const activityDataPoints: FlowTypeDataPoint = {
  id: 'config-activity',
  description: 'data points for activities',
  dataHandlers: [{ id: 'local', description: '', version: '' }],
  dataPoints: [...rewardDataPoints, ...campaignCompletionDataPoints],
};

const campaignDataPoints: FlowTypeDataPoint = {
  id: 'config-campaign',
  description: 'data points for campaigns',
  dataHandlers: [{ id: 'local', description: '', version: '' }],
  dataPoints: [...userProfileDataPoints, ...campaignCompletionDataPoints],
};

export const commonDataPoints: DataPoints = [
  campaignDataPoints,
  activityDataPoints,
];
