import * as React from 'react';
import { Button, StackLayout } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

export const JsonLogicInputTopBar = () => {
  const { formatMessage } = useIntl();
  return (
    <StackLayout orientation="horizontal" verticalAlignment="center">
      <Button
        as="a"
        icon="interfaceQuestionCircle"
        quiet
        size="small"
        priority="tertiary"
        target="_blank"
        href="https://everlong.atlassian.net/wiki/spaces/CACT/pages/5221974035/Rule+Configuration"
      >
        {formatMessage({ id: 'HELP' })}
      </Button>
    </StackLayout>
  );
};
