import * as React from 'react';
import { DataPointInput, useDataPoints } from '@web-config-app/data-points';
import { ControlComponent, ControlProps } from '../../../types/controls';
import { EntityFormControl } from '../../entity-form-control/entity-form-control.component';
import { useEntityDetailsProps } from '../../../hooks/use-entity-details-props/use-entity-details-props';

/**
 * DataPointInputControl renders when the type: string and the annotation 'x-entity-data-point' is present.
 */

export const DataPointInputControl: ControlComponent = (
  props: ControlProps,
) => {
  const { rootSchema, currentAuthoringEnvironment, entityType } =
    useEntityDetailsProps();

  const { schema } = props;

  const {
    dataPoints,
    isError: dataPointModalError,
    isLoading: dataPointModalLoading,
  } = useDataPoints({
    schema,
    rootSchema,
    currentAuthoringEnvironment,
    entityType,
  });

  return (
    <EntityFormControl
      {...props}
      omitWrapperComponent
      renderControl={({
        data,
        handleChange,
        path,
        label,
        hint,
        required,
        banner,
        inputStatus,
        enabled,
        statusMessage,
      }) => {
        const handleDataPointInputChange = (value?: string) => {
          handleChange(path, value);
        };
        return (
          <DataPointInput
            value={data ?? ''}
            onChange={handleDataPointInputChange}
            dataPoints={dataPoints}
            dataPointModalError={dataPointModalError}
            dataPointModalLoading={dataPointModalLoading}
            id={path}
            required={required}
            inputStatus={inputStatus}
            readOnly={!enabled}
            hint={hint}
            banner={banner}
            label={label}
            statusMessage={statusMessage}
            clearable={enabled && data && data.length > 0}
            onClear={() => handleChange(path, undefined)}
          />
        );
      }}
    />
  );
};
