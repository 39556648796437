import * as React from 'react';
import type {
  Entity,
  EntityDetail,
  ListEndpointResponse,
  ListEndpointPaginationLinks,
  EnvironmentKey,
} from '@web-config-app/core';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getEndpointParamsWithValues } from '../../utilities/get-endpoint-params-with-values/get-endpoint-params-with-values';
import { getEntityEndpoint } from '../../utilities/get-entity-endpoint/get-entity-endpoint.util';
import { useEnvironmentFetch } from '../use-environment-fetch/use-environment-fetch';
import { getQueryParamsFromUrl } from '../../utilities/get-query-params-from-url/get-query-params-from-url.util';

type EntityListUseQueryResult = UseQueryResult<ListEndpointResponse>;

export type UseEntityListResult = Omit<EntityListUseQueryResult, 'data'> & {
  /**
   * There's a clash between `useQuery` returning a `data` property and our endpoints that ALSO include a `data` property
   * in the response. So, to avoid have `data.data`, hoist the endpoint's `response.data` to the top level here.
   */
  data?: EntityDetail[];
  /**
   * Config APIs return results pagination as an object with values corresponding to
   * specific "pages" of results the entity data
   * @example
   * {
   *   self: 'v1/config-entities?page[cursor]=0&page[size]=25',
   *   first: 'v1/config-entities?page[cursor]=0&page[size]=25',
   *   next: 'v1/config-entities?page[cursor]=1&page[size]=25',
   *   last: 'v1/config-entities?page[cursor]=23&page[size]=25',
   * }
   */
  pagination?: ListEndpointPaginationLinks;
  /**
   * It extracts query parameters (like page cursor/offset and size) from the provided `url`
   * (typically obtained from the `pagination` object's links like `next`, `prev`, etc.)
   * and updates the internal state, triggering `useQuery` to fetch the corresponding page of data.
   *
   * @param url - The pagination URL (e.g., 'v1/entities?page[size]=30&page[offset]=90')
   * for the desired page.
   */
  loadPage: (page: string) => void;
};

/**
 * Our pagination params are always the same for all entities
 */
const initialPaginationParams = { 'page[offset]': '0', 'page[size]': '30' };

export const useEntityList = (
  {
    entity,
    enabled = true,
    environment,
    domainEntityType,
    searchQuery,
  }: {
    entity: Entity;
    enabled?: boolean;
    environment: EnvironmentKey;
    searchQuery?: string;
    domainEntityType?: string;
  },
  fetchFn?: (path: string, options: any) => Promise<Response>,
): UseEntityListResult => {
  const {
    id,
    endpoints: { list },
    apiUrl,
  } = entity;

  const [paginationParams, setPaginationParams] = React.useState<{
    [key: string]: string;
  } | null>(null);

  const loadPage = (url: string | undefined) => {
    if (!url) return;
    const params = getQueryParamsFromUrl(url);

    setPaginationParams(params);
  };

  if (!list) {
    throw new Error(`Entity ${id} has no defined list endpoint`);
  }

  /**
   * This effect needs to run when switching directly between two entity's list
   * pages to trigger properly loading the new entity's data
   */
  React.useEffect(() => {
    setPaginationParams(initialPaginationParams);
  }, [list.path, environment]);

  const currentEnvironmentFetch = useEnvironmentFetch(environment, apiUrl);
  const { endpointFetch } = getEntityEndpoint<ListEndpointResponse>(
    list,
    fetchFn ?? currentEnvironmentFetch,
  );

  /**
   * maintain the exact path called endpoint as the cache key
   *
   * For example, if `pageUrl` is set, use that. Otherwise use
   * the endpoint root URL
   *
   * Our endpoint also use a `data` property so we rename the `useQuery`
   * `data` property here to `queryResultData` to avoid having to use
   * `data.data` below.
   */
  const params = React.useMemo(() => {
    const paramObjects: { [key: string]: string } = {
      ...(domainEntityType
        ? { 'filter[domainEntityType]': domainEntityType }
        : undefined),
      ...(searchQuery
        ? { 'filter[query]': encodeURIComponent(searchQuery) }
        : undefined),
      ...paginationParams,
    };

    return getEndpointParamsWithValues(
      Object.entries(paramObjects).map(([name, value]) => ({ name, value })),
    );
  }, [domainEntityType, searchQuery, paginationParams]);

  const { data: queryResultData, ...query } = useQuery({
    queryKey: [
      environment,
      paginationParams,
      domainEntityType,
      searchQuery,
      list.path,
    ],
    queryFn: () => endpointFetch({ path: list.path, params }),
    enabled: enabled && !!list.path,
  });

  return {
    ...query,
    data: queryResultData?.data ?? [],
    pagination: queryResultData?.links,
    loadPage,
  };
};
