import { EndpointPathParameter, Entity } from '@web-config-app/core';
import { mockSimpleSchema } from './mock-schemas';

const mockPathParameters: EndpointPathParameter[] = [
  {
    name: 'cactusId',
    in: 'path',
    schema: {
      type: 'string',
    },
    required: true,
  },
];

export const mockCactusEntity: Entity = {
  id: 'cactuses',
  type: 'config-cactus',
  nameTranslationKey: 'CACTUSES',
  name: 'Cactuses (or cactii)',
  category: 'default',
  schema: mockSimpleSchema,
  endpoints: {
    list: {
      path: 'v1/config-cactuses',
      method: 'GET',
    },
    create: {
      path: 'v1/config-cactuses',
      method: 'POST',
    },
    get: {
      path: 'v1/config-cactuses/{cactusId}',
      method: 'GET',
      pathParameters: mockPathParameters,
    },
    update: {
      path: 'v1/config-cactuses/{cactusId}',
      method: 'PATCH',
      pathParameters: mockPathParameters,
    },
    publish: {
      path: 'v1/config-cactuses/{cactusId}',
      method: 'PATCH',
      pathParameters: mockPathParameters,
    },
    duplicateAsDraft: {
      path: 'v1/config-cactuses',
      method: 'POST',
    },
    custom: {
      ping: {
        path: 'v1/config-cactuses/ping',
        method: 'GET',
        actionType: 'overflow',
        label: {
          text: 'Ping',
        },
        loadingMessage: {
          text: 'Ping-a-ling',
        },
        successMessage: {
          text: 'Pong',
        },
        parameterMappings: {
          query: [
            {
              id: 'id',
            },
          ],
          requestBody: [],
        },
      },
    },
  },
};

export const mockOwlEntity = {
  id: 'pygmy-owls',
  type: 'config-pygmy-owl',
  nameTranslationKey: 'PYGMY_OWLS',
  name: 'Pygmy Owls',
  apiUrl: 'https://owls.com/v1/owls',
  schema: {
    'x-entity-metadata': {
      entityNamePath: '$.attributes.metaData.internalName',
    },
    type: 'object',
    properties: {
      id: {
        type: 'string',
      },
      type: {
        type: 'string',
        enum: ['config-pygmy-owl'],
      },
      attributes: {},
    },
  },
  endpoints: {
    list: {
      path: 'v1/config-pygmy-owls',
      method: 'GET',
    },
    get: {
      path: 'v1/config-pygmy-owls/{pygmyOwlId}',
      method: 'GET',
      pathParameters: [
        {
          name: 'pygmyOwlId',
          in: 'path',
          schema: {
            type: 'string',
          },
          required: true,
        },
      ],
    },
    create: {
      path: 'v1/config-pygmy-owls',
      method: 'POST',
    },
    update: {
      path: 'v1/config-pygmy-owls/{pygmyOwlId}',
      method: 'PATCH',
    },
    delete: {
      path: 'v1/config-pygmy-owls/{pygmyOwlId}',
      method: 'DELETE',
    },
  },
};

export const mockDesertsDomain = {
  id: 'deserts',
  name: 'Deserts',
  entities: [mockCactusEntity, mockOwlEntity],
};
