import { useConfigProperty } from '@leagueplatform/config';
import type { ConfigApp } from '@web-config-app/core';

/**
 * returns the entire app-level configuration
 *
 * The config app data lives in the LeagueProvider `config`, so we implement grabbing it
 * via the league SDK `config` module
 *
 */

export const useConfigApp = () => {
  // @ts-ignore TODO: This should be replaced with `useUnknownConfigProperty` but currently there
  // is a bug where `useUnknownConfigProperty` just returns undefined. So, in the meantime, we can
  // keep using `useConfigProperty` and ts-ignore until the fix is added. See this PR in league-web:
  // https://github.com/EverlongProject/league-web/pull/13372
  const configApp = useConfigProperty('configApp') as unknown as ConfigApp;
  const env = useConfigProperty('core.appEnvironment');

  return {
    ...configApp,
    env,
  };
};
