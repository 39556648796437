import type { AnnotatedJsonSchema } from '@web-config-app/core';
import { isPrimitiveObjectSchema } from '../is-primitive-object-schema/is-primitive-object-schema';

const isArrayOrObjectSchema = ({ type }: AnnotatedJsonSchema) =>
  ['object', 'array'].includes(String(type));

/**
 * Checks if a given schema represents a primitive array.
 *
 * A primitive array is defined as an array where all items are either primitives or, optionally, primitive objects.
 *
 * @param {AnnotatedJsonSchema} schema - The schema to check.
 * @param {boolean} [omitPrimitiveObjects=false] - If true, primitive objects are not considered as primitive items, by default this is false.
 * @returns {boolean} - Returns true if the schema represents a primitive array, otherwise false.
 */
export const isPrimitiveArraySchema = (
  schema: AnnotatedJsonSchema,
  omitPrimitiveObjects: boolean = false,
) => {
  if (schema.type !== 'array') return false;

  const itemsSchema = (schema?.items ?? {}) as AnnotatedJsonSchema;
  const { type, anyOf, allOf, oneOf } = itemsSchema;

  /**
   * type could be `undefined` for combinator schemas
   */

  if (type && type !== 'object') {
    return true;
  }

  if (type === 'object' && !omitPrimitiveObjects) {
    return isPrimitiveObjectSchema(itemsSchema);
  }

  const combinator = (anyOf ?? allOf ?? oneOf) as AnnotatedJsonSchema[];

  if (combinator) {
    /**
     * if every option in the combinator array is either a primitive or a primitive object return
     * true
     */
    return combinator.every(
      (optionSchema: AnnotatedJsonSchema) =>
        !isArrayOrObjectSchema(optionSchema) ||
        (isPrimitiveObjectSchema(optionSchema) && !omitPrimitiveObjects),
    );
  }

  // I'm not sure what gets us to this condition but it's not good, return false
  return false;
};
