import * as React from 'react';
import { Tooltip } from '@leagueplatform/genesis-core';
import {
  WorkflowActionMenuItem,
  CustomWorkflowActionMenuItem,
} from '@web-config-app/core';
import { SelectActionMenuItemContents } from '../select-action-menu/select-action-menu-item-contents/select-action-menu-item-contents.component';
import { ConfirmationModal } from '../confirmation-modal/confirmation-modal.component';
import { OptionModal } from '../option-modal/option-modal.component';

interface EntityActionMenuItemProps {
  item: Omit<
    WorkflowActionMenuItem | CustomWorkflowActionMenuItem,
    'operation'
  >;
}

export const EntityActionMenuItem: React.FC<EntityActionMenuItemProps> = ({
  item,
}) => {
  const { disabled, label, tooltip, modal } = item;

  if (disabled && tooltip) {
    return (
      <Tooltip
        align="center"
        avoidCollisions
        content={tooltip}
        side="left"
        sideOffset={30}
      >
        <SelectActionMenuItemContents disabled={disabled}>
          {label}
        </SelectActionMenuItemContents>
      </Tooltip>
    );
  }

  if (modal?.type === 'confirmation') {
    return (
      <ConfirmationModal
        modalTrigger={
          <SelectActionMenuItemContents
            disabled={disabled}
            color={modal?.destructive ? '$onSurfaceTextCritical' : undefined}
          >
            {label}
          </SelectActionMenuItemContents>
        }
        title={modal?.title}
        description={modal?.description}
        confirmCtaText={modal?.confirmCtaText}
        confirmCtaAction={modal?.confirmCtaAction}
        confirmCtaOnclick={modal?.confirmCtaOnclick}
        destructive={modal?.destructive}
      />
    );
  }

  if (modal?.type === 'option') {
    return (
      <OptionModal
        modalTrigger={
          <SelectActionMenuItemContents disabled={disabled}>
            {label}
          </SelectActionMenuItemContents>
        }
        title={modal.title}
        description={modal.description}
        primaryCtaText={modal.primaryCtaText}
        primaryCtaAction={modal.primaryCtaAction}
        secondaryCtaText={modal.secondaryCtaText}
        secondaryCtaAction={modal.secondaryCtaAction}
      />
    );
  }
  return <SelectActionMenuItemContents>{label}</SelectActionMenuItemContents>;
};
