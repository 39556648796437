import { useCallback, useMemo } from 'react';
import { useHistory } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import type {
  AuthoringEnvironmentClientInfo,
  Entity,
  EntityStatus,
  EnvironmentKey,
} from '@web-config-app/core';
import type { GetEntityDetailsPathForEnvAndId } from '../use-config-app-path/use-config-app-path';
import { useAuthoringEnvironments } from '../use-authoring-environments/use-authoring-environments';
import { useEntitySingleEnvironment } from '../use-entity-single-environment/use-entity-single-environment';
import type {
  MultiEnvironmentActions,
  OtherAuthoringEnvironmentState,
} from '../../types/multi-environment.types';

interface EntityDetailsEnvironmentSelectProps {
  status: EntityStatus;
  entityName: string;
  environmentKey: EnvironmentKey;
  entityInstanceId?: string;
  otherAuthoringEnvironmentState?: OtherAuthoringEnvironmentState;
  getEntityDetailsPathForEnvAndId: GetEntityDetailsPathForEnvAndId;
  isEntitySaved: boolean;
  entity: Entity;
}

export const useEntityDetailsEnvironmentSelect = ({
  status,
  entityName,
  environmentKey,
  entityInstanceId,
  getEntityDetailsPathForEnvAndId,
  otherAuthoringEnvironmentState,
  isEntitySaved,
  entity,
}: EntityDetailsEnvironmentSelectProps) => {
  const history = useHistory();
  const { formatMessage } = useIntl();

  const {
    currentAuthoringEnvironment,
    environmentMap,
    multiEnvironmentAuthoringEnabled,
  } = useAuthoringEnvironments({ environmentKey });
  const { getEntitySingleEnvironment } = useEntitySingleEnvironment();
  const { isEntitySingleEnv } = getEntitySingleEnvironment({ entity });

  const switchEnvironment = useCallback(
    (targetEnvironment: AuthoringEnvironmentClientInfo) => () => {
      if (entityInstanceId) {
        history.push(
          getEntityDetailsPathForEnvAndId(
            entityInstanceId,
            targetEnvironment.environmentKey,
          ),
        );
      }
    },
    [history, entityInstanceId, getEntityDetailsPathForEnvAndId],
  );

  const otherAuthoringEnvironmentEntityStatus =
    otherAuthoringEnvironmentState?.entityStatus;

  const copyToEnvironmentAction =
    otherAuthoringEnvironmentState?.copyToEnvironment;

  const availableEnvironments = useMemo(() => {
    if (!(environmentMap instanceof Map)) {
      return null;
    }
    const environments = Array.from(environmentMap.values());
    return (
      environments
        .map((env) => ({
          id: env.environmentKey,
          label: formatMessage({ id: env.nameKey }),
          status:
            env.environmentKey === currentAuthoringEnvironment
              ? status
              : otherAuthoringEnvironmentEntityStatus,
          // does not need an action if we are already on the environment selected, undefined to prevent re-render of form
          action:
            env.environmentKey !== currentAuthoringEnvironment
              ? switchEnvironment(env)
              : undefined,
          showConfirmationModal:
            env.environmentKey !== currentAuthoringEnvironment
              ? !isEntitySaved
              : false,
        }))
        // filtering out if the environment does not have a status
        .filter((env) => env.status)
    );
  }, [
    environmentMap,
    formatMessage,
    currentAuthoringEnvironment,
    status,
    otherAuthoringEnvironmentEntityStatus,
    switchEnvironment,
    isEntitySaved,
  ]);

  // currently the only multi environment action is to copy to the other environment if it doesn't exist yet!
  const multiEnvironmentActions: MultiEnvironmentActions | undefined =
    useMemo(() => {
      if (
        !(environmentMap instanceof Map) ||
        otherAuthoringEnvironmentEntityStatus ||
        !otherAuthoringEnvironmentState
      ) {
        return undefined;
      }

      const otherEnvironment = environmentMap.get(
        otherAuthoringEnvironmentState.environmentKey,
      );

      if (!otherEnvironment) {
        return undefined;
      }

      const otherEnvironmentName = formatMessage({
        id: otherEnvironment.nameKey,
      });

      return [
        [
          {
            label: formatMessage(
              { id: 'COPY_TO_ENVIRONMENT_ACTION' },
              { environment: otherEnvironmentName },
            ),
            id: `copy-to-${otherEnvironment.environmentKey}`,
            action: copyToEnvironmentAction,
            disabledTooltip: !isEntitySaved
              ? formatMessage(
                  { id: 'COPY_TO_ENVIRONMENT_UNSAVED_CHANGES' },
                  { entity: entityName },
                )
              : undefined,
          },
        ],
      ];
    }, [
      environmentMap,
      otherAuthoringEnvironmentEntityStatus,
      otherAuthoringEnvironmentState,
      formatMessage,
      copyToEnvironmentAction,
      isEntitySaved,
      entityName,
    ]);

  return {
    currentAuthoringEnvironment,
    availableEnvironments,
    multiEnvironmentAuthoringEnabled,
    multiEnvironmentActions,
    isEntitySingleEnv,
  };
};
