/**
 * Generates IDs and aria-describedby string for form fields.
 *
 * @param id The base ID for the form field.
 * @param hint Whether a hint is present.
 * @param statusMessage Whether a status message is present.
 * @returns An object containing the generated IDs and aria-describedby string.
 */
export const generateFormFieldAttributes = (
  id: string,
  hint?: string,
  statusMessage?: string,
) => {
  const statusMessageId = statusMessage ? `${id}-status-message` : undefined;
  const hintId = hint ? `${id}-hint` : undefined;

  const ariaDescribedBy: string[] = [];
  if (statusMessageId) ariaDescribedBy.push(statusMessageId);
  if (hintId) ariaDescribedBy.push(hintId);

  return {
    statusMessageId,
    hintId,
    ariaDescribedBy:
      ariaDescribedBy.length > 0 ? ariaDescribedBy.join(' ') : undefined,
  };
};
