import { useMemo } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { WorkflowAction } from '@web-config-app/core';
import type { EntityWorkflowActionsProps } from '../../types/hooks';
import { useEntityFormError } from '../use-entity-form-error/use-entity-form-error.hook';

export const usePrimaryAction = ({
  operations,
  status,
  isEntitySaved,
  entityConfigName,
  environmentName,
  publishDangerously,
  isReadOnly,
}: EntityWorkflowActionsProps): WorkflowAction => {
  const { formatMessage } = useIntl();
  const { validateEntityData } = useEntityFormError();

  return useMemo(() => {
    switch (status) {
      case 'draft':
        return {
          type: 'publish',
          label: formatMessage({
            id: 'PUBLISH',
          }),
          disabled: !(
            isEntitySaved && Boolean(operations?.publish?.enableAction)
          ),
          modal: publishDangerously
            ? {
                type: 'confirmation',
                title: formatMessage(
                  { id: 'PUBLISH_DANGEROUSLY_MODAL_TITLE' },
                  { environment: environmentName },
                ),
                description: formatMessage(
                  {
                    id: 'PUBLISH_DANGEROUSLY_MODAL_DESCRIPTION',
                  },
                  {
                    entity: entityConfigName,
                    environment: environmentName,
                  },
                ),
                confirmCtaText: formatMessage(
                  {
                    id: 'PUBLISH_DANGEROUSLY_MODAL_PRIMARY_CTA',
                  },
                  {
                    environment: environmentName,
                    entity: entityConfigName,
                  },
                ),
                checkboxConfirmationLabel: formatMessage(
                  {
                    id: 'PUBLISH_DANGEROUSLY_MODAL_CHECKBOX',
                  },
                  {
                    environment: environmentName,
                    entity: entityConfigName,
                  },
                ),
                confirmCtaAction: operations?.publish?.mutate,
                onBeforeOpen: validateEntityData,
              }
            : undefined,
          display: Boolean(operations?.publish?.enableAction),
          action: () => {
            if (validateEntityData()) {
              operations?.publish?.mutate();
            }
          },
        };
      case 'published':
        return {
          type: 'republish',
          label: formatMessage({
            id: 'PUBLISH_CHANGES',
          }),
          // Disable publishing/updating when the entity is readonly
          display: Boolean(operations?.republish?.enableAction) && !isReadOnly,
          disabled: isEntitySaved,
          action: () => {
            if (validateEntityData()) {
              operations?.republish?.mutate();
            }
          },
        };
      case 'archived':
        return {
          type: 'publish',
          label: formatMessage({
            id: 'PUBLISH',
          }),
          display: false,
        };
      case 'new':
      default:
        return {
          type: 'publish',
          label: formatMessage({
            id: 'PUBLISH',
          }),
          display: false,
        };
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    entityConfigName,
    formatMessage,
    isEntitySaved,
    isReadOnly,
    publishDangerously,
    operations?.publish?.enableAction,
    operations?.publish?.mutate,
    operations?.republish?.enableAction,
    operations?.republish?.mutate,
    status,
    validateEntityData,
    environmentName,
  ]);
};
