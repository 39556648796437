import { ruleTemplatesEditorStateStore } from '@web-config-app/core';
import React, { createContext, useRef } from 'react';

export const RuleTemplatesEditorStateStoreContext = createContext<
  typeof ruleTemplatesEditorStateStore | null
>(null);

export function RuleTemplatesEditorStateStoreProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  /**
   * create a stable reference to the store using useRef, ensuring the store is only created once
   */
  const store = useRef(ruleTemplatesEditorStateStore);

  return (
    <RuleTemplatesEditorStateStoreContext.Provider value={store.current}>
      {children}
    </RuleTemplatesEditorStateStoreContext.Provider>
  );
}
