import {
  EndpointResponse,
  EndpointFetch,
  EntityConfigEndpoint,
  EndpointRequestParameters,
  ConfigApiError,
} from '@web-config-app/core';
import { getEndpointPathWithParameters } from '../get-endpoint-path-with-parameters/get-endpoint-path-with-parameters';

/**
 * Creates an abstracted resource fetcher customized to the entity endpoint passed. Its' return value
 * can be typed by passing a type that corresponds to the endpoint's return value.
 *
 * By default it uses `leagueFetch` to make requests but you can optionally pass a custom
 *
 * @param entityEndpoint - a {@link EntityConfigEndpoint} that includes `path`, `method`, `queryParameters`, and `pathParameters`
 * @param fetchFn (optional) - pass a custom resource fetcher to override the default `leagueFetch`
 *
 * @returns {@link EndpointFetch<T>} where `T` is response type of the endpoint
 */

export const createEndpointFetch = <ResponseType extends EndpointResponse>(
  {
    path: basePath,
    method,
    queryParameters,
    pathParameters,
  }: Pick<
    EntityConfigEndpoint,
    'path' | 'method' | 'queryParameters' | 'pathParameters'
  >,
  fetchFn: (path: string, options?: any) => Promise<Response>,
): EndpointFetch<ResponseType> => {
  /**
   * All params are optional. Omitting `path` will use the endpoint base
   * url as defined in the entity.
   */

  const endpointFetch = async ({
    path,
    body,
    params,
    headers,
  }: EndpointRequestParameters = {}) => {
    /**
     * construct the full API path with parameters optionally composed in. It is
     * also possible to pass a fully constructed path `path` and omitting `params`
     */

    const pathWithParameters = getEndpointPathWithParameters(
      path ?? basePath,
      params ?? [],
      pathParameters,
      queryParameters,
    );

    /**
     * by default, fetch is `leagueFetch`, see the default arguments
     * above. But we allow for passing in a custom resource fetching
     * function
     */

    const response = await fetchFn(pathWithParameters, {
      body,
      method: method.toUpperCase(),
      headers,
    });

    const data: ResponseType = await response.json();

    if (!response.ok) {
      const error: ConfigApiError = {
        status: response.status,
        name: 'API Error',
        message: `Error calling ${pathWithParameters}: ${response.status}`,
        errors: data.errors,
      };
      throw error;
    }

    return data;
  };

  return endpointFetch;
};
