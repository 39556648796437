import type { RulesTemplateWithoutEntityType } from '@web-config-app/core';
import { mapRulesTemplatesForEntity } from './utils/map-rules-templates-for-entity';

const commonActivityRulesTemplatePartials: RulesTemplateWithoutEntityType[] = [
  {
    name: 'Complete any campaign',
    id: 'activity_complete_any_campaign',
    label: 'COMPLETE_ANY_CAMPAIGN',
    type: 'completionCriteria',
    rules: {
      '==': [
        {
          var: 'campaign.all_eligible_activities_completed',
        },
        true,
      ],
    },
  },
  {
    id: 'activity_complete_any_campaign_by_type',
    name: 'Complete any campaign by type',
    label: 'COMPLETE_ANY_CAMPAIGN_BY_TYPE',
    type: 'completionCriteria',
    rules: {
      and: [
        {
          '==': [
            {
              var: 'campaign.all_eligible_activities_completed',
            },
            true,
          ],
        },
        {
          '==': [
            {
              var: 'campaign.type',
            },
            'opt-in',
            {
              log: 'Possible values are: opt-in, suggested, challenge, reward',
            },
          ],
        },
      ],
    },
  },
  {
    id: 'activity_complete_specific_campaign_or_challenge',
    name: 'Complete a specific campaign or challenge',
    label: 'COMPLETE_SPECIFIC_CAMPAIGN_OR_CHALLENGE',
    type: 'completionCriteria',
    rules: {
      and: [
        {
          '==': [
            {
              var: 'campaign.all_eligible_activities_completed',
            },
            true,
          ],
        },
        {
          '==': [
            {
              var: 'campaign.id',
            },
            '<campaign_id>',
            {
              log: 'This is the ID if the campaign. You can find it on the listing page or in Contentful',
            },
          ],
        },
      ],
    },
  },
  {
    name: 'Award points based on max points threshold',
    id: 'activity_award_points_max_points_threshold',
    label: 'ACTIVITIES_AWARD_POINTS_MAX_POINTS_THRESHOLD',
    type: 'rewardCriteria',
    rules: {
      if: [
        {
          '>=': [
            {
              var: 'reward.reward_points.total_campaign_points_earned',
            },
            {
              var: 'reward.reward_points.max_campaign_redemption_points',
            },
          ],
        },
        0,
        {
          if: [
            {
              '<=': [
                {
                  '+': [
                    {
                      var: 'reward.reward_points.activity_points',
                    },
                    {
                      var: 'reward.reward_points.total_campaign_points_earned',
                    },
                  ],
                },
                {
                  var: 'reward.reward_points.max_campaign_redemption_points',
                },
              ],
            },
            {
              var: 'reward.reward_points.activity_points',
            },
            {
              '-': [
                {
                  var: 'reward.reward_points.max_campaign_redemption_points',
                },
                {
                  var: 'reward.reward_points.total_campaign_points_earned',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: 'Complete the reward goal set for a Challenge',
    id: 'activity_complete_reward_goal_set_for_challenge',
    label: 'ACTIVITY_COMPLETE_REWARD_GOAL_SET_FOR_CHALLENGE',
    type: 'rewardCriteria',
    rules: {
      and: [
        {
          '==': [
            {
              var: 'campaign.reward_program_goal_met',
            },
            true,
          ],
        },
        {
          '==': [
            {
              var: 'campaign.type',
            },
            'challenge',
          ],
        },
      ],
    },
  },
  {
    name: 'Complete X number of activities for a specific Campaign or Challenge',
    id: 'activity_complete_x_activities_for_specific_campaign_or_challenge',
    label: 'ACTIVITY_COMPLETE_X_ACTIVITIES_FOR_SPECIFIC_CAMPAIGN_OR_CHALLENGE',
    type: 'completionCriteria',
    rules: {
      and: [
        {
          '==': [
            {
              var: 'campaign.id',
            },
            '<campaign_id>',
            { log: 'The ID of the campaign found on listing page.' },
          ],
        },
        {
          '==': [
            {
              var: 'campaign.completed_activity_count',
            },
            '<completed_activity_count>',
            { log: 'The number of activities required to be completed' },
          ],
        },
      ],
    },
  },
  {
    name: 'activity_reward_points_biometric_observation_value',
    id: 'Reward points based on the value of a biometric observation',
    label: 'ACTIVITY_REWARD_POINTS_BIOMETRIC_OBSERVATION_VALUE',
    type: 'rewardCriteria',
    rules: {
      if: [
        {
          '>=': [
            {
              var: 'reward.reward_points.total_campaign_points_earned',
            },
            {
              var: 'reward.reward_points.max_campaign_redemption_points',
            },
          ],
        },
        0,
        {
          if: [
            {
              and: [
                {
                  '>=': [
                    { log: 'Path to biometric observation.' },
                    {
                      var: 'FHIR.Observation.sbp.valueQuantity.value',
                    },
                    { log: 'Minimum value for observation.' },
                    0,
                  ],
                },
                {
                  '<=': [
                    { log: 'Path to biometric observation. Same as above.' },
                    {
                      var: 'FHIR.Observation.sbp.valueQuantity.value',
                    },
                    { log: 'Maximum value for observation.' },
                    120,
                  ],
                },
              ],
            },
            {
              if: [
                {
                  '<=': [
                    {
                      '+': [
                        {
                          var: 'reward.reward_points.activity_points',
                        },
                        {
                          var: 'reward.reward_points.total_campaign_points_earned',
                        },
                      ],
                    },
                    {
                      var: 'reward.reward_points.max_campaign_redemption_points',
                    },
                  ],
                },
                {
                  var: 'reward.reward_points.activity_points',
                },
                {
                  '-': [
                    {
                      var: 'reward.reward_points.max_campaign_redemption_points',
                    },
                    {
                      var: 'reward.reward_points.total_campaign_points_earned',
                    },
                  ],
                },
              ],
            },
            0,
          ],
        },
      ],
    },
  },
];

export const commonActivityRulesTemplates =
  commonActivityRulesTemplatePartials.map(
    mapRulesTemplatesForEntity('config-activity'),
  );

export const commonCampaignRulesTemplatePartials: RulesTemplateWithoutEntityType[] =
  [
    {
      id: 'campaign_complete_campaign_meet_reward_point_threshold',
      name: 'Complete Campaign based on meeting Reward point threshold',
      label: 'COMPLETE_CAMPAIGN_MEET_REWARD_POINT_THRESHOLD',
      type: 'completionCriteria',
      rules: {
        '>=': [
          {
            var: 'campaign.total_points_earned',
          },
          {
            var: 'campaign.max_redemption_points',
          },
        ],
      },
    },
    {
      id: 'campaign_program_segment_by_age',
      name: 'Eligibility based on user age',
      label: 'CAMPAIGN_PROGRAM_BY_AGE',
      type: 'eligibilityCriteria',
      rules: {
        '>=': [
          {
            var: 'up.age',
          },
          18,
        ],
      },
    },
    {
      id: 'campaign_program_with_one_rule_condition',
      label: 'CAMPAIGN_PROGRAM_WITH_ONE_RULE_CONDITION',
      name: 'Program with 1 rule condition (String)',
      type: 'eligibilityCriteria',
      rules: {
        string_equal_ignore_case: [
          '<insert value here within quotes>',
          {
            var: '<insert data point here within these quotes>',
          },
        ],
      },
    },
    {
      id: 'campaign_program_with_not_condition',
      label: 'CAMPAIGN_PROGRAM_WITH_NOT_CONDITION',
      name: 'Program with not condition (String)',
      type: 'eligibilityCriteria',
      rules: {
        '!': {
          string_equal_ignore_case: [
            '<insert value here within quotes>',
            {
              var: '<insert data point here within these quotes>',
            },
          ],
        },
      },
    },
    {
      id: 'campaign_program_with_all_match',
      label: 'CAMPAIGN_PROGRAM_WITH_ALL_MATCH',
      name: 'Program with all match (String)',
      type: 'eligibilityCriteria',
      rules: {
        all_strings_in_list_match_ignore_case: [
          ['<insert value 1 here>', '<insert value 2 here and so on..>'],
          {
            var: '<insert data point here within quotes>',
          },
        ],
      },
    },
    {
      id: 'campaign_program_with_any_match',
      label: 'CAMPAIGN_PROGRAM_WITH_ANY_MATCH',
      name: 'Program with any match (String)',
      type: 'eligibilityCriteria',
      rules: {
        any_string_in_list_match_ignore_case: [
          ['<insert value 1 here>', '<insert value 2 here and so on..>'],
          {
            var: '<insert data point here within quotes>',
          },
        ],
      },
    },
    {
      id: 'campaign_program_with_multiple_rule_conditions',
      label: 'CAMPAIGN_PROGRAM_WITH_MULTIPLE_RULE_CONDITIONS',
      name: 'Program with multiple rule conditions (String)',
      type: 'eligibilityCriteria',
      rules: {
        and: [
          {
            string_equal_ignore_case: [
              '<insert value here within quotes>',
              {
                var: '<insert data point here within these quotes>',
              },
            ],
          },
          {
            string_equal_ignore_case: [
              '<insert value here within quotes>',
              {
                var: '<insert data point here within these quotes>',
              },
            ],
          },
        ],
      },
    },
    {
      id: 'campaign_program_with_any_match_and_joined_before_days',
      label: 'CAMPAIGN_PROGRAM_WITH_ANY_MATCH_AND_JOINED_BEFORE_DAYS',
      name: 'Program with any match and joined before days',
      type: 'eligibilityCriteria',
      rules: {
        and: [
          {
            any_string_in_list_match_ignore_case: [
              {
                var: '<Insert datapoint here>',
              },
              '<Insert value here>',
            ],
          },
          {
            date_time_diff_gt_eq_days: [
              {
                var: 'context.current_timestamp',
              },
              {
                var: 'up.joined_timestamp',
              },
              '<Insert joined before days here>',
            ],
          },
        ],
      },
    },
    {
      id: 'campaign_eligibility_segment_even_and_odd_age',
      label: 'CAMPAIGN_ELIGIBILITY_SEGMENT_EVEN_AND_ODD_AGE',
      name: 'Segment by Even and Odd Age',
      type: 'eligibilityCriteria',
      rules: {
        '==': [
          {
            '%': [{ var: 'up.age' }, 2],
          },
          0,
        ],
      },
    },
    {
      id: 'campaign_eligibility_completed_campaign_before_date',
      label: 'CAMPAIGN_ELIGIBILITY_COMPLETED_CAMPAIGN_BEFORE_DATE',
      name: 'Check if the user completed a campaign before a certain date',
      type: 'eligibilityCriteria',
      rules: {
        some: [
          {
            filter: [
              { var: 'ucc.completed_campaigns' },
              { '==': [{ var: 'campaign_id' }, '<contentful_id>'] },
            ],
          },
          { '<': [{ var: 'completion_date' }, '<cutoff date>'] },
        ],
      },
    },
    {
      id: 'campaign_branching_waiting_for_all_active_activities_to_be_terminated',
      label:
        'CAMPAIGN_BRANCHING_WAITING_FOR_ALL_ACTIVE_ACTIVITIES_TO_BE_TERMINATED',
      name: 'Wait for all active activities to be terminated before proceeding',
      type: 'branchingRule',
      rules: {
        '!': [{ var: 'campaign_execution.active_activity_ids' }],
      },
    },
    {
      id: 'campaign_branching_user_risk_factor_input_is_one_of_values',
      label: 'CAMPAIGN_BRANCHING_USER_RISK_FACTOR_INPUT_IS_ONE_OF_VALUES',
      name: 'User risk factor input is one of values',
      type: 'branchingRule',
      rules: {
        in: [{ var: 'urfi.code' }, [1, 2]],
      },
    },
    {
      id: 'campaign_branching_questionnaire_response_is_one_of_values',
      label: 'CAMPAIGN_BRANCHING_QUESTIONNAIRE_RESPONSE_IS_ONE_OF_VALUES',
      name: 'Questionnaire response is one of values',
      type: 'branchingRule',
      rules: {
        in: [
          {
            var: 'lpf.LeagueHealthProfiles-CodeSystem-hp-primary-observation-code-code-system.question_code.value',
          },
          ['answer_code'],
        ],
      },
    },
    {
      id: 'campaign_challenge_same_company',
      label: 'CAMPAIGN_CHALLENGE_RULE_SAME_COMPANY',
      name: 'Users from the same company (clientId).',
      type: 'challengeRule',
      rules: {
        if: [
          {
            '!!': {
              var: 'client_id',
            },
          },
          {
            var: 'client_id',
          },
          'false',
        ],
      },
    },
    {
      id: 'campaign_challenge_specific_company',
      label: 'CAMPAIGN_CHALLENGE_RULE_SPECIFIC_COMPANY',
      name: 'Users from a specific company (clientId).',
      type: 'challengeRule',
      rules: {
        if: [
          {
            and: [
              {
                '==': [
                  {
                    var: 'client_id',
                  },
                  '<clientId>',
                ],
              },
            ],
          },
          '<clientId>',
          'false',
        ],
      },
    },
    {
      id: 'campaign_challenge_list_of_companies',
      label: 'CAMPAIGN_CHALLENGE_RULE_LIST_OF_COMPANIES',
      name: 'Users from a list of companies (clientId).',
      type: 'challengeRule',
      rules: {
        if: [
          {
            and: [
              {
                '==': [
                  {
                    var: 'client_id',
                  },
                  '<clientId1>',
                ],
              },
            ],
          },
          '<clientId1>',
          {
            and: [
              {
                '==': [
                  {
                    var: 'client_id',
                  },
                  '<clientId2>',
                ],
              },
            ],
          },
          '<clientId2>',
          '<copy and paste the above block here>',
          'false',
        ],
      },
    },
  ];

export const commonCampaignRulesTemplates =
  commonCampaignRulesTemplatePartials.map(
    mapRulesTemplatesForEntity('config-campaign'),
  );

export const commonMasonryFilterRulesTemplatePartials: RulesTemplateWithoutEntityType[] =
  [
    // Province filter rule
    {
      id: 'user_province_filter_rule',
      name: 'User Province Filer Rule',
      label: 'USER_PROVINCE_FILER_MASONRY',
      type: 'filterRule',
      rules: { '==': [{ var: 'userProfile.provinceCode' }, 'CA-ON'] },
    },
    // Date range filter rule
    {
      id: 'date_range_filter_rule',
      name: 'Date range filter Rule',
      label: 'DATE_RANGE_RULE_MASONRY',
      type: 'filterRule',
      rules: {
        and: [
          {
            '>': [
              { var: 'system.systemTimeFormattedUTC' },
              '2023-01-12T20:08:57',
            ],
          },
          {
            '<': [
              { var: 'system.systemTimeFormattedUTC' },
              '2024-01-12T20:08:57',
            ],
          },
        ],
      },
    },
    // LPF Code
    {
      id: 'lpf_code_filter_rule',
      name: 'LPF Code filter Rule',
      label: 'LPF_CODE_RULE_MASONRY',
      type: 'filterRule',
      rules: {
        '==': [{ var: '<LPF_observation_value_variable_name>' }, 'true'],
      },
    },
    // HPQ Risk factor rule
    {
      id: 'hpq_risk_factor_filter_rule',
      name: 'HPQ Risk factor filter Rule',
      label: 'HPQ_RISK_FACTOR_RULE_MASONRY',
      type: 'filterRule',
      rules: { '==': [{ var: '<riskFactorPresent_variable_name>' }, true] },
    },
    // Item list rule
    {
      id: 'item_list_filter_rule',
      name: 'Item List filter Rule',
      label: 'ITEM_LIST_RULE_MASONRY',
      type: 'filterRule',
      rules: { '!': { '>': [{ var: 'itemsLength' }, 0] } },
    },
    // Client version/platform rule
    {
      id: 'client_version_platform_filter_rule',
      name: 'Client Version or Platform filter Rule',
      label: 'CLIENT_VERSION_OR_PLATFORM_RULE_MASONRY',
      type: 'filterRule',
      rules: { var: 'abtest.userInFiftyPercentFlag' },
    },
    // Node dismisses rule
    {
      id: 'node_dismisses_filter_rule',
      name: 'Node dismissed filter Rule',
      label: 'NODE_DISMISSED_RULE_MASONRY',
      type: 'filterRule',
      rules: { var: 'dismissed.isDismissed' },
    },
    // Region Rule
    {
      id: 'region_filter_rule',
      name: 'Region filter Rule',
      label: 'REGION_RULE_MASONRY',
      type: 'filterRule',
      rules: { '==': [{ var: 'request.country' }, 'CA'] },
    },
    // Region with province rule
    {
      id: 'region_with_province_filter_rule',
      name: 'Region with province filter Rule',
      label: 'REGION_WITH_PROVINCE_RULE_MASONRY',
      type: 'filterRule',
      rules: {
        or: [
          {
            and: [
              { '==': [{ var: 'request.clientCountry' }, 'CA'] },
              { '==': [{ var: 'request.clientRegionCode' }, 'ON'] },
            ],
          },
          {
            and: [
              { '==': [{ var: 'request.clientCountry' }, 'CA'] },
              { '==': [{ var: 'request.clientRegionCode' }, 'MB'] },
            ],
          },
        ],
      },
    },
    // Device consent
    {
      id: 'device_concent_filter_rule',
      name: 'Device concent filter Rule',
      label: 'DEVICE_CONCENT_RULE_MASONRY',
      type: 'filterRule',
      rules: { var: 'healthClient.isConsentValid' },
    },
    // Health assessment completed rule
    {
      id: 'health_assessment_completed_filter_rule',
      name: 'Health assessment completed filter Rule',
      label: 'HEALTH_ASSESSMENT_COMPLETED_RULE_MASONRY',
      type: 'filterRule',
      rules: { var: 'healthProfile.isHealthAssessmentCompleted' },
    },
    // Health profile rule
    {
      id: 'health_profile_filter_rule',
      name: 'Health Profile filter Rule',
      label: 'HEALTH_PROFILE_RULE_MASONRY',
      type: 'filterRule',
      rules: { var: 'healthProfile.isHealthProfileEnabled' },
    },
  ];

export const commonMasonryFilterRulesTemplates =
  commonMasonryFilterRulesTemplatePartials.map(
    mapRulesTemplatesForEntity('config-node'),
  );

export const oldCommonRulesTemplates = [
  ...commonActivityRulesTemplates,
  ...commonCampaignRulesTemplates,
  ...commonMasonryFilterRulesTemplates,
].reduce(
  (rules, rule) => ({
    ...rules,
    [rule.id]: rule,
  }),
  {},
);
