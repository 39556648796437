import { mkConfig, generateCsv, download } from 'export-to-csv';
import type { ParsedEntityData } from './use-format-entity-table-data';

export interface DownloadEntityDataCSVProps {
  filename: string;
  data: ParsedEntityData;
  columnHeaders: { key: string; displayLabel: string }[];
}

export const downloadEntityDataCSV = ({
  filename,
  data,
  columnHeaders,
}: DownloadEntityDataCSVProps) => {
  const csvConfig = mkConfig({
    columnHeaders,
    filename: filename.split(' ').join('-'),
  });

  const csv = generateCsv(csvConfig)(data);

  download(csvConfig)(csv);

  return csv;
};
