import { LeagueConfig, isNodeTestEnv } from '@leagueplatform/core';
import { leagueTheme } from '../theme/league';
import { EN_STRING_OVERRIDES } from '../locales/en';
import { MOCK_EN_STRINGS } from './mock-strings';

const MOCK_CONFIG_EN_STRING_OVERRIDES = {
  ...EN_STRING_OVERRIDES,
  ...MOCK_EN_STRINGS,
};

export const mockLocalDataPoints = [
  {
    id: 'config-activity',
    description: 'data points for activities',
    dataHandlers: [{ id: 'local', description: '', version: '' }],
    dataPoints: [
      {
        id: 'reward.reward_points',
        label: 'Reward points',
        description: '',
        dataType: 'object',
        dataHandlerId: 'local',
        dataPoints: [],
      },
    ],
  },
  {
    id: 'config-campaign',
    description: 'data points for campaigns',
    dataHandlers: [{ id: 'local', description: '', version: '' }],
    dataPoints: [
      {
        id: 'campaigns',
        label: 'Campaigns',
        description: '',
        dataType: 'object',
        dataHandlerId: 'local',
        dataPoints: [],
      },
    ],
  },
];

export const mockRulesTemplates = {
  mock_activities_rules_template: {
    id: 'mock_activities_rules_template',
    name: 'Activities Rules Template',
    label: 'MOCK_ACTIVITIES_RULES_TEMPLATE',
    rules: {},
    type: 'completionCriteria',
    entityType: 'config-activity',
  },
  mock_campaigns_rules_template: {
    id: 'mock_campaigns_rules_template',
    name: 'Campaigns Rules Template',
    label: 'MOCK_CAMPAIGNS_RULES_TEMPLATE',
    rules: {
      '>=': [
        {
          var: 'campaign.total_points_earned',
        },
        {
          var: 'campaign.max_redemption_points',
        },
      ],
    },
    type: 'eligibilityCriteria',
    entityType: 'config-campaign',
  },
  mock_cactuses_rules_template: {
    id: 'mock_cactuses_rules_template',
    name: 'Cactuses Rules Template',
    label: 'MOCK_CACTUSES_RULES_TEMPLATE',
    rules: {
      and: [
        {
          any_string_in_list_match_ignore_case: [
            '{stringList}',
            {
              var: '{dataPoint}',
            },
          ],
        },
        {
          '>=': [
            {
              var: 'up.age',
            },
            '{age}',
          ],
        },
        {
          '<=': [
            {
              var: 'up.age',
            },
            '{age}',
          ],
        },
      ],
    },
    inputs: {
      stringList: {
        type: 'array',
        'x-entity-label': {
          key: 'LOCATION_TYPE',
        },
        minItems: 1,
        items: {
          type: 'string',
          'x-entity-label': {
            key: 'LOCATION',
          },
        },
      },
      dataPoint: {
        type: 'string',
        'x-entity-label': {
          key: 'MOCK_BASE_INPUT_CONTROL_LABEL',
        },
      },
      age: {
        type: 'number',
        'x-entity-label': {
          key: 'AGE',
        },
        minimum: 5,
      },
    },
    type: 'completionCriteria',
    entityType: 'config-cactus',
  },
  mock_owls_rules_template: {
    id: 'mock_owls_rules_template',
    name: 'Owls Rules Template',
    label: 'MOCK_OWLS_RULES_TEMPLATE',
    rules: {},
    type: 'eligibilityCriteria',
    entityType: 'config-pygmy-owl',
  },
} as const;

export const mockRuleCombination = {
  single_rule_condition: {
    id: `single_rule_condition`,
    name: 'Single rule condition',
    label: 'SINGLE_RULE_CONDITION',
    type: 'ruleCombination',
    rules: '{ruleCombination}',
    inputs: {
      ruleCombination: {
        type: 'string',
        'x-entity-label': {
          key: 'RULES',
        },
        format: 'json-logic',
        'x-entity-presentation': {
          rulesTemplateType: 'completionCriteria',
        },
        'x-entity-control': {
          type: 'ruleTemplateJsonLogic',
        },
      },
    },
  },
  or_at_least_one_must_match_rule_combination: {
    id: `or_at_least_one_must_match_rule_combination`,
    name: 'OR At least one must match',
    label: 'OR_AT_LEAST_ONE_MUST_MATCH_RULE_COMBINATION',
    type: 'ruleCombination',
    rules: {
      or: ['{ruleCombination}'],
    },
    inputs: {
      ruleCombination: {
        type: 'array',
        'x-entity-label': {
          key: 'RULES',
        },
        items: {
          type: 'string',
          'x-entity-label': {
            key: 'RULES',
          },
          format: 'json-logic',
          'x-entity-presentation': {
            rulesTemplateType: 'completionCriteria',
          },
          'x-entity-control': {
            type: 'ruleTemplateJsonLogic',
          },
        },
      },
    },
  },
};

export const mockConfig: LeagueConfig = {
  core: {
    clientId: 'test-app-web',
    contentUrl: 'https://contenturl.test.example.com',
    api: {
      url: 'https://apiurl.test.example.com',
      wsUrl: 'wss://wsurl.test.example.com',
      legacyRestApi: 'https://rest.test.example.com',
    },
    appEnvironment: 'development',

    /**
     * auth is required at the type level for the sake of consumers,
     * but we don't need to initialize it for tests.
     */
    // @ts-ignore
    auth: undefined,
    customMethods: {
      handleLink: async () => {},
    },
    ui: {
      theme: leagueTheme,
    },
    i18n: {
      stringOverrides: {
        default: MOCK_CONFIG_EN_STRING_OVERRIDES,
        en: MOCK_CONFIG_EN_STRING_OVERRIDES,
      },
    },
  },
  configApp: {
    configEntity: {
      name: 'Config Entity',
      id: 'config-entities',
      nameTranslationKey: 'CONFIG_ENTITY',
      category: 'core',
      endpoints: {
        list: {
          method: 'GET',
          queryParameters: [
            {
              name: 'page[size]',
              in: 'query',
              required: false,
              schema: {
                type: 'integer',
              },
              description:
                'The number of records to return in a result. Default is 10 per page.',
            },
            {
              name: 'page[offset]',
              in: 'query',
              required: false,
              schema: {
                type: 'integer',
              },
              description:
                'The Offset needed for pagination. Typically size * (page -1).',
            },
            {
              name: 'filter[domainEntityType]',
              in: 'query',
              required: false,
              schema: {
                type: 'string',
              },
            },
            {
              name: 'filter[query]',
              in: 'query',
              required: false,
              schema: {
                type: 'string',
              },
            },
          ],
          path: '/v1/config-entities',
        },
        create: {
          method: 'POST',
          path: '/v1/config-entities',
          requiredEntityMetadataFieldValues: {
            status: 'draft',
          },
        },
        update: {
          method: 'PATCH',
          pathParameters: [
            {
              name: 'entityId',
              in: 'path',
              required: true,
              schema: {
                type: 'string',
              },
              description: 'Entity Id',
            },
          ],
          path: '/v1/config-entities/{entityId}',
          requiredEntityMetadataFieldValues: {
            status: 'draft',
          },
        },
        publish: {
          method: 'PATCH',
          pathParameters: [
            {
              name: 'entityId',
              in: 'path',
              required: true,
              schema: {
                type: 'string',
              },
              description: 'Entity Id',
            },
          ],
          path: '/v1/config-entities/{entityId}',
          requiredEntityMetadataFieldValues: {
            status: 'published',
          },
        },
        republish: {
          method: 'PATCH',
          pathParameters: [
            {
              name: 'entityId',
              in: 'path',
              required: true,
              schema: {
                type: 'string',
              },
              description: 'Entity Id',
            },
          ],
          path: '/v1/config-entities/{entityId}',
          requiredEntityMetadataFieldValues: {
            status: 'published',
          },
        },
        archive: {
          method: 'PATCH',
          pathParameters: [
            {
              name: 'entityId',
              in: 'path',
              required: true,
              schema: {
                type: 'string',
              },
              description: 'Entity Id',
            },
          ],
          path: '/v1/config-entities/{entityId}',
          requiredEntityMetadataFieldValues: {
            status: 'archived',
          },
        },
        unpublish: {
          method: 'PATCH',
          pathParameters: [
            {
              name: 'entityId',
              in: 'path',
              required: true,
              schema: {
                type: 'string',
              },
              description: 'Entity Id',
            },
          ],
          path: '/v1/config-entities/{entityId}',
          requiredEntityMetadataFieldValues: {
            status: 'draft',
          },
        },
        unarchive: {
          method: 'PATCH',
          pathParameters: [
            {
              name: 'entityId',
              in: 'path',
              required: true,
              schema: {
                type: 'string',
              },
              description: 'Entity Id',
            },
          ],
          path: '/v1/config-entities/{entityId}',
          requiredEntityMetadataFieldValues: {
            status: 'draft',
          },
        },
        get: {
          method: 'GET',
          pathParameters: [
            {
              name: 'entityId',
              in: 'path',
              required: true,
              schema: {
                type: 'string',
              },
              description: 'Entity Id',
            },
          ],
          path: '/v1/config-entities/{entityId}',
        },
        deepClone: {
          method: 'POST',
          requiredEntityMetadataFieldValues: {
            status: 'draft',
            action: 'clone',
          },
          path: '/v1/config-entities',
          prefetch: {
            path: '/v1/config-entities/{entityId}',
            method: 'GET',
            pathParameters: [
              {
                name: 'entityId',
                in: 'path',
                required: true,
                schema: {
                  type: 'string',
                },
                description: 'Entity Id',
              },
            ],
            queryParameters: [
              {
                name: 'include',
                in: 'query',
                required: true,
                schema: {
                  type: 'string',
                },
                description: 'include',
              },
            ],
          },
        },
        duplicateAsDraft: {
          method: 'POST',
          path: '/v1/config-entities',
          requiredEntityMetadataFieldValues: {
            status: 'draft',
          },
        },
        copyToEnvironment: {
          method: 'POST',
          path: '/v1/config-entities',
          requiredEntityMetadataFieldValues: {
            status: 'draft',
          },
        },
      },
      apiUrl: 'http://localhost:4001',
      type: 'config-entity',
      schema: {
        type: 'object',
        properties: {
          id: {
            type: 'string',
          },
          type: {
            type: 'string',
            enum: ['config-entity'],
          },
          attributes: {
            type: 'object',
            properties: {
              entityMetadata: {
                type: 'object',
                properties: {
                  name: {
                    type: 'string',
                  },
                  status: {
                    type: 'string',
                    enum: ['draft', 'published', 'archived'],
                  },
                  lastUpdatedDate: {
                    type: 'string',
                    format: 'date-time',
                  },
                  lastPublishedDate: {
                    type: 'string',
                    format: 'date-time',
                  },
                  readOnly: {
                    type: 'boolean',
                  },
                  domainId: {
                    type: 'string',
                  },
                  domainEntityType: {
                    type: 'string',
                  },
                  editorState: {
                    type: 'string',
                  },
                  attachments: {
                    type: 'array',
                    items: {
                      type: 'object',
                      properties: {
                        assetId: {
                          type: 'string',
                        },
                      },
                    },
                  },
                },
              },
              domainEntityAttributes: {
                type: 'object',
              },
            },
          },
        },
        required: ['type', 'id'],
      },
    },
    tenantName: 'Oh hi Mark',
    tenantLogo: '',
    authoring: {
      baseEnvironment: {
        apiUrl: 'https://api.current.league.dev',
        name: 'Base Environment',
        id: 'ca:staging',
        environmentKey: 'staging',
        audience: 'https://base-audience.league.dev',
      },
      previewEnvironments: [
        {
          apiUrl: 'https://api.preview.league.dev',
          name: 'Preview Environment',
          type: 'preview',
          audience: 'https://preview-audience.league.dev',
          id: 'ca:uat',
          environmentKey: 'test',
        },
      ],
      disableMultiEnvironmentOperations: false,
    },
    domains: [
      {
        id: 'journey',
        name: 'Journey',
        icon: 'domainJourney',
        category: 'default',
        entities: [
          {
            id: 'activities',
            name: 'Activities',
            nameTranslationKey: 'ACTIVITIES',
            type: 'config-activity',
            category: 'default',
          },
          {
            name: 'Campaigns',
            id: 'campaigns',
            nameTranslationKey: 'CAMPAIGNS',
            type: 'config-campaign',
            category: 'default',
            singleEnvironment: 'base',
          },
          {
            name: 'Hidden entity',
            id: 'hidden-entity',
            nameTranslationKey: 'HIDDEN_ENTITY',
            type: 'config-hidden',
            category: 'hidden',
          },
        ],
      },
      {
        id: 'masonry',
        name: 'Masonry',
        icon: 'illustrativeCompose',
        category: 'default',
        entities: [
          {
            name: 'Containers',
            id: 'containers',
            nameTranslationKey: 'CONTAINERS',
            category: 'default',
          },
          {
            name: 'Widgets',
            id: 'widgets',
            nameTranslationKey: 'WIDGETS',
            category: 'default',
          },
        ],
      },
      {
        id: 'management',
        name: 'Management',
        icon: 'illustrativeCompose',
        category: 'hidden',
        entities: [
          {
            name: 'Assets',
            id: 'assets',
            type: 'config-asset',
            nameTranslationKey: 'ASSETS',
            category: 'hidden',
            endpoints: {
              create: '/create',
              get: {
                path: '/get-config-asset/{assetId}',
                pathParameters: [
                  {
                    name: 'assetId',
                    schema: { type: 'string' },
                    in: 'path',
                  },
                ],
              },
            },
          },
        ],
      },
    ],
    rulesTemplates: mockRulesTemplates,
    dataPoints: mockLocalDataPoints,
  },

  __internal: { isNodeTestEnv: isNodeTestEnv() },
};
