import { useMemo } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { isProduction } from '@leagueplatform/core';
import type { WorkflowAction } from '@web-config-app/core';
import { draftErrorFilterKeywords } from '@web-config-app/validation';
import type { EntityWorkflowActionsProps } from '../../types/hooks';
import { useEntityFormError } from '../use-entity-form-error/use-entity-form-error.hook';

export const useSecondaryAction = ({
  operations,
  status,
  isEntitySaved,
}: EntityWorkflowActionsProps): WorkflowAction => {
  const { formatMessage } = useIntl();
  const { validateEntityData } = useEntityFormError();

  return useMemo(() => {
    switch (status) {
      case 'draft':
        return {
          type: 'update',
          label: formatMessage({
            id: 'SAVE_CHANGES',
          }),
          disabled: isEntitySaved,
          display: Boolean(operations?.update?.enableAction),
          action: () => {
            // TODO:: Remove with https://everlong.atlassian.net/browse/CACT-1533
            if (isProduction()) {
              operations?.update?.mutate();
            } else if (
              validateEntityData({
                filteredErrorKeywords: draftErrorFilterKeywords,
              })
            ) {
              operations?.update?.mutate();
            }
          },
        };
      case 'published':
        return {
          type: 'update',
          label: formatMessage({
            id: 'SAVE_CHANGES',
          }),
          display: false,
        };
      case 'archived':
        return {
          type: 'unarchive',
          label: formatMessage({
            id: 'UN_ARCHIVE',
          }),
          display: Boolean(operations?.unarchive?.enableAction),
          action: operations?.unarchive?.mutate,
        };
      case 'new':
      default:
        return {
          type: 'create',
          label: formatMessage({
            id: 'SAVE_DRAFT',
          }),
          display: Boolean(operations?.create?.enableAction),
          action: () => {
            // TODO:: Remove with https://everlong.atlassian.net/browse/CACT-1533
            if (isProduction()) {
              operations?.create?.mutate();
            } else if (
              validateEntityData({
                filteredErrorKeywords: draftErrorFilterKeywords,
              })
            ) {
              operations?.create?.mutate();
            }
          },
        };
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    formatMessage,
    isEntitySaved,
    operations?.create?.enableAction,
    operations?.create?.mutate,
    operations?.update?.enableAction,
    operations?.update?.mutate,
    operations?.unarchive?.enableAction,
    operations?.unarchive?.mutate,
    status,
    validateEntityData,
  ]);
};
