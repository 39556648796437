import {
  EntityDetailSchema,
  EntityRootData,
  EntityDetail,
  EntityDetailSchemaWithRelationships,
  DataSource,
  LocalDataSource,
  RelationshipDataSource,
  MergedDataSource,
  DMLDataSource,
} from '@web-config-app/core';
import { getRelationshipSchemaItemType } from '@web-config-app/schema-utils';
import { cloneDeep } from 'lodash-es';
import jp from 'jsonpath';
import { isUsingConfigAppBackend } from '@web-config-app/common';

/**
 * This will limit the data source to include ONLY entities of the type
 * defined in the linked relationship
 */

const getIncludedDataSourceForRelationship = (
  includedEntities: EntityDetail[] | undefined,
  relationship: string | undefined,
  schema: EntityDetailSchemaWithRelationships,
) => {
  if (!relationship) return includedEntities;
  const { type } = getRelationshipSchemaItemType(relationship, schema);
  return includedEntities?.filter((entity) => entity.type === type);
};

type BaseDataSource = LocalDataSource | RelationshipDataSource;
const isLocalDataSource = (
  dataSource:
    | LocalDataSource
    | RelationshipDataSource
    | MergedDataSource
    | DMLDataSource,
): dataSource is LocalDataSource => 'path' in dataSource;

const isRelationshipDataSource = (
  dataSource:
    | LocalDataSource
    | RelationshipDataSource
    | MergedDataSource
    | DMLDataSource,
): dataSource is RelationshipDataSource => 'relationship' in dataSource;

const isMergedDataSource = (
  dataSource:
    | LocalDataSource
    | RelationshipDataSource
    | MergedDataSource
    | DMLDataSource,
): dataSource is MergedDataSource => 'mergeSources' in dataSource;

const isBaseDataSource = (
  dataSource: DataSource,
): dataSource is BaseDataSource =>
  isLocalDataSource(dataSource) || isRelationshipDataSource(dataSource);

/**
 * Adjusts the path based on whether the tenant is using config app backend
 * For config app backend, the data is under attributes.domainEntityAttributes
 * For other backends, the data is directly under attributes
 */
const getAdjustedPath = (
  path: string,
  isTenantUsingConfigAppBackend?: boolean,
  isEntityUsingConfigAppBackend?: boolean,
) => {
  // If the path already starts with $.attributes, we need to adjust it
  if (
    path.startsWith('$.attributes') &&
    isUsingConfigAppBackend({
      tenantLevelOverride: isTenantUsingConfigAppBackend,
      entityLevelOverride: isEntityUsingConfigAppBackend,
    })
  ) {
    return path.replace('$.attributes', '$.attributes.domainEntityAttributes');
  }
  return path;
};

/**
 * Get any data sources that have been declared in the entity schema's root using
 * the {@link EntityDataSourcesAnnotation} annotation
 */

export const getSchemaDataSources = (
  {
    'x-entity-data-source-registry': dataSourceRegistry,
    ...schema
  }: EntityDetailSchema,
  data: EntityRootData | undefined,
  includedEntities?: EntityDetail[],
  isTenantUsingConfigAppBackend?: boolean,
  isEntityUsingConfigAppBackend?: boolean,
) => {
  const allSources = dataSourceRegistry?.sources ?? [];
  const mergedSources = allSources.filter(isMergedDataSource);
  const compiledBaseSources = allSources
    .filter(isBaseDataSource)
    .map((source) =>
      isRelationshipDataSource(source)
        ? {
            name: source.name,
            value: getIncludedDataSourceForRelationship(
              includedEntities,
              source.relationship,
              schema,
            ),
          }
        : {
            name: source.name,
            value: cloneDeep(
              jp.value(
                data ?? {},
                getAdjustedPath(
                  source.path,
                  isTenantUsingConfigAppBackend,
                  isEntityUsingConfigAppBackend,
                ),
              ),
            ),
          },
    );

  const compiledMergedSources = mergedSources.map(
    ({ baseSource: baseSourceName, mergeSources, name: mergeSourceName }) => {
      const baseSource = compiledBaseSources.find(
        (mergeSource) => mergeSource.name === baseSourceName,
      );

      const sourcesData = mergeSources
        .filter((source) => source.dataSource !== baseSourceName)
        .map(
          ({
            dataSource: mergeDataSourceName,
            identifier,
            baseIdentifier,
            valueField,
          }) => {
            const sourceData = compiledBaseSources.find(
              (baseSourceItem) => baseSourceItem.name === mergeDataSourceName,
            );
            if (!sourceData) {
              throw new Error(`Data source ${mergeDataSourceName} not found`);
            }
            return {
              data: sourceData.value,
              identifier,
              baseIdentifier,
              valueField,
            };
          },
        );

      return {
        name: mergeSourceName,
        value: {
          baseSource,
          mergeSources: sourcesData,
        },
      };
    },
  );

  return [...compiledBaseSources, ...compiledMergedSources];
};
