import { useCallback } from 'react';
import { useSetEntityOperationLoadingMsg } from '@web-config-app/core-react';

/**
 * Defines handlers used by multiple operation events and passed to
 * the react-query useMutation and useQuery hooks.
 */

export const useCommonOperationEventHandlers = () => {
  const setEntityOperationLoadingMsg = useSetEntityOperationLoadingMsg();

  const onSettled = useCallback(() => {
    setEntityOperationLoadingMsg(null);
  }, [setEntityOperationLoadingMsg]);

  return { onSettled };
};
