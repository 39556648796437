import * as React from 'react';
import type {
  Entity,
  EntityDetail,
  EntityDetailSchema,
  EnvironmentKey,
  OptionalOperationType,
} from '@web-config-app/core';
import { isUsingConfigAppBackend } from '@web-config-app/common';
import {
  getEntityInstanceName,
  ruleTemplatesEditorStateStore,
  optionalOperations,
} from '@web-config-app/core';
import {
  useConfigApp,
  useConfigEntity,
  useEntityOperations,
  useEntityData,
  useEntityMetadata,
  useSetEntityData,
  useResetEntityData,
  useIncludedEntities,
  useAddIncludedEntity,
  useSetIncludedEntities,
  parseEditorStateEntityMetadata,
  useUpdateEditorStateInEntityMetadata,
  useRuleTemplatesEditorStateStoreActions,
} from '@web-config-app/core-react';
import type {
  UseEntityOperationsProps,
  UseMultiEnvironmentEntityOperationsProps,
} from '@web-config-app/core-react';
import {
  EntityDetailsProvider,
  createDefaultValueForSchema,
  createDefaultValueForConfigEntity,
  useSyncDataSources,
} from '@web-config-app/entity-form';
import {
  applyDefaultSchemaAnnotations,
  computeSchema,
  applyConditionalLogic,
} from '@web-config-app/schema-utils';
import { EntityDetailsMultiEnvironmentContainer } from '../entity-details-multi-environment-container/entity-details-multi-environment.container';

const isOptionalOperation = (
  operation: string,
): operation is OptionalOperationType =>
  optionalOperations.includes(operation as OptionalOperationType);

const getDomainEntityEnabledOptionalOperations = (
  entity: Entity,
  isConfigAppBackendEnabled: boolean,
) => {
  if (!isConfigAppBackendEnabled) {
    return null;
  }

  return Object.keys(entity.endpoints).filter(isOptionalOperation);
};

export interface EntityDetailsPageContainerProps {
  entity: Entity;
  // TODO:: Fix inconsistent typing of instanceId since it can be undefined
  // https://everlong.atlassian.net/browse/CACT-1600
  instanceId: string;
  currentAuthoringEnvironment: EnvironmentKey;
  path: string;
  options?: UseEntityOperationsProps['options'];
  multiEnvironmentOptions?: UseMultiEnvironmentEntityOperationsProps['options'];
  createDefaultEntityData?: boolean;
  otherAuthoringEnvironment?: EnvironmentKey;
}

export const EntityDetailsPageContainer = ({
  entity,
  instanceId,
  currentAuthoringEnvironment,
  options,
  multiEnvironmentOptions,
  path,
  children,
  createDefaultEntityData,
  otherAuthoringEnvironment,
}: React.PropsWithChildren<EntityDetailsPageContainerProps>) => {
  const { isUsingConfigAppBackend: tenantIsUsingConfigAppBackend } =
    useConfigApp();
  const configEntity = useConfigEntity({ domainEntity: entity });

  const isConfigAppBackendEnabled = isUsingConfigAppBackend({
    tenantLevelOverride: tenantIsUsingConfigAppBackend,
    entityLevelOverride: entity.isUsingConfigAppBackend,
  });

  /**
   * The base schema is the schema as it is stored in the entity config without
   * any conditional logic applied.
   */
  const baseSchema = React.useMemo(
    () => applyDefaultSchemaAnnotations(entity.schema),
    [entity.schema],
  );
  const defaultEntityData = React.useMemo(() => {
    if (!createDefaultEntityData) {
      return undefined;
    }

    return isConfigAppBackendEnabled
      ? createDefaultValueForConfigEntity(configEntity.schema, entity.schema)
      : createDefaultValueForSchema<EntityDetail>(baseSchema);
  }, [
    baseSchema,
    createDefaultEntityData,
    configEntity.schema,
    entity.schema,
    isConfigAppBackendEnabled,
  ]);

  const entityRootData = useEntityData();
  const setEntityData = useSetEntityData();
  const resetEntityData = useResetEntityData();
  const includedEntities = useIncludedEntities();
  const setIncludedEntities = useSetIncludedEntities();
  const addIncludedEntity = useAddIncludedEntity();
  const { setRuleTemplatesData, resetRuleTemplatesData } =
    useRuleTemplatesEditorStateStoreActions();
  const { status, name: entityMetaDataName } = useEntityMetadata();
  const updateEditorStateInEntityMetadata =
    useUpdateEditorStateInEntityMetadata();

  /**
   * Subscribes to changes in the ruleTemplatesEditorStateStore and updates the entity metadata with the latest editor state.
   */
  ruleTemplatesEditorStateStore.subscribe((state) => {
    const ruleTemplatesEditorData = state.ruleTemplatesData;
    if (!ruleTemplatesEditorData) return;
    // TODO:: ensure this only writes to ruleTemplates in editorState https://everlong.atlassian.net/browse/CACT-2061
    updateEditorStateInEntityMetadata({
      ruleTemplates: ruleTemplatesEditorData,
    });
  });

  /**
   * Reset rule template editor state on unmount of page
   */
  React.useEffect(
    () => () => {
      resetRuleTemplatesData();
    },
    [resetRuleTemplatesData],
  );

  /**
   * The name of the entity is either stored in entityMetadata or is located
   * in some custom location resulting in extra work being done inside
   * `getEntityInstanceName`.
   *
   * TODO: Refactor in https://everlong.atlassian.net/browse/CACT-1875
   */
  const name =
    entityMetaDataName || getEntityInstanceName(entityRootData, entity);
  /**
   * We apply conditional rendering to the baseSchema using the current
   * entity data which will return either the full baseSchema or a schema
   * with some properties removed in the event that the conditions for
   * including them are not met
   */

  const rootSchema = React.useMemo(() => {
    const computedAttributesSchema = computeSchema(
      baseSchema.properties.attributes,
      isConfigAppBackendEnabled
        ? entityRootData?.attributes?.domainEntityAttributes
        : entityRootData?.attributes,
      [applyConditionalLogic],
      { recursive: false },
    );

    return {
      ...baseSchema,
      properties: {
        ...baseSchema.properties,
        attributes: computedAttributesSchema,
      },
    } as EntityDetailSchema;
  }, [baseSchema, entityRootData?.attributes, isConfigAppBackendEnabled]);

  useSyncDataSources(
    rootSchema,
    entityRootData ?? {},
    includedEntities,
    entity,
  );

  const operationsEntity = isConfigAppBackendEnabled ? configEntity : entity;

  const domainEntityEnabledOptionalOperations =
    getDomainEntityEnabledOptionalOperations(entity, isConfigAppBackendEnabled);

  const operations = useEntityOperations({
    entity: operationsEntity,
    domainEntityEnabledOptionalOperations,
    instanceId,
    options,
    entityRootData: entityRootData ?? {},
    onEntityGetSuccess: (data, includedEntitiesData) => {
      setEntityData(data);
      setIncludedEntities(includedEntitiesData);

      const editorStateEntityMetadata = parseEditorStateEntityMetadata(data);
      if (editorStateEntityMetadata?.ruleTemplates) {
        setRuleTemplatesData(editorStateEntityMetadata.ruleTemplates);
      }
    },
    environment: currentAuthoringEnvironment,
  });

  React.useEffect(() => {
    if (createDefaultEntityData && defaultEntityData) {
      setEntityData(defaultEntityData);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return otherAuthoringEnvironment ? (
    <EntityDetailsMultiEnvironmentContainer
      name={name}
      status={status}
      entity={entity}
      instanceId={instanceId}
      multiEnvironmentOptions={multiEnvironmentOptions}
      entityRootData={entityRootData}
      setEntityData={setEntityData}
      currentAuthoringEnvironment={currentAuthoringEnvironment}
      otherAuthoringEnvironment={otherAuthoringEnvironment}
      operations={operations}
      rootSchema={rootSchema}
      path={path}
      includedEntities={includedEntities}
      setIncludedEntities={setIncludedEntities}
      addIncludedEntity={addIncludedEntity}
      resetEntityData={resetEntityData}
      isUsingConfigAppBackend={isConfigAppBackendEnabled}
    >
      {children}
    </EntityDetailsMultiEnvironmentContainer>
  ) : (
    <EntityDetailsProvider
      name={name}
      status={status}
      entityRootData={entityRootData}
      setEntityData={setEntityData}
      currentAuthoringEnvironment={currentAuthoringEnvironment}
      operations={operations}
      rootSchema={rootSchema}
      path={path}
      instanceId={instanceId}
      entityType={entity.type}
      entity={entity}
      configEntitySchema={configEntity.schema}
      includedEntities={includedEntities}
      setIncludedEntities={setIncludedEntities}
      addIncludedEntity={addIncludedEntity}
      resetEntityData={resetEntityData}
      isUsingConfigAppBackend={isConfigAppBackendEnabled}
    >
      {children}
    </EntityDetailsProvider>
  );
};
