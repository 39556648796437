import { useMemo, useEffect } from 'react';
import {
  type EntityRootData,
  type EntityDetailSchema,
  type Entity,
  type EntityStatus,
} from '@web-config-app/core';
import { useConfigApp } from '@web-config-app/core-react';
import { isUsingConfigAppBackend } from '@web-config-app/common';
import { get } from 'lodash-es';
import { useEntityTree } from '../use-entity-tree/use-entity-tree';
import { useEntityTreeState } from '../use-entity-tree-state/use-entity-tree-state';
import type { TreeNode } from '../../types/tree';

export interface UseEntityDetailsProps {
  rootSchema: EntityDetailSchema;
  entity: Entity;
  entityRootData: EntityRootData | undefined;
  setEntityData: (data: any, path: string) => void;
  path?: string;
  instanceId?: string;
  onTreeNodeSelect?: (args: any) => void;
  name: string | undefined;
  status: EntityStatus;
}

export type UseEntityDetailsResult = ReturnType<typeof useEntityDetails>;

const rootPaths = ['root', ''];
const getFullEntityDataPath = (
  path: string,
  isTenantUsingConfigAppBackend?: boolean,
  isEntityUsingConfigAppBackend?: boolean,
) => {
  const baseDataPath = isUsingConfigAppBackend({
    tenantLevelOverride: isTenantUsingConfigAppBackend,
    entityLevelOverride: isEntityUsingConfigAppBackend,
  })
    ? 'attributes.domainEntityAttributes'
    : 'attributes';
  return rootPaths.includes(path) ? baseDataPath : `${baseDataPath}.${path}`;
};

export const useEntityDetails = ({
  rootSchema,
  entity,
  entityRootData,
  path,
  instanceId,
  onTreeNodeSelect,
  setEntityData,
  name,
  status,
}: UseEntityDetailsProps) => {
  const { isUsingConfigAppBackend: isTenantUsingConfigAppBackend } =
    useConfigApp();
  const {
    expandedNodes,
    toggleNode,
    expandParentNodesForPath,
    resetNodes: resetEntityTreeNodes,
  } = useEntityTreeState((state) => state);

  const { entityTree, nodeMap, handleNodeToggle } = useEntityTree({
    schema: isUsingConfigAppBackend({
      tenantLevelOverride: isTenantUsingConfigAppBackend,
      entityLevelOverride: entity.isUsingConfigAppBackend,
    })
      ? rootSchema.properties.attributes
      : rootSchema,
    data: entityRootData,
    onSelect: (node: TreeNode) => {
      const nodeId = typeof node === 'string' ? node : node?.id;
      onTreeNodeSelect?.(node);
      if (typeof nodeId === 'string') {
        toggleNode(nodeId);
      }
    },
    entity,
  });

  const { formSchema, formData, formPath } = useMemo(() => {
    if (path) {
      const currentNode = nodeMap.get(path);
      if (currentNode && currentNode.data) {
        const {
          data: { schema: schemaForPath },
        } = currentNode;
        const dataPath = getFullEntityDataPath(
          path,
          isTenantUsingConfigAppBackend,
          entity.isUsingConfigAppBackend,
        );
        const dataForPath = get(entityRootData, dataPath);

        return {
          formSchema: schemaForPath,
          formData: dataForPath,
          formPath: path,
        };
      }
    }

    return {
      formSchema: undefined,
      formData: undefined,
      formPath: undefined,
    };
  }, [
    entityRootData,
    nodeMap,
    path,
    isTenantUsingConfigAppBackend,
    entity.isUsingConfigAppBackend,
  ]);

  useEffect(() => {
    /**
     * Ensure that the node for the current path as well as any
     * parent nodes are expanded. This may be necessary in cases where
     * a form is opened via deep link or object card
     */
    if (formPath) {
      expandParentNodesForPath(formPath);
    }
  }, [formPath, expandParentNodesForPath]);
  /**
   * pass this function to the EntityForm so that as its data changes, it can update the `rootData` accordingly.
   */
  const onFormDataChange = (updatedFormData: any) => {
    const dataPath = getFullEntityDataPath(
      path ?? '',
      isTenantUsingConfigAppBackend,
      entity.isUsingConfigAppBackend,
    );
    setEntityData(updatedFormData, dataPath);
  };

  const setEntityName = (newName: string) => {
    setEntityData(newName, 'attributes.entityMetadata.name');
  };

  const isReadOnly = entityRootData?.attributes?.entityMetadata?.readOnly;

  return {
    name,
    status,
    instanceId,
    entityTree,
    nodeMap,
    expandedNodes,
    handleNodeToggle,
    setEntityData,
    setEntityName,
    resetEntityTreeNodes,
    rootSchema,
    formSchema,
    formData,
    formPath,
    onFormDataChange,
    isReadOnly,
  };
};
