import { useMemo } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { environmentKeys } from '@web-config-app/core';
import type { EntityDetail, EnvironmentKey } from '@web-config-app/core';

export type ParsedEntityData = { path: string; data?: any; otherData?: any }[];

const parseEntityData = (
  data: any,
  parentPath = '',
): { path: string; data?: any }[] => {
  let result: { path: string; data?: any }[] = [];

  Object.keys(data ?? {}).forEach((key) => {
    const currentPath = parentPath ? `${parentPath}.${key}` : key;
    if (typeof data[key] === 'object' && data[key] !== null) {
      result = result.concat(parseEntityData(data[key], currentPath));
    } else {
      const rowData = typeof data[key] !== 'object' ? `${data[key]}` : '';
      result.push({ path: currentPath, data: rowData });
    }
  });

  return result;
};

export interface EnvironmentData {
  key: EnvironmentKey;
  data: Partial<EntityDetail> | undefined;
}

export interface SingleEnvironmentDataTableRow {
  path: string;
  data?: any;
}

export interface MultiEnvironmentDataTableRow
  extends SingleEnvironmentDataTableRow {
  otherData?: any;
}

export type DataTableRow =
  | SingleEnvironmentDataTableRow
  | MultiEnvironmentDataTableRow;

export const useFormatEntityTableData = ({
  currentEnvironmentData,
  otherEnvironmentData,
}: {
  currentEnvironmentData: EnvironmentData;
  otherEnvironmentData?: EnvironmentData;
}): {
  rows: DataTableRow[];
  isMultiEnvironment: boolean;
  columnHeaders: { key: string; displayLabel: string }[];
} => {
  const { formatMessage } = useIntl();

  return useMemo(() => {
    const current = parseEntityData(currentEnvironmentData?.data?.attributes);

    if (otherEnvironmentData?.data === undefined) {
      return {
        isMultiEnvironment: false,
        rows: current,
        columnHeaders: [
          {
            key: 'path',
            displayLabel: formatMessage({ id: 'PATH' }),
          },
          {
            key: 'data',
            displayLabel: formatMessage({ id: 'VALUE' }),
          },
        ],
      };
    }

    const other = parseEntityData(otherEnvironmentData?.data?.attributes);
    const paths: string[] = Array.from(
      new Set([...current, ...(other ?? [])].map(({ path }) => path)),
    );

    return {
      isMultiEnvironment: true,
      rows: paths.map((path) => ({
        path,
        data: current.find((item) => item.path === path)?.data,
        otherData: other.find((item) => item.path === path)?.data,
      })),
      columnHeaders: [
        {
          key: 'path',
          displayLabel: formatMessage({ id: 'PATH' }),
        },
        {
          key: 'data',
          displayLabel: formatMessage(
            { id: 'DATA_FOR_ENVIRONMENT' },
            {
              environment: formatMessage({
                id: environmentKeys[currentEnvironmentData.key],
              }),
            },
          ),
        },
        {
          key: 'otherData',
          displayLabel: formatMessage(
            { id: 'DATA_FOR_ENVIRONMENT' },
            {
              environment: formatMessage({
                id: environmentKeys[otherEnvironmentData.key],
              }),
            },
          ),
        },
      ],
    };
  }, [currentEnvironmentData, otherEnvironmentData, formatMessage]);
};
