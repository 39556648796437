import * as React from 'react';

export const AttachmentIconSvg = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 7.5C4.5 3.35786 7.85786 0 12 0C16.1421 0 19.5 3.35786 19.5 7.5V15.749C19.5 16.1632 19.1642 16.499 18.75 16.499C18.3358 16.499 18 16.1632 18 15.749V7.5C18 4.18629 15.3137 1.5 12 1.5C8.68629 1.5 6 4.18629 6 7.5V18.75C6 20.8211 7.67893 22.5 9.75 22.5C11.8211 22.5 13.5 20.8211 13.5 18.75V7.5C13.5 6.67157 12.8284 6 12 6C11.1716 6 10.5 6.67157 10.5 7.5V17.25C10.5 17.6642 10.1642 18 9.75 18C9.33579 18 9 17.6642 9 17.25V7.5C9 5.84315 10.3431 4.5 12 4.5C13.6569 4.5 15 5.84315 15 7.5V18.75C15 21.6495 12.6495 24 9.75 24C6.8505 24 4.5 21.6495 4.5 18.75V7.5Z"
      fill="currentColor"
    />
  </svg>
);
