import * as React from 'react';
import { EntityActionMenuItem } from '@web-config-app/core-react-ui';
import type {
  CustomWorkflowMultiStepActionMenuItem,
  Entity,
  EntityConfigMultiStepCustomOperation,
  EnvironmentKey,
} from '@web-config-app/core';
import { useEntityMultiStepCustomOperation } from '@web-config-app/core-react';
import type { UseEntityCustomOperationOptions } from '@web-config-app/core-react';

export interface CustomEntityMultiStepActionMenuItemProps {
  item: Omit<CustomWorkflowMultiStepActionMenuItem, 'operation'>;
  operation: EntityConfigMultiStepCustomOperation;
  entity: Entity;
  data: any;
  entityName: string;
  environment: EnvironmentKey;
  registerCustomMenuAction: (itemId: string, action: () => void) => void;
  options: UseEntityCustomOperationOptions | undefined;
}

export const CustomEntityMultiStepActionMenuItem = ({
  item,
  entity,
  operation,
  data,
  environment,
  registerCustomMenuAction,
  entityName,
  options,
}: CustomEntityMultiStepActionMenuItemProps) => {
  const run = useEntityMultiStepCustomOperation({
    entity,
    data,
    entityName,
    environment,
    operation,
    options,
  });

  /**
   * Register the custom action with the container, which needs to pass
   * the register function as a prop to this component.
   */
  React.useEffect(() => {
    if (!item.modal) {
      registerCustomMenuAction(item.id, run);
    }
  }, [item, run, registerCustomMenuAction]);

  const menuItem = {
    ...item,
    modal: item.modal ? { ...item.modal, confirmCtaAction: run } : undefined,
  };

  return <EntityActionMenuItem item={menuItem} />;
};
