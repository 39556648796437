import * as React from 'react';
import {
  ControlComponent,
  EntityFormControlProps,
} from '../../../../types/controls';
import { EntityFormControl } from '../../../entity-form-control/entity-form-control.component';
import { AddItemModalTriggers } from '../../../add-item-modal-triggers/add-item-modal-triggers.component';
import { AddItemByIdModal } from '../../../add-item-by-id-modal/add-item-by-id-modal.component';

import { AttachmentListItem } from './attachment-list-item.component';
import { useAttachmentInputControl } from './use-attachment-input-control.hook';
import { UploadAssetModal } from '../upload-asset-modal/upload-asset-modal.component';

const AttachmentInputControlContents = ({
  data,
  handleChange,
  path,
  enabled,
}: EntityFormControlProps) => {
  const { addItemTriggerList, addItemModalProps, uploadAttachmentModalProps } =
    useAttachmentInputControl({ handleChange, path });

  return data ? (
    <AttachmentListItem imageData={data} />
  ) : (
    <>
      <AddItemModalTriggers
        triggerList={addItemTriggerList}
        disabled={!enabled}
      />
      <AddItemByIdModal {...addItemModalProps} />
      <UploadAssetModal {...uploadAttachmentModalProps} fileType="pdf" />
    </>
  );
};

export const AttachmentInputControl: ControlComponent = (props) => (
  <EntityFormControl
    {...props}
    renderControl={AttachmentInputControlContents}
  />
);
