import { useIntl } from '@leagueplatform/locales';
import { ConfigErrorType } from '@web-config-app/core';
import { useConfigUserFeedback } from '../../state/config-user-feedback-state/config-user-feedback-state';

/**
 * useConfigErrorBanner is responsible for the logic of the error banner and determines the errorBanner contents based on the errorType
 */

export const useConfigErrorBanner = () => {
  const { formatMessage } = useIntl();

  const {
    errorType,
    configErrors,
    showFormValidation,
    filteredErrorKeywords,
    showErrorBanner,
    hideErrorBanner,
  } = useConfigUserFeedback((state) => ({
    showErrorBanner:
      state.showErrorBanner && Boolean(state.configError?.errors?.length),
    errorType: state.configError?.type,
    configErrors: state.configError?.errors,
    showFormValidation: state.showFormValidation,
    filteredErrorKeywords: state.filteredErrorKeywords,
    hideErrorBanner: () => state.setShowErrorBanner(false),
  }));

  const errorArrayAmount = configErrors?.length;

  const bannerTitle: Record<ConfigErrorType, string> = {
    [ConfigErrorType.ServerError]: 'SERVER_ERROR',
    [ConfigErrorType.ClientError]: 'CONFIGURATION_ERROR',
    [ConfigErrorType.FormValidationError]: 'CONFIGURATION_ERROR',
  };

  // Get the banner title based on errorType, defaulting to 'ERROR'
  const errorBannerTitle = formatMessage(
    {
      id: errorType ? bannerTitle[errorType] : 'ERROR',
    },
    { count: errorArrayAmount },
  );
  // Get the banner contents based on errorType, the default is an empty array
  const errorBannerContents =
    errorType === ConfigErrorType.ServerError
      ? {
          errorMessage: configErrors?.[0]?.detail || '',
        }
      : { errorList: configErrors || [] };
  return {
    showErrorBanner,
    hideErrorBanner,
    errorBannerContents,
    errorBannerTitle,
    showFormValidation,
    filteredErrorKeywords,
  };
};
