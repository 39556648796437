import * as React from 'react';
import {
  Icon,
  StackLayout,
  TextAction,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { CopyButton, PdfIconSvg } from '@web-config-app/core-react-ui';
import imageUploadErrorIcon from 'assets/image-upload-error.png';
import { useGetAssetDataById } from '../use-get-asset-data-by-id.hook';
import { SelectedImageLoadingSkeleton } from '../image-input-control/selected-image/selected-image-loading-skeleton.component';

type AttachmentListItemProps = {
  imageData: string;
};

export const AttachmentListItem = ({ imageData }: AttachmentListItemProps) => {
  const { formatMessage } = useIntl();

  const {
    data: imageDataFromId,
    isLoading,
    isError,
  } = useGetAssetDataById(imageData, { enabled: true });

  if (isLoading) {
    return <SelectedImageLoadingSkeleton />;
  }

  const displayErrorFallback = isError || !imageDataFromId;

  const getAttachmentTitleAndUrl = () => {
    if (displayErrorFallback) {
      return {
        title: formatMessage({ id: 'COULD_NOT_LOAD_ATTACHMENT' }),
        url: '',
      };
    }
    return {
      title: imageDataFromId?.attributes.title,
      url: imageDataFromId?.attributes.url,
    };
  };

  const { title, url } = getAttachmentTitleAndUrl();

  return (
    <StackLayout
      horizontalAlignment="spaceBetween"
      orientation="horizontal"
      verticalAlignment="center"
      spacing="$half"
    >
      {displayErrorFallback ? (
        <Icon size="$twoAndHalf" icon={imageUploadErrorIcon} />
      ) : (
        <Icon icon={<PdfIconSvg />} />
      )}
      <StackLayout
        orientation="horizontal"
        verticalAlignment="center"
        horizontalAlignment="spaceBetween"
        css={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          minWidth: 0,
        }}
      >
        <UtilityText
          size="sm"
          emphasis={displayErrorFallback ? 'base' : 'emphasized'}
          css={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            flexGrow: 0,
            maxWidth: '17rem', // hard-coding this for now, but we will need to update this once the ticket to allow array control to be unordered lists (which will remove the numbering and arrows, giving us more space) is done https://everlong.atlassian.net/browse/CACT-1945
            ...(displayErrorFallback && { color: '$onSurfaceTextCritical' }),
          }}
        >
          {title}
        </UtilityText>
        <TextAction emphasis="subtle">
          <UtilityText
            size="xs"
            emphasis="subtle"
            css={{
              fontWeight: '$caption',
              textDecoration: 'underline',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              // hard-coding this for now, but we will need to update this once the ticket to allow array control to be unordered lists (which will remove the numbering and arrows, giving us more space) is done https://everlong.atlassian.net/browse/CACT-1945
              maxWidth: '20rem',
            }}
          >
            {url}
          </UtilityText>
        </TextAction>
      </StackLayout>
      <CopyButton
        priority="secondary"
        label={formatMessage({ id: 'COPY_FILE_PATH' })}
        contents={url}
        hideLabel
        css={{
          border: '$none',
        }}
      />
    </StackLayout>
  );
};
