import { EntityRootData, ParameterMapping } from '@web-config-app/core';
import { get } from 'lodash-es';

/**
 * Generates arrays of endpoint parameters (path or query), both with and
 * without values, based on a parameter mapping.
 *
 * This function takes a parameter mapping, a type ('path' or 'query'), and
 * optional data, and returns an object containing two arrays: `parameters`
 * (without values) and `paramsWithValues` (with values).  These arrays are
 * suitable for defining endpoint parameters in an API request.
 *
 * The `parameters` array contains objects with `name`, `in` (path or query),
 * and a default string `schema`.
 *
 * The `paramsWithValues` array contains objects with `name` and `value`.  The
 * value depends on the `type` and whether `data` is provided:
 *
 * - **If `type` is 'query':** The `value` is the paramValue itself (from the
 *   `operationParametersMapping`) as it will not be derived from entity data.
 * - **If `type` is 'path' The `value` is retrieved
 *   from the `data` object using the path since paramValue is a path to the
 * entity data needed.
 *
 * @template T - A generic type that must be either 'path' or 'query',
 *   specifying the parameter type.
 * @param type - The type of parameter to generate ('path' or 'query').
 * @param operationParametersMapping - The parameter mapping object,
 *   defining the parameter names and paths to values.
 * @param data - The entity data from which to retrieve parameter values (for
 *   'path' parameters).  Optional.
 * @returns An object containing two arrays: `parameters` and
 *   `paramsWithValues`.  Returns an object with empty arrays if
 *   `operationParametersMapping` is undefined.
 */
export const getCustomOperationParameters = <T extends 'path' | 'query'>(
  type: T,
  operationParametersMapping?: ParameterMapping,
  data?: EntityRootData,
) => {
  if (!operationParametersMapping)
    return { parameters: [], paramsWithValues: [] };

  const parameters = operationParametersMapping.map((pathParam) => {
    const [[name]] = Object.entries(pathParam);
    return {
      name,
      in: type,
      schema: {
        type: 'string',
      },
    } as const;
  });

  const paramsWithValues =
    operationParametersMapping.map((pathParam) => {
      const [[name, paramValue]] = Object.entries(pathParam);
      const value = type === 'query' ? paramValue : get(data, paramValue);
      return {
        name,
        value,
      };
    }) || [];

  return {
    parameters,
    paramsWithValues,
  };
};
