import { AnnotatedJsonSchema } from '@web-config-app/core';

/**
 * Takes an {@link AnnotatedJsonSchema} and returns a modified version that contains the
 * x-entity-control.type `objectCard` annotation as well as truncated schema.
 *
 * It removes properties/items from the schema since:
 *
 * 1) this is an entity form page schema, isolated from other pages or the root schema
 * 2) these properties are rendered as an object card so the subschema is never consumed
 *
 * The removed properties will still be rendered on child pages since their schemas are created
 * independently
 */

export const createObjectCardSchema = (
  schema: AnnotatedJsonSchema,
): AnnotatedJsonSchema => ({
  ...schema,
  'x-entity-control': {
    type: 'objectCard',
  },
  ...(schema.properties ? { properties: {} } : undefined),
  ...(schema.items ? { items: {} } : undefined),
});
