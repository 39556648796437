import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from '@tanstack/react-query';
import type {
  Entity,
  EntityConfig,
  GetEndpointResponse,
  EntityDetail,
  EnvironmentKey,
} from '@web-config-app/core';
import { isEntity } from '@web-config-app/core';
import { getEntityEndpoint } from '../../utilities/get-entity-endpoint/get-entity-endpoint.util';
import { getEndpointParamsWithValues } from '../../utilities/get-endpoint-params-with-values/get-endpoint-params-with-values';
import { getIdParam } from '../../utilities/get-id-param/get-id-param';
import { getIncludeParam } from '../../utilities/get-include-param/get-include-param';
import { useEnvironmentFetch } from '../use-environment-fetch/use-environment-fetch';

type EntityGetUseQueryResult = UseQueryResult<GetEndpointResponse>;

export type UseEntityGetResult = Omit<EntityGetUseQueryResult, 'data'> & {
  /**
   * There's a clash between `useQuery` returning a `data` property and our endpoints that ALSO include a `data` property
   * in the response. So, to avoid have `data.data`, hoist the endpoint's `response.data` to the top level here.
   */
  data?: EntityDetail;
  included?: EntityDetail[];
};

/**
 * useEntityGet - The following hook is responsible for calling the BE API associated to the Get (GET) service of an entity.
 * @param props
 * @param props.entity - the whole {@link Entity} that exposes the needed values including the id, endpoints, etc.
 * @param props.instanceId - the entity ID needed to call the GET api with.
 * @param props.options - optional config object
 * @param props.options.fetchFn - You can pass in your own custom fetch function to be used, instead of the default currentEnvironmentFetch
 * @returns the result of useQuery, which included the fetching, loading statuses and the data returned.
 */
export interface UseEntityGetOptions extends Partial<UseQueryOptions> {
  fetchFn?: (path: string, options: any) => Promise<Response>;
  getIncludedEntities?: boolean;
}

interface UseEntityGetProps {
  entity: Entity | EntityConfig;
  instanceId?: string;
  environment: EnvironmentKey;
  options?: UseEntityGetOptions;
}

export const useEntityGet = ({
  entity,
  instanceId,
  environment,
  options,
}: UseEntityGetProps): UseEntityGetResult => {
  const {
    endpoints: { get },
    apiUrl,
  } = entity;

  const params = getEndpointParamsWithValues([
    getIdParam(get.pathParameters ?? [], get.path, instanceId),
    getIncludeParam(
      get.queryParameters ?? [],
      isEntity(entity) ? entity.schema : undefined,
      options?.getIncludedEntities ?? false,
    ),
  ]);

  const { fetchFn, enabled = true, retry } = options ?? {};

  const currentEnvironmentFetch = useEnvironmentFetch(environment, apiUrl);

  const { endpointFetch } = getEntityEndpoint<GetEndpointResponse>(
    get,
    fetchFn ?? currentEnvironmentFetch,
  );

  /**
   * Uses the endpoint root URL
   *
   * We rename the data returned by useQuery so we can differentiate the data from our API response which
   * also has a `data` property. This avoids a result with a `result.data.data` structure.
   */

  const { data: queryResultData, ...queryResult } = useQuery({
    queryFn: () =>
      endpointFetch({
        params,
      }),
    queryKey: [environment, get.path, instanceId],
    enabled: enabled && Boolean(instanceId),
    retry: retry ?? false,
    // TODO: NEED TO ACCESS WHAT VALUE TO ADD HERE IN THE FUTURE. COMMENTING OUT TO RESOLVE A CACHING ISSUE
    // staleTime: Infinity,
  });

  return {
    ...queryResult,
    data: queryResultData?.data,
    included: queryResultData?.included,
  };
};
