import * as React from 'react';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@radix-ui/react-collapsible';
import { useIntl } from '@leagueplatform/locales';
import {
  UtilityText,
  StackLayout,
  Icon,
  Box,
  styled,
} from '@leagueplatform/genesis-core';

export interface EntityCloneItemAccordionProps {
  childAmount: number;
}

const StyledCollapsibleButton = styled(Box, {
  backgroundColor: 'transparent',
  border: 'none',
  borderRadius: '$button',
});
export const EntityCloneChildItemsCollapsible = ({
  childAmount,
  children,
}: React.PropsWithChildren<EntityCloneItemAccordionProps>) => {
  const { formatMessage } = useIntl();
  const [open, setOpen] = React.useState(true);

  return (
    <Collapsible open={open} onOpenChange={setOpen}>
      <StyledCollapsibleButton
        as={CollapsibleTrigger}
        css={{
          width: '100%',
          '&[data-state="open"] .collapsible-icon': {
            transform: 'rotate(90deg)',
          },
        }}
      >
        <StackLayout
          orientation="horizontal"
          spacing="$half"
          verticalAlignment="center"
        >
          <Icon
            icon="interfaceChevronRight"
            size="$threeQuarters"
            className="collapsible-icon"
          />
          <UtilityText emphasis="subtle" css={{ typography: '$bodyOne' }}>
            {formatMessage(
              { id: 'DEEP_CLONE_ENTITY_CLONE_ITEM_CHILD_ENTITIES' },
              { count: childAmount },
            )}
          </UtilityText>
        </StackLayout>
      </StyledCollapsibleButton>
      <CollapsibleContent>
        <Box css={{ paddingX: '$oneAndHalf', paddingBottom: '$one' }}>
          {children}
        </Box>
      </CollapsibleContent>
    </Collapsible>
  );
};
