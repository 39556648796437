import * as React from 'react';
import { FormField, StackLayout, Box } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { EntityFormBanner, HintText } from '@web-config-app/core-react-ui';
import { EntityFormControlProps } from '../../types/controls';

/**
 * Genesis Core FormField that can be used to wrap primitive property inputs (single input element) via the EntityFormControl
 * It provides a built-in label, hint, banner, and error status message that are populated from the transformed schema via useEntityFormControl
 * To correctly apply the id and name, the property's input element must be the direct child of this component.
 *
 * If primitive control is an array item, we hide the label and not render the banner or hint text
 */

export const PrimitiveControlFormFieldBanner = ({
  children,
  banner,
  hideBanner = false,
}: React.PropsWithChildren<{
  banner?: EntityFormControlProps['banner'];
  hideBanner?: boolean;
}>) => {
  const showBanner = (banner?.title || banner?.description) && !hideBanner;
  return (
    <StackLayout
      spacing="$half"
      data-testid="primitive-control-form-field"
      css={{ width: '100%', margin: `${hideBanner ? 0 : '$oneAndQuarter 0'}` }}
    >
      {showBanner && (
        <EntityFormBanner
          status={banner.status}
          title={banner.title}
          description={banner.description}
        />
      )}

      {children}
    </StackLayout>
  );
};

export const PrimitiveControlFormField: React.FC<
  React.PropsWithChildren<EntityFormControlProps>
> = ({
  hint,
  label,
  id,
  inputStatus,
  statusMessage,
  banner,
  children,
  required,
  hideLabel,
}) => {
  const { formatMessage } = useIntl();

  return (
    <PrimitiveControlFormFieldBanner banner={banner} hideBanner={hideLabel}>
      <Box css={{ width: '100%' }}>
        <FormField
          hint={
            !hideLabel && (
              <HintText css={{ paddingBottom: '$quarter' }}>{hint}</HintText>
            )
          }
          id={id}
          label={label}
          inputStatus={inputStatus}
          statusIconLabel={formatMessage({ id: 'ERROR' })}
          statusMessage={statusMessage}
          required={!!required}
          hideLabel={hideLabel}
        >
          {children}
        </FormField>
      </Box>
    </PrimitiveControlFormFieldBanner>
  );
};
