import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  EntityDetailsHeader,
  EntityActionMenuItem,
} from '@web-config-app/core-react-ui';
import {
  useEntityDetailsProps,
  useEntityWorkflowActions,
  useEntityHeaderSaveStatus,
} from '@web-config-app/entity-form';
import type {
  WorkflowActionMenuItem,
  CustomWorkflowAction,
  EnvironmentKey,
  Entity,
} from '@web-config-app/core';
import {
  getEntityStatusBadgeProps,
  EntityDetailActionType,
  EntityStatusValue,
  EntityConfigCustomOperation,
  EntityConfigMultiStepCustomOperation,
} from '@web-config-app/core';
import { isUsingConfigAppBackend } from '@web-config-app/common';
import {
  useEntityDetailsEnvironmentSelect,
  useConfigApp,
} from '@web-config-app/core-react';
import type { GetEntityDetailsPathForEnvAndId } from '@web-config-app/core-react';
import { CustomEntityActionMenuItem } from '../custom-entity-action-menu-item/custom-entity-action-menu-item.component';
import { CustomEntityMultiStepActionMenuItem } from '../custom-entity-multi-step-action-menu-item/custom-entity-multi-step-action-menu-item.component';
import { DeepCloneModalContainer } from '../deep-clone-modal-container/deep-clone-modal.container';

interface EntityDetailsHeaderContainerProps {
  isLoading: boolean;
  entity: Entity;
  entityListPath: string;
  environmentKey: EnvironmentKey;
  environmentName: string;
  getEntityDetailsPathForEnvAndId: GetEntityDetailsPathForEnvAndId;
  setEntityName: (name: string) => void;
  publishDangerously: boolean;
}

const isCustomAction = (
  item?: WorkflowActionMenuItem | CustomWorkflowAction,
): item is CustomWorkflowAction =>
  typeof item !== 'undefined' && item.type === 'custom';

const isMultiStepOperation = (
  operation: EntityConfigCustomOperation | EntityConfigMultiStepCustomOperation,
): operation is EntityConfigMultiStepCustomOperation => 'steps' in operation;

export const EntityDetailsHeaderContainer = ({
  isLoading = false,
  entity,
  entityListPath,
  environmentKey,
  getEntityDetailsPathForEnvAndId,
  setEntityName,
  publishDangerously,
  environmentName,
}: EntityDetailsHeaderContainerProps) => {
  const { formatMessage } = useIntl();
  const { isUsingConfigAppBackend: isTenantUsingConfigAppBackend } =
    useConfigApp();

  const { nameTranslationKey, schema } = entity;
  const entityConfigName = formatMessage(
    { id: nameTranslationKey },
    { count: 1 },
  );

  const {
    operations,
    name,
    status,
    instanceId,
    entityRootData,
    otherAuthoringEnvironmentState,
    isReadOnly,
    resetEntityData,
  } = useEntityDetailsProps();

  const { isEntitySaved, message: saveStatusMessage } =
    useEntityHeaderSaveStatus({
      operations,
      entityRootData,
      status,
    });

  const {
    currentAuthoringEnvironment,
    availableEnvironments,
    multiEnvironmentAuthoringEnabled,
    multiEnvironmentActions,
    isEntitySingleEnv,
  } = useEntityDetailsEnvironmentSelect({
    status,
    entityName: entityConfigName,
    getEntityDetailsPathForEnvAndId,
    environmentKey,
    entityInstanceId: instanceId,
    otherAuthoringEnvironmentState,
    isEntitySaved,
    entity,
  });

  const {
    primaryAction,
    secondaryAction,
    menuItems: workflowMenuItems = [],
  } = useEntityWorkflowActions({
    operations,
    entity,
    status,
    isEntitySaved,
    entityConfigName,
    entityListPath,
    environmentName,
    publishDangerously,
    isReadOnly,
    resetEntityData,
  });

  /**
   * Since we can't handle custom actions the same way as predefined actions
   * (which are passed, via `useEntityOperations`, as `operations`) and incorporated
   * into `workflowMenuItems`, we need come up with a way to associate the clicked
   * menu item with its corresponding action.
   *
   * This should be rectified in https://everlong.atlassian.net/browse/CACT-1844
   */
  const [customActionsMap, setCustomActionsMap] = React.useState<
    Record<string, () => void>
  >({});

  const registerCustomMenuAction = React.useCallback(
    (itemId: string, action: () => void) => {
      if (customActionsMap[itemId] !== action) {
        setCustomActionsMap({
          ...customActionsMap,
          [itemId]: action,
        });
      }
    },
    [customActionsMap],
  );

  const statusBadgeProps = getEntityStatusBadgeProps(status);

  const statusBadge = {
    label: formatMessage({ id: statusBadgeProps?.labelKey }),
    status: statusBadgeProps?.genesisStatus,
  };

  const isArchived = status === EntityStatusValue.Archived;

  const menuItems = workflowMenuItems.map((item) => {
    if (isCustomAction(item)) {
      const { operation } = item;

      return {
        id: item.id,
        label: isMultiStepOperation(operation) ? (
          <CustomEntityMultiStepActionMenuItem
            entityName={entityConfigName}
            item={item}
            operation={operation}
            entity={entity}
            environment={environmentKey}
            data={entityRootData}
            registerCustomMenuAction={registerCustomMenuAction}
            options={operations?.custom?.options}
          />
        ) : (
          <CustomEntityActionMenuItem
            entityName={entityConfigName}
            item={item}
            operation={operation}
            entity={entity}
            instanceId={instanceId}
            environment={environmentKey}
            data={entityRootData}
            registerCustomMenuAction={registerCustomMenuAction}
            options={operations?.custom?.options}
          />
        ),
      };
    }

    return {
      id: item.type,
      label: <EntityActionMenuItem item={item} />,
    };
  });

  const [isDeepCloneModalOpen, setIsDeepCloneModalOpen] = React.useState(false);
  const onMenuItemClicked = React.useCallback(
    (itemId: EntityDetailActionType | string) => {
      const menuItem = workflowMenuItems.find((item) =>
        isCustomAction(item) ? item.id === itemId : item.type === itemId,
      );
      if (isCustomAction(menuItem) && itemId in customActionsMap) {
        customActionsMap[itemId]();
      } else {
        menuItem?.action?.();
      }

      // Temp setup to show deep clone modal
      if (itemId === 'deepClone') {
        setIsDeepCloneModalOpen(true);
      }
    },
    [workflowMenuItems, customActionsMap],
  );

  const menuItemGroup = { menuItems, onMenuItemClicked };

  const entityInstanceName =
    name || `${formatMessage({ id: 'UNTITLED' })} ${entityConfigName}`;

  /**
   * Check if the entity adds a custom path to the entity name or whether
   * it uses the {@link EntityMetadata} interface.
   */
  const isNameInEntityMetadata =
    isUsingConfigAppBackend({
      tenantLevelOverride: isTenantUsingConfigAppBackend,
      entityLevelOverride: entity.isUsingConfigAppBackend,
    }) || !schema['x-entity-metadata']?.entityNamePath;

  const hasDeepCloneItem = workflowMenuItems.find(
    (item) => item.type === 'deepClone',
  );
  return (
    <>
      {hasDeepCloneItem ? (
        <DeepCloneModalContainer
          isOpen={isDeepCloneModalOpen}
          setIsOpen={setIsDeepCloneModalOpen}
          deepCloneOptions={operations?.deepClone?.options}
        />
      ) : null}
      <EntityDetailsHeader
        isLoading={isLoading}
        exitModalPrimaryButtonPath={entityListPath}
        menuItemGroup={menuItemGroup}
        secondaryAction={secondaryAction}
        primaryAction={primaryAction}
        statusBadge={statusBadge}
        entityInstanceName={entityInstanceName}
        isEntitySaved={isEntitySaved}
        saveStatusMessage={saveStatusMessage}
        isArchived={isArchived}
        entityConfigName={entityConfigName}
        instanceId={instanceId}
        currentAuthoringEnvironment={currentAuthoringEnvironment}
        availableEnvironments={availableEnvironments}
        multiEnvironmentActions={multiEnvironmentActions}
        isNameEditable={isNameInEntityMetadata}
        setEntityName={setEntityName}
        multiEnvironmentAuthoringEnabled={multiEnvironmentAuthoringEnabled}
        resetEntityData={resetEntityData}
        isEntitySingleEnv={isEntitySingleEnv}
      />
    </>
  );
};
