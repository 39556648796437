import {
  RulesTemplate,
  RulesTemplateWithoutEntityType,
} from '@web-config-app/core';

export const mapRulesTemplatesForEntity =
  (entityType: string) =>
  (ruleTemplatePartial: RulesTemplateWithoutEntityType): RulesTemplate => ({
    ...ruleTemplatePartial,
    entityType,
  });
