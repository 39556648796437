import type { EntityDetail, EntityDetailSchema } from '@web-config-app/core';
import { createDefaultValueForSchema } from '../create-default-value-for-schema/create-default-value-for-schema';

/**
 * Combines the default values for the config entity and the domain entity to create the expected
 * shape, where the domain entity attributes are nested under the `domainEntityAttributes` key.
 *
 * @param configEntitySchema
 * @param domainEntitySchema
 * @returns {@link EntityDetail} (partial)
 */
export const createDefaultValueForConfigEntity = (
  configEntitySchema: EntityDetailSchema,
  domainEntitySchema: EntityDetailSchema,
) => {
  const defaultDomainEntityData =
    createDefaultValueForSchema<EntityDetail>(domainEntitySchema);
  const configEntityDefaultData =
    createDefaultValueForSchema<EntityDetail>(configEntitySchema);

  return {
    ...configEntityDefaultData,
    attributes: {
      ...configEntityDefaultData.attributes,
      entityMetadata: {
        ...configEntityDefaultData.attributes?.entityMetadata,
        domainEntityType: defaultDomainEntityData.type,
      },
      domainEntityAttributes: defaultDomainEntityData.attributes,
    },
  };
};
