import * as React from 'react';
import type { Entity, EntityDetail } from '@web-config-app/core';
import type { ControlProps } from '../../../../types/controls';
import { useAnnotatedSchemaProps } from '../../../../hooks/use-annotated-schema-props/use-annotated-schema-props';
import { useReferencedEntityData } from '../../../../hooks/use-referenced-entity-data/use-referenced-entity-data';
import { useEntityDetailsProps } from '../../../../hooks/use-entity-details-props/use-entity-details-props';
import { useAddEntityById } from '../internals/use-add-entity-by-id.hook';
import { useEntityReferenceControlState } from '../internals/use-entity-reference-control-state';
import { EntitySelectControlPresenter } from '../internals/entity-select-control-presenter.component';
import { getComposedSchemaPropertyPath } from '../../../../utils/get-composed-schema-property-path/get-composed-schema-property-path';
import { useEntityReferenceControlContext } from '../../../../hooks/use-entity-reference-control-context/use-entity-reference-control-context';

/**
 * Renders for schemas that have a defined `x-entity-reference.domainEntityType` value. This control can operate on
 * entity references that are pure domainEntityIds
 */

export const EntityReferenceControlWrapper: React.FC<
  ControlProps & { referencedEntity: Entity }
> = ({ schema, handleChange, path, data: instanceId, referencedEntity }) => {
  const isArrayItem = useEntityReferenceControlContext();
  const { formPath, isUsingConfigAppBackend, currentAuthoringEnvironment } =
    useEntityDetailsProps();

  const { label, hint, errors, inputStatus, banner } = useAnnotatedSchemaProps(
    schema,
    null,
    getComposedSchemaPropertyPath(formPath, path),
  );

  /**
   * Grab props to be pass to the AddItemByIdModal component that allows a user to enter an entity ID
   */
  const {
    idValue,
    setIdValue,
    findEntityById,
    isLoading: isLoadingByIdSubmission,
    isError: isLoadingByIdError,
  } = useAddEntityById(referencedEntity, {
    enabled: false,
    retry: 1,
  });

  /**
   * `useReferencedEntityData` allows us to retrieve the name and status of the entity to display
   * in the control.
   */

  const {
    referencedEntity: entityReferenceData,
    isLoading,
    isError,
  } = useReferencedEntityData({
    entity: referencedEntity,
    instanceId,
    environment: currentAuthoringEnvironment,
    isRelationshipReference: false,
  });

  const {
    closeAddById,
    closeChooseFromList,
    openChooseFromList,
    addItemTriggerList,
    isAddingById,
    isAddingFromList,
  } = useEntityReferenceControlState();

  const handleValueChange = (entityDetail: EntityDetail) => {
    const value = isUsingConfigAppBackend
      ? entityDetail.attributes.entityMetadata.domainEntityId
      : entityDetail.id;
    handleChange(path, value);
  };

  const onSubmitAddById = () => {
    findEntityById({
      onSuccess: ({ data: response }) => {
        const entityDetail = response.data;
        handleValueChange(entityDetail);
        closeAddById();
      },
    });
  };

  const onSubmitChooseFromList = (entityDetail: EntityDetail) => {
    handleValueChange(entityDetail);
    closeChooseFromList();
  };

  const onClearEntityReference = () => {
    handleChange(path, undefined);
  };

  return (
    <EntitySelectControlPresenter
      hint={hint}
      label={label!}
      errors={errors!}
      inputStatus={inputStatus}
      banner={banner}
      entityReferenceData={entityReferenceData}
      isLoading={isLoading}
      isError={isError}
      isLoadingByIdSubmission={isLoadingByIdSubmission}
      isLoadingByIdError={isLoadingByIdError}
      onSubmitAddById={onSubmitAddById}
      onSubmitChooseFromList={onSubmitChooseFromList}
      addItemTriggerList={addItemTriggerList}
      isAddingById={isAddingById}
      isAddingFromList={isAddingFromList}
      closeAddById={closeAddById}
      closeChooseFromList={closeChooseFromList}
      entity={referencedEntity}
      openChooseFromList={openChooseFromList}
      idValue={idValue}
      setIdValue={setIdValue}
      isArrayItem={isArrayItem}
      onClearEntityReference={onClearEntityReference}
    />
  );
};
