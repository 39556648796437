import { useContext } from 'react';
import { useStore } from 'zustand';
import type { EntityDetailsDataStore } from '@web-config-app/core';
import { EntityDetailsDataStoreContext } from '../../providers/entity-details-data-store-provider/entity-details-data-store-provider.component';

/**
 * Custom hook to access the entity details data store. This hook should not be consumed
 * directly in components. Instead, import and use one of the hooks that are built on top
 * of this hook, for example `useEntityData` or `useEntityStatus`.
 *
 * @param selector - a function that selects the desired state from the store
 * @returns the selected state
 */

export function useEntityDetailsDataStore<T>(
  selector: (state: EntityDetailsDataStore) => T,
): T {
  const entityDetailsDataStoreContext = useContext(
    EntityDetailsDataStoreContext,
  );
  if (!entityDetailsDataStoreContext) {
    throw new Error(
      'useEntityDetailsDataStore must be used within the EntityDetailsDataStoreProvider',
    );
  }
  /**
   * use Zustand's useStore to get the store's state and actions
   */
  return useStore(entityDetailsDataStoreContext, selector);
}
