import React from 'react';
import { ControlProps } from '@jsonforms/core';
import {
  InputLabel,
  InputStatusMessage,
  StackItem,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { EntityFormBanner, HintText } from '@web-config-app/core-react-ui';
import { useEntityFormControl } from '../../../hooks/use-entity-form-control/use-entity-form-control';

/*
 * JsonLogicInputControl and InnerJsonLogicInputControl needs its own wrapper because it must bind the label and editor together with the correct id - the default wrapper uses Genesis form field and through that the input element has to be a direct child
 *
 */
export const JsonLogicInputControlWrapper: React.FC<
  React.PropsWithChildren<ControlProps>
> = ({ children, ...props }) => {
  const entityFormControlProps = useEntityFormControl(props);
  const {
    banner,
    label,
    required,
    inputStatus,
    statusMessage,
    hint,
    id,
    hideLabel,
  } = entityFormControlProps;
  const showBanner = banner?.title || banner?.description;
  return (
    <StackLayout css={{ marginTop: `${hideLabel ? '$none' : '$oneAndHalf'}` }}>
      {showBanner && !hideLabel && (
        <EntityFormBanner
          status={banner.status}
          title={banner.title}
          css={{ marginY: '$half' }}
          description={banner.description}
        />
      )}
      <StackItem css={{ width: '100%', marginBottom: '$half' }}>
        <InputLabel htmlFor={id} required={required} hideLabel={hideLabel}>
          {label}
        </InputLabel>
        {!hideLabel && hint && (
          <HintText css={{ paddingBottom: '$quarter' }}>{hint}</HintText>
        )}
      </StackItem>
      {children}
      <InputStatusMessage
        inputStatus={inputStatus}
        statusIconLabel={inputStatus}
      >
        {statusMessage}
      </InputStatusMessage>
    </StackLayout>
  );
};
