import React from 'react';
import { Tabs } from '@leagueplatform/genesis-core';

interface JsonLogicInputWithTabsProps {
  tabsData: {
    value: string;
    label: string;
    content: React.ReactNode;
  }[];
  defaultTab: string;
  ariaLabel: string;
}

/**
 * A component that renders a tabbed interface using the `Tabs` component from `@leagueplatform/genesis-core`.
 *
 * This component dynamically generates tabs based on the provided `tabsData`. Each tab displays a label and associated content.
 *
 * @param {object} props - The component props.
 * @param {Array} props.tabsData - An array of tab data objects, each containing:
 *   - `value`: The value of the tab, used for identification.
 *   - `label`: The label to be displayed on the tab.
 *   - `content`: The content to be displayed when the tab is active.
 * @param {string} props.defaultTab - The value of the tab that should be active by default.
 * @param {string} props.ariaLabel - The ARIA label for the tab list, used for accessibility.
 */
export const JsonLogicInputWithTabs = ({
  tabsData,
  defaultTab,
  ariaLabel,
}: JsonLogicInputWithTabsProps) => (
  <Tabs.Root defaultValue={defaultTab}>
    <Tabs.TabList
      aria-label={ariaLabel}
      tabDistribution="hugContents"
      css={{
        '.GDS-tab-list': {
          justifyContent: 'flex-end',
        },
      }}
    >
      {tabsData.map((item) => (
        <Tabs.Tab key={item.value} value={item.value}>
          {item.label}
        </Tabs.Tab>
      ))}
    </Tabs.TabList>
    {tabsData.map((item) => (
      <Tabs.TabPanel key={item.value} value={item.value}>
        {item.content}
      </Tabs.TabPanel>
    ))}
  </Tabs.Root>
);
