import { useCallback } from 'react';
import { EntityFormControlProps } from '../../types/controls';

type UseEntityFormHandleChangeProps = Pick<
  EntityFormControlProps,
  'path' | 'handleChange' | 'schema'
>;

/**
 * This calls the returns a callback that invokes EntityForm handleChange for the cases
 * where we are operating outside of a normal control, for example:
 * An entity reference exists in the Entity's schema as a string ID and is not just a relationship.
 *
 * In the event that either handleChange or path are undefined, the the callback
 * will not invoke the handleChange function.
 */

export const useEntityFormHandleChange = (
  props: UseEntityFormHandleChangeProps | undefined,
) => {
  const { path, handleChange, schema } = props ?? {};
  return useCallback(
    (value: string | undefined) =>
      schema?.type === 'string' &&
      handleChange &&
      path &&
      handleChange(path, value),
    [path, handleChange, schema],
  );
};

export type UseEntityFormHandleChange = ReturnType<
  typeof useEntityFormHandleChange
>;
