import { DataSource, DMLDataSource } from '../../types/schemas';

export const isDMLDataSource = (
  dataSource: DataSource,
): dataSource is DMLDataSource =>
  dataSource.name === 'dml' &&
  'apiPath' in dataSource &&
  typeof dataSource.apiPath === 'string';

export const findDMLDataSource = (
  sources: DataSource[] | undefined,
): DMLDataSource | undefined => {
  if (!sources) {
    return undefined;
  }
  const foundSource = sources.find(isDMLDataSource);
  if (foundSource) {
    return foundSource as DMLDataSource;
  }
  return undefined;
};
