import * as React from 'react';
import { DomainNav } from '@web-config-app/core-react-ui';
import {
  useConfigApp,
  useConfigAppParams,
  useConfigAppPath,
  useEntitySingleEnvironment,
} from '@web-config-app/core-react';
import { Category } from '@web-config-app/core';

export const DomainNavContainer = () => {
  const { domains } = useConfigApp();
  const { domainId, environmentKey } = useConfigAppParams();
  const { getEntitySingleEnvironment } = useEntitySingleEnvironment();

  const generateConfigPaths = useConfigAppPath();

  const navigationDomains = React.useMemo(
    () =>
      domains
        .filter(
          (domain) =>
            domain.category !== Category.Hidden &&
            !domain.entities.every(
              (entity) => entity.category === Category.Hidden,
            ),
        )
        .map((domain) => {
          const { domainPath } = generateConfigPaths({
            domainId: domain.id,
            environmentKey,
          });

          const entitiesWithPath = domain.entities
            .filter((entity) => entity.category !== Category.Hidden)
            .map((entity) => {
              const { singleEnv } = getEntitySingleEnvironment({ entity });

              const { entityListPath: path } = generateConfigPaths({
                domainId: domain.id,
                entityId: entity.id,
                environmentKey: singleEnv?.environmentKey ?? environmentKey,
              });
              return { ...entity, path };
            });

          return {
            ...domain,
            domainPath,
            isActive: domainId === domain.id,
            entities: entitiesWithPath,
          };
        }),
    [
      domains,
      generateConfigPaths,
      environmentKey,
      domainId,
      getEntitySingleEnvironment,
    ],
  );

  return <DomainNav domains={navigationDomains} />;
};
