import { ObjectPrimitiveArrayItemLayout } from '../layouts/object-primitive-array-item-layout/object-primitive-array-item-layout.component';
import { ObjectCompoundLayout } from '../layouts/object-compound/object-compound-layout.component';
import { ObjectPrimitiveLayout } from '../layouts/object-primitive/object-primitive-layout.component';
import {
  isObjectWithHeaderLayout,
  isPrimitiveObjectArrayItemLayout,
  isPrimitiveObjectLayout,
} from '../testers/layout-testers';
import { createObjectLayoutRenderer } from '../utils/create-renderer';

/**
 * Object Layouts
 */

export const objectPrimitiveLayoutRenderer = createObjectLayoutRenderer(
  isPrimitiveObjectLayout,
  ObjectPrimitiveLayout,
);

export const objectCompoundLayoutRenderer = createObjectLayoutRenderer(
  isObjectWithHeaderLayout,
  ObjectCompoundLayout,
);

export const objectPrimitiveArrayItemLayoutRenderer =
  createObjectLayoutRenderer(
    isPrimitiveObjectArrayItemLayout,
    ObjectPrimitiveArrayItemLayout,
  );
