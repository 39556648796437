import * as React from 'react';
import { JsonEditor, JsonEditorValue } from '@web-config-app/core-react-ui';
import type { AnnotatedJsonSchema } from '@web-config-app/core';
import { useConfigApp } from '@web-config-app/core-react';
import { useRulesTemplates } from './old-use-rules-templates';
import type { ControlComponent } from '../../../../types/controls';
import { EntityFormControl } from '../../../entity-form-control/entity-form-control.component';
import { OldJsonLogicInputTopBar } from './old-json-logic-input-top-bar.component';
import { useEntityDetailsProps } from '../../../../hooks/use-entity-details-props/use-entity-details-props';
import { oldDecodeJsonLogicString } from './old-decode-json-logic-string';
import { JsonLogicInputControlWrapper } from '../json-logic-input-control-wrapper.component';

const getRulesTemplateType = (schema: AnnotatedJsonSchema) =>
  schema?.['x-entity-presentation']?.rulesTemplateType;

export const OldJsonLogicInputControlContents = ({
  data,
  handleChange,
  path,
  enabled,
  id,
  schema,
}: {
  data: string;
  handleChange: (path: string, value: any) => void;
  path: string;
  enabled: boolean;
  id: string;
  schema: AnnotatedJsonSchema;
}) => {
  const { entityType, formPath } = useEntityDetailsProps();
  const rulesType = getRulesTemplateType(schema);
  const { tenantName } = useConfigApp();

  /**
   * The two refs are needed to handle a bug where if switching between 2 different json-logic controls on separate pages directly, stale data (from the previous json-logic component) was being shown in the control.
   */
  const cacheKeyRef = React.useRef<string | undefined>(formPath);
  const valueRef = React.useRef<string | undefined>(data);

  const rulesTemplates = useRulesTemplates({
    entityType,
    rulesType,
    tenantName,
  });

  const [jsonContent, setJsonContent] = React.useState<JsonEditorValue>({
    json: undefined,
    text: oldDecodeJsonLogicString(data),
  });

  React.useEffect(() => {
    /**
     * Handles a bug where the component initially renders with data `undefined` before being
     * eventually passed as the existing string data and where if switching between 2 different json-logic controls on separate pages directly, stale data (from the previous json-logic component) was being shown in the control.. This can likely be mitigated by future state optimization work.
     *
     * TODO: https://everlong.atlassian.net/browse/CACT-1290
     */
    if (
      (typeof data === 'string' && typeof jsonContent.text === 'undefined') ||
      (cacheKeyRef.current !== formPath && valueRef.current !== data)
    ) {
      cacheKeyRef.current = formPath;
      valueRef.current = data;
      setJsonContent({
        json: undefined,
        text: oldDecodeJsonLogicString(data),
      });
    } else {
      valueRef.current = data;
    }
  }, [data, formPath, jsonContent.text]);

  const handleOnJsonChange = (content: JsonEditorValue) => {
    setJsonContent(content);
    handleChange(path, content.text);
  };

  const [selectedRuleTemplate, setSelectedRuleTemplate] = React.useState<
    string | undefined
  >();

  const handleTemplateChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const templateValue = event.target.value;
    setSelectedRuleTemplate(templateValue);
  };

  const handleOnApply = () => {
    const selectedTemplate = rulesTemplates?.find(
      (template) => template.id === selectedRuleTemplate,
    );

    handleOnJsonChange({
      json: undefined,
      text: JSON.stringify(selectedTemplate?.rules ?? {}, null, 2),
    });
  };

  const showTemplateSelect = enabled && rulesTemplates;

  return (
    <JsonEditor
      readOnly={!enabled}
      onChange={handleOnJsonChange}
      editorValue={jsonContent}
      editorId={id}
      topBarContents={
        showTemplateSelect && (
          <OldJsonLogicInputTopBar
            rulesTemplates={rulesTemplates}
            onChange={handleTemplateChange}
            selectedTemplate={selectedRuleTemplate}
            onClickApply={handleOnApply}
          />
        )
      }
    />
  );
};

export const JsonLogicInputControl: ControlComponent = (props) => (
  <EntityFormControl
    {...props}
    renderControl={OldJsonLogicInputControlContents}
    wrapperComponent={JsonLogicInputControlWrapper}
  />
);
