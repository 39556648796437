import { useCallback } from 'react';
import {
  useSetEntityOperationLoadingMsg,
  useAddConfigMessage,
  useEntityOperationError,
} from '@web-config-app/core-react';
import type { ConfigApiError } from '@web-config-app/core';

/**
 * Defines all the event handlers used by custom actions and passed
 * to the react-query useMutation hook.
 */

export const useCustomActionEventHandlers = () => {
  const addConfigMessage = useAddConfigMessage();
  const setEntityOperationLoadingMsg = useSetEntityOperationLoadingMsg();
  const handleEntityOperationError = useEntityOperationError();

  const onMutate = useCallback(
    (loadingMessage?: string) => {
      if (loadingMessage) {
        setEntityOperationLoadingMsg(loadingMessage);
      }
    },
    [setEntityOperationLoadingMsg],
  );

  const onSuccess = useCallback(
    (successMessage?: string) => {
      if (successMessage) {
        addConfigMessage({ text: successMessage, type: 'success' });
      }
    },
    [addConfigMessage],
  );

  const onError = useCallback(
    (error: ConfigApiError) => {
      handleEntityOperationError(error);
    },
    [handleEntityOperationError],
  );

  return {
    onMutate,
    onSuccess,
    onError,
  };
};
