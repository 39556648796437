import { CreateTenantConfigInfo } from '@web-config-app/core';
import { exampleEntityRulesTemplates } from './rules-templates';
import { EXAMPLE_STRINGS_EN } from './strings';

export const exampleConfig: CreateTenantConfigInfo = {
  strings: {
    en: EXAMPLE_STRINGS_EN,
  },
  rulesTemplates: exampleEntityRulesTemplates,
};
