import { DataPoint } from '@web-config-app/core';

export const campaignCompletionDataPoints: DataPoint[] = [
  {
    id: 'campaign-completion',
    label: 'Campaign Completion',
    description: '',
    dataType: 'object',
    dataHandlerId: 'local',
    dataPoints: [
      {
        id: 'campaign.all_eligible_activities_completed',
        label: 'All eligible activities completed',
        description: '',
        dataType: 'boolean',
        dataHandlerId: 'local',
      },
      {
        id: 'campaign.id',
        label: 'Campaign id',
        description: '',
        dataType: 'string',
        dataHandlerId: 'local',
      },
      {
        id: 'campaign.type',
        label: 'Campaign type',
        description: '',
        dataType: 'string',
        dataHandlerId: 'local',
      },
      {
        id: 'campaign.completed_activity_count',
        label: 'Campaign completed activity count',
        description: '',
        dataType: 'number',
        dataHandlerId: 'local',
      },
    ],
  },
];
