import React from 'react';
import { type WorkflowAction } from '@web-config-app/core';
import { ConfirmationModal } from '../confirmation-modal/confirmation-modal.component';
import { EntityActionButton } from '../entity-action-button/entity-action-button.component';

export type EntityActionButtonModalProps = Omit<
  WorkflowAction,
  'action' | 'type'
> & {
  buttonPriority: 'primary' | 'secondary';
};

export const EntityActionButtonModal = ({
  disabled,
  display,
  label,
  modal,
  buttonPriority,
}: EntityActionButtonModalProps) => {
  const [modalOpen, setModalOpen] = React.useState(false);

  if (!display) return null;

  const handleActionButtonClick = () => {
    if (modal && 'onBeforeOpen' in modal && modal.onBeforeOpen) {
      const result = modal.onBeforeOpen();
      if (result) {
        setModalOpen(true);
      }
    } else {
      setModalOpen(true);
    }
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  if (modal?.type === 'confirmation')
    return (
      <>
        <EntityActionButton
          buttonPriority={buttonPriority}
          display={display}
          disabled={disabled}
          label={label}
          action={handleActionButtonClick}
        />
        <ConfirmationModal
          open={modalOpen}
          onOpenChange={handleCloseModal}
          title={modal.title}
          description={modal.description}
          confirmCtaText={modal.confirmCtaText}
          checkboxConfirmationLabel={modal.checkboxConfirmationLabel}
          confirmCtaAction={modal.confirmCtaAction}
        />
      </>
    );

  // if it does not equal supported modal.type return nothing
  return null;
};
