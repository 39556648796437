import { CreateTenantConfigInfo } from '@web-config-app/core';
import {
  highmarkActivityRulesTemplates,
  highmarkCampaignRulesTemplates,
} from './rules-templates';
import { HIGHMARK_STRINGS_EN } from './strings';
import { highmarkDataPoints } from './highark-data-points';

export const highmarkIELConfig: CreateTenantConfigInfo = {
  strings: {
    en: HIGHMARK_STRINGS_EN,
  },
  rulesTemplates: [
    ...highmarkActivityRulesTemplates,
    ...highmarkCampaignRulesTemplates,
  ],
  dataPoints: highmarkDataPoints,
};
