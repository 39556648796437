import type {
  EntityCloneItem,
  RootEntityCloneItem,
} from '../../types/authoring';
import type {
  GetEndpointResponse,
  EntityDetail,
  EntityRelationship,
} from '../../types/entities';

const getIncludedEntitiesMap = (included: EntityDetail[]) => {
  const entities: { [key: string]: EntityDetail } = {};

  included.forEach((entity) => {
    entities[entity.id] = entity;
  });

  return entities;
};

const sortEntitiesByDomainEntityType = (entities: EntityCloneItem[]) =>
  entities.sort((a, b) => {
    const typeA = a.domainEntityType ?? '';
    const typeB = b.domainEntityType ?? '';
    if (typeA < typeB) return -1;
    if (typeA > typeB) return 1;
    return 0;
  });

const sortAndGroupEntitiesByDomainEntityType = (
  entities: EntityCloneItem[],
) => {
  const hasDomainEntityTypes = new Set<string | undefined>();
  return sortEntitiesByDomainEntityType(entities).map((entity) => {
    if (hasDomainEntityTypes.has(entity.domainEntityType)) {
      return { ...entity, domainEntityType: undefined };
    }
    hasDomainEntityTypes.add(entity.domainEntityType);
    return entity;
  });
};

/**
 * Transforms the deep clone GET response into a structured object mapping included entities
 * into the parent/child tree structure to be passed to the UI and a map of entities to be
 * used to construct the expected body when submitting the deep clone request
 *
 * @param response {@link GetEndpointResponse}
 */

export const getDeepCloneEntityStructure = ({
  data: rootEntity,
  included = [],
}: GetEndpointResponse) => {
  const includedEntities = getIncludedEntitiesMap(included);
  const parentIds: string[] = [];
  /**
   * declaring this function inside the main function to avoid passing `includedEntities` around
   * as an argument
   */
  function getEntityChildrenFromRelationships({
    relationships,
    id,
  }: EntityDetail) {
    if (!relationships) {
      return [];
    }

    parentIds.push(id);

    const childEntities: EntityCloneItem[] = [];

    /**
     * Declaring this as a function avoids needing to duplicate this logic
     * in each branch of the conditional below
     */

    function getAndAddEntityCloneItem(entityToClone: EntityDetail | undefined) {
      if (entityToClone) {
        childEntities.push({
          type: 'child',
          id: entityToClone.id,
          domainEntityType:
            entityToClone.attributes.entityMetadata.domainEntityType,
          originalName: entityToClone.attributes.entityMetadata.name,
          childEntities: getEntityChildrenFromRelationships(entityToClone),
        });
      }
    }

    function processRelationshipEntity(relationshipEntity: EntityRelationship) {
      if (relationshipEntity) {
        if (parentIds.includes(relationshipEntity.id)) {
          const parentEntity =
            includedEntities[relationshipEntity.id] ?? rootEntity;
          const childEntity = includedEntities[id];
          throw new Error(
            `Circular reference detected while preparing to clone entity. Child "${childEntity.attributes.entityMetadata.name}" (${childEntity.id}) references parent entity "${parentEntity.attributes.entityMetadata.name}" (${parentEntity.id})`,
          );
        }
        getAndAddEntityCloneItem(includedEntities[relationshipEntity.id]);
      }
    }

    Object.values(relationships).forEach(({ data }) => {
      if (data) {
        if (Array.isArray(data)) {
          data.forEach((entity) => {
            processRelationshipEntity(entity);
          });
        } else {
          processRelationshipEntity(data);
        }
      }
    });

    return sortAndGroupEntitiesByDomainEntityType(childEntities);
  }

  const structured: RootEntityCloneItem = {
    type: 'root',
    id: rootEntity.id,
    domainEntityType: rootEntity.attributes.entityMetadata.domainEntityType,
    originalName: rootEntity.attributes.entityMetadata.name,
    childEntities: getEntityChildrenFromRelationships(rootEntity),
  };

  return {
    structured,
    map: {
      ...includedEntities,
      [rootEntity.id]: rootEntity,
    },
  };
};
