import * as React from 'react';
import type {
  EntityCloneItem,
  EntityDetail,
  RootEntityCloneItem,
} from '@web-config-app/core';
import {
  useConfigEntity,
  useEntityDeepClone,
  useConfigAppParams,
  useAuthoringEnvironments,
  DeepCloneRequestBody,
  UseEntityDeepCloneOptions,
  useEntities,
} from '@web-config-app/core-react';
import { DeepCloneModal } from '@web-config-app/core-react-ui';
import { getDeepCloneEntityStructure } from '@web-config-app/core';
import { set } from 'lodash-es';
import { useIntl } from '@leagueplatform/locales';

export const DeepCloneModalContainer = ({
  isOpen,
  setIsOpen,
  deepCloneOptions,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  deepCloneOptions?: UseEntityDeepCloneOptions;
}) => {
  const { formatMessage } = useIntl();
  const [entitiesMap, setEntitiesMap] = React.useState<
    Record<string, EntityDetail>
  >({});
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const allEntities = useEntities();
  const configEntity = useConfigEntity();
  const { entityInstanceId } = useConfigAppParams();
  const { currentAuthoringEnvironment } = useAuthoringEnvironments();
  const {
    deepCloneEntity,
    deepCloneGetData,
    isGetFetching: isLoadingDeepCloneGetData,
  } = useEntityDeepClone({
    entity: configEntity,
    instanceId: isOpen ? entityInstanceId : undefined,
    environment: currentAuthoringEnvironment,
    options: deepCloneOptions,
  });

  /**
   * Replaces the domainEntityType field in the structured entity with the corresponding translated nameTranslationKey from allEntities, including its childEntities.
   *
   * @param {RootEntityCloneItem} structured - The structured entity to process.
   * @returns {RootEntityCloneItem} The updated structured entity with domainEntityType replaced by nameTranslationKey.
   */
  const replaceEntityTypeWithName = React.useCallback(
    (structured: RootEntityCloneItem) => {
      const replace = (entity: RootEntityCloneItem | EntityCloneItem) => {
        const foundEntity = entity?.domainEntityType
          ? allEntities.get(entity.domainEntityType)
          : undefined;
        const translationKey = foundEntity?.nameTranslationKey;
        const updatedDomainEntityType = translationKey
          ? formatMessage(
              { id: translationKey },
              { count: entity?.type === 'root' ? 1 : 2 },
            )
          : entity.domainEntityType;
        const updatedEntity = {
          ...entity,
          domainEntityType: updatedDomainEntityType,
        };

        if (updatedEntity.childEntities) {
          updatedEntity.childEntities =
            updatedEntity.childEntities.map(replace);
        }

        return updatedEntity;
      };

      return replace(structured);
    },
    [allEntities, formatMessage],
  );

  const data = React.useMemo(() => {
    if (deepCloneGetData?.data) {
      try {
        const { structured } = getDeepCloneEntityStructure(deepCloneGetData);
        return replaceEntityTypeWithName(structured) as RootEntityCloneItem;
      } catch (e: any) {
        setErrorMessage(e.toString());
      }
    }
    return null;
  }, [deepCloneGetData, replaceEntityTypeWithName]);

  React.useEffect(() => {
    if (deepCloneGetData?.data) {
      try {
        const { map } = getDeepCloneEntityStructure(deepCloneGetData);
        setEntitiesMap(map);
      } catch (e) {
        console.error(e);
      }
    }
  }, [deepCloneGetData]);

  const deepCloneAction = () => {
    if (deepCloneGetData) {
      deepCloneEntity({
        data: entitiesMap[deepCloneGetData.data.id] ?? deepCloneGetData.data,
        included:
          deepCloneGetData.included?.map((includedEntity: EntityDetail) => {
            const entity = entitiesMap[includedEntity.id];
            return entity ?? includedEntity;
          }) ?? [],
      } as DeepCloneRequestBody);
    }
    setIsOpen(false);
  };

  const onNameChange = (id: string, name: string) => {
    const updateEntity = { ...entitiesMap[id] };
    if (updateEntity) {
      set(updateEntity, 'attributes.entityMetadata.name', name);
      setEntitiesMap({
        ...entitiesMap,
        [id]: updateEntity,
      });
    }
  };

  return (
    <DeepCloneModal
      onClose={() => {
        setIsOpen(false);
        setErrorMessage(null);
      }}
      isOpen={isOpen}
      data={data}
      errorMessage={errorMessage}
      deepCloneAction={deepCloneAction}
      onNameChange={onNameChange}
      isLoadingDeepCloneGetData={isLoadingDeepCloneGetData}
    />
  );
};
