export const EN_QUESTIONNAIRE_STRINGS = {
  QUESTIONNAIRE: '{count, plural, one {Questionnaire} other {Questionnaires}}',
  QUESTIONNAIRE_ENTITY_NAME: 'Questionnaire',
  QUESTIONNAIRE_METADATA_LABEL: 'Questionnaire',
  QUESTIONNAIRE_METADATA_TITLE_LABEL: 'Title',
  QUESTIONNAIRE_METADATA_TITLE_HINT: 'Displays the title of the Questionnaire',
  QUESTIONNAIRE_METADATA_QUESTIONNAIRE_ID_LABEL: 'ID',
  QUESTIONNAIRE_METADATA_QUESTIONNAIRE_DESCRIPTION_LABEL: 'Description',
  QUESTIONNAIRE_METADATA_QUESTIONNAIRE_DESCRIPTION_HINT:
    'Displays the details about the questionnaire',
  QUESTIONS_LABEL: 'Questions',
  QUESTION_LABEL_ADD: 'Add Question',
  QUESTION_ITEM_LABEL: 'Question',
  QUESTION_TEXT_LABEL: 'Text',
  QUESTION_TEXT_HINT: 'Displays the text of the question',
  LINK_ID_LABEL: 'Link ID',
  QUESTION_CODE_LABEL: 'Question Code',
  QUESTION_CODE_HINT: 'Displays the question codes of the question',
  QUESTION_BINDING_LABEL: 'Question Binding',
  QUESTION_BINDING_HINT:
    'Normative if verbiage from standardized health survey is used. Preferred if verbiage has been deviated from a standardized health survey.',
  QUESTION_BINDING_NORMATIVE: 'normative',
  QUESTION_BINDING_PREFERRED: 'preferred',
  QUESTION_ANSWER_TYPE_LABEL: 'Answer Type',
  QUESTION_ANSWER_TYPE_HINT:
    'Displays type of expected answer (e.g., quantity, choice)',
  QUESTION_ANSWER_TYPE_QUANTITY: 'quantity',
  QUESTION_ANSWER_TYPE_CHOICE: 'choice',
  QUESTION_ANSWER_TYPE_TEXT: 'text',
  QUESTION_ANSWER_TYPE_DATE_WITH_CHOICE: 'date with choice',
  QUESTION_ANSWER_TYPE_DATE: 'date',
  QUESTION_UNITS_LABEL: 'Unit',
  QUESTION_UNITS_HINT:
    'If answer is collecting measurable and quantified input ',
  QUESTION_ALLOW_MULTIPLE_LABEL: 'Allow Multiple',
  QUESTION_ALLOW_MULTIPLE_HINT:
    'Indicates if the question can have multiple responses',
  ANSWER_OPTION_LABEL_ADD: 'Add Answer Option',
  QUESTION_ANSWER_OPTIONS_LABEL: 'Answer Options',
  QUESTION_ANSWER_OPTION_LABEL: 'Answer Option',
  QUESTION_ANSWER_OPTION_TYPE_LABEL: 'Type',
  QUESTION_ANSWER_OPTION_TYPE_HINT: 'Displays type of Answer Option',
  QUESTION_ANSWER_OPTION_TYPE_DATE: 'date',
  QUESTION_ANSWER_OPTION_TYPE_CODING: 'coding',
  QUESTION_ANSWER_OPTION_TYPE_STRING: 'string',
  QUESTION_ANSWER_OPTION_TYPE_DECIMAL: 'decimal',
  QUESTION_ANSWER_OPTION_TYPE_INTEGER: 'integer',
  QUESTION_ANSWER_OPTION_TYPE_TIME: 'time',
  QUESTION_ANSWER_OPTION_DISPLAY_TEXT_LABEL: 'Display Text',
  QUESTION_ANSWER_OPTION_VALUE_CODING_LABEL: 'Value - Coding',
  QUESTION_ANSWER_OPTION_CODE_LABEL: 'Answer Option Code',
  QUESTION_REQUIRED_LABEL: 'Required',
  QUESTION_REQUIRED_HINT:
    'Select if the expectation is that all users must see and answer this question',
  QUESTION_QUESTION_CODES_ITEM: 'Question Code',
  QUESTION_QUESTION_CODES_ADD_LABEL: 'Add Question Code',
  QUESTION_QUESTION_UNITS_ADD_LABEL: 'Add Unit',
  QUESTION_QUESTION_UNITS_ITEM: 'Unit',
  QUESTION_ANSWER_OPTIONS_ITEM: 'Answer Options Code',
  QUESTIONNAIRE_METADATA_QUESTIONNAIRE_CONTEXT_ONBOARDING: 'Onboarding',
  QUESTIONNAIRE_METADATA_QUESTIONNAIRE_CONTEXT_CONSENT: 'Consent',
  QUESTIONNAIRE_METADATA_QUESTIONNAIRE_CONTEXT_CAMPAIGN_ACTIVITY:
    'Campaign Activity',
  QUESTIONNAIRE_METADATA_QUESTIONNAIRE_CONTEXT_HEALTH_ASSESSMENT:
    'Health Assessment',
  QUESTIONNAIRE_METADATA_QUESTIONNAIRE_CONTEXT_SOCIAL_DETERMINANTS_OF_HEALTH:
    'Social Determinants of Health',
  QUESTIONNAIRE_METADATA_QUESTIONNAIRE_CONTEXT_LABEL: 'Use Context',
  QUESTIONNAIRE_METADATA_QUESTIONNAIRE_CONTEXT_HINT:
    'Displays the use context of the questionnaire',
  LINK_ID_PREFIX_SPECIFIER_LABEL: 'Link ID Prefix Specifier',
  QUESTION_ANSWER_OPTION_VALUE_DATE_LABEL: 'Value - Date',
  QUESTION_ANSWER_OPTION_VALUE_DECIMAL_LABEL: 'Value - Decimal',
  QUESTION_ANSWER_OPTION_VALUE_INTEGER_LABEL: 'Value - Integer',
  QUESTION_ANSWER_OPTION_VALUE_STRING_LABEL: 'Value - String',
  QUESTION_ANSWER_OPTION_VALUE_TIME_LABEL: 'Value - Time',
  GENERATE_TOOLBOX_ACTIVITY: 'Generate Toolbox Activity',
  GENERATING_TOOLBOX_ACTIVITY: 'Generating Toolbox Activity',
  TOOLBOX_ACTIVITY_GENERATED:
    'Toolbox Activity Created. View it on the Activity Entity listing page.',
  CONFIRM_GENERATE_TOOLBOX_ACTIVITY:
    'Are you sure you want to generate a Toolbox Activity for this Questionnaire?',
};
