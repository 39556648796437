import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { JsonEditor } from '@web-config-app/core-react-ui';
import type { AnnotatedJsonSchema } from '@web-config-app/core';
import { Box } from '@leagueplatform/genesis-core';
import type { ControlComponent } from '../../../types/controls';
import { EntityFormControl } from '../../entity-form-control/entity-form-control.component';
import { JsonLogicInputTopBar } from './json-logic-input-top-bar.component';
import { JsonLogicInputTemplateSelect } from './json-logic-input-template-select.component';
import { JsonLogicInputWithTabs } from './json-logic-input-with-tabs.component';
import { RuleTemplateForm } from './rule-template-form/rule-template-form.component';
import { JsonLogicInputControlWrapper } from './json-logic-input-control-wrapper.component';
import { useRuleCombinationContext } from './rule-combination-provider/rule-combination-provider.component';
import { useJsonLogicControl } from './use-json-logic-control';

/**
 * The Rule Template Json Logic Control is rendered within the Rule Combination control, and is rendered for a single rule.
 */
const RuleTemplateJsonLogicInputControlContents = ({
  data,
  handleChange,
  path,
  enabled,
  id,
  schema,
}: {
  data: string;
  handleChange: (path: string, value: any) => void;
  path: string;
  enabled: boolean;
  id: string;
  schema: AnnotatedJsonSchema;
}) => {
  const { formatMessage } = useIntl();

  const ruleContext = useRuleCombinationContext();

  const arrayIndexString = path.split('.').pop();
  const arrayIndex = arrayIndexString
    ? (() => {
        const parsed = parseInt(arrayIndexString, 10);
        return Number.isNaN(parsed) ? undefined : parsed;
      })()
    : undefined;
  const {
    rulesTemplates,
    jsonContent,
    selectedRuleTemplate,
    ruleTemplateFormData,
    handleOnJsonChange,
    handleRuleTemplateFormDataChange,
    handleTemplateChange,
  } = useJsonLogicControl({
    data,
    handleChange,
    path,
    schema,
    parentFieldPath: ruleContext?.combinationFieldPath,
    isArrayItem: ruleContext?.isArray,
    arrayIndex,
  });

  let parsedData;

  try {
    parsedData = data && JSON.parse(data);
  } catch (error) {
    console.error('JsonLogicInputControl - Could not parse Data:', error);
  }

  const ruleTemplateTabId = `rule-template-${path}`;
  const jsonLogicTabId = `json-logic-${path}`;

  const showTemplateSelect = enabled && rulesTemplates;
  const jsonLogicInputTabsData = [
    {
      value: ruleTemplateTabId,
      label: formatMessage({ id: 'RULE_TEMPLATE' }),
      content: (
        <Box>
          <RuleTemplateForm
            selectedRuleTemplate={selectedRuleTemplate}
            ruleTemplateData={ruleTemplateFormData}
            onRuleTemplateDataChange={handleRuleTemplateFormDataChange}
          />
        </Box>
      ),
    },
    {
      value: jsonLogicTabId,
      label: formatMessage({ id: 'JSON_LOGIC' }),
      content: (
        <JsonEditor
          readOnly={!!selectedRuleTemplate}
          onChange={handleOnJsonChange}
          editorValue={jsonContent ?? {}}
          editorId={id}
          topBarContents={showTemplateSelect && <JsonLogicInputTopBar />}
        />
      ),
    },
  ];

  const emptyParsedData = parsedData && !Object.keys(parsedData).length;

  // Select the default tab as:
  //  rule template when we have a selected rule template, if the data returned is undefined, or if the data returned is an empty object
  //  json logic when the data returned is not an empty object and doesn't fall under our rule templates id.
  const defaultTab =
    selectedRuleTemplate || !parsedData || emptyParsedData
      ? ruleTemplateTabId
      : jsonLogicTabId;

  return (
    <Box
      css={{
        width: '100%',
        paddingX: '$one',
        border: 'solid $colors$onSurfaceBorderSubdued $borderWidths$thin',
        borderRadius: '$medium',
      }}
    >
      {showTemplateSelect && (
        <JsonLogicInputTemplateSelect
          rulesTemplates={rulesTemplates}
          onChange={handleTemplateChange}
          selectedTemplate={selectedRuleTemplate?.id}
          templateLabel={formatMessage({ id: 'RULE_TEMPLATE' })}
          id={path}
        />
      )}
      <JsonLogicInputWithTabs
        tabsData={jsonLogicInputTabsData}
        defaultTab={defaultTab}
        ariaLabel={formatMessage({ id: 'RULE_VIEW_OPTIONS_ARIA_LABEL' })}
      />
    </Box>
  );
};

// RuleTemplateJsonLogicInputControl component
export const RuleTemplateJsonLogicInputControl: ControlComponent = (props) => (
  <EntityFormControl
    {...props}
    renderControl={RuleTemplateJsonLogicInputControlContents}
    wrapperComponent={JsonLogicInputControlWrapper}
  />
);
