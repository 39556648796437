export const getQueryParamsFromUrl = (
  url: string,
): { [key: string]: string } => {
  const query = url.split('?')[1];
  if (!query) {
    return {};
  }

  const params = new URLSearchParams(query);
  return Object.fromEntries(params.entries());
};
