import { useEffect } from 'react';
import { isEqual } from 'lodash-es';
import {
  type Entity,
  type EntityDetail,
  type EntityDetailSchema,
  type EntityRootData,
} from '@web-config-app/core';
import { useConfigApp } from '@web-config-app/core-react';
import { useDataSourceStore } from './data-source-store-provider';
import { getSchemaDataSources } from '../../utils/get-schema-data-sources/get-schema-data-sources';

/**
 * Listens for changes to the root schema and data and updates any registered data sources
 * accordingly.
 */

export const useSyncDataSources = (
  schema: EntityDetailSchema,
  data: EntityRootData | undefined,
  includedEntities?: EntityDetail[],
  entity?: Entity,
) => {
  const dataSourceStore = useDataSourceStore();
  const { isUsingConfigAppBackend: isTenantUsingConfigAppBackend } =
    useConfigApp();
  const isEntityUsingConfigAppBackend = entity?.isUsingConfigAppBackend;

  useEffect(() => {
    getSchemaDataSources(
      schema,
      data,
      includedEntities,
      isTenantUsingConfigAppBackend,
      isEntityUsingConfigAppBackend,
    ).forEach(({ name, value: newValue }) => {
      const { dataSources } = dataSourceStore.getState();
      const currentValue = dataSources.get(name);

      if (!isEqual(newValue, currentValue)) {
        dataSources.set(name, newValue);
        dataSourceStore.setState({ dataSources });
      }
    });
  }, [
    data,
    schema,
    dataSourceStore,
    includedEntities,
    isTenantUsingConfigAppBackend,
    isEntityUsingConfigAppBackend,
  ]);
};
