import * as React from 'react';
import type { ConfirmationModal as ConfirmationModalType } from '@web-config-app/core';
import {
  Button,
  Checkbox,
  Modal,
  StackLayout,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { Link } from '@leagueplatform/routing';
import {
  ConfigModalContent,
  ConfigModalDescription,
  ConfigModalTitle,
} from '../common-config-modal-attributes/common-config-modal-attributes';

const disabledLinkStyle = {
  backgroundColor: '$interactiveBackgroundDisabled',
  color: '$onSurfaceTextSubdued',
  borderColor: 'transparent',
  pointerEvents: 'none',
};

export type ConfirmationModalProps = Omit<
  ConfirmationModalType,
  'type' | 'onBeforeOpen'
> & {
  modalTrigger?: React.ReactNode;
  open?: boolean;
  onOpenChange?: () => void;
};

/**
 * ConfirmationModal component displays a modal with a title, description, a cancel button, and a confirm button.
 * The confirm button's action can be a link (string) for navigation or a function for custom actions.
 * It can optionally render a checkbox to require user confirmation before enabling the confirm button.
 *
 * @param {React.ReactNode} [props.modalTrigger] - Optional UI Trigger for opening the modal (e.g., a button).
 * @param {string} [props.confirmCtaText] - Optional text for the confirm CTA button.
 * @param {string | (() => void)} [props.confirmCtaAction] - Optional action to perform on confirm CTA button click (string for navigation (relative URL), function for custom action).
 * @param {() => void} [props.confirmCtaOnclick] - Optional action to be performed when confirmCtaAction is of type string
 * @param {string} props.title - Title of the modal.
 * @param {string} props.description - Description within the modal.
 * @param {boolean} [props.open] - Optional prop to control the open state of the modal externally. If not provided, the component manages the state internally.
 * @param {() => void} [props.onOpenChange] - Optional callback function when the modal's open state changes. Must be provided if `open` prop is used for external state management.
 * @param {string} [props.checkboxConfirmationLabel] - Optional label for the confirmation checkbox. If provided, a checkbox will be displayed and the confirm button will be disabled until the checkbox is checked.
 * @param {boolean} [props.destructive] - Optional prop to control whether the confirmCta button will be destructive. Defaults to false.
 */

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  modalTrigger,
  confirmCtaText,
  confirmCtaAction,
  title,
  description,
  open,
  onOpenChange,
  checkboxConfirmationLabel,
  confirmCtaOnclick,
  destructive = false,
}) => {
  const { formatMessage } = useIntl();

  // Internal state to manage modal open/close if 'open' prop is not provided
  const [modalOpen, setModalOpen] = React.useState(false);

  /**
   * If checkboxConfirmationLabel is provided (truthy), then confirmation is required before confirmCtaAction
   * If checkboxConfirmationLabel is not provided, `confirmed` is initialized as true so confirmCtaAction is enabled immediately.
   */
  const [confirmed, setConfirmed] = React.useState(!checkboxConfirmationLabel);

  const handleOpenChange = React.useCallback(() => {
    if (onOpenChange) {
      onOpenChange();
    } else {
      setModalOpen((prevOpen) => !prevOpen);
    }
    // reset confirm state on modal close if applicable
    if (checkboxConfirmationLabel) {
      setConfirmed(false);
    }
  }, [checkboxConfirmationLabel, onOpenChange]);

  const handlePrimaryButtonClick = () => {
    if (typeof confirmCtaAction === 'function') {
      confirmCtaAction();
    }
    handleOpenChange();
  };

  const confirmActionSharedProps = {
    'aria-describedby': checkboxConfirmationLabel,
    destructive,
    disabled: !confirmed,
  };

  const confirmCtaButton =
    typeof confirmCtaAction === 'string' ? (
      <Button
        as={Link}
        to={confirmCtaAction}
        onClick={(evt: React.MouseEvent<HTMLAnchorElement>) => {
          if (!confirmed) {
            evt.preventDefault();
          } else {
            confirmCtaOnclick?.();
          }
        }}
        css={!confirmed ? disabledLinkStyle : undefined}
        {...confirmActionSharedProps}
      >
        {confirmCtaText}
      </Button>
    ) : (
      <Button {...confirmActionSharedProps} onClick={handlePrimaryButtonClick}>
        {confirmCtaText}
      </Button>
    );

  return (
    <Modal.Root open={open ?? modalOpen} onOpenChange={handleOpenChange}>
      <Modal.Trigger>{modalTrigger}</Modal.Trigger>
      <ConfigModalContent>
        <ConfigModalTitle title={title} />
        <ConfigModalDescription description={description} />
        {checkboxConfirmationLabel && (
          <Checkbox
            id="confirmation"
            name="confirmation"
            label={<UtilityText>{checkboxConfirmationLabel}</UtilityText>}
            checked={confirmed}
            onChange={() => setConfirmed(!confirmed)}
          />
        )}
        <StackLayout
          orientation="horizontal"
          horizontalAlignment="end"
          spacing="$one"
        >
          <Modal.Close>
            <Button priority="tertiary" quiet>
              {formatMessage({ id: 'CANCEL' })}
            </Button>
          </Modal.Close>
          {confirmCtaAction && confirmCtaButton}
        </StackLayout>
      </ConfigModalContent>
    </Modal.Root>
  );
};
