import * as React from 'react';
import { EntityConfig, DomainConfig } from '@web-config-app/core';
import { useConfigApp } from '../use-config-app/use-config-app';

/**
 * Custom hook to retrieve a map of all entities from the configuration app.
 *
 * This hook uses the `useConfigApp` hook to get the domains and their entities,
 * and then creates a map where the keys are the entity types and the values are the entity configurations.
 *
 * @returns {Map<string, EntityConfig>} A map of entity types to their configurations.
 */
export const useEntities = (): Map<string, EntityConfig> => {
  const { domains } = useConfigApp();

  return React.useMemo(() => {
    const entityMap = new Map<string, EntityConfig>();

    domains.forEach((domain: DomainConfig) => {
      domain.entities.forEach((entity: EntityConfig) => {
        entityMap.set(entity.type, entity);
      });
    });

    return entityMap;
  }, [domains]);
};
