import type { Entity } from '@web-config-app/core';
import { getConfigEntityWithDomainEntitySchema } from '@web-config-app/core';
import { useConfigApp } from '../use-config-app/use-config-app';

/**
 * @param props.domainEntity - optional. Including a domain entity will inject the domain entity's
 * attributes schema into the config entity schema's `domainEntityAttributes` key.
 * @returns {@link ConfigEntity}
 */

export const useConfigEntity = ({
  domainEntity,
}: { domainEntity?: Entity } = {}) => {
  const { configEntity } = useConfigApp();

  return domainEntity
    ? getConfigEntityWithDomainEntitySchema(configEntity, domainEntity)
    : configEntity;
};
