import {
  Entity,
  ConfigEntity,
  EndpointPathParameter,
  EndpointQueryParameter,
  EntityDetail,
  EntityConfigEndpoint,
  Category,
  EntityConfigCustomOperation,
} from '@web-config-app/core';

import {
  mockSimpleSchema,
  mockConfigEntitySchema,
  mockConfigEntitySchemaWithDomainEntityAttributes,
} from './mock-schemas';

const mockEndpointPath = `v1/config-cactuses`;
const mockEndpointWithIdPath = `v1/config-cactuses/{cactusId}`;

const mockPathParams: EndpointPathParameter[] = [
  {
    name: 'cactusId',
    in: 'path',
    schema: {
      type: 'string',
    },
    required: true,
  },
];

const mockQueryParams: EndpointQueryParameter[] = [
  {
    name: 'arms[count]',
    in: 'query',
    schema: {
      type: 'string',
    },
    required: false,
  },
  {
    name: 'spikes[count]',
    in: 'query',
    schema: {
      type: 'string',
    },
    required: false,
  },
];

const includeParam: EndpointQueryParameter = {
  name: 'include',
  in: 'query',
  schema: {
    type: 'string',
  },
  required: false,
};

export const mockListEndpoint: EntityConfigEndpoint = {
  path: mockEndpointPath,
  method: 'GET',
  pathParameters: mockPathParams,
  queryParameters: mockQueryParams,
};

const mockGetEndpoint: EntityConfigEndpoint = {
  path: mockEndpointWithIdPath,
  method: 'GET',
  pathParameters: mockPathParams,
  queryParameters: [includeParam],
};

const mockCreateEndpoint: EntityConfigEndpoint = {
  path: mockEndpointPath,
  method: 'POST',
  pathParameters: mockPathParams,
  queryParameters: mockQueryParams,
};

const mockDuplicateEndpoint: EntityConfigEndpoint = {
  path: mockEndpointPath,
  method: 'POST',
};

const mockUpdateEndpoint: EntityConfigEndpoint = {
  path: mockEndpointWithIdPath,
  method: 'POST',
  pathParameters: mockPathParams,
  queryParameters: mockQueryParams,
};

export const mockCustomOperation: EntityConfigCustomOperation = {
  method: 'PATCH',
  label: {
    text: 'Purge',
  },
  loadingMessage: {
    text: 'Purge loading',
  },
  successMessage: {
    text: 'Purge success',
  },
  actionType: 'overflow',
  path: '/get-purge-now/{status}/cool/{id}',
  parameterMappings: {
    query: [
      {
        hello: 'yes',
      },
    ],
    path: [
      {
        id: 'id',
      },
      {
        status: 'attributes.entityMetadata.status',
      },
    ],
    requestBody: [
      {
        internalName: 'attributes.metaData.internalName',
      },
      {
        type: 'type',
      },
    ],
  },
};

export const mockConfigEntity: ConfigEntity = {
  name: 'Config Entity',
  id: 'config-entities',
  nameTranslationKey: 'CONFIG_ENTITY',
  category: 'core',
  endpoints: {
    list: {
      method: 'GET',
      queryParameters: [
        {
          name: 'page[size]',
          in: 'query',
          required: false,
          schema: {
            type: 'integer',
          },
          description:
            'The number of records to return in a result. Default is 10 per page.',
        },
        {
          name: 'page[offset]',
          in: 'query',
          required: false,
          schema: {
            type: 'integer',
          },
          description:
            'The Offset needed for pagination. Typically size * (page -1).',
        },
        {
          name: 'filter[domainEntityType]',
          in: 'query',
          required: false,
          schema: {
            type: 'string',
          },
        },
        {
          name: 'filter[query]',
          in: 'query',
          required: false,
          schema: {
            type: 'string',
          },
        },
      ],
      path: '/v1/config-entities',
    },
    create: {
      method: 'POST',
      path: '/v1/config-entities',
      requiredEntityMetadataFieldValues: {
        status: 'draft',
      },
    },
    update: {
      method: 'PATCH',
      pathParameters: [
        {
          name: 'entityId',
          in: 'path',
          required: true,
          schema: {
            type: 'string',
          },
          description: 'Entity Id',
        },
      ],
      path: '/v1/config-entities/{entityId}',
      requiredEntityMetadataFieldValues: {
        status: 'draft',
      },
    },
    publish: {
      method: 'PATCH',
      pathParameters: [
        {
          name: 'entityId',
          in: 'path',
          required: true,
          schema: {
            type: 'string',
          },
          description: 'Entity Id',
        },
      ],
      path: '/v1/config-entities/{entityId}',
      requiredEntityMetadataFieldValues: {
        status: 'published',
      },
    },
    republish: {
      method: 'PATCH',
      pathParameters: [
        {
          name: 'entityId',
          in: 'path',
          required: true,
          schema: {
            type: 'string',
          },
          description: 'Entity Id',
        },
      ],
      path: '/v1/config-entities/{entityId}',
      requiredEntityMetadataFieldValues: {
        status: 'published',
      },
    },
    archive: {
      method: 'PATCH',
      pathParameters: [
        {
          name: 'entityId',
          in: 'path',
          required: true,
          schema: {
            type: 'string',
          },
          description: 'Entity Id',
        },
      ],
      path: '/v1/config-entities/{entityId}',
      requiredEntityMetadataFieldValues: {
        status: 'archived',
      },
    },
    unpublish: {
      method: 'PATCH',
      pathParameters: [
        {
          name: 'entityId',
          in: 'path',
          required: true,
          schema: {
            type: 'string',
          },
          description: 'Entity Id',
        },
      ],
      path: '/v1/config-entities/{entityId}',
      requiredEntityMetadataFieldValues: {
        status: 'draft',
      },
    },
    unarchive: {
      method: 'PATCH',
      pathParameters: [
        {
          name: 'entityId',
          in: 'path',
          required: true,
          schema: {
            type: 'string',
          },
          description: 'Entity Id',
        },
      ],
      path: '/v1/config-entities/{entityId}',
      requiredEntityMetadataFieldValues: {
        status: 'draft',
      },
    },
    get: {
      method: 'GET',
      pathParameters: [
        {
          name: 'entityId',
          in: 'path',
          required: true,
          schema: {
            type: 'string',
          },
          description: 'Entity Id',
        },
      ],
      path: '/v1/config-entities/{entityId}',
    },
    deepClone: {
      method: 'POST',
      requiredEntityMetadataFieldValues: {
        status: 'draft',
        action: 'clone',
      },
      path: '/v1/config-entities',
      prefetch: {
        path: '/v1/config-entities/{entityId}',
        method: 'GET',
        pathParameters: [
          {
            name: 'entityId',
            in: 'path',
            required: true,
            schema: {
              type: 'string',
            },
            description: 'Entity Id',
          },
        ],
        queryParameters: [
          {
            name: 'include',
            in: 'query',
            required: true,
            schema: {
              type: 'string',
            },
            description: 'include',
          },
        ],
      },
    },
    duplicateAsDraft: {
      method: 'POST',
      path: '/v1/config-entities',
      requiredEntityMetadataFieldValues: {
        status: 'draft',
      },
    },
    copyToEnvironment: {
      method: 'POST',
      path: '/v1/config-entities',
      requiredEntityMetadataFieldValues: {
        status: 'draft',
      },
    },
  },
  apiUrl: 'http://localhost:4001',
  type: 'config-entity',
  schema: mockConfigEntitySchema,
};

export const mockConfigEntityWithDomainEntitySchemaAttributes = {
  ...mockConfigEntity,
  schema: mockConfigEntitySchemaWithDomainEntityAttributes,
};

export const mockEntity: Entity = {
  id: 'cactuses',
  name: 'Cactuses',
  type: 'config-cactus',
  isUsingConfigAppBackend: true,
  category: Category.Default,
  nameTranslationKey: 'CACTUSES',
  schema: mockSimpleSchema,
  endpoints: {
    list: mockListEndpoint,
    create: mockCreateEndpoint,
    get: mockGetEndpoint,
    update: mockUpdateEndpoint,
    duplicateAsDraft: mockDuplicateEndpoint,
    custom: {
      patch: mockCustomOperation,
    },
  },
};

export const mockEntityDetail: EntityDetail = {
  id: '123456789012312455',
  type: 'campaign',
  attributes: {
    entityMetadata: {
      status: 'draft',
      name: 'Fun Campaign',
      readOnly: true,
      attachments: [
        {
          assetId: '123456789',
        },
      ],
    },
    metaData: {
      internalName: 'Fun Campaign',
    },
  },
};
