import * as React from 'react';
import { Box, Icon } from '@leagueplatform/genesis-core';
import { Nullable } from '@web-config-app/core';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { EntityTreeItem } from './entity-tree-item/entity-tree-item.component';
import type { TreeNode, Tree } from '../../types/tree';
import { ExpandIconSvg } from './entity-tree-item-icon.component';

/**
 * TODO: This file is for previewing tree calculation only. Actual UI will be
 * implemented in https://everlong.atlassian.net/browse/CACT-532
 */

export const renderTree = (
  tree: TreeNode[],
  onAnchorClick?: (args: any) => void,
) =>
  Array.isArray(tree)
    ? tree.map((node, index) => (
        <EntityTreeItem
          key={node.id}
          path={node.id}
          itemId={node.id}
          label={node.name}
          type={node.type}
          index={index}
          onClick={onAnchorClick}
        >
          {Array.isArray(node.children)
            ? renderTree(node.children, onAnchorClick)
            : null}
        </EntityTreeItem>
      ))
    : null;

export const CollapseIcon = () => (
  <Icon tint="$onSurfaceIconPrimary" icon={<ExpandIconSvg />} />
);
export const ExpandIcon = () => (
  <Icon
    tint="$onSurfaceIconPrimary"
    icon={<ExpandIconSvg />}
    css={{ transform: 'rotate(-90deg)' }}
  />
);

export interface EntityTreeViewProps {
  entityTree: Tree;
  expandedItems?: string[];
  onSelect?: (nodeId: string) => void;
  activeTreeNodeId?: Nullable<string>;
  onItemSelectionToggle?: (evt: React.SyntheticEvent, itemId: string) => void;
  defaultExpandedItems?: string[];
  preventNavigation?: boolean;
}

export const EntityTreeView: React.FC<EntityTreeViewProps> = ({
  entityTree,
  expandedItems,
  onSelect,
  preventNavigation,
  activeTreeNodeId,
  defaultExpandedItems,
  onItemSelectionToggle,
}) => {
  const [firstItem] = entityTree;
  const selectedItem = activeTreeNodeId ?? firstItem?.id;

  return (
    <Box
      css={{
        height: '100%',
        flexGrow: 1,
        paddingTop: '$oneAndHalf',
        textWrap: 'nowrap',
        overflowX: 'auto',
        '.MuiSimpleTreeView-root': {
          position: 'relative',
          width: '100%',
          overflowX: 'auto',
          '.MuiSimpleTreeView-root': {
            position: 'relative',
            width: '100%',
            overflowX: 'auto',
          },
          '.Mui-selected:before': {
            position: 'absolute',
            content: '',
            display: 'block',
            width: '100%',
            left: 0,
            height: '$twoAndHalf',
            backgroundColor: '$surfaceBackgroundTertiary',
          },
          '.MuiTreeItem-iconContainer': {
            width: '$oneAndHalf',
            height: '$oneAndHalf',
            position: 'relative',
          },
          '.MuiTreeItem-content': {
            borderRadius: '$none',
            padding: '$half',
          },
          '.MuiTreeItem-content:hover': {
            backgroundColor: 'transparent',
          },
          ul: {
            // this powers the array item counters
            counterReset: 'array-item-count',
          },
        },
      }}
    >
      <SimpleTreeView
        slots={{
          collapseIcon: CollapseIcon,
          expandIcon: ExpandIcon,
        }}
        expandedItems={expandedItems}
        defaultExpandedItems={defaultExpandedItems}
        selectedItems={selectedItem}
        onItemFocus={(evt: React.SyntheticEvent | null, itemId: string) => {
          if (preventNavigation) {
            evt?.stopPropagation();
            evt?.preventDefault();
          }
          if (typeof itemId === 'string') {
            onSelect?.(itemId);
          }
        }}
        onItemSelectionToggle={onItemSelectionToggle}
      >
        {renderTree(entityTree, onSelect)}
      </SimpleTreeView>
    </Box>
  );
};
