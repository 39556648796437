import {
  getDeepCloneEntityStructure,
  GetEndpointResponse,
} from '@web-config-app/core';

export const mockDeepCloneGetResponse = {
  data: {
    attributes: {
      domainEntityAttributes: null,
      entityMetadata: {
        domainEntityType: 'config-campaign',
        name: 'Root Campaign',
      },
    },
    id: 'root-entity-id',
    relationships: {
      relatedCampaigns: {
        data: [
          {
            id: 'config-campaign-id-1',
            type: 'config-campaign',
          },
          {
            id: 'config-campaign-id-2',
            type: 'config-campaign',
          },
        ],
      },
      parentCampaign: {
        data: {
          id: 'config-campaign-id-3',
          type: 'config-campaign',
        },
      },
    },
    type: 'config-entity',
  },
  included: [
    {
      attributes: {
        domainEntityAttributes: null,
        entityMetadata: {
          domainEntityType: 'config-activity',
          name: 'Activity Name 2',
        },
      },
      id: 'config-activity-id-2',
      type: 'config-entity',
    },
    {
      attributes: {
        domainEntityAttributes: null,
        entityMetadata: {
          domainEntityType: 'config-campaign',
          name: 'Campaign Child',
        },
      },
      id: 'config-campaign-id-1',
      relationships: {
        activity: {
          data: [
            {
              id: 'config-activity-id-1',
              type: 'config-activity',
            },
            {
              id: 'config-activity-id-2',
              type: 'config-activity',
            },
          ],
        },
      },
      type: 'config-entity',
    },
    {
      attributes: {
        domainEntityAttributes: null,
        entityMetadata: {
          domainEntityType: 'config-campaign',
          name: 'Campaign Child 2',
        },
      },
      id: 'config-campaign-id-2',
      type: 'config-entity',
    },
    {
      attributes: {
        domainEntityAttributes: null,
        entityMetadata: {
          domainEntityType: 'config-activity',
          name: 'Activity Name 1',
        },
      },
      id: 'config-activity-id-1',
      type: 'config-entity',
    },
    {
      attributes: {
        domainEntityAttributes: null,
        entityMetadata: {
          domainEntityType: 'config-campaign',
          name: 'Parent Campaign Name',
        },
      },
      id: 'config-campaign-id-3',
      type: 'config-entity',
    },
  ],
};

export const mockDeepCloneEntityStructure = getDeepCloneEntityStructure(
  mockDeepCloneGetResponse as unknown as GetEndpointResponse,
);
