import { Format } from 'ajv';
import { FormatType } from '../../types';

export const validateJsonLogic = (value?: string | undefined | null) => {
  if (typeof value !== 'string') return false;

  try {
    JSON.parse(value);
  } catch {
    return false;
  }

  /**
   * Check for any template placeholders that have not been replaced with a value
   */
  return value.match(/{\w+}/) === null;
};

const jsonLogicFormat: Format = {
  type: 'string',
  validate: validateJsonLogic,
};

export const jsonLogicFormatEntry = {
  name: FormatType.JsonLogic,
  format: jsonLogicFormat,
};
