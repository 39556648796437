import { useEffect } from 'react';
import { cloneDeep, pick } from 'lodash-es';
import type {
  EnvironmentKey,
  Entity,
  EntityDetail,
  EntityRootData,
  Nullable,
  OptionalOperationType,
} from '@web-config-app/core';
import { API_JSON_CONTENT_TYPE } from '@web-config-app/api';
import {
  UseEntityGetOptions,
  useEntityGet,
} from '../use-entity-get/use-entity-get';
import {
  UseEntityUpdateOptions,
  useEntityUpdate,
} from '../use-entity-update/use-entity-update';
import {
  UseEntityCreateOptions,
  useEntityCreate,
} from '../use-entity-create/use-entity-create';
import { useEntityDuplicate } from '../use-entity-duplicate/use-entity-duplicate';
import { UseEntityCustomOperationOptions } from '../use-entity-custom-operation/use-entity-custom-operation';
import { UseEntityDeepCloneOptions } from '../use-entity-deep-clone/use-entity-deep-clone';

export interface UseEntityOperationsProps {
  entity: Entity;
  domainEntityEnabledOptionalOperations?: Nullable<
    Array<OptionalOperationType>
  >;
  // TODO:: Fix inconsistent typing of instanceId since it can be undefined
  // https://everlong.atlassian.net/browse/CACT-1600
  instanceId: string;
  entityRootData: EntityRootData | undefined;
  onEntityGetSuccess?: (
    data: EntityDetail,
    included: EntityDetail[] | undefined,
  ) => void;
  options?: {
    get?: UseEntityGetOptions;
    update?: UseEntityUpdateOptions;
    create?: UseEntityCreateOptions;
    duplicateAsDraft?: UseEntityCreateOptions;
    deepClone?: UseEntityDeepCloneOptions;
    custom?: UseEntityCustomOperationOptions;
  };
  environment: EnvironmentKey;
}

export type UseEntityOperationsResult = ReturnType<typeof useEntityOperations>;

/**
 * Provides a convenience composition of all of an entity instance's data operations
 * like GET, UPDATE, DELETE, CREATE and returns them in a single object.
 *
 */

export const useEntityOperations = ({
  entity,
  domainEntityEnabledOptionalOperations = null,
  instanceId,
  entityRootData,
  onEntityGetSuccess,
  options,
  environment,
}: UseEntityOperationsProps) => {
  const custom = {
    options: options?.custom,
  };

  // GET Operations
  const get = useEntityGet({
    entity,
    instanceId,
    environment,
    options: {
      getIncludedEntities: true,
      ...options?.get,
    },
  });

  // TODO: After CACT BE launch, switch to useEntityDeepClone https://everlong.atlassian.net/browse/CACT-1920
  const deepClone = { enableAction: false, options: options?.deepClone };

  // POST Operations
  const create = useEntityCreate({
    entity,
    environment,
    data: entityRootData,
    options: options?.create,
    headers: {
      'Content-Type': API_JSON_CONTENT_TYPE,
    },
    operation: 'create',
  });

  const duplicateAsDraft = useEntityDuplicate({
    entity,
    environment,
    data: entityRootData,
    options: options?.duplicateAsDraft,
  });

  // PATCH Operations
  const update = useEntityUpdate({
    entity,
    environment,
    instanceId,
    data: entityRootData,
    options: options?.update,
    operation: 'update',
  });

  const publish = useEntityUpdate({
    entity,
    environment,
    instanceId,
    data: entityRootData,
    options: options?.update,
    operation: 'publish',
  });

  const republish = useEntityUpdate({
    entity,
    environment,
    instanceId,
    data: entityRootData,
    options: options?.update,
    operation: 'republish',
  });

  const unpublish = useEntityUpdate({
    entity,
    environment,
    instanceId,
    data: entityRootData,
    options: options?.update,
    operation: 'unpublish',
  });

  const archive = useEntityUpdate({
    entity,
    environment,
    instanceId,
    data: entityRootData,
    options: options?.update,
    operation: 'archive',
  });

  const unarchive = useEntityUpdate({
    entity,
    environment,
    instanceId,
    data: entityRootData,
    options: options?.update,
    operation: 'unarchive',
  });

  /**
   * on successful get, refresh the entityData
   */
  useEffect(() => {
    if (get.data && onEntityGetSuccess) {
      // By cloning the data from the GET call, it removes changes directly to the api response due to prop reference, keeping the response unchanged and pure.
      const isolatedData = cloneDeep(get?.data);
      onEntityGetSuccess(isolatedData, get.included);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get.data, get.included]);

  const requiredOperations = {
    get,
  };

  const optionalOperations = {
    update,
    create,
    publish,
    republish,
    unpublish,
    archive,
    unarchive,
    duplicateAsDraft,
    deepClone,
    custom,
  };

  const enabledOptionalOperations =
    domainEntityEnabledOptionalOperations &&
    domainEntityEnabledOptionalOperations.length > 0
      ? pick(optionalOperations, domainEntityEnabledOptionalOperations)
      : optionalOperations;

  return {
    ...requiredOperations,
    ...enabledOptionalOperations,
  };
};
