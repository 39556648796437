import type {
  AppTenantConfig,
  ConfigAppCoreAuthClientOptions,
  ConfigEntity,
  DataPoints,
  RulesTemplate,
  TenantStrings,
} from '@web-config-app/core';
import {
  commonCoreConfig,
  viteAppEnvironment,
  commonCoreAuthClientOptionsClientId,
  commonCoreAuthClientOptionsDomain,
  commonCoreAuthClientOptionsRedirectUri,
} from '../configs/common/core';
import { mergeTenantStrings } from './merge-tenant-strings';
import { mergeRulesTemplates } from './merge-rules-templates';
import leagueLogo from '../assets/league-logo.svg';
import { getGeneratedEnvironmentsConfigForTenant } from '../configs/generated/get-generated-environment-config-for-tenant';
import { getGeneratedDomainsConfigForTenant } from '../configs/generated/get-generated-domains-config-for-tenant';
import GeneratedEntityConfigs from '../configs/generated/json/entities.json';
import { GeneratedConfigTenantId } from '../types/config';
import { mergeLocalDataPoints } from './merge-local-data-points';

interface CreateTenantConfigArgs {
  tenantId: GeneratedConfigTenantId;
  strings?: TenantStrings;
  rulesTemplates?: RulesTemplate[];
  dataPoints?: DataPoints;
}

const getAuth0ClientOptions = (authConfig: ConfigAppCoreAuthClientOptions) => ({
  ...authConfig.clientOptions,
  domain: authConfig.clientOptions?.domain ?? commonCoreAuthClientOptionsDomain,
  client_id:
    authConfig.clientOptions?.client_id ?? commonCoreAuthClientOptionsClientId,
  redirect_uri:
    authConfig.clientOptions?.redirect_uri ??
    commonCoreAuthClientOptionsRedirectUri,
});

// TODO - can i tighten this up? https://everlong.atlassian.net/browse/CACT-1921
const configEntity = GeneratedEntityConfigs[
  'config-entities'
] as unknown as ConfigEntity;

export const createTenantConfig = ({
  tenantId,
  strings,
  rulesTemplates,
  dataPoints,
}: CreateTenantConfigArgs): AppTenantConfig => {
  const { en: enLocale, ...tenantStringsOtherLocales } =
    mergeTenantStrings(strings);

  const tenantDetails = getGeneratedEnvironmentsConfigForTenant(tenantId);
  const domains = getGeneratedDomainsConfigForTenant(tenantId);

  const appEnvironmentAuthoring =
    tenantDetails.appEnvironment[viteAppEnvironment];

  const { baseEnvironment, previewEnvironment, auth0 } =
    appEnvironmentAuthoring;

  const tenantRulesTemplates = mergeRulesTemplates(rulesTemplates);
  const tenantDataPoints = mergeLocalDataPoints(dataPoints);

  const auth = {
    clientOptions: {
      audience: auth0.audience,
      organization: auth0.organizationId,
      domain: auth0.domain,
      client_id: auth0.clientId,
    },
  };
  const auth0ClientOptions = getAuth0ClientOptions(auth);
  return {
    core: {
      ...commonCoreConfig,
      auth: {
        clientOptions: auth0ClientOptions,
        // Number of hours for which a session can be inactive before user is prompted to log out/continue
        // Note: Any changes to this value have to be synced with the idle_session_lifetime in the auth0 configuration.
        idleSessionLifetimeHours: 1,
      },
      appEnvironment: viteAppEnvironment,
      contentUrl: baseEnvironment.api.contentUrl,
      api: {
        wsUrl: baseEnvironment.api.wsUrl,
        url: baseEnvironment.api.url,
        legacyRestApi: baseEnvironment.api.legacyRestApi,
      },
      i18n: {
        stringOverrides: {
          default: enLocale,
          en: enLocale,
          ...tenantStringsOtherLocales,
        },
      },
      analytics: {
        GAPropertyId: 'UA-xxxxxxxx-x',
        segmentKey: 'gqIuNkEL7p5hTUQMfKA1R57GSzgL1xFU',
      },
    },
    configApp: {
      tenantName: tenantDetails.label,
      domains,
      authoring: {
        baseEnvironment: {
          apiUrl: baseEnvironment.api.url,
          environmentKey: baseEnvironment.environmentKey,
          id: baseEnvironment.id,
          audience: baseEnvironment.auth0.primaryAudience,
          publishDangerously: baseEnvironment.publishDangerously,
          defaultAuthoringEnvironment:
            baseEnvironment.defaultAuthoringEnvironment,
        },
        previewEnvironments:
          previewEnvironment?.id &&
          previewEnvironment.environmentKey !== 'production'
            ? [
                {
                  id: previewEnvironment.id,
                  apiUrl: previewEnvironment.api.url,
                  environmentKey: previewEnvironment.environmentKey,
                  type: 'preview',
                  audience: previewEnvironment.auth0.primaryAudience,
                  publishDangerously: previewEnvironment.publishDangerously,
                  defaultAuthoringEnvironment:
                    previewEnvironment.defaultAuthoringEnvironment,
                },
              ]
            : undefined,
        disableMultiEnvironmentOperations:
          appEnvironmentAuthoring.disableMultiEnvironmentOperations,
      },
      tenantLogo: leagueLogo,
      rulesTemplates: tenantRulesTemplates,
      dataPoints: tenantDataPoints,
      /**
       * TODO: Temp flag for testing the config app backend
       */
      isUsingConfigAppBackend: tenantDetails.isUsingConfigAppBackend === true,
      configEntity,
    },
  };
};
