import * as React from 'react';
import {
  StackLayout,
  Box,
  TextAction,
  UtilityText,
  Button,
} from '@leagueplatform/genesis-core';
import {
  Entity,
  EntityDetail,
  getEntityInstanceName,
} from '@web-config-app/core';
import { useIntl } from '@leagueplatform/locales';
import {
  useConfigAppParams,
  useConfigAppPath,
} from '@web-config-app/core-react';
import { EntityStatusBadge } from './entity-status-badge.component';

export interface EntitySelectValueProps {
  entityDetail: EntityDetail;
  entity: Entity;
  openChooseFromList: () => void;
  onClear?: (args: any) => void;
  isArrayItem?: boolean;
}

export const EntitySelectValue: React.FC<EntitySelectValueProps> = ({
  entityDetail,
  entity,
  openChooseFromList,
  onClear,
  isArrayItem = false,
}) => {
  const { formatMessage } = useIntl();

  const { environmentKey } = useConfigAppParams();

  const generateConfigPaths = useConfigAppPath();
  const { getEntityDetailsPathForId } = generateConfigPaths({
    domainId: entity.domain,
    entityId: entity.id,
    environmentKey,
  });
  /**
   * TODO: If we stick to our intensions here, the return of getEntityDetailsPathForId should be customizable to not be specific
   * to our config app routing. To do this properly, it would have to be some kind of function
   * param to pass to configuration. That feels like a lot of work for now, so instead let's file a
   * ticket and deal with it when we get back to extensibility.
   *
   * https://everlong.atlassian.net/browse/CACT-1035
   */
  return (
    <StackLayout
      orientation="horizontal"
      horizontalAlignment="start"
      verticalAlignment="center"
      spacing="$one"
      css={{ paddingY: '$half', width: '100%' }}
    >
      <Box css={{ flexGrow: 1 }}>
        <TextAction
          indicator="newTabInternal"
          target="_blank"
          href={getEntityDetailsPathForId(entityDetail.id)}
        >
          {getEntityInstanceName(entityDetail, entity)}
        </TextAction>
      </Box>
      <UtilityText emphasis="subtle">{entityDetail.id}</UtilityText>
      <EntityStatusBadge
        status={entityDetail.attributes.entityMetadata.status}
      />
      <Button
        size="medium"
        priority="secondary"
        quiet
        onClick={() => openChooseFromList()}
        icon="interfaceChange"
        hideLabel
      >
        {formatMessage({ id: 'CHANGE_SELECTION' })}
      </Button>
      {isArrayItem === false ? (
        <Button
          type="button"
          icon="tinyClear"
          hideLabel
          quiet
          priority="tertiary"
          size="toolbar"
          onClick={() => onClear?.(entityDetail)}
        >
          {formatMessage({ id: 'CLEAR_ENTITY_REFERENCE_SELECTION' })}
        </Button>
      ) : null}
    </StackLayout>
  );
};
