import * as React from 'react';
import { useHistory } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import { useQueryClient } from '@tanstack/react-query';
import {
  useEntity,
  useConfigAppParams,
  useConfigAppPath,
  useSearchParam,
  useConfigUserFeedback,
  useEntityOperationError,
  useAuthoringEnvironments,
  useAuthoringEnvironmentInfo,
  EntityDetailsDataStoreProvider,
  useEntitySingleEnvironment,
  useConfigApp,
  useConfigEntity,
  RuleTemplatesEditorStateStoreProvider,
} from '@web-config-app/core-react';
import type { GetEntityDetailsPathForEnvAndId } from '@web-config-app/core-react';
import {
  useEntityDetailsProps,
  DataSourceStoreProvider,
} from '@web-config-app/entity-form';
import {
  EntityDetailsPageContainer,
  EntityDetailsPage,
} from '@web-config-app/core-react-containers';
import type { Entity, EnvironmentKey } from '@web-config-app/core';
import { entityDetailActionPendingKey } from '@web-config-app/core';
import { isUsingConfigAppBackend } from '@web-config-app/common';
import { openInTab } from '@leagueplatform/web-common';
import { useCustomActionEventHandlers } from '../../hooks/use-custom-action-event-handlers/use-custom-action-event-handlers';
import { useCommonOperationEventHandlers } from '../../hooks/use-common-operation-event-handlers/use-common-operation-event-handlers';

interface ConfigAppEntityDetailsPageWithContextProps {
  instanceId?: string;
  entity: Entity;
  entityListPath: string;
  environmentKey: EnvironmentKey;
  getEntityDetailsPathForEnvAndId: GetEntityDetailsPathForEnvAndId;
}

const ConfigAppEntityDetailsPageWithContext = ({
  instanceId,
  entity,
  entityListPath,
  environmentKey,
  getEntityDetailsPathForEnvAndId,
}: ConfigAppEntityDetailsPageWithContextProps) => {
  const history = useHistory();
  const {
    status,
    formData,
    formSchema,
    formPath,
    onFormDataChange,
    handleNodeToggle,
    entityTree,
    operations,
    expandedNodes,
    resetEntityTreeNodes,
    setEntityName,
    currentAuthoringEnvironment,
    isReadOnly,
  } = useEntityDetailsProps();
  const { formatMessage } = useIntl();
  const { publishDangerously, nameKey } = useAuthoringEnvironmentInfo(
    currentAuthoringEnvironment,
  );
  const { getEntitySingleEnvironment } = useEntitySingleEnvironment();
  const { isEntitySingleEnv } = getEntitySingleEnvironment({ entity });

  const environmentName = formatMessage({ id: nameKey });
  /**
   * This will reset the entity tree expanded state store whenever we load a new entity instance.
   */
  React.useEffect(() => {
    resetEntityTreeNodes();
  }, [instanceId, resetEntityTreeNodes]);

  React.useEffect(() => {
    /**
     * When landing on the route initially we want to set the first entity tree
     * node's id as url param in order to open and mark the first node as active
     */
    const isInstanceDataLoaded = Boolean(instanceId && operations.get.data);
    if (
      (!formPath || formPath === '') &&
      (isInstanceDataLoaded || !instanceId)
    ) {
      const [firstNode] = entityTree;
      const searchParams = new URLSearchParams({ path: firstNode.id });
      history.push({
        search: searchParams.toString(),
      });
    }
  }, [formPath, entityTree, history, instanceId, operations.get.data]);

  const { isError, isLoading } = operations.get;

  return (
    <EntityDetailsPage
      entity={entity}
      entityListPath={entityListPath}
      isLoading={isLoading}
      isError={isError}
      formData={formData}
      formSchema={formSchema}
      formPath={formPath}
      entityTree={entityTree}
      handleNodeToggle={handleNodeToggle}
      onFormDataChange={onFormDataChange}
      expandedNodes={expandedNodes}
      publishDangerously={publishDangerously}
      entityStatus={status}
      environmentName={environmentName}
      environmentKey={environmentKey}
      isReadOnly={isReadOnly}
      getEntityDetailsPathForEnvAndId={getEntityDetailsPathForEnvAndId}
      setEntityName={setEntityName}
      isEntitySingleEnv={isEntitySingleEnv}
    />
  );
};

export const ConfigAppEntityDetailsPage = () => {
  const { formatMessage } = useIntl();
  const { domainId, entityId, entityInstanceId, environmentKey } =
    useConfigAppParams<{
      domainId: string;
      entityId: string;
      entityInstanceId: string;
      environmentKey: EnvironmentKey;
    }>();

  const generateConfigPaths = useConfigAppPath();
  const {
    getEntityDetailsPathForId,
    entityListPath,
    getEntityDetailsPathForEnvAndId,
  } = generateConfigPaths({
    domainId,
    entityId,
    environmentKey,
  });

  const history = useHistory();
  const queryClient = useQueryClient();

  const entity = useEntity({ domainId, entityId });
  const { otherAuthoringEnvironment, currentAuthoringEnvironment } =
    useAuthoringEnvironments({
      environmentKey,
    });

  const path = useSearchParam('path') ?? '';

  const hasEntityId = Boolean(entityInstanceId);

  const { resetConfigUserFeedbackStore, setEntityOperationLoadingMsg } =
    useConfigUserFeedback((state) => ({
      resetConfigUserFeedbackStore: state.resetConfigUserFeedbackStore,
      setEntityOperationLoadingMsg: state.setEntityOperationLoadingMsg,
    }));

  const handleEntityOperationError = useEntityOperationError();

  const entityName =
    entity && formatMessage({ id: entity.nameTranslationKey }, { count: 1 });

  const { onSettled } = useCommonOperationEventHandlers();
  const customActionEventHandlers = useCustomActionEventHandlers();

  const { isUsingConfigAppBackend: tenantIsUsingConfigAppBackend } =
    useConfigApp();
  const configEntity = useConfigEntity();

  const isUsingConfigAppBE = isUsingConfigAppBackend({
    tenantLevelOverride: tenantIsUsingConfigAppBackend,
    entityLevelOverride: entity?.isUsingConfigAppBackend,
  });

  const listQueryKey = React.useMemo(
    () =>
      isUsingConfigAppBE
        ? [environmentKey, configEntity.endpoints.list.path, entity?.type, null]
        : [environmentKey, entity?.endpoints.list.path],
    [
      configEntity.endpoints.list.path,
      entity?.endpoints.list.path,
      entity?.type,
      environmentKey,
      isUsingConfigAppBE,
    ],
  );

  /**
   *Reset on unmount of the page
   */
  React.useEffect(
    () => () => {
      resetConfigUserFeedbackStore();
    },
    [resetConfigUserFeedbackStore],
  );

  return entity?.schema ? (
    <EntityDetailsDataStoreProvider>
      <RuleTemplatesEditorStateStoreProvider>
        <DataSourceStoreProvider>
          <EntityDetailsPageContainer
            entity={entity}
            instanceId={entityInstanceId}
            currentAuthoringEnvironment={currentAuthoringEnvironment}
            path={path}
            createDefaultEntityData={!hasEntityId}
            otherAuthoringEnvironment={otherAuthoringEnvironment}
            options={{
              get: {
                retry: 1,
              },
              update: {
                onMutate: (operation) => {
                  const loadingMessage = formatMessage(
                    { id: entityDetailActionPendingKey[operation] },
                    { entity: entityName },
                  );
                  setEntityOperationLoadingMsg(loadingMessage);
                },
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: listQueryKey,
                  });
                  queryClient.invalidateQueries({
                    queryKey: [
                      environmentKey,
                      isUsingConfigAppBE
                        ? configEntity.endpoints.get.path
                        : entity.endpoints.get.path,
                      entityInstanceId,
                    ],
                  });
                  resetConfigUserFeedbackStore();
                },
                onError: (error: any) => {
                  handleEntityOperationError(error);
                },
                onSettled,
              },
              create: {
                onMutate: (operation) => {
                  const loadingMessage = formatMessage(
                    { id: entityDetailActionPendingKey[operation] },
                    { entity: entityName },
                  );
                  setEntityOperationLoadingMsg(loadingMessage);
                },
                onSuccess: ({ data }) => {
                  queryClient.invalidateQueries({
                    queryKey: listQueryKey,
                  });
                  const newEntityId = data?.id;
                  history.push(getEntityDetailsPathForId(newEntityId));
                  resetConfigUserFeedbackStore();
                },
                onError: (error: any) => {
                  handleEntityOperationError(error);
                },
                onSettled,
              },
              duplicateAsDraft: {
                onMutate: (operation) => {
                  const loadingMessage = formatMessage(
                    { id: entityDetailActionPendingKey[operation] },
                    { entity: entityName },
                  );
                  setEntityOperationLoadingMsg(loadingMessage);
                },
                onSuccess: ({ data }) => {
                  queryClient.invalidateQueries({
                    queryKey: listQueryKey,
                  });

                  const newEntityId = data?.id;
                  openInTab(getEntityDetailsPathForId(newEntityId));
                  resetConfigUserFeedbackStore();
                },
                onError: (error: any) => {
                  handleEntityOperationError(error);
                },
                onSettled,
              },
              deepClone: {
                request: {
                  onMutate: (operation) => {
                    const loadingMessage = formatMessage(
                      { id: entityDetailActionPendingKey[operation] },
                      { entity: entityName },
                    );
                    setEntityOperationLoadingMsg(loadingMessage);
                  },
                  onSuccess: ({ data }) => {
                    queryClient.invalidateQueries({
                      queryKey: [entity.endpoints.list.path],
                    });

                    const newEntityId = data?.id;
                    openInTab(getEntityDetailsPathForId(newEntityId));
                    resetConfigUserFeedbackStore();
                  },
                  onError: (error: any) => {
                    handleEntityOperationError(error);
                  },
                  onSettled,
                },
              },
              custom: {
                ...customActionEventHandlers,
                onSettled,
              },
            }}
            multiEnvironmentOptions={{
              copyToEnvironment: {
                onMutate: (operation, environment) => {
                  const loadingMessage = formatMessage(
                    { id: entityDetailActionPendingKey[operation] },
                    { entity: entityName, environment },
                  );
                  setEntityOperationLoadingMsg(loadingMessage);
                },
                onSuccess: ({ data }, environment: EnvironmentKey) => {
                  queryClient.invalidateQueries({
                    queryKey: [
                      environmentKey,
                      isUsingConfigAppBE
                        ? configEntity.endpoints.get.path
                        : entity.endpoints.get.path,
                      entityInstanceId,
                    ],
                  });
                  resetConfigUserFeedbackStore();
                  const newEntityId = data?.id;
                  history.push(
                    getEntityDetailsPathForEnvAndId(newEntityId, environment),
                  );
                },
                onError: (error: any) => {
                  handleEntityOperationError(error);
                },
                onSettled,
              },
            }}
          >
            <ConfigAppEntityDetailsPageWithContext
              entity={entity}
              instanceId={entityInstanceId}
              entityListPath={entityListPath}
              environmentKey={environmentKey}
              getEntityDetailsPathForEnvAndId={getEntityDetailsPathForEnvAndId}
            />
          </EntityDetailsPageContainer>
        </DataSourceStoreProvider>
      </RuleTemplatesEditorStateStoreProvider>
    </EntityDetailsDataStoreProvider>
  ) : null;
};
