import { RuleTemplateData } from '@web-config-app/core';

export const replacePlaceholderInRules = (
  rules: Record<string, any> | string,
  ruleTemplateData: RuleTemplateData,
  isObject?: boolean,
) => {
  try {
    let ruleString = JSON.stringify(rules);

    Object.entries(ruleTemplateData).forEach(([key, value]) => {
      const placeholder = `"{${key}}"`;

      let filteredValue = value;
      // if the value is an array, filter out null or undefined
      if (Array.isArray(value)) {
        filteredValue = value.filter(Boolean);
      }

      const replacementValue = isObject
        ? JSON.parse(JSON.stringify(filteredValue))
        : JSON.stringify(filteredValue);
      ruleString = ruleString.replaceAll(placeholder, replacementValue);
    });

    return JSON.parse(ruleString);
  } catch (error) {
    console.error('Error processing template json:', error);
    return null;
  }
};
