export const removeItemAtIndex = <T>(array: T[], index: number): T[] => {
  if (!Array.isArray(array)) {
    throw new Error('Input is not an array.');
  }

  if (index < 0 || index >= array.length) {
    return [...array];
  }

  const newArray = [...array];
  newArray.splice(index, 1);

  return newArray;
};
