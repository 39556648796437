import * as React from 'react';
import {
  GDSInputStatus,
  GDSStatus,
  TextInput,
} from '@leagueplatform/genesis-core';
import {
  FormFieldWithTrailingAction,
  generateFormFieldAttributes,
} from '@web-config-app/core-react-ui';
import type { DataPoints } from '@web-config-app/core';
import { DataPointModal } from '../data-point-modal/data-point-modal.component';

export type DataPointInputProps = {
  id: string;
  label: string;
  hint?: string;
  value?: any;
  onChange?: (data?: string) => void;
  required?: boolean;
  readOnly?: boolean;
  inputStatus?: GDSInputStatus;
  clearable?: boolean;
  onClear?: () => void;
  statusMessage?: string;
  dataPoints?: DataPoints;
  dataPointModalError?: boolean;
  dataPointModalLoading?: boolean;
  banner?: {
    title?: React.ReactNode;
    status?: GDSStatus;
    description?: React.ReactNode;
  };
};

export const DataPointInput = ({
  id,
  label,
  hint,
  value,
  onChange,
  banner,
  readOnly,
  required,
  inputStatus,
  statusMessage,
  dataPointModalError,
  dataPointModalLoading,
  clearable,
  onClear,
  dataPoints,
}: DataPointInputProps) => {
  const { ariaDescribedBy } = generateFormFieldAttributes(
    id,
    hint,
    statusMessage,
  );

  const handleFieldChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      onChange?.(inputValue);
    },
    [onChange],
  );

  const handleDataPointSelect = React.useCallback(
    (dataPointId?: string) => {
      onChange?.(dataPointId);
    },
    [onChange],
  );

  return (
    <FormFieldWithTrailingAction
      id={id}
      label={label}
      action={
        <DataPointModal
          dataPoints={dataPoints || []}
          onSelected={handleDataPointSelect}
          disabled={readOnly}
          isError={dataPointModalError}
          isLoading={dataPointModalLoading}
        />
      }
      hint={hint}
      required={required}
      banner={banner}
      statusMessage={statusMessage}
    >
      <TextInput
        id={id}
        readOnly={readOnly}
        inputStatus={inputStatus}
        required={required}
        onChange={handleFieldChange}
        value={value}
        aria-describedby={ariaDescribedBy}
        clearable={clearable}
        onClear={onClear}
      />
    </FormFieldWithTrailingAction>
  );
};
