import * as React from 'react';
import { Box, StackLayout, UtilityText } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { IconButton } from '@web-config-app/core-react-ui';
import imageUploadErrorIcon from 'assets/image-upload-error.png';
import { useGetAssetDataById } from '../../use-get-asset-data-by-id.hook';
import { SelectedImageLoadingSkeleton } from './selected-image-loading-skeleton.component';

const IMAGE_SIZE = '2.5rem';

interface SelectedImageProps {
  imageData: string;
  isImageURL: boolean;
  onClear: () => void;
  disabled?: boolean;
}

export const SelectedImage = ({
  imageData,
  isImageURL,
  onClear,
  disabled,
}: SelectedImageProps) => {
  const { formatMessage } = useIntl();

  const imageId = isImageURL ? null : imageData;

  /**
   * We should only fetch the image data by ID in the case where the
   * imageData passed in is an ID. If the image data is a URL for the image,
   * we bypass fetching function, use the URL directly for the image
   * thumbnail and title, and we hide the image ID in the rendered UI
   */

  const {
    data: imageDataFromId,
    isLoading,
    isError,
  } = useGetAssetDataById(imageData, { enabled: !isImageURL });

  if (isLoading) {
    return <SelectedImageLoadingSkeleton />;
  }

  const displayErrorFallback = isError || (!isImageURL && !imageDataFromId);

  const getImageTitleAndUrl = () => {
    if (isImageURL) {
      return {
        title: imageData,
        url: imageData,
      };
    }
    if (displayErrorFallback) {
      return {
        title: formatMessage({ id: 'COULD_NOT_LOAD_IMAGE' }),
        url: imageUploadErrorIcon,
      };
    }
    return {
      title: imageDataFromId?.attributes.title,
      url: imageDataFromId?.attributes.url,
    };
  };

  const { title, url } = getImageTitleAndUrl();

  return (
    <StackLayout
      orientation="horizontal"
      horizontalAlignment="spaceBetween"
      verticalAlignment="center"
      spacing="$half"
    >
      <StackLayout
        orientation="horizontal"
        spacing="$half"
        verticalAlignment="center"
        css={{ width: 0 }}
      >
        <Box
          as="img"
          src={url}
          alt=""
          css={{
            objectFit: 'cover',
            width: IMAGE_SIZE,
            height: IMAGE_SIZE,
          }}
        />
        <UtilityText
          size="sm"
          css={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            ...(displayErrorFallback && { color: '$onSurfaceTextCritical' }),
          }}
          emphasis={displayErrorFallback ? 'base' : 'emphasized'}
        >
          {title}
        </UtilityText>
      </StackLayout>
      <StackLayout
        orientation="horizontal"
        spacing="$one"
        verticalAlignment="center"
        css={{
          flexGrow: 0,
        }}
      >
        {imageId && (
          <UtilityText size="xs" emphasis="subtle">
            {imageId}
          </UtilityText>
        )}
        <IconButton
          icon="interfaceChange"
          label={formatMessage({ id: 'CLEAR_SELECTED_IMAGE' })}
          onClick={onClear}
          disabled={disabled}
        />
      </StackLayout>
    </StackLayout>
  );
};
