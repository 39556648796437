import * as React from 'react';
import { JsonEditorValue } from '@web-config-app/core-react-ui';
import {
  useRulesTemplates,
  useRuleTemplatesEditorStateStoreActions,
} from '@web-config-app/core-react';
import type {
  AnnotatedJsonSchema,
  RulesTemplateWithSchema,
} from '@web-config-app/core';
import { useEntityDetailsProps } from '../../../hooks/use-entity-details-props/use-entity-details-props';
import { decodeJsonLogicString } from './decode-json-logic-string';
import type { RuleTemplateFormData } from './rule-template-form/rule-template-form.component';
import { replacePlaceholderInRules } from '../../../utils/replace-placeholders-in-rules/replace-placeholders-in-rules.util';
import { getRulesTemplateWithSchema } from '../../../utils/get-rules-template-with-schema/get-rules-template-with-schema.util';

interface UseJsonLogicControlProps {
  handleChange: (path: string, value: any) => void;
  path: string;
  schema: AnnotatedJsonSchema;
  data?: string;
  parentFieldPath?: string;
  isArrayItem?: boolean;
  arrayIndex?: number;
}

const getRulesTemplateType = (schema: AnnotatedJsonSchema) =>
  schema?.['x-entity-presentation']?.rulesTemplateType;

export const useJsonLogicControl = ({
  data,
  handleChange,
  path,
  schema,
  parentFieldPath,
  isArrayItem,
  arrayIndex,
}: UseJsonLogicControlProps) => {
  const { entityType, formPath } = useEntityDetailsProps();
  const rulesType = getRulesTemplateType(schema);
  const jsonLogicFieldPath = `${formPath}.${path}`;

  // If this is a rule combination control - use the regular form path, if this is a child single rule - we want it to be the parent path
  const topLevelFieldPath = parentFieldPath || jsonLogicFieldPath;

  // If this is a rule combination control, there will be no nestedFieldPath
  const nestedFieldPath = parentFieldPath ? jsonLogicFieldPath : undefined;

  const controlType = parentFieldPath ? 'singleRule' : 'ruleCombination';

  // If its a single rule, we want the spacing to make the rule formatted nice in the json editor
  const jsonSpacing = controlType === 'singleRule' ? 2 : undefined;

  const { getRuleTemplateFieldData, setRuleTemplateFieldData } =
    useRuleTemplatesEditorStateStoreActions();

  const ruleTemplateFieldData = getRuleTemplateFieldData({
    fieldPath: topLevelFieldPath,
    nestedFieldPath,
    arrayIndex,
  });

  const cacheKeyRef = React.useRef<string | undefined>(formPath);
  const valueRef = React.useRef<string | undefined>(data);

  const rulesTemplates = useRulesTemplates({
    entityType,
    rulesType,
    controlType,
  });

  const dataTemplateId = ruleTemplateFieldData?.rulesTemplate?.templateId;

  const dataRuleTemplate = rulesTemplates?.find(
    (template) => template.id === dataTemplateId,
  );

  const [jsonContent, setJsonContent] = React.useState<JsonEditorValue>({
    json: undefined,
    text: decodeJsonLogicString(data),
  });
  const [selectedRuleTemplate, setSelectedRuleTemplate] = React.useState<
    RulesTemplateWithSchema | undefined
  >(
    getRulesTemplateWithSchema({
      selectedRuleTemplate: dataRuleTemplate,
      parentLabelKey: schema['x-entity-label']?.key,
    }),
  );

  React.useEffect(() => {
    /**
     * Handles a bug where the component initially renders with data `undefined` before being
     * eventually passed as the existing string data and where if switching between 2 different json-logic controls on separate pages directly, stale data (from the previous json-logic component) was being shown in the control.. This can likely be mitigated by future state optimization work.
     *
     * TODO: https://everlong.atlassian.net/browse/CACT-1290
     */
    if (
      (typeof data === 'string' && typeof jsonContent.text === 'undefined') ||
      (cacheKeyRef.current !== formPath && valueRef.current !== data)
    ) {
      cacheKeyRef.current = formPath;
      valueRef.current = data;
      setJsonContent({
        json: undefined,
        text: decodeJsonLogicString(data),
      });
    } else {
      valueRef.current = data;
    }
  }, [data, formPath, jsonContent.text]);

  const handleOnJsonChange = React.useCallback(
    (content: JsonEditorValue) => {
      setJsonContent(content);
      handleChange(path, content.text);
    },
    [handleChange, path],
  );

  const [ruleTemplateFormData, setRuleTemplateFormData] =
    React.useState<RuleTemplateFormData>(
      ruleTemplateFieldData?.rulesTemplate?.templateValues,
    );

  const handleRuleTemplateFormDataChange = (formData: RuleTemplateFormData) => {
    setRuleTemplateFormData(formData);

    if (selectedRuleTemplate && formData) {
      setRuleTemplateFieldData({
        fieldPath: topLevelFieldPath,
        nestedFieldPath,
        isArrayItem,
        arrayIndex,
        ruleTemplateFieldData: {
          rulesTemplate: {
            rulesMode: 'template',
            templateId: selectedRuleTemplate?.id,
            templateValues: formData,
          },
        },
      });
      const updatedRuleJson = replacePlaceholderInRules(
        selectedRuleTemplate.rules,
        formData,
        controlType === 'ruleCombination',
      );
      handleOnJsonChange({
        json: undefined,
        text: JSON.stringify(updatedRuleJson ?? {}, null, jsonSpacing),
      });
    }
  };

  const handleTemplateChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const templateValue = event.target.value;

    const selectedTemplate = rulesTemplates?.find(
      (template) => template.id === templateValue,
    );

    setRuleTemplateFieldData({
      nestedFieldPath,
      shouldResetNestedFields: !parentFieldPath,
      fieldPath: topLevelFieldPath,
      isArrayItem,
      arrayIndex,
      ruleTemplateFieldData: {
        rulesTemplate: {
          rulesMode: 'template',
          templateId: selectedTemplate?.id,
        },
      },
    });

    setSelectedRuleTemplate(
      getRulesTemplateWithSchema({
        selectedRuleTemplate: selectedTemplate,
        parentLabelKey: schema['x-entity-label']?.key,
      }),
    );
    // ruleTemplateFormData must always be an object instead of undefined to support our input validation - this way the errors via EntityForm / JsonForms are always tied to specific inputs not "data must be an object"
    setRuleTemplateFormData({});

    handleOnJsonChange({
      json: undefined,
      text: JSON.stringify(selectedTemplate?.rules ?? {}, null, jsonSpacing),
    });
  };

  return {
    rulesTemplates,
    jsonContent,
    jsonLogicFieldPath,
    selectedRuleTemplate,
    ruleTemplateFormData,
    handleOnJsonChange,
    handleRuleTemplateFormDataChange,
    handleTemplateChange,
  };
};
