import * as React from 'react';
import {
  Modal,
  HeadingText,
  Box,
  StackLayout,
  ParagraphText,
  Button,
  Spinner,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import type { RootEntityCloneItem, Nullable } from '@web-config-app/core';
import { EntityCloneList } from '../entity-clone-list/entity-clone-list.component';

export interface DeepCloneModalProps {
  isOpen: boolean;
  data?: Nullable<RootEntityCloneItem | undefined>;
  isLoadingDeepCloneGetData: boolean;
  errorMessage?: Nullable<string>;
  deepCloneAction: () => void;
  onNameChange: (id: string, name: string) => void;
  onClose: () => void;
}

export const DeepCloneModal = ({
  isOpen,
  data,
  isLoadingDeepCloneGetData,
  deepCloneAction,
  onNameChange,
  errorMessage = null,
  onClose,
}: DeepCloneModalProps) => {
  const { formatMessage } = useIntl();
  const isReady = !isLoadingDeepCloneGetData && data;
  return (
    <Modal.Root open={isOpen} onOpenChange={(open) => !open && onClose()}>
      <Modal.Content
        layout="fullscreen"
        css={{
          '.GDS-modal-content': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
        showCloseButton={false}
      >
        <StackLayout
          orientation="vertical"
          spacing="$one"
          horizontalAlignment="stretch"
          css={{ height: '100%' }}
        >
          <StackLayout
            orientation="horizontal"
            horizontalAlignment="end"
            css={{ flexGrow: 0 }}
          >
            <Modal.Close>
              <Button
                priority="tertiary"
                quiet
                icon="tinyClose"
                hideLabel
                size="small"
                className="GDS-modal-close-button"
              >
                {formatMessage({ id: 'CLOSE' })}
              </Button>
            </Modal.Close>
          </StackLayout>
          <Modal.Title>
            <HeadingText level="1" size="xxl">
              {formatMessage({ id: 'DEEP_CLONE_MODAL_TITLE' })}
            </HeadingText>
          </Modal.Title>
          <Modal.Description>
            <ParagraphText>
              {formatMessage({ id: 'DEEP_CLONE_MODAL_DESCRIPTION' })}
            </ParagraphText>
          </Modal.Description>
          {isLoadingDeepCloneGetData ? (
            <StackLayout
              orientation="vertical"
              spacing="$one"
              horizontalAlignment="center"
              verticalAlignment="center"
              css={{ minHeight: 400 }}
            >
              <Spinner
                loading
                loadingText={formatMessage({
                  id: 'DEEP_CLONE_MODAL_FETCHING_TEXT',
                })}
              />
            </StackLayout>
          ) : (
            <>
              <StackLayout
                orientation="vertical"
                spacing="$one"
                horizontalAlignment="stretch"
                css={{
                  padding: '$one',
                  backgroundColor: '$surfaceBackgroundSecondary',
                  borderColor: '$onSurfaceBorderSubdued',
                  borderWidth: '$thin',
                  borderStyle: 'solid',
                  flexGrow: 1,
                  overflow: 'auto',
                }}
              >
                {isReady && !errorMessage && (
                  <Box as="form" css={{ maxWidth: 800 }}>
                    <EntityCloneList
                      entity={data}
                      onNameChange={onNameChange}
                    />
                  </Box>
                )}
                {errorMessage && (
                  <ParagraphText css={{ color: '$onSurfaceTextCritical' }}>
                    {errorMessage}
                  </ParagraphText>
                )}
              </StackLayout>
              <StackLayout
                orientation="horizontal"
                spacing="$one"
                horizontalAlignment="end"
                css={{ flex: '0 0 auto' }}
              >
                <Button
                  onClick={() => deepCloneAction()}
                  disabled={Boolean(errorMessage)}
                >
                  {formatMessage({ id: 'DEEP_CLONE_MODAL_PROCEED_BUTTON' })}
                </Button>
              </StackLayout>
            </>
          )}
        </StackLayout>
      </Modal.Content>
    </Modal.Root>
  );
};
