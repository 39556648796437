import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';

export const useEntityReferenceControlState = () => {
  const { formatMessage } = useIntl();
  const [isAddingById, setIsAddingById] = React.useState<boolean>(false);
  const [isAddingFromList, setIsAddingFromList] =
    React.useState<boolean>(false);

  const openChooseFromList = () => {
    setIsAddingFromList(true);
  };

  const closeChooseFromList = () => {
    setIsAddingFromList(false);
  };

  const openAddById = () => {
    setIsAddingById(true);
  };

  const closeAddById = () => {
    setIsAddingById(false);
  };

  const addItemTriggerList = [
    {
      buttonLabel: formatMessage({
        id: 'ADD_BY_ID',
      }),
      openFn: openAddById,
    },
    {
      buttonLabel: formatMessage({
        id: 'CHOOSE_FROM_LIST',
      }),
      openFn: openChooseFromList,
    },
  ];

  return {
    isAddingById,
    isAddingFromList,
    openChooseFromList,
    closeChooseFromList,
    openAddById,
    closeAddById,
    addItemTriggerList,
  };
};
