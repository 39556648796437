import * as React from 'react';
import { EntityLoader } from '@web-config-app/core-react';
import type {
  ControlProps,
  EntitySelectControlSchema,
} from '../../../types/controls';
import { useEntityReferenceSchemaType } from '../../../hooks/use-entity-reference-schema-type/use-entity-reference-schema-type';
import { EntityRelationshipControlWrapper } from './entity-relationship-control-wrapper/entity-relationship-control-wrapper.component';
import { EntityReferenceControlWrapper } from './entity-reference-control-wrapper/entity-reference-control-wrapper.component';
import { isRelationshipEntityReference } from '../../../utils/is-relationship-entity-reference/is-relationship-entity-reference';

export const EntitySelectControl: React.FC<ControlProps> = (props) => {
  const { schema } = props;

  const entityType = useEntityReferenceSchemaType({
    // eslint-disable-next-line react/destructuring-assignment
    schema: schema as EntitySelectControlSchema,
  });

  const { 'x-entity-reference': entityReference } =
    schema as EntitySelectControlSchema;

  const Wrapper = isRelationshipEntityReference(entityReference)
    ? EntityRelationshipControlWrapper
    : EntityReferenceControlWrapper;

  return (
    <EntityLoader
      entityType={entityType}
      render={(entity) => <Wrapper {...props} referencedEntity={entity} />}
    />
  );
};
