import * as React from 'react';
import {
  StackLayout,
  StackItem,
  Button,
  Modal,
  HeadingText,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { isUsingConfigAppBackend } from '@web-config-app/common';
import { EntityDetail, getEntityInstanceName } from '@web-config-app/core';
import {
  useEntityList,
  useConfigApp,
  useConfigEntity,
} from '@web-config-app/core-react';
import {
  SelectableEntityListTable,
  EntityListTablePagination,
} from '@web-config-app/core-react-ui';
import { useEntityDetailsProps } from '../../../../../hooks/use-entity-details-props/use-entity-details-props';
import { EntityListSelectModalProps } from '../../../../../types/components';

export const AddEntityReferenceFromListModal: React.FC<
  EntityListSelectModalProps
> = ({ onSubmit: onSelect, onCancel, open, entity, headingLabel }) => {
  const { formatMessage } = useIntl();
  const [selectedEntity, setSelectedEntity] = React.useState<
    EntityDetail | undefined
  >();
  const { currentAuthoringEnvironment } = useEntityDetailsProps();
  const { isUsingConfigAppBackend: isTenantUsingConfigAppBackend } =
    useConfigApp();
  const configEntity = useConfigEntity();
  const isConfigAppBackendEnabled = isUsingConfigAppBackend({
    tenantLevelOverride: isTenantUsingConfigAppBackend,
    entityLevelOverride: entity.isUsingConfigAppBackend,
  });

  /**
   * It would be maybe preferable for this modal to not be consuming useEntityList directly and instead
   * implementing some API (from `useEntityDetailsProps()?) to be able to get this data.
   */
  const { pagination, data, isLoading, loadPage } = useEntityList({
    entity: isConfigAppBackendEnabled ? configEntity : entity,
    enabled: open,
    environment: currentAuthoringEnvironment,
    domainEntityType: isConfigAppBackendEnabled ? entity.type : undefined,
  });

  return (
    <Modal.Root
      onOpenChange={(isOpen: boolean) => !isOpen && onCancel()}
      open={open}
    >
      <Modal.Content layout="fullscreen">
        <StackLayout spacing="$oneAndHalf" horizontalAlignment="stretch">
          <StackLayout
            orientation="horizontal"
            horizontalAlignment="stretch"
            verticalAlignment="center"
          >
            <StackItem horizontalAlignment="fillContainer">
              <Modal.Title>
                <HeadingText level="2" size="lg">
                  {headingLabel}
                </HeadingText>
              </Modal.Title>
            </StackItem>

            <StackItem horizontalAlignment="end">
              <Button
                size="medium"
                priority="primary"
                onClick={() => onSelect(selectedEntity)}
              >
                {formatMessage({ id: 'DONE' })}
              </Button>
            </StackItem>
          </StackLayout>
          <SelectableEntityListTable
            entityData={data}
            isLoading={isLoading}
            getItemName={(d: EntityDetail) => getEntityInstanceName(d, entity)}
            onEntitySelected={(e: EntityDetail) => setSelectedEntity(e)}
          />
          {pagination && (
            <EntityListTablePagination
              pagination={pagination}
              loadPage={loadPage}
            />
          )}
        </StackLayout>
      </Modal.Content>
    </Modal.Root>
  );
};
