import * as React from 'react';
import { Icon, ParagraphText, StackLayout } from '@leagueplatform/genesis-core';
import {
  RulesTemplateWithSchema,
  RuleTemplateData,
} from '@web-config-app/core';
import { JsonFormsRendererRegistryEntry } from '@jsonforms/core';
import { useConfigUserFeedback } from '@web-config-app/core-react';
import { useIntl } from '@leagueplatform/locales';
import { EntityForm } from '../../../entity-form/entity-form.component';
import { createErrorFilteringMiddleware } from '../../../../utils/create-error-filtering-middleware/create-error-filtering-middleware.util';

export type RuleTemplateFormData = RuleTemplateData | undefined;

const EmptyRuleState = ({ children }: React.PropsWithChildren) => (
  <StackLayout
    orientation="horizontal"
    horizontalAlignment="center"
    verticalAlignment="center"
    css={{
      backgroundColor: '$surfaceBackgroundSecondary',
      border: 'solid $colors$onSurfaceBorderSubdued $borderWidths$thin',
      borderRadius: '$thin',
      height: '$five',
    }}
  >
    {children}
  </StackLayout>
);

/**
 * Renders a form for editing rule template data.
 *
 * This component utilizes the `EntityForm` to display and manage the input fields defined in the `selectedRuleTemplate.schema`.
 * If no rule template is selected or the selected template has no inputs, it displays an `EmptyRuleState` component.
 *
 * @param {object} params - The parameters for the component.
 * @param {RulesTemplateWithSchema} [params.selectedRuleTemplate] - The selected rule template, including its schema for the form.
 * @param {RuleTemplateFormData} [params.ruleTemplateData] - The initial data for the form.
 * @param {Function} params.onRuleTemplateDataChange - A callback function invoked when the form data changes.
 * @param {Function} params.renderers - An array of JsonFormsRendererRegistryEntry to be passed into EntityForm
 */

export const RuleTemplateForm = ({
  selectedRuleTemplate,
  ruleTemplateData,
  onRuleTemplateDataChange,
  renderers = [],
}: {
  selectedRuleTemplate?: RulesTemplateWithSchema;
  ruleTemplateData?: RuleTemplateFormData;
  onRuleTemplateDataChange: (data: any) => any;
  renderers?: Array<JsonFormsRendererRegistryEntry>;
}) => {
  const { formatMessage } = useIntl();

  const { filteredErrorKeywords, showFormValidation } = useConfigUserFeedback(
    (state) => state,
  );

  const entityFormMiddleware = createErrorFilteringMiddleware(
    filteredErrorKeywords,
  );

  if (!selectedRuleTemplate)
    return (
      <EmptyRuleState>
        <ParagraphText emphasis="subtle">
          {formatMessage({ id: 'CHOOSE_A_RULE_TEMPLATE' })}
        </ParagraphText>
      </EmptyRuleState>
    );

  if (!selectedRuleTemplate.schema)
    return (
      <EmptyRuleState>
        <Icon icon="tinyValidationCheck" tint="$successIcon" size="$one" />{' '}
        <ParagraphText
          css={{
            color: '$decorativeBrandSecondaryDarkest',
            marginLeft: '$quarter',
          }}
        >
          {formatMessage({ id: 'NO_RULE_PARAMETERS_NEEDED' })}
        </ParagraphText>
      </EmptyRuleState>
    );

  return (
    <EntityForm
      schema={selectedRuleTemplate.schema}
      renderers={renderers}
      data={ruleTemplateData}
      showValidation={showFormValidation}
      middleware={entityFormMiddleware}
      onDataChange={onRuleTemplateDataChange}
    />
  );
};
