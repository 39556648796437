import * as React from 'react';
import { useEntityDetailsProps } from '@web-config-app/entity-form';
import { EntityDataTableModal } from '@web-config-app/core-react-ui';

export const EntityDataTableModalContainer = () => {
  const {
    entityRootData,
    otherAuthoringEnvironmentState,
    currentAuthoringEnvironment,
    name,
  } = useEntityDetailsProps();
  const {
    entityData: entityRootDataOnOtherEnvironment,
    environmentKey: otherEnvironmentKey,
  } = otherAuthoringEnvironmentState ?? {};
  const otherEnvironmentData = otherEnvironmentKey && {
    key: otherEnvironmentKey,
    data: entityRootDataOnOtherEnvironment,
  };

  return (
    <EntityDataTableModal
      currentEnvironmentData={{
        key: currentAuthoringEnvironment,
        data: entityRootData,
      }}
      otherEnvironmentData={otherEnvironmentData}
      entityName={name}
    />
  );
};
