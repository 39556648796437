import {
  AuthoringEnvironmentClientInfo,
  Entity,
  EntityConfig,
} from '@web-config-app/core';
import { useAuthoringEnvironments } from '../use-authoring-environments/use-authoring-environments';

export const useEntitySingleEnvironment = () => {
  const { environmentMap } = useAuthoringEnvironments();

  const getEntitySingleEnvironment = ({
    entity,
  }: {
    entity: Entity | EntityConfig;
  }): {
    singleEnv: AuthoringEnvironmentClientInfo | undefined;
    isEntitySingleEnv: boolean;
  } => {
    if (!(environmentMap instanceof Map)) {
      return { singleEnv: undefined, isEntitySingleEnv: false };
    }
    const singleEnv = Array.from(environmentMap.values()).find(
      (env) => env.type === entity?.singleEnvironment,
    );
    return { singleEnv, isEntitySingleEnv: !!singleEnv };
  };

  return { getEntitySingleEnvironment };
};
