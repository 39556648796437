import { useMemo, useCallback } from 'react';
import type { AnnotatedJsonSchema } from '@web-config-app/core';
import { useTranslate } from '@web-config-app/core-react';
import { EntityFormControlAnnotationProps } from '../../types/controls';

export const useArrayControlCombinatorHelpers = (
  combinatorProperties: EntityFormControlAnnotationProps['combinatorProperties'],
) => {
  const translate = useTranslate();

  const combinatorActionMenuItems = useMemo(
    () =>
      combinatorProperties?.combinatorSelectOptions?.map(
        ({ label: optionLabel, value }) => ({
          id: value,
          label: optionLabel,
        }),
      ),
    [combinatorProperties?.combinatorSelectOptions],
  );

  const getSelectedSubSchema = useCallback(
    (defaultValue?: { [property: string]: any }) => {
      if (!combinatorProperties) {
        return undefined;
      }
      const { combinatorDiscriminator, combinatorSubSchemaMap } =
        combinatorProperties;
      const discriminatingId = defaultValue?.[combinatorDiscriminator];
      return combinatorSubSchemaMap[discriminatingId];
    },
    [combinatorProperties],
  );

  const getLabelForSelectedSubSchema = useCallback(
    (selectedSubSchema?: AnnotatedJsonSchema) => {
      if (!selectedSubSchema || !combinatorProperties) {
        return undefined;
      }
      const { combinatorDiscriminator } = combinatorProperties;

      return (
        translate(
          selectedSubSchema.properties?.[combinatorDiscriminator]?.[
            'x-entity-label'
          ]?.key,
        ) ?? selectedSubSchema?.title
      );
    },
    [combinatorProperties, translate],
  );

  return {
    combinatorActionMenuItems,
    getSelectedSubSchema,
    getLabelForSelectedSubSchema,
  };
};
