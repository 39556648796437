import { usePrimaryAction } from './use-primary-action';
import { useSecondaryAction } from './use-secondary-action';
import { useMenuItems } from './use-menu-items';
import type { EntityWorkflowActionsProps } from '../../types/hooks';

export type UseEntityWorkflowActionsResult = ReturnType<
  typeof useEntityWorkflowActions
>;

/**
 * useEntityWorkflowActions - a hub that handles all things entity top bar workflow actions including label, enabled, tooltip, etc. for `EntityStatusValue`
 * @param operations - used in this hook where needed, like to determine which mutate function to call for the save button
 * @param status - used to determine different states and statuses of top bar things. Defaults to New when the status is undefined
 * @param isEntitySaved - based on if the entity is in sync with the data from the GET api call
 * @param entityConfigName - used to construct the tooltip of a menu item
 * @returns top bar workflows to be used by the header container
 */

export const useEntityWorkflowActions = (props: EntityWorkflowActionsProps) => {
  const primaryAction = usePrimaryAction(props);
  const secondaryAction = useSecondaryAction(props);
  const menuItems = useMenuItems(props);

  return {
    primaryAction,
    secondaryAction,
    menuItems,
  };
};
