import type { RulesTemplateWithoutEntityType } from '@web-config-app/core';
import { mapRulesTemplatesForEntity } from '../../../utils/map-rules-templates-for-entity';

const exampleEntityPartials: RulesTemplateWithoutEntityType[] = [
  {
    id: 'example_entity_program_with_one_rule_condition',
    label: 'EXAMPLE_PROGRAM_WITH_ONE_RULE_CONDITION',
    name: 'Example Program with One Rule Condition',
    type: 'eligibilityCriteria',
    rules: {
      string_equal_ignore_case: [
        '{condition}',
        {
          var: '{dataPoint}',
        },
      ],
    },
    inputs: {
      condition: {
        type: 'string',
        'x-entity-label': {
          key: 'CONDITION',
        },
      },
      dataPoint: {
        type: 'string',
        'x-entity-label': {
          key: 'DATA_POINT',
        },
      },
    },
  },
  {
    id: 'example_entity_eligibility_segment_even_and_odd_age',
    label: 'EXAMPLE_ELIGIBILITY_SEGMENT_EVEN_AND_ODD_AGE',
    name: 'Example Segment by Even and Odd Age',
    type: 'eligibilityCriteria',
    rules: {
      '==': [
        {
          '%': [{ var: 'up.age' }, 2],
        },
        0,
      ],
    },
  },
];

export const exampleEntityRulesTemplates = exampleEntityPartials.map(
  mapRulesTemplatesForEntity('config-example-entity'),
);
