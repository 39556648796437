import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import type { EntityCloneItem as EntityCloneItemType } from '@web-config-app/core';
import {
  TextInput,
  StackLayout,
  FormField,
} from '@leagueplatform/genesis-core';

export type EntityCloneItemProps = Omit<EntityCloneItemType, 'type'> & {
  onNameChange: (id: string, name: string) => void;
};

/**
 * Input component for renaming an entity during the cloning process.
 *
 * @component
 * @param {EntityCloneItemProps & React.PropsWithChildren} props - The props for the EntityCloneItemInput component.
 * @returns {JSX.Element} The rendered EntityCloneItemInput component.
 */
export const EntityCloneItemInput = ({
  id,
  originalName,
  onNameChange,
  children,
}: React.PropsWithChildren<EntityCloneItemProps>) => {
  const [name, setName] = React.useState(originalName);
  const { formatMessage } = useIntl();

  const handleOnChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = evt.currentTarget.value;
    setName(newValue);
    onNameChange(id, newValue);
  };

  return (
    <StackLayout
      orientation="vertical"
      spacing="$half"
      css={{ paddingTop: '$half' }}
    >
      <FormField
        id={id}
        label={formatMessage(
          { id: 'DEEP_CLONE_OPTIONAL_ENTITY_ITEM_RENAME' },
          { originalName },
        )}
      >
        <TextInput value={name} onChange={handleOnChange} />
      </FormField>
      {children}
    </StackLayout>
  );
};
