import * as React from 'react';
import {
  useEntityList,
  useConfigApp,
  useConfigAppPath,
  useConfigEntity,
  useAuthoringEnvironments,
  useResetEntityData,
  useEntitySingleEnvironment,
} from '@web-config-app/core-react';
import type { EnvironmentKey, Entity } from '@web-config-app/core';
import { isUsingConfigAppBackend } from '@web-config-app/common';
import { useHistory } from '@leagueplatform/routing';
import { Category } from '@web-config-app/core';
import { EntityListPageViewProps } from '@web-config-app/core-react-ui';
import { useIntl } from '@leagueplatform/locales';

export type EntityListPageContentsRenderer = (
  props: EntityListPageViewProps,
) => JSX.Element;

interface EntityListPageContentsProps {
  entity: Entity;
  domainId: string;
  entityId: string;
  environmentKey: EnvironmentKey;
  entityListPageContentsRenderer: EntityListPageContentsRenderer;
}
const EntityListPageContents = ({
  entity,
  entityListPageContentsRenderer,
  entityId,
  domainId,
  environmentKey,
}: EntityListPageContentsProps) => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const generateConfigPaths = useConfigAppPath();
  const resetEntityData = useResetEntityData();

  const { getEntityDetailsPathForId, entityCreatePath } = generateConfigPaths({
    domainId,
    entityId,
    environmentKey,
  });

  const { tenantName, isUsingConfigAppBackend: isTenantUsingConfigAppBackend } =
    useConfigApp();
  const configEntity = useConfigEntity();
  const {
    currentAuthoringEnvironment,
    environmentMap,
    multiEnvironmentAuthoringEnabled,
  } = useAuthoringEnvironments({
    environmentKey,
  });
  const { getEntitySingleEnvironment } = useEntitySingleEnvironment();
  const { isEntitySingleEnv } = getEntitySingleEnvironment({ entity });
  const { pagination, data, isError, isLoading, loadPage } = useEntityList({
    entity: isUsingConfigAppBackend({
      tenantLevelOverride: isTenantUsingConfigAppBackend,
      entityLevelOverride: entity.isUsingConfigAppBackend,
    })
      ? configEntity
      : entity,
    environment: currentAuthoringEnvironment,
    domainEntityType: isUsingConfigAppBackend({
      tenantLevelOverride: isTenantUsingConfigAppBackend,
      entityLevelOverride: entity.isUsingConfigAppBackend,
    })
      ? entity.type
      : undefined,
  });
  const displayEmptyCard = isError || (!isLoading && !data?.length);

  const emptyCardHeading = formatMessage(
    { id: isError ? 'ERROR_ENTITY_TITLE' : 'EMPTY_ENTITY_TITLE' },
    { entity: entity.name },
  );

  const emptyCardDescription = formatMessage(
    {
      id: isError ? 'ERROR_ENTITY_DESCRIPTION' : 'EMPTY_ENTITY_DESCRIPTION',
    },
    { entity: entity.name, tenant: tenantName },
  );

  const availableEnvironments = React.useMemo(
    () =>
      multiEnvironmentAuthoringEnabled && environmentMap instanceof Map
        ? Array.from(environmentMap.values()).map?.((clientInfo) => ({
            id: clientInfo.environmentKey,
            label: formatMessage({ id: clientInfo.nameKey }),
            action: () => {
              const { entityListPath } = generateConfigPaths({
                domainId,
                entityId,
                environmentKey: clientInfo.environmentKey,
              });
              history.push(entityListPath);
            },
          }))
        : null,
    [
      multiEnvironmentAuthoringEnabled,
      environmentMap,
      domainId,
      entityId,
      generateConfigPaths,
      history,
      formatMessage,
    ],
  );

  if (entity.category === Category.Hidden) {
    throw new Error(`Entity ${entity.id} list page does not exist`);
  }

  return entityListPageContentsRenderer({
    displayEmptyCard,
    emptyCardHeading,
    emptyCardDescription,
    availableEnvironments,
    currentAuthoringEnvironment,
    multiEnvironmentAuthoringEnabled,
    getEntityDetailsPathForId,
    entityCreatePath,
    pagination,
    loadPage,
    isLoading,
    data,
    entity,
    resetEntityData,
    isEntitySingleEnv,
    environmentKey,
  });
};

interface EntityListPageContainerProps {
  entityListPageContentsRenderer: EntityListPageContentsRenderer;
  entity: Entity;
  domainId: string;
  entityId: string;
  environmentKey: EnvironmentKey;
}

export const EntityListPageContainer = ({
  entityListPageContentsRenderer,
  entityId,
  entity,
  domainId,
  environmentKey,
}: EntityListPageContainerProps) => (
  <EntityListPageContents
    entity={entity}
    domainId={domainId}
    entityId={entityId}
    environmentKey={environmentKey}
    entityListPageContentsRenderer={entityListPageContentsRenderer}
  />
);
