import React from 'react';
import {
  VisuallyHidden,
  Spinner,
  StatusBanner,
  ParagraphText,
  StackLayout,
  Box,
} from '@leagueplatform/genesis-core';
import { UseBaseQueryResult } from '@tanstack/react-query';
import { useIntl } from '@leagueplatform/locales';
import {
  AnnotatedJsonSchema,
  EnvironmentKey,
  Entity,
  EntityStatus,
  EntityStatusValue,
  environmentKeys,
} from '@web-config-app/core';
import {
  bannerAnimation,
  EntityDetailsPageLayout,
  ErrorBanner,
  ErrorBoundaryWrapper,
  ErrorPage,
  LoadingOverlayModal,
} from '@web-config-app/core-react-ui';
import {
  EntityForm,
  EntityTreeView,
  useEntityFormState,
  createErrorFilteringMiddleware,
  ruleCombinationJsonLogicInputControlRenderer,
} from '@web-config-app/entity-form';
import {
  useConfigErrorBanner,
  useConfigUserFeedback,
} from '@web-config-app/core-react';
import type { GetEntityDetailsPathForEnvAndId } from '@web-config-app/core-react';
import { AttachmentsModal } from '../../containers/attachments-modal-container/attachments-modal';
import { EntityDetailsHeaderContainer } from '../../containers/entity-details-header-container/entity-details-header.container';
import { EntityDataTableModalContainer } from '../../containers/entity-data-table-modal-container/entity-data-table-modal.container';
import {
  EntityFormSkeleton,
  SidePanelSkeleton,
} from './entity-details-skeleton.component';

export type EntityDetailsPageProps = Pick<
  UseBaseQueryResult,
  'isError' | 'isLoading'
> & {
  formData: any;
  formSchema?: AnnotatedJsonSchema;
  formPath?: string;
  entity: Entity;
  entityListPath: string;
  entityTree: any;
  onFormDataChange: (updatedData: any) => void;
  formDataChangeDebounceLength?: number;
  handleNodeToggle?: (nodeId: string) => void;
  expandedNodes?: string[];
  publishDangerously: boolean;
  environmentName: string;
  entityStatus?: EntityStatus;
  environmentKey: EnvironmentKey;
  isReadOnly?: boolean;
  getEntityDetailsPathForEnvAndId: GetEntityDetailsPathForEnvAndId;
  setEntityName: (name: string) => void;
  isEntitySingleEnv?: boolean;
};

export const EntityDetailsPage: React.FC<EntityDetailsPageProps> = ({
  isLoading,
  isError: isFullPageError,
  formData,
  formSchema,
  formPath,
  entity,
  entityListPath,
  entityTree,
  handleNodeToggle,
  onFormDataChange,
  expandedNodes,
  publishDangerously,
  entityStatus,
  environmentName,
  formDataChangeDebounceLength = 400,
  environmentKey,
  isReadOnly,
  getEntityDetailsPathForEnvAndId,
  setEntityName,
  isEntitySingleEnv: showEntitySingleEnvBanner,
}) => {
  const { formatMessage } = useIntl();
  const entityName =
    entity && formatMessage({ id: entity.nameTranslationKey }, { count: 1 });
  const { formDataChangeHandler, localData, localSchema } = useEntityFormState({
    onFormDataChange,
    formDataChangeDebounceLength,
    formPath,
    formData,
    formSchema,
  });

  const { entityOperationLoadingMsg, configMessages, resetConfigMessages } =
    useConfigUserFeedback((state) => state);

  const {
    showErrorBanner,
    hideErrorBanner,
    errorBannerTitle,
    errorBannerContents,
    showFormValidation,
    filteredErrorKeywords,
  } = useConfigErrorBanner();

  if (isFullPageError) {
    return <ErrorPage />;
  }
  const entityFormMiddleware = createErrorFilteringMiddleware(
    filteredErrorKeywords,
  );

  const successMessages =
    configMessages?.filter((message) => message.type === 'success') ?? [];

  // Showing PublishDangerouslyBanner when the config is not a readonly config
  const showPublishDangerouslyBanner =
    publishDangerously &&
    entityStatus === EntityStatusValue.Published &&
    !isReadOnly;

  const showReadonlyBanner =
    entityStatus === EntityStatusValue.Published && isReadOnly;

  return (
    <EntityDetailsPageLayout
      topBar={
        <EntityDetailsHeaderContainer
          setEntityName={setEntityName}
          isLoading={isLoading}
          entity={entity}
          entityListPath={entityListPath}
          environmentKey={environmentKey}
          environmentName={environmentName}
          publishDangerously={publishDangerously}
          getEntityDetailsPathForEnvAndId={getEntityDetailsPathForEnvAndId}
        />
      }
      statusBanners={
        <div aria-live="polite">
          {showPublishDangerouslyBanner && (
            <StatusBanner
              data-testid="publish-dangerously-banner"
              showIcon={false}
              status="warning"
              css={{ animation: bannerAnimation }}
            >
              <ParagraphText>
                {formatMessage(
                  { id: 'PUBLISHING_DANGEROUSLY_STATUS_BANNER' },
                  { environmentName },
                )}
              </ParagraphText>
            </StatusBanner>
          )}
          {showReadonlyBanner && (
            <StatusBanner
              data-testid="readonly-config-banner"
              showIcon={false}
              status="warning"
              css={{ animation: bannerAnimation }}
            >
              <ParagraphText>
                {formatMessage(
                  { id: 'READONLY_CONFIG_STATUS_BANNER' },
                  { entity: entityName },
                )}
              </ParagraphText>
            </StatusBanner>
          )}
          {showEntitySingleEnvBanner && (
            <StatusBanner
              data-testid="entity-single-environment-banner"
              status="info"
              css={{ animation: bannerAnimation }}
            >
              <ParagraphText>
                {formatMessage(
                  { id: 'ENTITY_SINGLE_ENVIRONMENT_STATUS_BANNER' },
                  {
                    environment: formatMessage({
                      id: environmentKeys[environmentKey],
                    }),
                  },
                )}
              </ParagraphText>
            </StatusBanner>
          )}
          {successMessages.length > 0 && (
            <StatusBanner
              status="success"
              css={{ animation: bannerAnimation }}
              onDismissClick={() => resetConfigMessages()}
            >
              <Box as="ul" css={{ marginBlock: 0 }}>
                {successMessages.map((message) => (
                  <ParagraphText key={message.text} as="li">
                    {message.text}
                  </ParagraphText>
                ))}
              </Box>
            </StatusBanner>
          )}
          {showErrorBanner && (
            <ErrorBanner
              title={errorBannerTitle}
              onDismissClick={hideErrorBanner}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...errorBannerContents}
            />
          )}
        </div>
      }
      sidePanel={
        isLoading ? (
          <SidePanelSkeleton />
        ) : (
          <StackLayout horizontalAlignment="stretch">
            <EntityTreeView
              entityTree={entityTree}
              onSelect={handleNodeToggle}
              activeTreeNodeId={formPath}
              expandedItems={expandedNodes}
            />
            <StackLayout
              horizontalAlignment="start"
              spacing="$threeQuarters"
              css={{
                paddingX: '$oneAndHalf',
                paddingY: '$threeQuarters',
              }}
            >
              {entity.enableAttachments && formData && <AttachmentsModal />}
              <EntityDataTableModalContainer />
            </StackLayout>
          </StackLayout>
        )
      }
    >
      <>
        {Boolean(entityOperationLoadingMsg) && (
          <LoadingOverlayModal
            isOpen={Boolean(entityOperationLoadingMsg)}
            loadingText={`${entityOperationLoadingMsg}`}
          >
            <ParagraphText aria-hidden>
              {entityOperationLoadingMsg}
            </ParagraphText>
          </LoadingOverlayModal>
        )}
        <VisuallyHidden>
          <Spinner
            loading={isLoading}
            loadingText={formatMessage({ id: 'LOADING' })}
          />
        </VisuallyHidden>
        {isLoading ? (
          <EntityFormSkeleton />
        ) : (
          <ErrorBoundaryWrapper>
            <EntityForm
              renderers={[ruleCombinationJsonLogicInputControlRenderer]}
              schema={localSchema}
              data={localData}
              onDataChange={formDataChangeHandler}
              showValidation={showFormValidation}
              middleware={entityFormMiddleware}
            />
          </ErrorBoundaryWrapper>
        )}
      </>
    </EntityDetailsPageLayout>
  );
};
