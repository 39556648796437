import * as React from 'react';
import type { EnvironmentKey, Entity } from '@web-config-app/core';
import type {
  OtherAuthoringEnvironmentState,
  UseMultiEnvironmentEntityOperationsProps,
} from '@web-config-app/core-react';
import {
  useMultiEnvironmentEntityOperations,
  useConfigEntity,
} from '@web-config-app/core-react';
import { EntityDetailsProvider } from '@web-config-app/entity-form';

interface EntityDetailsMultiEnvironmentContainerProps
  extends Omit<
    React.ComponentProps<typeof EntityDetailsProvider>,
    'entityType' | 'instanceId' | 'configEntitySchema'
  > {
  entity: Entity;
  // TODO:: Fix inconsistent typing of instanceId since it can be undefined
  // https://everlong.atlassian.net/browse/CACT-1600
  instanceId: string;
  multiEnvironmentOptions?: UseMultiEnvironmentEntityOperationsProps['options'];
  currentAuthoringEnvironment: EnvironmentKey;
  otherAuthoringEnvironment: EnvironmentKey;
  children: React.ReactNode;
}

/**
 * A container that provides additional multi-env context and data for entity details in a multi-environment setup.
 *
 * This component fetches the entity data for the otherAuthoringEnvironment
 * using `useEntityOperations` and provides it to the `EntityDetailsProvider` through the `otherAuthoringEnvironment` prop.
 *
 */

export const EntityDetailsMultiEnvironmentContainer = ({
  entity,
  instanceId,
  entityRootData,
  setEntityData,
  operations,
  multiEnvironmentOptions,
  rootSchema,
  path,
  name,
  status,
  includedEntities,
  addIncludedEntity,
  setIncludedEntities,
  resetEntityData,
  children,
  currentAuthoringEnvironment,
  otherAuthoringEnvironment,
  isUsingConfigAppBackend,
}: React.PropsWithChildren<EntityDetailsMultiEnvironmentContainerProps>) => {
  const configEntity = useConfigEntity({ domainEntity: entity });
  const {
    entityStatus: entityStatusOnTargetEnvironment,
    copyToEnvironment,
    entityData: entityDataOnTargetEnvironment,
  } = useMultiEnvironmentEntityOperations({
    entity: isUsingConfigAppBackend ? configEntity : entity,
    options: multiEnvironmentOptions,
    entityData: entityRootData,
    instanceId,
    sourceEnvironment: currentAuthoringEnvironment,
    targetEnvironment: otherAuthoringEnvironment,
  });

  const otherAuthoringEnvironmentState: OtherAuthoringEnvironmentState = {
    environmentKey: otherAuthoringEnvironment,
    entityStatus: entityStatusOnTargetEnvironment,
    entityData: entityDataOnTargetEnvironment,
    copyToEnvironment,
  };

  return (
    <EntityDetailsProvider
      configEntitySchema={configEntity.schema}
      entityRootData={entityRootData}
      setEntityData={setEntityData}
      operations={operations}
      currentAuthoringEnvironment={currentAuthoringEnvironment}
      otherAuthoringEnvironmentState={otherAuthoringEnvironmentState}
      rootSchema={rootSchema}
      path={path}
      name={name}
      status={status}
      instanceId={instanceId}
      entityType={entity.type}
      entity={entity}
      includedEntities={includedEntities}
      addIncludedEntity={addIncludedEntity}
      setIncludedEntities={setIncludedEntities}
      resetEntityData={resetEntityData}
      isUsingConfigAppBackend={isUsingConfigAppBackend}
    >
      {children}
    </EntityDetailsProvider>
  );
};
