import { DataPoint } from '@web-config-app/core';

export const rewardDataPoints: DataPoint[] = [
  {
    id: 'reward.reward_points',
    label: 'Reward points',
    description: '',
    dataType: 'object',
    dataHandlerId: 'local',
    dataPoints: [
      {
        id: 'reward.reward_points.total_campaign_points_earned',
        label: 'Total Campaign Points Earned',
        description: 'Total campaign points earned from reward system.',
        dataType: 'number',
        dataHandlerId: 'local',
      },
      {
        id: 'reward.reward_points.max_campaign_redemption_points',
        label: 'Max Campaign Redemption Points',
        description: 'Maximum campaign redemption points from reward system.',
        dataType: 'number',
        dataHandlerId: 'local',
      },
      {
        id: 'reward.reward_points.activity_points',
        label: 'Activity Points',
        description: 'Activity points earned from reward system.',
        dataType: 'number',
        dataHandlerId: 'local',
      },
    ],
  },
];
