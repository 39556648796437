import type { RulesTemplateWithoutEntityType } from '@web-config-app/core';
import { mapRulesTemplatesForEntity } from '../../../utils/map-rules-templates-for-entity';

const campaignPartials: RulesTemplateWithoutEntityType[] = [
  {
    id: 'manulife_campaign_eligibility_reward_program_segmentation',
    label: 'MANULIFE_CAMPAIGN_ELIGIBILITY_REWARD_PROGRAM_SEGMENTATION',
    name: 'Reward Program Segmentation',
    type: 'eligibilityCriteria',
    rules: {
      any_string_in_list_match_ignore_case: [
        '{rewardProgramIds}',
        {
          var: 'lpf.lpf_user_rewards_eligibility_observation.eligible_category:rewards_eligibility',
        },
      ],
    },
    inputs: {
      rewardProgramIds: {
        type: 'array',
        items: {
          type: 'string',
          'x-entity-label': {
            key: 'REWARD_PROGRAM_IDS',
          },
        },
        'x-entity-label': {
          key: 'REWARD_PROGRAM_IDS',
        },
      },
    },
  },
];

export const manulifeCampaignRulesTemplates = campaignPartials.map(
  mapRulesTemplatesForEntity('config-campaign'),
);
