import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  type EnvironmentKey,
  type Entity,
  type EntityRootData,
  EntityConfigCustomOperation,
  ConfigApiError,
} from '@web-config-app/core';
import { API_JSON_CONTENT_TYPE } from '@web-config-app/api';
import { getEntityEndpoint } from '../../utilities/get-entity-endpoint/get-entity-endpoint.util';
import { OnMutate, OnSuccess } from '../../types/operation.types';
import { useEnvironmentFetch } from '../use-environment-fetch/use-environment-fetch';
import { createCustomRequestBodyData } from '../../utilities/create-custom-request-body-data/create-custom-request-body-data';
import { getEndpointParamsWithValues } from '../../utilities/get-endpoint-params-with-values/get-endpoint-params-with-values';
import { useGetTranslatedText } from '../use-get-translated-text/use-get-translated-text';
import { getCustomOperationParameters } from '../../utilities/get-custom-operation-parameters/get-custom-operation-parameters';

export type UseEntityCustomOperationResult = ReturnType<
  typeof useEntityCustomOperation
>;

export type UseEntityCustomOperationOptions = Pick<
  UseMutationOptions<unknown, ConfigApiError>,
  'onError' | 'onSettled'
> & {
  onMutate?: OnMutate;
  onSuccess?: OnSuccess;
  fetchFn?: (path: string, options: any) => Promise<Response>;
};

export interface UseEntityCustomOperationProps {
  entity: Entity;
  entityName: string;
  instanceId?: string;
  environment: EnvironmentKey;
  operation: EntityConfigCustomOperation;
  data?: EntityRootData;
  headers?: HeadersInit;
  options?: UseEntityCustomOperationOptions;
}

/**
 * useEntityCustomOperation - The following hook is responsible for calling the BE API associated to the a custom operation.
 *It constructs the necessary path and query parameters and body response data transformations based on the custom operation config, and executes the API call using `useMutation`.
 *
 * @param props.operation - the custom operation with all info needed
 * @param props.entity - the whole {@link Entity}
 * @param props.instanceId - the entity instance ID, use for onSuccess messages if applicable
 * @param props.data - the root data of the form used when saving the entity data
 * @param props.headers - optional headers to pass into the fetch function
 * @param props.options - optional config object, used for calling functions on mutate/ success
 * @param props.options.fetchFn - You can pass in your own custom fetch function to be used, instead of the default leagueFetch
 * @returns the result of useMutation, which is then used by our EntityDetailsProvider, who is responsible for relaying the result where needed.
 */

export const useEntityCustomOperation = ({
  entity,
  entityName,
  instanceId,
  operation,
  environment,
  data,
  headers = {
    'Content-Type': API_JSON_CONTENT_TYPE,
  },
  options,
}: UseEntityCustomOperationProps) => {
  const getTranslatedText = useGetTranslatedText();

  const { apiUrl } = entity;

  const currentEnvironmentFetch = useEnvironmentFetch(environment, apiUrl);

  const { parameters: pathParameters, paramsWithValues: pathParamsWithValues } =
    getCustomOperationParameters(
      'path',
      operation.parameterMappings?.path,
      data,
    );
  const {
    parameters: queryParameters,
    paramsWithValues: queryParamsWithValues,
  } = getCustomOperationParameters('query', operation.parameterMappings?.query);

  const params = getEndpointParamsWithValues([
    ...pathParamsWithValues,
    ...queryParamsWithValues,
  ]);

  const { endpointFetch } = getEntityEndpoint(
    {
      ...operation,
      queryParameters,
      pathParameters,
    },
    options?.fetchFn ?? currentEnvironmentFetch,
  );

  const body = createCustomRequestBodyData(operation, data);

  return useMutation({
    mutationFn: () =>
      endpointFetch({
        path: operation.path,
        params,
        body,
        headers,
      }),
    onMutate: () => {
      const loadingMessage = getTranslatedText(
        entityName,
        operation.loadingMessage,
      );
      options?.onMutate?.(loadingMessage);
    },
    onSuccess: () => {
      const onSuccessMessage = getTranslatedText(
        entityName,
        operation.successMessage,
      );
      options?.onSuccess?.(onSuccessMessage, instanceId);
    },
    onError: options?.onError,
    onSettled: options?.onSettled,
  });
};
