import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Modal,
  FormField,
  TextInput,
  StackLayout,
  Button,
  HeadingText,
  UtilityText,
  InputStatusMessage,
  Box,
} from '@leagueplatform/genesis-core';
import { GetEndpointResponse } from '@web-config-app/core';
import { FileUpload } from './file-upload.component';
import {
  formatAssetUploadData,
  useUploadAssetData,
} from './use-upload-asset-data.hook';
import { useUploadAssetModal } from './use-upload-asset-modal.hook';

interface UploadAssetModalProps {
  open: boolean;
  onCancel: () => void;
  onSubmitSuccess: ({ id, url }: { id: string; url: string }) => void;
  fileType?: 'image' | 'pdf';
}

export const UploadAssetModal = ({
  open,
  onCancel,
  onSubmitSuccess,
  fileType = 'image',
}: UploadAssetModalProps) => {
  const { formatMessage } = useIntl();
  const { isPending: isUploadingAsset, mutate: uploadAsset } =
    useUploadAssetData();
  const {
    title,
    setTitle,
    description,
    setDescription,
    file,
    setFile,
    setFileUploadError,
    formErrors,
    handleOpenChange,
    validateForm,
    getErrorProps,
    fileErrorMessage,
    clearValues,
  } = useUploadAssetModal({ onCancel });

  const getModalContent = () => {
    if (fileType === 'pdf') {
      return {
        modalTitle: formatMessage({ id: 'ADD_ATTACHMENT' }),
        fileUploadLabel: formatMessage({ id: 'FILE' }),
        submitButtonLabel: formatMessage({ id: 'UPLOAD_ATTACHMENT' }),
        accepts: '.pdf',
      };
    }
    return {
      modalTitle: formatMessage({ id: 'ADD_IMAGE' }),
      fileUploadLabel: formatMessage({ id: 'IMAGE_FILE' }),
      submitButtonLabel: formatMessage({ id: 'UPLOAD_IMAGE' }),
    };
  };

  const { modalTitle, fileUploadLabel, submitButtonLabel, accepts } =
    getModalContent();

  const { titleError } = formErrors;

  return (
    <Modal.Root onOpenChange={handleOpenChange} open={open}>
      <Modal.Content>
        <Box
          as="form"
          onSubmit={(e) => {
            e.preventDefault();
            // the form should not be submittable unless all required fields (including the file) are filled in, but double checking here for typescript's sake
            if (file) {
              uploadAsset(formatAssetUploadData({ title, description, file }), {
                onSuccess: (data) => {
                  /*
                    `data` on our EndpointResponse is typed as `unknown`. However, it behaves here like our GetEndpointResponse so casting accordingly.
                  */
                  const { id, attributes } = (data as GetEndpointResponse).data;
                  onSubmitSuccess({ url: attributes.url, id });
                  clearValues();
                  onCancel();
                },
              });
            }
          }}
        >
          <StackLayout
            as="fieldset"
            spacing="$one"
            horizontalAlignment="stretch"
            css={{
              border: '$none',
              padding: '$none',
              margin: '$none',
            }}
          >
            <Modal.Title>
              <Box as="legend" css={{ marginBottom: '$one' }}>
                <HeadingText level="2" size="lg">
                  {modalTitle}
                </HeadingText>
              </Box>
            </Modal.Title>
            <StackLayout spacing="$oneAndHalf" horizontalAlignment="stretch">
              <StackLayout spacing="$quarter" horizontalAlignment="stretch">
                <UtilityText
                  as="p"
                  id="file_upload_description"
                  css={{
                    typography: '$caption',
                    fontWeight: '$label',
                  }}
                  emphasis="subtle"
                >
                  {fileUploadLabel}
                </UtilityText>
                <FileUpload
                  file={file}
                  onSelect={(newFile: File) => setFile(newFile)}
                  onError={(error: Error | null) => setFileUploadError(error)}
                  disabled={isUploadingAsset}
                  accepts={accepts}
                />
                {fileErrorMessage && (
                  <InputStatusMessage inputStatus="error">
                    {fileErrorMessage}
                  </InputStatusMessage>
                )}
              </StackLayout>
              <FormField
                label={formatMessage({ id: 'TITLE' })}
                id="asset_title"
                required
                {...(titleError && getErrorProps(titleError))}
              >
                <TextInput
                  onChange={(evt) => setTitle(evt.currentTarget.value)}
                  value={title}
                  disabled={isUploadingAsset}
                  required
                />
              </FormField>
              <FormField
                label={formatMessage({ id: 'DESCRIPTION' })}
                id="asset_description"
                // you would think that this could be omitted, but if I don't include `required={false}`, I don't get the `(optional)` text as part of the label
                required={false}
              >
                <TextInput
                  onChange={(evt) => setDescription(evt.currentTarget.value)}
                  value={description}
                  disabled={isUploadingAsset}
                />
              </FormField>
            </StackLayout>
            <StackLayout
              orientation="horizontal"
              horizontalAlignment="end"
              spacing="$half"
            >
              <Modal.Close>
                <Button
                  priority="tertiary"
                  quiet
                  size="medium"
                  type="button"
                  onClick={onCancel}
                  disabled={isUploadingAsset}
                >
                  {formatMessage({ id: 'CANCEL' })}
                </Button>
              </Modal.Close>
              <Button
                size="medium"
                priority="primary"
                type="submit"
                loading={isUploadingAsset}
                onClick={validateForm}
              >
                {submitButtonLabel}
              </Button>
            </StackLayout>
          </StackLayout>
        </Box>
      </Modal.Content>
    </Modal.Root>
  );
};
