import { DataPoints } from '@web-config-app/core';

export const highmarkDataPoints: DataPoints = [
  {
    id: 'config-campaign',
    description: 'Campaign Data Points',
    dataHandlers: [{ id: 'local', description: '', version: '' }],
    dataPoints: [
      {
        id: 'core.league-fhir-observation-code',
        label: 'Consent',
        description: '',
        dataType: 'object',
        dataHandlerId: 'local',
        dataPoints: [
          {
            id: 'core.league-fhir-observation-code.gina_consent.value',
            label: 'Gina Consent value',
            description: 'Value of Gina Consent',
            dataType: 'string',
            dataHandlerId: 'local',
          },
          {
            id: 'core.league-fhir-observation-code.sdoh_consent.value',
            label: 'Sdoh Consent value',
            description: 'Value of Sdoh Consent',
            dataType: 'string',
            dataHandlerId: 'local',
          },
        ],
      },
    ],
  },
];
