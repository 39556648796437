import React from 'react';
import { isEqual } from 'lodash-es';
import { useIntl } from '@leagueplatform/locales';
import {
  EntityRootData,
  EntityStatus,
  EntityStatusValue,
} from '@web-config-app/core';
import { UseEntityOperationsResult } from '@web-config-app/core-react';

interface EntityHeaderSaveStatusProps {
  operations?: UseEntityOperationsResult;
  status?: EntityStatus;
  entityRootData?: EntityRootData;
}

export type UseEntityHeaderSaveStatusResult = ReturnType<
  typeof useEntityHeaderSaveStatus
>;

export const useEntityHeaderSaveStatus = ({
  status,
  operations,
  entityRootData,
}: EntityHeaderSaveStatusProps) => {
  const { formatMessage } = useIntl();

  // TODO: Update me in CACT-1144
  return React.useMemo(() => {
    // when get?.data is undefined, we are in the NEW state
    const newEntity = !operations?.get?.data;
    // entityRootData keeps track of the current state of the form, we use that to compare with the original data we got from the GET entity call
    const changesSaved = isEqual(entityRootData, operations?.get?.data);

    const isDraft = status === EntityStatusValue.Draft;

    let messageKey = newEntity ? 'UNSAVED_CHANGES' : 'ALL_CHANGES_SAVED';
    if (!newEntity) {
      if (changesSaved) {
        messageKey = isDraft ? 'ALL_CHANGES_SAVED' : 'ALL_CHANGES_PUBLISHED';
      } else {
        messageKey = isDraft ? 'UNSAVED_CHANGES' : 'UNPUBLISHED_CHANGES';
      }
    }
    return {
      message: formatMessage({ id: messageKey }),
      isEntitySaved: changesSaved,
    };
  }, [entityRootData, operations?.get?.data, status, formatMessage]);
};
