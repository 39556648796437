export const EN_LPF_STRINGS = {
  CONCEPT_LABEL: 'Cohort',
  CODE_LABEL: 'Code',
  DEFINITION_LABEL: 'Definition',
  JSON_LOGIC_RULE_LABEL: 'JSON Logic Rule',
  CODE_SYSTEM_CONCEPTS: 'Cohorts',
  QUESTIONNAIRE_RESPONSE: 'Questionnaire response',
  QUESTION_CODES: 'Question Codes',
  USERS_AGE: "User's Age",
  USERS_LOCATION: "User's Location",
  HEALTH_RISKS: 'Health Risks',
  HEALTH_CAMPAIGN_STATUS: 'Health Campaign Completed',
  ACTIVITY_STATUS_RULE: 'Activity Status',
  LOCATION_DATAPOINT: 'Location',
  MATCHING_STRINGS: 'Matching Strings',
  STRING_TO_MATCH: 'Another Match',
  DISTRICT: 'District',
  HEALTH_RISKS_DATAPOINTS: 'Health Risks',
  HEALTH_RISK: 'Health Risk',
  CAMPAIGN_STATUS: 'Campaign Status',
  INACTIVE: 'Inactive',
  QUESTIONNAIRE_OBSERVATION: 'Questionnaire Observation',
  CUSTOMER_PROVIDED_DATAPOINT_TRUE: 'Customer-provided data point is true',
  CUSTOMER_PROVIDED_DATAPOINT_STRING: 'Customer-provided data point (String)',
  CUSTOMER_PROVIDED_DATAPOINT_NUMBER: 'Customer-provided data point (Number)',
  BIOMETRIC_OBSERVATION: 'Biometric Observation',
  DATAPOINT: 'Data point',
  QUESTIONNAIRE_ID: 'Questionnaire ID',
  OPERATOR: 'Operator',
};
