import * as React from 'react';
import { GDSIconProps, Icon, TextAction } from '@leagueplatform/genesis-core';

/**
 * Interface for the props of the TextActionButtonIcon component.
 */
interface TextActionButtonIconProps {
  /**
   * The icon to display within the button. Can be a React element, typically an SVG icon component, or a string that maps to an IconSource in theme.
   */
  icon: React.ReactElement | string;
  /**
   * The text label to display within the button.
   */
  label: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  size?: GDSIconProps['size'];
}

/**
 * A button component that displays an SVG icon and a text label, Built on top of TextAction since TextAction does not support passing your own svg icons.
 *
 * @param {TextActionButtonIconProps} props - The props for the component.
 * @returns {JSX.Element} The rendered button.
 */
export const TextActionButtonIcon = ({
  icon,
  label,
  onClick,
  disabled = false,
  size = '$one',
}: TextActionButtonIconProps) => (
  <TextAction
    as="button"
    type="button"
    size="medium"
    disabled={disabled}
    onClick={onClick}
  >
    <Icon css={{ marginInlineEnd: '$quarter' }} size={size} icon={icon} />
    {label}
  </TextAction>
);
