import * as React from 'react';

interface RuleCombinationProviderContextValueProps {
  combinationFieldPath: string;
  isArray: boolean;
}

export const RuleCombinationProviderContextContext =
  React.createContext<RuleCombinationProviderContextValueProps | null>(null);

export const RuleCombinationControlProvider = ({
  children,
  combinationFieldPath,
  isArray,
}: React.PropsWithChildren<RuleCombinationProviderContextValueProps>) => {
  const value = React.useMemo(
    () => ({
      combinationFieldPath,
      isArray,
    }),
    [combinationFieldPath, isArray],
  );

  return (
    <RuleCombinationProviderContextContext.Provider value={value}>
      {children}
    </RuleCombinationProviderContextContext.Provider>
  );
};

export const useRuleCombinationContext = () => {
  const ctx = React.useContext(RuleCombinationProviderContextContext);
  return ctx;
};
