import * as React from 'react';

export const PdfIconSvg = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.24597 1.498C1.83176 1.498 1.49597 1.83379 1.49597 2.248V21.748C1.49597 22.1622 1.83176 22.498 2.24597 22.498H5.24597C5.66019 22.498 5.99597 22.8338 5.99597 23.248C5.99597 23.6622 5.66019 23.998 5.24597 23.998H2.24597C1.00333 23.998 -0.00402832 22.9906 -0.00402832 21.748V2.248C-0.00402832 1.00536 1.00333 -0.00199986 2.24597 -0.00199986H12.875C13.4719 -0.00187254 14.0444 0.235432 14.4663 0.65767L20.3373 6.52867C20.7591 6.95076 20.9962 7.52328 20.996 8.12V12.748C20.996 13.1622 20.6602 13.498 20.246 13.498C19.8318 13.498 19.496 13.1622 19.496 12.748V8.12C19.4961 7.92113 19.4172 7.73004 19.2766 7.58933L13.4056 1.71833C13.265 1.57755 13.0739 1.49809 12.875 1.498H2.24597Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.746 -0.00199986C13.1602 -0.00199986 13.496 0.333787 13.496 0.748V6.748C13.496 7.16221 13.8318 7.498 14.246 7.498H20.246C20.6602 7.498 20.996 7.83379 20.996 8.248C20.996 8.66221 20.6602 8.998 20.246 8.998H14.246C13.0033 8.998 11.996 7.99064 11.996 6.748V0.748C11.996 0.333787 12.3318 -0.00199986 12.746 -0.00199986Z"
      fill="currentColor"
    />
    <path
      d="M20.5255 22H19.2076V15.7027H23.3316V16.8286H20.5255V18.4695H23.0872V19.5474H20.5255V22Z"
      fill="currentColor"
    />
    <path
      d="M12.6583 15.6984H15.1371C17.0093 15.6984 18.0915 16.833 18.0915 18.8055C18.0915 20.8523 17.0224 22 15.1371 22H12.6583V15.6984ZM13.9763 16.8243V20.8741H14.9276C16.0928 20.8741 16.7474 20.1584 16.7474 18.8274C16.7474 17.5531 16.071 16.8243 14.9276 16.8243H13.9763Z"
      fill="currentColor"
    />
    <path
      d="M6.76367 15.7027H9.39081C10.6869 15.7027 11.5728 16.5712 11.5728 17.8804C11.5728 19.1765 10.652 20.0449 9.32535 20.0449H8.08161V22H6.76367V15.7027ZM8.08161 16.785V18.9757H9.03732C9.7923 18.9757 10.2331 18.5917 10.2331 17.8847C10.2331 17.169 9.80103 16.785 9.04169 16.785H8.08161Z"
      fill="currentColor"
    />
  </svg>
);
