import type {
  FlatDataPoint,
  ParentId,
  DataPoint,
  DataPoints,
} from '@web-config-app/core';

/**
 * Transforms and flattens data points for the dataPoint modal navigation
 *
 * @param {DataPoints} sourceDataPoints - The input data points
 * @returns {FlatDataPoint[]} - The transformed and flattened array of data points.
 */
export const transformAndFlattenDataPoints = (
  sourceDataPoints?: DataPoints,
): FlatDataPoint[] => {
  if (!sourceDataPoints || sourceDataPoints.length === 0) return [];

  /**
   * Determine if sourceDataPoints is a list of flowTypes or a single flowType's dataPoints
   * If it's a list of flowTypes (sourceDataPoints.length > 1) - we want to add a 'label' to each flowType incase it doesn't exist
   * If its a single flowType - we directly use the dataPoints array to avoid showing a single folder in the dataPoint modal
   */
  const dataPointsArray =
    sourceDataPoints.length > 1
      ? sourceDataPoints.map((flowType) => ({
          ...flowType,
          // label does not exist at the moment in dml contract, id as backup
          label: flowType.label || flowType.id,
          dataType: 'object',
        }))
      : sourceDataPoints[0].dataPoints;

  let transformDataPoints: Function;
  const transformedAndFlatDataPoints: FlatDataPoint[] = [];

  const transformDataPoint = (
    dataPoint: DataPoint,
    parentId: ParentId = null,
  ): void => {
    const { dataType, id, label, dataPoints = [] } = dataPoint;

    const transformedDataPoint = {
      dataType,
      id,
      isCategory: dataPoints.length > 0,
      label,
      parentId,
    };

    transformedAndFlatDataPoints.push(transformedDataPoint);
    transformDataPoints(dataPoints, id);
  };

  transformDataPoints = (
    dataPoints: DataPoint[],
    parentId: ParentId = null,
  ): void => {
    dataPoints.map((point) => transformDataPoint(point, parentId));
  };

  transformDataPoints(dataPointsArray);

  return transformedAndFlatDataPoints;
};
