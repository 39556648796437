import { useCallback } from 'react';
import { TranslationObject } from '@web-config-app/core';
import { useTranslate } from '../use-translate/use-translate';

/**
 * Hook that provides a translation function for operation labels and messages.
 *
 * This hook uses the `useTranslate` hook to provide a translation function that can be used to translate
 * operation labels and messages, with support for dynamic values (e.g., entity name).
 *
 * The returned function takes a translation object and an entity name as arguments.
 * The translation object can have either a `text` property (for direct text) or a `key` and `values` properties (for translated text with dynamic values).
 *
 * @returns {Function} A function that takes a translation object and an entity name and returns the translated string or undefined if no translation is found.
 *
 * @example
 * const getTranslatedText = useGetTranslatedText();
 *
 * // With direct text
 * const label = getTranslatedText({ text: 'My Label' }, 'myEntity'); // label will be 'My Label'
 *
 * // With translation key and values
 * const message = getTranslatedText({ key: 'MY_MESSAGE_KEY', values: { count: 5 } }, 'myEntity');
 * // message will be the translated string for 'MY_MESSAGE_KEY' with the 'count' and 'entity' values interpolated
 */

export const useGetTranslatedText = () => {
  const translate = useTranslate();
  const getTranslatedText = useCallback(
    (entityName: string, translationObject?: TranslationObject) => {
      if (!translationObject) return undefined;
      const { text, key, values } = translationObject;
      if (text) {
        return text;
      }
      return translate(key, { ...values, entity: entityName });
    },
    [translate],
  );
  return getTranslatedText;
};
