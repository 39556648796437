import { RulesTemplate } from '@web-config-app/core';
import { useConfigApp } from '../use-config-app/use-config-app';
import { standAloneRuleCombinationPartials } from './rule-combination-templates';

export interface UseRulesTemplatesProps {
  entityType: string;
  rulesType?: RulesTemplate['type'];
  controlType: 'ruleCombination' | 'singleRule';
}

export const useRulesTemplates = (props: UseRulesTemplatesProps) => {
  const { entityType, rulesType, controlType } = props;
  const { rulesTemplates } = useConfigApp();

  const allRulesTemplates = Object.values(rulesTemplates ?? {});

  if (!rulesType) return undefined;

  const ruleCombinationRuleTemplates: RulesTemplate[] =
    standAloneRuleCombinationPartials(rulesType).map((template) => ({
      ...template,
      entityType,
    }));

  if (controlType === 'ruleCombination') {
    return ruleCombinationRuleTemplates;
  }

  // default return is 'singleRule' controlType mode
  return entityType || rulesType
    ? allRulesTemplates.filter((rulesTemplate) => {
        if (entityType && rulesType) {
          return (
            rulesTemplate.entityType === entityType &&
            rulesTemplate.type === rulesType
          );
        }

        if (entityType) {
          return rulesTemplate.entityType === entityType;
        }

        if (rulesType) {
          return rulesTemplate.type === rulesType;
        }

        return false;
      })
    : allRulesTemplates;
};
