/* eslint-disable no-param-reassign */
import { createStore } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import type {
  RuleTemplateDataField,
  RuleTemplatesEditorStateData,
} from '../../types/entities';
import { removeItemAtIndex } from '../../utilities/remove-array-item-at-index/remove-array-item-at-index';

export type RuleTemplatesEditorStateStore = RuleTemplatesEditorState &
  RuleTemplatesEditorStateActions;

export interface RuleTemplatesEditorState {
  ruleTemplatesData: RuleTemplatesEditorStateData;
}

export interface RuleTemplatesEditorStateActions {
  setRuleTemplatesData: (data: RuleTemplatesEditorStateData) => void;
  resetRuleTemplatesData: () => void;
  getRuleTemplateFieldData: ({
    fieldPath,
    nestedFieldPath,
    arrayIndex,
  }: {
    fieldPath: string;
    nestedFieldPath?: string;
    arrayIndex?: number;
  }) => RuleTemplateDataField | undefined;
  addNestedFieldToArray: ({ fieldPath }: { fieldPath: string }) => void;
  removeFromNestedFieldsArray: ({
    arrayIndex,
    fieldPath,
  }: {
    arrayIndex: number;
    fieldPath: string;
  }) => void;
  setRuleTemplateFieldData: ({
    fieldPath,
    ruleTemplateFieldData,
    shouldResetNestedFields,
    nestedFieldPath,
    isArrayItem,
    arrayIndex,
  }: {
    fieldPath: string;
    ruleTemplateFieldData: RuleTemplateDataField;
    shouldResetNestedFields?: boolean;
    nestedFieldPath?: string;
    isArrayItem?: boolean;
    arrayIndex?: number;
  }) => void;
}

export const ruleTemplatesEditorStateStore =
  createStore<RuleTemplatesEditorStateStore>()(
    immer((set, get) => ({
      ruleTemplatesData: {
        fields: {},
      },
      setRuleTemplatesData: (data) =>
        set((draft) => {
          draft.ruleTemplatesData = data;
        }),
      resetRuleTemplatesData: () =>
        set((draft) => {
          draft.ruleTemplatesData = { fields: {} };
        }),
      getRuleTemplateFieldData: ({
        fieldPath,
        nestedFieldPath,
        arrayIndex,
      }) => {
        const ruleTemplateState = get().ruleTemplatesData;

        // For nestedFields
        if (nestedFieldPath) {
          const targetField = ruleTemplateState.fields[fieldPath];
          if (
            Array.isArray(targetField?.nestedFields) &&
            arrayIndex !== undefined
          ) {
            return targetField.nestedFields[arrayIndex];
          }

          if (
            targetField?.nestedFields &&
            !Array.isArray(targetField.nestedFields)
          ) {
            return targetField.nestedFields[nestedFieldPath];
          }
        }

        // For top-level fields
        return ruleTemplateState?.fields?.[fieldPath];
      },
      removeFromNestedFieldsArray: ({ fieldPath, arrayIndex }) => {
        set((draft) => {
          const nestedFields =
            draft.ruleTemplatesData?.fields?.[fieldPath]?.nestedFields;

          if (!Array.isArray(nestedFields)) {
            return;
          }

          draft.ruleTemplatesData.fields[fieldPath].nestedFields =
            removeItemAtIndex(nestedFields, arrayIndex);
        });
      },
      addNestedFieldToArray: ({ fieldPath }) => {
        set((draft) => {
          if (!draft.ruleTemplatesData.fields[fieldPath]) {
            draft.ruleTemplatesData.fields[fieldPath] = {
              nestedFields: [],
            };
          }

          // Ensure nestedFields is an array, creating it if it doesn't exist or is the wrong type.
          if (
            !draft.ruleTemplatesData.fields[fieldPath].nestedFields ||
            !Array.isArray(
              draft.ruleTemplatesData.fields[fieldPath].nestedFields,
            )
          ) {
            draft.ruleTemplatesData.fields[fieldPath].nestedFields = [];
          }

          // Even though we do all the checks above, typescript is still screaming about nestedFields potentially not being an array - have added a typecast to keep it happy
          (
            draft.ruleTemplatesData.fields[fieldPath]
              .nestedFields as RuleTemplateDataField[]
          ).push({
            rulesTemplate: {
              rulesMode: 'custom',
            },
          });
        });
      },
      setRuleTemplateFieldData: ({
        fieldPath,
        ruleTemplateFieldData,
        nestedFieldPath,
        shouldResetNestedFields,
        isArrayItem,
        arrayIndex,
      }) => {
        set((draft) => {
          if (!draft.ruleTemplatesData) {
            return;
          }

          // if there is no nestedFieldPath that means this is a parent
          if (!nestedFieldPath) {
            if (
              !draft.ruleTemplatesData.fields[fieldPath] ||
              shouldResetNestedFields
            ) {
              draft.ruleTemplatesData.fields[fieldPath] = ruleTemplateFieldData;
              return;
            }
            draft.ruleTemplatesData.fields[fieldPath].rulesTemplate =
              ruleTemplateFieldData.rulesTemplate;
          }

          if (nestedFieldPath) {
            draft.ruleTemplatesData.fields =
              draft.ruleTemplatesData.fields || {};
            const targetField = draft.ruleTemplatesData.fields?.[fieldPath];

            // For array items - Rule Combination
            if (isArrayItem) {
              const existingArray = targetField?.nestedFields || [];

              if (!Array.isArray(existingArray)) {
                return;
              }

              if (
                arrayIndex !== undefined &&
                arrayIndex >= 0 &&
                arrayIndex < existingArray.length
              ) {
                existingArray[arrayIndex] = ruleTemplateFieldData;
              } else {
                existingArray.push(ruleTemplateFieldData);
              }

              draft.ruleTemplatesData.fields[fieldPath] = {
                nestedFields: existingArray,
              };
            } else {
              // For non-array items - single rule
              if (!targetField) {
                draft.ruleTemplatesData.fields[fieldPath] = {
                  nestedFields: { [nestedFieldPath]: ruleTemplateFieldData },
                };
                return;
              }

              // type guard check
              if (Array.isArray(targetField.nestedFields)) {
                return;
              }

              // Field already exists so we update
              targetField.nestedFields = {
                ...targetField.nestedFields,
                [nestedFieldPath]: {
                  ...targetField.nestedFields?.[nestedFieldPath],
                  ...ruleTemplateFieldData,
                },
              };
            }
          }
        });
      },
    })),
  );
