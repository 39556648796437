/**
 * Converts a JSON string returned from the backend that has encoded some characters, like `>` and `<`, as
 * UTF in order to prevent malicious stuff like script injection. We can decode the UTF characters
 * by running the string through JSON.parse() and then immediately re-JSON.stringify()-ing it.
 */

export const decodeJsonLogicString = (
  jsonString: string | undefined = '',
): string => {
  if (jsonString === '') return jsonString;

  try {
    const json = JSON.parse(jsonString);
    return JSON.stringify(json, null, 2);
  } catch (err) {
    console.error('JsonLogicInputControl: Could not decode data string', err);
  }

  return '';
};
