import * as React from 'react';

export const SearchFolderIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    data-testid="search-folder-icon"
  >
    <path
      d="M8.53,18.25H3.15"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M19.64,8.53v-2.78c0-.77-.62-1.39-1.39-1.39h-9.72v-1.39c0-.77-.62-1.39-1.39-1.39H2.97c-.77,0-1.39.62-1.39,1.39v13.71c.01.78.59,1.44,1.37,1.54.78.1,1.51-.38,1.72-1.13l2.18-7.55c.17-.59.72-1,1.34-1h12.85c.43,0,.84.2,1.1.54.26.34.35.79.24,1.2"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M15.49,20.35c2.69,0,4.86-2.18,4.86-4.86s-2.18-4.86-4.86-4.86-4.86,2.18-4.86,4.86,2.18,4.86,4.86,4.86Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M22.42,22.42l-3.5-3.5"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);
