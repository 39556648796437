import { getRelationshipSchemaItemType } from '@web-config-app/schema-utils';
import type { EntitySelectControlSchema } from '../../types/controls';
import { useEntityDetailsProps } from '../use-entity-details-props/use-entity-details-props';
import { isRelationshipEntityReference } from '../../utils/is-relationship-entity-reference/is-relationship-entity-reference';

export const useEntityReferenceSchemaType = ({
  schema,
}: {
  schema: EntitySelectControlSchema;
}) => {
  const entityReference = schema['x-entity-reference'];

  const { rootSchema } = useEntityDetailsProps();

  if (isRelationshipEntityReference(entityReference)) {
    const { type: entityType } = getRelationshipSchemaItemType(
      entityReference.relationship,
      rootSchema,
    );

    return entityType;
  }

  return entityReference.domainEntityType;
};
