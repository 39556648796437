import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  UtilityText,
  Modal,
  Button,
  TextAction,
  HeadingText,
  HeadingBar,
} from '@leagueplatform/genesis-core';
import type {
  EnvironmentData,
  MultiEnvironmentDataTableRow,
  SingleEnvironmentDataTableRow,
} from './use-format-entity-table-data';
import { useFormatEntityTableData } from './use-format-entity-table-data';
import { downloadEntityDataCSV } from './download-entity-data-csv';

const DataCell = ({ children }: React.PropsWithChildren<{}>) => (
  <Box
    as="td"
    css={{
      padding: '$half',
      borderStyle: 'solid',
      borderColor: '$onSurfaceBorderSubdued',
      borderWidth: '$thin',
      width: '50%',
      maxWidth: 550,
      wordWrap: 'break-word',
    }}
  >
    <UtilityText>{children}</UtilityText>
  </Box>
);

const TableHeaderCell = ({ children }: React.PropsWithChildren<{}>) => (
  <Box
    as="th"
    scope="col"
    css={{
      backgroundColor: '$surfaceBackgroundTertiary',
      textAlign: 'left',
      padding: '$half',
      width: '50%',
    }}
  >
    <UtilityText>{children}</UtilityText>
  </Box>
);

const isMultiEnvironmentRow = (
  row: SingleEnvironmentDataTableRow | MultiEnvironmentDataTableRow,
): row is MultiEnvironmentDataTableRow => 'otherData' in row;

export interface EntityDataTableModalProps {
  currentEnvironmentData: EnvironmentData;
  otherEnvironmentData?: EnvironmentData;
  entityName: string | undefined;
}

export const EntityDataTableModal = ({
  currentEnvironmentData,
  otherEnvironmentData,
  entityName,
}: EntityDataTableModalProps) => {
  const { formatMessage } = useIntl();
  const { rows, columnHeaders, isMultiEnvironment } = useFormatEntityTableData({
    currentEnvironmentData,
    otherEnvironmentData,
  });

  return (
    <Modal.Root>
      <Modal.Trigger>
        <TextAction as="button" size="medium" icon="illustrativeDocument">
          {formatMessage({ id: 'VIEW_ALL_DATA' })}
        </TextAction>
      </Modal.Trigger>

      <Modal.Content layout="fullscreen" showCloseButton={false}>
        <HeadingBar
          rightAction={
            <Modal.Close>
              <Button
                priority="tertiary"
                quiet
                icon="tinyClose"
                hideLabel
                size="small"
              >
                {formatMessage({ id: 'CLOSE' })}
              </Button>
            </Modal.Close>
          }
          leftAction={
            <Button
              priority="secondary"
              quiet
              icon="interfaceDownload"
              size="medium"
              onClick={() =>
                downloadEntityDataCSV({
                  filename: entityName ?? 'Untitled',
                  data: rows,
                  columnHeaders,
                })
              }
            >
              {formatMessage({ id: 'DOWNLOAD_AS_CSV' })}
            </Button>
          }
          title={
            <Modal.Title>
              <HeadingText level="3" size="md">
                {formatMessage({ id: 'VIEW_ALL_DATA' })}
              </HeadingText>
            </Modal.Title>
          }
        />
        <Box
          css={{
            padding: '$half',
            borderColor: '$onSurfaceBorderSubdued',
            borderWidth: 0,
            borderWidthTop: '$thin',
            borderStyle: 'solid',
            maxWidth: '95%',
            display: 'flex',
            justifyContent: 'stretch',
          }}
        >
          <Box
            as="table"
            css={{
              borderRadius: '$small',
              borderColor: '$onSurfaceBorderSubdued',
              borderWidth: '$thin',
              borderStyle: 'solid',
              borderSpacing: 0,
              width: '100%',
              maxWidth: '100%',
            }}
          >
            <Box as="thead">
              <Box as="tr" css={{ position: 'sticky', top: 0 }}>
                {columnHeaders.map(({ key, displayLabel }) => (
                  <TableHeaderCell key={key}>{displayLabel}</TableHeaderCell>
                ))}
              </Box>
            </Box>
            <Box as="tbody">
              {rows.length > 0 ? (
                rows.map((row) =>
                  isMultiEnvironment && isMultiEnvironmentRow(row) ? (
                    <Box
                      as="tr"
                      key={row.path}
                      className={
                        row.data !== row.otherData ? 'highlight-unequal' : ''
                      }
                      css={{
                        '&.highlight-unequal td': {
                          backgroundColor: '$highlightBackgroundSubdued',
                          fontWeight: 'bold',
                        },
                      }}
                    >
                      <DataCell>{row.path}</DataCell>
                      <DataCell>{row.data}</DataCell>
                      <DataCell>{row.otherData}</DataCell>
                    </Box>
                  ) : (
                    <Box as="tr" key={row.path}>
                      <DataCell>{row.path}</DataCell>
                      <DataCell>{row.data}</DataCell>
                    </Box>
                  ),
                )
              ) : (
                <Box as="tr">
                  <Box as="td" colSpan={2}>
                    <UtilityText emphasis="subtle">
                      {formatMessage({ id: 'NO_DATA' })}
                    </UtilityText>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Modal.Content>
    </Modal.Root>
  );
};
