import { set, cloneDeep } from 'lodash-es';
import type { ConfigEntity, Entity } from '../types/configuration';

/**
 * The config app backend uses a generic schema, stored in the configEntity.schema property. Domain-specific
 * attributes are stored in `attributes.domainEntityAttributes`, a generic object at the configEntity level.
 * However, each domain entity implements its own schema and we need to use that for form generation and validation of the config-entity
 * as a whole for any given domain entity.
 *
 * This function combines the two, inserting the domain entity's `attributes` schema into the config entity's schema
 * in the `domainEntityAttributes` slot.
 *
 * @param configEntity {ConfigEntity}
 * @param domainEntity {Entity}
 * @returns {@link ConfigEntity}
 */

export const getConfigEntityWithDomainEntitySchema = (
  configEntity: ConfigEntity,
  { schema: domainEntitySchema }: Entity,
) => {
  const copy = cloneDeep(configEntity);
  const configSchemaWithDomainSchemaAttributes = set(
    copy,
    'schema.properties.attributes.properties.domainEntityAttributes',
    domainEntitySchema.properties.attributes,
  );

  return configSchemaWithDomainSchemaAttributes;
};
