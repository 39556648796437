import { useCallback } from 'react';
import { EditorState } from '@web-config-app/core';
import { useSetEntityData } from '../use-set-entity-data/use-set-entity-data';

/**
 * Provides a function to set the editor state in the entity metadata.
 *
 * This hook uses the `useSetEntityData` hook to update the `editorState` property in the entity metadata.
 * It takes an `EditorState` object as input, stringifies it as JSON, and updates the entity data with the new editor state.
 *
 * @returns {function} A function that takes an `EditorState` object and updates the entity data with the new editor state.
 */
export const useUpdateEditorStateInEntityMetadata = () => {
  const setEntityData = useSetEntityData();

  const updateEditorStateInEntityMetadata = useCallback(
    (editorState: EditorState) => {
      setEntityData(
        JSON.stringify(editorState),
        'attributes.entityMetadata.editorState',
      );
    },
    [setEntityData],
  );
  return updateEditorStateInEntityMetadata;
};
