import React from 'react';
import { EntityListPageView } from '@web-config-app/core-react-ui';
import {
  useEntity,
  useConfigAppParams,
  EntityDetailsDataStoreProvider,
} from '@web-config-app/core-react';
import { EntityListPageContainer } from '@web-config-app/core-react-containers';

export function ConfigAppEntityListPage() {
  const { domainId, entityId, environmentKey } = useConfigAppParams();
  const entity = useEntity({ domainId, entityId });
  const hasResources = !!entity && !!domainId && !!entityId && !!environmentKey;
  return hasResources ? (
    <EntityDetailsDataStoreProvider>
      <EntityListPageContainer
        entityListPageContentsRenderer={EntityListPageView}
        entity={entity}
        domainId={domainId}
        entityId={entityId}
        environmentKey={environmentKey}
      />
    </EntityDetailsDataStoreProvider>
  ) : null;
}
