import { useContext } from 'react';
import { useStore } from 'zustand';
import type { RuleTemplatesEditorStateStore } from '@web-config-app/core';
import { RuleTemplatesEditorStateStoreContext } from '../../providers/rule-templates-editor-state-store-provider/rule-templates-editor-state-store-provider.component';

/**
 * Custom hook to access the Rule Templates Editor State data store. This hook should not be consumed
 * directly in components. Instead, import and use one of the hooks that are built on top
 * of this hook, for example `useRuleTemplatesEditorStateStore`
 *
 * @param selector - a function that selects the desired state from the store
 * @returns the selected state
 */

export function useRuleTemplatesEditorStateStore<T>(
  selector: (state: RuleTemplatesEditorStateStore) => T,
): T {
  const ruleTemplatesEditorStateStoreContext = useContext(
    RuleTemplatesEditorStateStoreContext,
  );
  if (!ruleTemplatesEditorStateStoreContext) {
    throw new Error(
      'useRuleTemplateEditorStateStore must be used within the RuleTemplatesEditorStateStore',
    );
  }
  /**
   * use Zustand's useStore to get the store's state and actions
   */
  return useStore(ruleTemplatesEditorStateStoreContext, selector);
}
