import React from 'react';
import {
  HeadingText,
  Modal,
  StackLayout,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { EntityForm } from '@web-config-app/entity-form';
import {
  AttachmentIconSvg,
  TextActionButtonIcon,
} from '@web-config-app/core-react-ui';
import { useIntl } from '@leagueplatform/locales';
import { AnnotatedJsonSchema } from '@web-config-app/core';
import {
  useEntityMetadata,
  useSetEntityData,
} from '@web-config-app/core-react';

const attachmentSchema: AnnotatedJsonSchema = {
  type: 'array',
  items: {
    type: 'string',
    'x-entity-asset': {
      type: 'attachment',
    },
    'x-entity-label': {
      key: 'ATTACHMENT',
    },
    'x-entity-presentation': {
      hideLabel: true,
    },
  },
  'x-entity-label': {
    key: 'ATTACHMENTS',
  },
  'x-entity-presentation': {
    hideLabel: true,
  },
};

export function AttachmentsModal() {
  const { formatMessage } = useIntl();
  const { attachments } = useEntityMetadata();

  // our Array Control requires an array of strings, but the entity data is an array of objects, so we need to convert the data
  const attachmentsAsArrayOfStrings = attachments?.map((item) => item.assetId);

  const [attachmentsData, setAttachmentsData] = React.useState<
    string[] | undefined
  >(attachmentsAsArrayOfStrings);

  const setEntityData = useSetEntityData();

  const transformDataForEntityMetadata = (formData: Array<string>) => {
    // then we convert the array of strings back to an array of objects so it's ready for the entity data
    const attachmentsAsArrayOfObjects = formData.map((item: string) => ({
      assetId: item,
    }));
    setAttachmentsData(formData);
    setEntityData(
      attachmentsAsArrayOfObjects,
      'attributes.entityMetadata.attachments',
    );
  };

  return (
    <Modal.Root>
      <Modal.Trigger>
        <TextActionButtonIcon
          icon={<AttachmentIconSvg />}
          label={formatMessage({ id: 'VIEW_ATTACHMENTS' })}
          size="$oneAndQuarter"
        />
      </Modal.Trigger>
      <Modal.Content
        width="925px"
        padding="$four"
        css={{
          '.GDS-modal-content': {
            paddingBlockStart: '$none',
            paddingBlockEnd: '$oneAndHalf',
          },
        }}
      >
        <StackLayout horizontalAlignment="stretch">
          <Modal.Title>
            <HeadingText size="xl" level="2" css={{ paddingBottom: '$one' }}>
              {formatMessage({ id: 'ATTACHMENTS' })}
            </HeadingText>
          </Modal.Title>
          <UtilityText
            as="p"
            emphasis="subtle"
            css={{ typography: '$caption' }}
          >
            {formatMessage({ id: 'ATTACHMENTS_MODAL_DESCRIPTION' })}
          </UtilityText>
          <EntityForm
            schema={attachmentSchema}
            data={attachmentsData}
            onDataChange={transformDataForEntityMetadata}
          />
        </StackLayout>
      </Modal.Content>
    </Modal.Root>
  );
}
