import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
} from '@tanstack/react-query';
import type {
  Entity,
  ConfigEntity,
  GetEndpointResponse,
  EnvironmentKey,
  EntityDetail,
  DeepCloneEndpointResponse,
} from '@web-config-app/core';
import { getEntityEndpoint } from '../../utilities/get-entity-endpoint/get-entity-endpoint.util';
import { useEnvironmentFetch } from '../use-environment-fetch/use-environment-fetch';
import { getEndpointParamsWithValues } from '../../utilities/get-endpoint-params-with-values/get-endpoint-params-with-values';
import { getIdParam } from '../../utilities/get-id-param/get-id-param';
import { getIncludeParam } from '../../utilities/get-include-param/get-include-param';
import { injectOperationEntityMetadataRequiredFields } from '../../utilities/inject-operation-entity-metadata-required-fields/inject-operation-entity-metadata-required-fields';
import { transposeIdToSourceId } from './transpose-id-to-source-id';
import {
  OnMutateWithOperation,
  OnSuccessWithEnvironment,
} from '../../types/operation.types';

export type DeepCloneRequestOptions = Pick<
  UseMutationOptions<DeepCloneEndpointResponse, Error, DeepCloneRequestBody>,
  'onError' | 'onSettled'
> & {
  onMutate?: OnMutateWithOperation;
  fetchFn?: (path: string, options: any) => Promise<Response>;
  onSuccess?: OnSuccessWithEnvironment;
};

export type UseEntityDeepCloneOptions = {
  fetchFn?: (path: string, options: any) => Promise<Response>;
  prefetch?: UseQueryOptions<GetEndpointResponse>;
  request?: DeepCloneRequestOptions;
};

export interface UseEntityDeepCloneProps {
  // TODO: update this to ConfigEntity after CACT BE launch
  // https://everlong.atlassian.net/browse/CACT-1920
  entity: Entity;
  instanceId: string | undefined;
  environment: EnvironmentKey;
  options?: UseEntityDeepCloneOptions;
}

export type UseEntityDeepCloneResult = ReturnType<typeof useEntityDeepClone>;

export interface DeepCloneRequestBody extends EntityDetail {
  data: EntityDetail;
  included: EntityDetail[];
}

const isEntityDetail = (data: any): data is EntityDetail =>
  typeof data === 'object' &&
  'id' in data &&
  'type' in data &&
  'attributes' in data;

export const useEntityDeepClone = ({
  entity,
  instanceId,
  environment,
  options,
}: UseEntityDeepCloneProps) => {
  const {
    endpoints: {
      deepClone: {
        prefetch: deepClonePrefetchOperation,
        ...deepCloneOperationConfig
      },
    },
    apiUrl,
    // TODO: remove below type assertion after CACT BE launch
    // https://everlong.atlassian.net/browse/CACT-1920
  } = entity as ConfigEntity;

  const currentEnvironmentFetch = useEnvironmentFetch(environment, apiUrl);

  const { endpointFetch: deepCloneGetFetch } =
    getEntityEndpoint<GetEndpointResponse>(
      deepClonePrefetchOperation,
      options?.fetchFn ?? currentEnvironmentFetch,
    );

  const { endpointFetch: deepCloneRequestFetch } =
    getEntityEndpoint<DeepCloneEndpointResponse>(
      deepCloneOperationConfig,
      options?.fetchFn ?? currentEnvironmentFetch,
    );

  const prefetchParams = getEndpointParamsWithValues([
    getIdParam(
      deepClonePrefetchOperation.pathParameters ?? [],
      deepClonePrefetchOperation.path,
      instanceId,
    ),
    getIncludeParam(
      deepClonePrefetchOperation.queryParameters ?? [],
      undefined,
      true,
      // I wish this could be schema-driven
      'allReferences',
    ),
  ]);

  const {
    data: deepCloneGetData,
    isError: isGetError,
    isSuccess: isGetSuccess,
    isFetching: isGetFetching,
  } = useQuery({
    queryKey: [deepClonePrefetchOperation.path, instanceId],
    queryFn: () => deepCloneGetFetch({ params: prefetchParams }),
    enabled: !!instanceId,
  });

  const {
    data: deepCloneResponse,
    mutate: deepCloneEntity,
    isError: isDeepCloneRequestError,
    isSuccess: isDeepCloneRequestSuccess,
    isPending: isDeepCloneRequestPending,
  } = useMutation({
    mutationFn: (deepCloneData: DeepCloneRequestBody) => {
      const dataWithRequiredMetadataFields =
        injectOperationEntityMetadataRequiredFields(
          deepCloneData?.data,
          deepCloneOperationConfig,
        );

      const requestData = isEntityDetail(dataWithRequiredMetadataFields)
        ? transposeIdToSourceId(dataWithRequiredMetadataFields)
        : undefined;

      return deepCloneRequestFetch({
        body: JSON.stringify({
          data: requestData,
          included: deepCloneData?.included?.map(transposeIdToSourceId) ?? [],
        }),
      });
    },
    onSettled: options?.request?.onSettled,
    onMutate: () => {
      options?.request?.onMutate?.('deepClone');
    },
    onSuccess: (responseData) => {
      options?.request?.onSuccess?.(responseData, 'staging');
    },
    onError: options?.request?.onError,
  });

  return {
    deepCloneGetData,
    deepCloneResponse,
    isGetError,
    isGetSuccess,
    isGetFetching,
    deepCloneEntity,
    isDeepCloneRequestError,
    isDeepCloneRequestSuccess,
    isDeepCloneRequestPending,
    enableAction:
      Boolean(instanceId) &&
      Boolean(entity.endpoints.deepClone) &&
      Boolean(entity.endpoints.create),
  };
};
