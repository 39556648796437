import * as React from 'react';
import type { OptionModal as OptionModalType } from '@web-config-app/core';
import { Button, Modal, StackLayout } from '@leagueplatform/genesis-core';
import {
  ConfigModalContent,
  ConfigModalDescription,
  ConfigModalTitle,
} from '../common-config-modal-attributes/common-config-modal-attributes';

export type OptionModalProps = Omit<OptionModalType, 'type'> & {
  modalTrigger?: React.ReactNode;
  open?: boolean;
  onOpenChange?: () => void;
};

/**
 * OptionModal component displays a modal with a title, description, a primary and secondary button. Promoting the user to choose between two options.
 *
 * @param {string} props.title - Title of the modal.
 * @param {string} props.description - Description within the modal.
 * @param {string} [props.primaryCtaText] - Text for the primary button.
 * @param {() => void} [props.primaryCtaAction] - Action to perform on the primary CTA button
 * @param {string} [props.secondaryCtaText] - Text for the secondary button.
 *  * @param {() => void} [props.secondaryCtaAction] - Action to perform on the secondary CTA button
 * @param {React.ReactNode} [props.modalTrigger] - Optional UI Trigger for opening the modal (e.g., a button).
 * @param {boolean} [props.open] - Optional prop to control the open state of the modal externally. If not provided, the component manages the state internally.
 * @param {() => void} [props.onOpenChange] - Optional callback function when the modal's open state changes. Must be provided if `open` prop is used for external state management.
 */

export const OptionModal: React.FC<OptionModalProps> = ({
  title,
  description,
  primaryCtaText,
  primaryCtaAction,
  secondaryCtaText,
  secondaryCtaAction,
  modalTrigger,
  open,
  onOpenChange,
}) => {
  // Internal state to manage modal open/close if 'open' prop is not provided
  const [modalOpen, setModalOpen] = React.useState(false);

  const handleOpenChange = React.useCallback(() => {
    if (onOpenChange) {
      onOpenChange();
    } else {
      setModalOpen((prevOpen) => !prevOpen);
    }
  }, [onOpenChange]);

  return (
    <Modal.Root open={open ?? modalOpen} onOpenChange={handleOpenChange}>
      <Modal.Trigger>{modalTrigger}</Modal.Trigger>
      <ConfigModalContent>
        <ConfigModalTitle title={title} />
        <ConfigModalDescription description={description} />
        <StackLayout
          orientation="horizontal"
          horizontalAlignment="center"
          spacing="$one"
        >
          {secondaryCtaText && (
            <Button
              onClick={() => {
                secondaryCtaAction?.();
                handleOpenChange();
              }}
              priority="secondary"
            >
              {secondaryCtaText}
            </Button>
          )}
          {primaryCtaText && (
            <Button
              onClick={() => {
                primaryCtaAction?.();
                handleOpenChange();
              }}
            >
              {primaryCtaText}
            </Button>
          )}
        </StackLayout>
      </ConfigModalContent>
    </Modal.Root>
  );
};
