import * as React from 'react';
import type { EntityCloneItem } from '@web-config-app/core';
import { useIntl } from '@leagueplatform/locales';
import { Box, HeadingText } from '@leagueplatform/genesis-core';
import { EntityCloneItemInput } from '../entity-clone-item-input/entity-clone-item-input.component';
import { EntityCloneChildItemsCollapsible } from '../entity-clone-child-items-collapsible/entity-clone-child-items-collapsible.component';

interface EntityCloneListProps {
  entity: EntityCloneItem;
  onNameChange: (id: string, name: string) => void;
}

/**
 * This component displays a list of entities that can be cloned by rendering an entity and its child entities recursively for cloning.
 *
 * It handles both rendering individual entity items and recursively rendering their child entities.
 *
 * @component
 * @param {EntityCloneListProps} props - The props for the EntityCloneList component.
 * @returns {JSX.Element} The rendered EntityCloneList component.
 */
export const EntityCloneList = ({
  entity,
  onNameChange,
}: EntityCloneListProps) => {
  const { formatMessage } = useIntl();
  return (
    <>
      {entity.type === 'root' ? (
        <HeadingText level="3" size="xs">
          {formatMessage(
            { id: 'THIS_ENTITY' },
            { entity: entity.domainEntityType },
          )}
        </HeadingText>
      ) : (
        entity.domainEntityType && (
          <HeadingText
            level="4"
            size="xs"
            css={{ marginTop: '$threeQuarters' }}
          >
            {entity.domainEntityType}
          </HeadingText>
        )
      )}
      <EntityCloneItemInput
        key={entity.id}
        {...entity}
        onNameChange={onNameChange}
      >
        {entity.childEntities && entity.childEntities.length > 0 && (
          <Box css={{ marginInlineStart: '$half', width: '100%' }}>
            <EntityCloneChildItemsCollapsible
              childAmount={entity.childEntities.length}
            >
              {entity.childEntities.map((childEntity) => (
                <EntityCloneList
                  key={childEntity.id}
                  entity={childEntity}
                  onNameChange={onNameChange}
                />
              ))}
            </EntityCloneChildItemsCollapsible>
          </Box>
        )}
      </EntityCloneItemInput>
    </>
  );
};
