import {
  useEntityCreate,
  useEntityReference,
} from '@web-config-app/core-react';
import { useEntityDetailsProps } from '../../../../hooks/use-entity-details-props/use-entity-details-props';

interface FormatAssetUploadDataArgs {
  title: string;
  description: string;
  file: File;
}

export const formatAssetUploadData = ({
  title,
  description,
  file,
}: FormatAssetUploadDataArgs) => {
  const formData = new FormData();

  const formattedData = JSON.stringify({
    type: 'config-asset',
    attributes: {
      title,
      description,
    },
  });
  formData.append('data', formattedData);
  formData.append('file', file);
  return formData;
};

export function useUploadAssetData() {
  const configAssetEntity = useEntityReference({ entityType: 'config-asset' });
  const { currentAuthoringEnvironment } = useEntityDetailsProps();
  return useEntityCreate({
    entity: configAssetEntity,
    operation: 'create',
    environment: currentAuthoringEnvironment,
  });
}
