import * as React from 'react';
import {
  StackLayout,
  InputLabel,
  GDSStatus,
  InputStatusMessage,
  Box,
  GDSInputStatus,
  StackItem,
} from '@leagueplatform/genesis-core';
import { EntityFormBanner } from '../entity-form-banner/entity-form-banner.component';
import { HintText } from '../hint-text/hint-text.component';
import { generateFormFieldAttributes } from './generate-form-field-ids.util';

export interface FormFieldWithTrailingActionProps {
  id: string; // must be same as input id
  label: string;
  children: React.ReactNode;
  action?: React.ReactNode;
  hint?: string;
  required?: boolean;
  statusMessage?: string;
  inputStatus?: GDSInputStatus;
  hideLabel?: boolean;
  banner?: {
    title?: React.ReactNode;
    status?: GDSStatus;
    description?: React.ReactNode;
  };
}

/**
 * A form field component with a trailing action (e.g., a button) and support for hints, status messages, and banners.  The children of this component *must* be contain a single input component (Not more than one), and must be provided with the `id` prop matching this component's `id`, and the `aria-describedby` attribute provided by the `generateFormFieldAttributes` utility.
 *
 * **How to use this component:**
 *
 * 1.  Pass the desired input component as a child of this component.
 * 2.  Ensure the input component accepts and uses the following props:
 *     *   `id`:  The `id` prop (this will be the same as the `FormFieldTrailingAction`'s `id` prop). This is crucial for accessibility and connecting the label to the input.
 *     *   `aria-describedby`: The `aria-describedby` attribute.  This is used to associate the hint and status message with the input.
 *
 *
 * **Example:**
 * ```tsx
 *
 *   const { ariaDescribedBy } = generateFormFieldAttributes("my-input", hint, statusMessage);
 *
 * <FormFieldTrailingAction id="my-input" label="My Input" action={<button>Action</button> hint={hint} statusMessage={statusMessage} }>
 *   <TextInput
 *      id="my-input"
 *      aria-describedby={ariaDescribedBy}
 *   />
 * </FormFieldTrailingAction>
 * ```
 *
 * @param {FormFieldWithTrailingActionProps} props - The props for the component.
 * @returns {JSX.Element} The rendered form field.
 */

export const FormFieldWithTrailingAction = ({
  id,
  label,
  children,
  banner,
  hint,
  statusMessage,
  inputStatus,
  action,
  hideLabel,
  required = true,
}: FormFieldWithTrailingActionProps) => {
  const { statusMessageId, hintId } = generateFormFieldAttributes(
    id,
    hint,
    statusMessage,
  );

  const showBanner = banner?.title || banner?.description;

  return (
    <>
      {showBanner && (
        <EntityFormBanner
          status={banner.status}
          title={banner.title}
          description={banner.description}
          css={{
            marginBottom: '$half',
          }}
        />
      )}
      <StackLayout css={{ flexDirection: 'column-reverse', width: '100%' }}>
        <Box css={{ width: '100%' }}>
          {children}
          {statusMessage && (
            <InputStatusMessage
              data-testid="status-message"
              inputStatus={inputStatus || 'error'}
              id={statusMessageId}
            >
              {statusMessage}
            </InputStatusMessage>
          )}
        </Box>
        <StackLayout
          horizontalAlignment="spaceBetween"
          orientation="horizontal"
          css={{ width: '100%' }}
        >
          <StackItem css={{ paddingBottom: '$half' }}>
            <InputLabel htmlFor={id} required={required} hideLabel={hideLabel}>
              {label}
            </InputLabel>
            {hint && <HintText id={hintId}>{hint}</HintText>}
          </StackItem>
          {action}
        </StackLayout>
      </StackLayout>
    </>
  );
};
