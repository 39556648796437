import { useCallback, useEffect } from 'react';
import { validateData } from '@web-config-app/validation';
import { isUsingConfigAppBackend } from '@web-config-app/common';
import { ConfigErrorType } from '@web-config-app/core';
import {
  useConfigUserFeedback,
  useConfigApp,
} from '@web-config-app/core-react';
import { useEntityDetailsProps } from '../use-entity-details-props/use-entity-details-props';
import { useFormatFormErrors } from '../use-format-form-errors/use-format-form-errors.hook';

interface ValidateAndSetErrorsProps {
  // array of error keywords to remove errors with a particular keyword from validation
  filteredErrorKeywords?: string[];
}

/**
 * useEntityFormError manages form validation and error handling for the entire entity form (root data and root schema) via the configUserFeedbackStore
 *
 * This hook provides the `validateEntityData` function to validate the form data against the schema, and to set the filteredErrorKeywords - handling the display of validation errors using the `useConfigUserFeedback` hook.
 */
export const useEntityFormError = () => {
  const {
    setShowFormValidation,
    additionalValidationErrors,
    setFilteredErrorKeywords,
    filteredErrorKeywords,
    setConfigError,
    showFormValidation,
    setShowErrorBanner,
    resetConfigUserFeedbackStore,
  } = useConfigUserFeedback((state) => state);

  const { isUsingConfigAppBackend: isTenantUsingConfigAppBackend } =
    useConfigApp();
  const formatConfigError = useFormatFormErrors();

  const { rootSchema, entityRootData, configEntitySchema, entity } =
    useEntityDetailsProps();

  /**
   * Validates the form data and sets the configError in the configUserFeedbackStore
   * If there are no errors, it unsubscribes from error updates (showFormValidation is false), resets the config error,
   *
   * @param {string[]} [params.filteredErrorKeywords] - An optional array of error keywords to exclude from validation.
   *   If provided, only errors whose `keyword` property is not included in this array will be considered.
   *
   * @returns {boolean} `true` if the validation is successful (no errors), `false` otherwise.
   */
  const validateEntityData = useCallback(
    ({
      filteredErrorKeywords: newFilteredErrorKeywords,
    }: ValidateAndSetErrorsProps = {}): boolean => {
      if (newFilteredErrorKeywords) {
        // we only want to setFilteredErrorKeywords if they are passed on initial validation check - on re-validation checks they should not be reset
        setFilteredErrorKeywords(newFilteredErrorKeywords);
      }

      // Validate the data against the schema and get the potential errors
      const { formErrors } = validateData({
        schema: isUsingConfigAppBackend({
          tenantLevelOverride: isTenantUsingConfigAppBackend,
          entityLevelOverride: entity?.isUsingConfigAppBackend,
        })
          ? configEntitySchema
          : rootSchema,
        data: entityRootData,
        // if newFilterOutKeywords are passed used these, if they are not passed it is a re-validation check so we should use the filteredErrorKeywords stored in state
        filteredErrorKeywords:
          newFilteredErrorKeywords || filteredErrorKeywords,
      });

      const additionalErrorsFlattened = Object.values(
        additionalValidationErrors,
      ).flat();

      if (!formErrors.length && !additionalErrorsFlattened.length) {
        resetConfigUserFeedbackStore();
        return true;
      }

      const errors = formatConfigError(formErrors);

      const error = {
        type: ConfigErrorType.FormValidationError,
        errors: [...errors, ...additionalErrorsFlattened],
      };

      setConfigError(error);
      setShowErrorBanner(true);
      setShowFormValidation(true);

      return false;
    },
    // TODO:: configEntitySchema being in dependency array is causing infinite loop - to fix this
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      additionalValidationErrors,
      rootSchema,
      entityRootData,
      filteredErrorKeywords,
      formatConfigError,
      setConfigError,
      setShowErrorBanner,
      setShowFormValidation,
      setFilteredErrorKeywords,
      resetConfigUserFeedbackStore,
    ],
  );

  /**
   * If showFormValidation is true, continuously re-validate the form and update the config errors without overriding the filterErrorKeywords to provide live feedback to the user on entityRootData change.
   * once the formErrors are cleared, showFormValidation is set to false
   */

  useEffect(() => {
    if (showFormValidation) {
      validateEntityData();
    }
  }, [entityRootData, validateEntityData, showFormValidation]);

  return {
    validateEntityData,
  };
};
