import * as React from 'react';
import {
  Box,
  HeadingText,
  StackItem,
  StackLayout,
} from '@leagueplatform/genesis-core';
import type { Nullable, EnvironmentKey } from '@web-config-app/core';
import type { AvailableEnvironment } from '@web-config-app/core-react';
import { EnvironmentSelect } from '../environment-select/environment-select.component';

type EntityListPageLayoutProps = {
  entityName: string;
  actions: React.ReactNode | JSX.Element;
  availableEnvironments: Nullable<AvailableEnvironment[]>;
  currentAuthoringEnvironment: EnvironmentKey;
  multiEnvironmentAuthoringEnabled?: boolean;
  isEntitySingleEnv?: boolean;
  statusBanners?: React.ReactNode | JSX.Element;
};

export const EntityListPageLayout: React.FC<
  React.PropsWithChildren<EntityListPageLayoutProps>
> = ({
  entityName,
  actions,
  currentAuthoringEnvironment,
  availableEnvironments,
  multiEnvironmentAuthoringEnabled,
  statusBanners,
  children,
  isEntitySingleEnv,
}) => (
  <StackLayout
    orientation="vertical"
    horizontalAlignment="stretch"
    css={{
      padding: '$twoAndHalf',
    }}
  >
    <Box aria-live="polite">
      {statusBanners && (
        <Box css={{ maxHeight: '14rem', overflowY: 'auto' }}>
          {statusBanners}
        </Box>
      )}
    </Box>
    <StackLayout
      orientation="horizontal"
      horizontalAlignment="spaceBetween"
      css={{ paddingY: '$threeQuarters', flexGrow: 0, gap: '$threeQuarters' }}
    >
      <HeadingText level="1" size="xl" css={{ flexGrow: 1 }}>
        {entityName}
      </HeadingText>

      {multiEnvironmentAuthoringEnabled &&
        Array.isArray(availableEnvironments) && (
          <StackItem css={{ flexGrow: 0, marginBlockEnd: '$threeQuarters' }}>
            <EnvironmentSelect
              entityName={entityName}
              currentAuthoringEnvironment={currentAuthoringEnvironment}
              availableEnvironments={availableEnvironments}
              disabled={isEntitySingleEnv}
            />
          </StackItem>
        )}

      {actions && (
        <StackItem css={{ flexDirection: 'row', gap: '$threeQuarters' }}>
          {actions}
        </StackItem>
      )}
    </StackLayout>
    <Box css={{ marginY: '$oneAndHalf', flexGrow: 1 }}>{children}</Box>
  </StackLayout>
);
