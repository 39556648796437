import React, { FC } from 'react';
import {
  Box,
  Button,
  Icon,
  StackItem,
  StackLayout,
  UtilityText,
} from '@leagueplatform/genesis-core';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { MenuItem, SelectActionMenuProps } from '../../types/components.types';
import { createMenuItems } from './create-menu-items/create-menu-items';
import { ConfirmationModal } from '../confirmation-modal/confirmation-modal.component';
import {
  dropdownMenuContentStyles,
  dropdownMenuTriggerStyles,
} from './select-action-menu.styles';
import { SelectActionMenuItem } from './select-action-menu-item/select-action-menu-item.component';

/**
 * SelectActionMenu is built on top of the `DropdownMenu` from `@radix-ui/react-dropdown-menu`. It follows renders a dropdown menu with grouped action items, following the [Menu Button](https://www.w3.org/WAI/ARIA/apg/patterns/menu-button/) and [Menu](https://www.w3.org/WAI/ARIA/apg/patterns/menubar/) semantics and keyboard controls.
 * **Note** that the `menu` and `menuitem` roles used for this component are specifically meant to handle a **"grouping of common actions  or functions that the user can invoke"** ([via MDN](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/menu_role)).
 * * Think of `ActionMenu` as an options popup menu on a section or element within the interface, this is not a replacement for a native <select> form element.
 *
 * This component provides a user-friendly and consistent way to present a list of selectable options with optional groupings that render a semantic separator nad visual break between grouping.
 *
 * @param {React.ReactNode} props.triggerLabel - The label or element that triggers the menu dropdown. It will render as the menuLabel semantically if a `menuLabel` is not provided.
 * @param {string} props.precedingLabel - An optional preceding label for the UI, this is hidden from screen readers as it is not semantically linked to the menu.
 * @param {SelectMenuItemGroup[]} props.menuGroups - An array of menu item groups, each containing an array of `MenuItem` objects.
 * @param {string} [props.menuLabel] - An optional semantic label for the menu, if not provided the text of the menuLabel will be used.
 *   Each `MenuItem` can have an optional `confirmationModal` property. If this property is set, a confirmation modal will be shown
 *   before the item's action is triggered. The `confirmationModal` property should be an object with the following properties:
 *     - `title`: The title of the confirmation modal.
 *     - `description`: The description to be displayed in the modal.
 *     - `confirmCta`: The text for the confirm button in the modal.
 */

export const SelectActionMenu: FC<SelectActionMenuProps> = ({
  triggerLabel,
  menuGroups,
  precedingLabel,
  menuLabel,
  disabled,
}) => {
  const [openItemModal, setOpenItemModal] = React.useState<MenuItem | null>(
    null,
  );
  // used to manage the radix dropdown open state to avoid conflicts with the modal actions- this way we can manage the close of the dropdown before the modal trigger is opened
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const handleModalOpenChange = (item: MenuItem | null) => {
    setOpenItemModal((prevItem) => {
      if (prevItem !== null) {
        return null;
      }
      return item;
    });
  };

  const handleCloseModal = () => {
    setOpenItemModal(null);
  };

  const menuItems = createMenuItems({
    menuGroups,
  });

  return (
    <>
      <DropdownMenu.Root open={dropdownOpen} onOpenChange={setDropdownOpen}>
        <StackLayout orientation="horizontal" verticalAlignment="center">
          {precedingLabel && (
            <UtilityText css={{ typography: '$label' }} aria-hidden>
              {precedingLabel}
            </UtilityText>
          )}
          <DropdownMenu.Trigger asChild>
            <Button
              priority="tertiary"
              size="medium"
              aria-label={menuLabel}
              disabled={disabled}
              css={dropdownMenuTriggerStyles}
            >
              <StackItem
                css={{
                  flexDirection: 'row',
                  typography: '$bodyTwo',
                  color: '$onSurfaceTextPrimary',
                }}
              >
                {triggerLabel}
                <Icon
                  icon="interfaceChevronDown"
                  css={{ marginLeft: '$half' }}
                  size="$oneAndQuarter"
                  tint="$interactiveActionSubdued"
                />
              </StackItem>
            </Button>
          </DropdownMenu.Trigger>
        </StackLayout>
        <Box as={DropdownMenu.Content} css={dropdownMenuContentStyles}>
          {menuItems.map((item) => (
            <SelectActionMenuItem
              key={`menu-item-${item.id}`}
              item={item}
              setDropdownOpen={setDropdownOpen}
              handleModalOpenChange={handleModalOpenChange}
            />
          ))}
        </Box>
      </DropdownMenu.Root>
      {openItemModal && openItemModal.confirmationModal && (
        <ConfirmationModal
          open={Boolean(openItemModal)}
          onOpenChange={handleCloseModal}
          title={openItemModal?.confirmationModal?.title}
          description={openItemModal?.confirmationModal?.description}
          confirmCtaAction={() => {
            openItemModal?.action?.();
            handleCloseModal();
          }}
          confirmCtaText={openItemModal?.confirmationModal?.confirmCta}
        />
      )}
    </>
  );
};
